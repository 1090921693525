import React, { useRef, useCallback, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import styled, { useTheme, ThemeProvider } from "styled-components";
import {
  CanvasElement,
  CanvasElementType,
  ElementContent,
  Orientation,
  TextContent,
  LinkContent,
  SocialButtonsContent,
  ImageContent,
  GroupContent,
  AvatarContent,
  ButtonSize,
} from "../../../types";
import { getElementIcon } from "../../../../../utilities/canvasElementUtils";
import {
  handleToolbarDragStart,
  handleCanvasDrop,
} from "../../../../../utilities/canvasDragDropUtils";
import { useCleeckyKitCurrentTheme } from "CleeckyKit/themes";

interface ElementToolbarProps {
  elements: CanvasElement[];
  onElementsChange: (elements: CanvasElement[]) => void;
  setIsDragging: (isDragging: boolean) => void;
  disableScroll: () => void;
  elementsLimit?: number;
}

const ToolbarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors?.background?.card};
  padding: 16px;
  border-radius: 1rem;
  margin: 0 8px 16px 8px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows?.card?.elevated};
`;

const ToolbarContent = styled.div<{
  $hasScrollLeft: boolean;
  $hasScrollRight: boolean;
}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 24px;
      pointer-events: none;
      z-index: 1;
      opacity: 0.8;
    }

    &::before {
      left: 0;
      background: ${({ $hasScrollLeft, theme }) =>
        $hasScrollLeft
          ? `linear-gradient(to right, ${theme.colors.background.card}, transparent)`
          : "transparent"};
    }

    &::after {
      right: 0;
      background: ${({ $hasScrollRight, theme }) =>
        $hasScrollRight
          ? `linear-gradient(to left, ${theme.colors.background.card}, transparent)`
          : "transparent"};
    }
  }
`;

const ElementsContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: hidden;
  padding: 4px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  flex: 1;
  position: relative;

  @media (max-width: 768px) {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 4px 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    .toolbar-element {
      scroll-snap-align: start;
      flex: 0 0 auto;
      min-width: auto;
    }
  }
`;

const ToolbarNavigationButton = styled.button<{ $direction: "left" | "right" }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.input};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  opacity: 0.8;
  transition: all 0.2s ease;
  z-index: 2;

  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.colors.background.hover};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  svg {
    width: 14px;
    height: 14px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ $direction }) => ($direction === "left" ? "left: 0;" : "right: 0;")}
    background: ${({ theme }) => `${theme.colors.background.card}dd`};
    backdrop-filter: blur(4px);
    border: none;
    box-shadow: ${({ theme }) => theme.shadows.card.default};
  }
`;

const ToolbarElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: grab;
  background: ${({ theme }) => theme.colors.background.input};
  border-radius: 4px;
  user-select: none;
  touch-action: none;
  white-space: nowrap;
  flex-shrink: 0;
  transition: transform 0.2s ease, opacity 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
  }

  &:active {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
    cursor: grabbing;
    transform: scale(0.95);
    opacity: 0.8;
  }

  &.dragging {
    transform: scale(0.95);
    opacity: 0.5;
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  @media (max-width: 768px) {
    padding: 8px 10px;
    gap: 6px;

    span {
      font-size: 13px;
    }
  }
`;

export const ElementToolbar: React.FC<ElementToolbarProps> = ({
  elements,
  onElementsChange,
  setIsDragging,
  disableScroll,
  elementsLimit,
}) => {
  const { t } = useTranslation();
  const { currentTheme } = useCleeckyKitCurrentTheme();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [hasScrollLeft, setHasScrollLeft] = useState(false);
  const [hasScrollRight, setHasScrollRight] = useState(false);
  const [dropTargetId, setDropTargetId] = useState<string | null>(null);
  const [dropPosition, setDropPosition] = useState<"before" | "after" | null>(
    null
  );

  const checkToolbarScroll = useCallback(() => {
    if (toolbarRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = toolbarRef.current;
      setHasScrollLeft(scrollLeft > 0);
      setHasScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    checkToolbarScroll();
    window.addEventListener("resize", checkToolbarScroll);
    return () => {
      window.removeEventListener("resize", checkToolbarScroll);
    };
  }, [checkToolbarScroll]);

  const handleScroll = (direction: "left" | "right") => {
    if (toolbarRef.current) {
      const toolbarElement = toolbarRef.current;
      const isMobile = window.innerWidth <= 768;
      const containerWidth = toolbarElement.clientWidth;
      const currentScrollLeft = toolbarElement.scrollLeft;
      const maxScroll = toolbarElement.scrollWidth - containerWidth;

      if (isMobile) {
        // Em dispositivos móveis, scroll baseado na viewport
        const viewportWidth = containerWidth * 0.8; // 80% da largura visível
        const newScrollLeft =
          direction === "left"
            ? Math.max(0, currentScrollLeft - viewportWidth)
            : Math.min(maxScroll, currentScrollLeft + viewportWidth);

        toolbarElement.scrollTo({
          left: newScrollLeft,
          behavior: "smooth",
        });
      } else {
        // Em desktop mantém o scroll de 200px
        const scrollAmount = 200;
        const newScrollLeft = Math.min(
          maxScroll,
          Math.max(
            0,
            currentScrollLeft +
              (direction === "left" ? -scrollAmount : scrollAmount)
          )
        );

        toolbarElement.scrollTo({
          left: newScrollLeft,
          behavior: "smooth",
        });
      }

      // Atualiza os estados de scroll após a animação
      setTimeout(() => {
        const hasLeft = toolbarElement.scrollLeft > 0;
        const hasRight =
          toolbarElement.scrollLeft <
          toolbarElement.scrollWidth - containerWidth - 1;
        setHasScrollLeft(hasLeft);
        setHasScrollRight(hasRight);
      }, 300);
    }
  };

  const availableElements: CanvasElementType[] = [
    "AVATAR",
    "NAME",
    "USERNAME",
    "LINK",
    "SOCIAL_BUTTONS",
    "TEXT",
    "TITLE",
    "IMAGE",
    //"GROUP",
  ];

  const handleToolbarTouchStart = (
    e: React.TouchEvent,
    type: CanvasElementType
  ) => {
    const touch = e.touches[0];
    const target = e.currentTarget;
    const rect = target.getBoundingClientRect();

    setIsDragging(true);
    disableScroll();

    // Criar elemento fantasma para o touch drag
    const ghost = target.cloneNode(true) as HTMLElement;
    ghost.classList.add("dragging-element");
    ghost.style.position = "fixed";
    ghost.style.left = `${touch.clientX - rect.width / 2}px`;
    ghost.style.top = `${touch.clientY - rect.height / 2}px`;
    ghost.style.width = `${rect.width}px`;
    ghost.style.pointerEvents = "none";
    ghost.style.zIndex = "9999";
    ghost.style.opacity = "0.8";
    ghost.style.transform = "scale(1.05)";
    document.body.appendChild(ghost);

    // Armazenar informações do elemento sendo arrastado
    (window as any).dragData = {
      elementType: type,
      isNewElement: true,
      ghost,
      offsetX: touch.clientX - rect.left,
      offsetY: touch.clientY - rect.top,
    };
  };

  const handleToolbarTouchMove = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    const dragData = (window as any).dragData;

    if (dragData?.ghost) {
      dragData.ghost.style.left = `${touch.clientX - dragData.offsetX}px`;
      dragData.ghost.style.top = `${touch.clientY - dragData.offsetY}px`;
    }
  };

  const handleToolbarTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
    const dragData = (window as any).dragData;
    const touch = e.changedTouches[0];

    // Verifica se já atingiu o limite de elementos
    if (elementsLimit !== undefined && elements.length >= elementsLimit) {
      if (dragData?.ghost) {
        dragData.ghost.remove();
      }
      delete (window as any).dragData;
      setIsDragging(false);
      disableScroll();
      return;
    }

    // Limpa o elemento fantasma
    if (dragData?.ghost) {
      dragData.ghost.remove();
    }

    // Encontra o elemento alvo sob o toque
    const elementUnderTouch = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );

    if (elementUnderTouch) {
      const dropZone = elementUnderTouch.closest(".drop-zone") as HTMLElement;
      if (dropZone) {
        // Criar um novo elemento do tipo arrastado com o conteúdo apropriado
        const newElement: CanvasElement = {
          id: `${dragData.elementType.toLowerCase()}_${Date.now()}`,
          type: dragData.elementType,
          content: createInitialContent(dragData.elementType, elements.length),
          order: elements.length,
          show: true,
        };

        // Adicionar o novo elemento à lista
        onElementsChange([...elements, newElement]);
      }
    }

    delete (window as any).dragData;
    setIsDragging(false);
    disableScroll();
  };

  // Função auxiliar para criar o conteúdo inicial baseado no tipo
  const createInitialContent = (
    type: CanvasElementType,
    order: number
  ): ElementContent => {
    const baseContent = {
      order,
      show: true,
    };

    switch (type) {
      case "TEXT":
      case "TITLE":
      case "NAME":
      case "USERNAME":
        return {
          ...baseContent,
          text: "",
          size: type === "TITLE" ? "h1" : undefined,
          alignment: "CENTER" as const,
          justify: "CENTER" as const,
        };
      case "LINK":
        return {
          ...baseContent,
          label: "",
          url: "",
          size: "medium" as ButtonSize,
          borderRadius: "8px",
        };
      case "SOCIAL_BUTTONS":
        return {
          ...baseContent,
          buttons: [],
          orientation: "HORIZONTAL" as Orientation,
        };
      case "IMAGE":
        return {
          ...baseContent,
          file: {
            path: "",
            type: "FILE" as const,
          },
          title: "",
          textPosition: "BOTTOM" as const,
        };
      case "AVATAR":
        return {
          ...baseContent,
          file: {
            path: "",
            type: "FILE" as const,
          },
          borderRadius: "50%",
        };
      case "GROUP":
        return {
          ...baseContent,
          items: [],
        };
      default:
        throw new Error(`Tipo de elemento não suportado: ${type}`);
    }
  };

  return (
    <ToolbarWrapper className="toolbar-wrapper">
      <ToolbarContent
        $hasScrollLeft={hasScrollLeft}
        $hasScrollRight={hasScrollRight}>
        <ToolbarNavigationButton
          $direction="left"
          onClick={() => handleScroll("left")}
          disabled={!hasScrollLeft}
          title={t(
            "cleeckykit:common.interface.elements.canvas.toolbar.previous"
          )}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ToolbarNavigationButton>
        <ElementsContainer ref={toolbarRef} onScroll={checkToolbarScroll}>
          {availableElements.map((type) => (
            <ToolbarElement
              key={type}
              className="toolbar-element"
              draggable
              onDragStart={(e) =>
                handleToolbarDragStart(
                  e,
                  type,
                  elements,
                  setIsDragging,
                  disableScroll
                )
              }
              onTouchStart={(e) => handleToolbarTouchStart(e, type)}
              onTouchMove={handleToolbarTouchMove}
              onTouchEnd={handleToolbarTouchEnd}
              style={{
                opacity:
                  elementsLimit !== undefined &&
                  elements.length >= elementsLimit
                    ? 0.5
                    : 1,
                pointerEvents:
                  elementsLimit !== undefined &&
                  elements.length >= elementsLimit
                    ? "none"
                    : "auto",
              }}>
              <FontAwesomeIcon icon={getElementIcon(type)} />
              <span>
                {t(
                  `cleeckykit:common.interface.elements.canvas.elements.${type.toLowerCase()}.label`
                )}
              </span>
            </ToolbarElement>
          ))}
        </ElementsContainer>
        <ToolbarNavigationButton
          $direction="right"
          onClick={() => handleScroll("right")}
          disabled={!hasScrollRight}
          title={t("cleeckykit:common.interface.elements.canvas.toolbar.next")}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ToolbarNavigationButton>
      </ToolbarContent>
    </ToolbarWrapper>
  );
};

ElementToolbar.displayName = "ElementToolbar";

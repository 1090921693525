import React from "react";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";
import {
  GlobalSettingsContent,
  SettingsSection,
  SettingsSectionTitle,
  ColorPickerWrapper,
  ColorPickerLabel,
  SizeInputWrapper,
  SizeInputLabel,
} from "../../../styles";
import { GlobalColors, GlobalSizes } from "../../../types";

interface GlobalSettingsProps {
  globalColors: GlobalColors;
  globalSizes: GlobalSizes;
  onGlobalColorsChange: (colors: GlobalColors) => void;
  onGlobalSizesChange: (sizes: GlobalSizes) => void;
}

export const GlobalSettings: React.FC<GlobalSettingsProps> = ({
  globalColors,
  globalSizes,
  onGlobalColorsChange,
  onGlobalSizesChange,
}) => {
  const { t } = useTranslation();
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();

  const handleColorChange = (key: keyof GlobalColors) => (color: string) => {
    onGlobalColorsChange({
      ...globalColors,
      [key]: color,
    });
  };

  const handleSizeChange =
    (key: keyof GlobalSizes) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onGlobalSizesChange({
        ...globalSizes,
        [key]: e.target.value,
      });
    };

  return (
    <GlobalSettingsContent>
      <SettingsSection>
        <SettingsSectionTitle>
          {t(
            "cleeckykit:common.interface.elements.canvas.global_settings.colors.title"
          )}
        </SettingsSectionTitle>

        <ColorPickerWrapper>
          <CleeckyKit.ColorPicker
            disableOpacity
            value={globalColors.textColor}
            onChange={(color) => handleColorChange("textColor")(color)}
          />
          <ColorPickerLabel>
            {t(
              "cleeckykit:common.interface.elements.canvas.global_settings.colors.text"
            )}
          </ColorPickerLabel>
        </ColorPickerWrapper>

        <ColorPickerWrapper>
          <CleeckyKit.ColorPicker
            disableOpacity
            value={globalColors.highlightColor}
            onChange={(color) => handleColorChange("highlightColor")(color)}
          />
          <ColorPickerLabel>
            {t(
              "cleeckykit:common.interface.elements.canvas.global_settings.colors.highlight"
            )}
          </ColorPickerLabel>
        </ColorPickerWrapper>

        <ColorPickerWrapper>
          <CleeckyKit.ColorPicker
            disableOpacity
            value={globalColors.borderColor}
            onChange={(color) => handleColorChange("borderColor")(color)}
          />
          <ColorPickerLabel>
            {t(
              "cleeckykit:common.interface.elements.canvas.global_settings.colors.border"
            )}
          </ColorPickerLabel>
        </ColorPickerWrapper>

        <ColorPickerWrapper>
          <CleeckyKit.ColorPicker
            disableOpacity
            value={globalColors.pageBackgroundColor}
            onChange={(color) =>
              handleColorChange("pageBackgroundColor")(color)
            }
          />
          <ColorPickerLabel>
            {t(
              "cleeckykit:common.interface.elements.canvas.global_settings.colors.page_background"
            )}
          </ColorPickerLabel>
        </ColorPickerWrapper>

        <ColorPickerWrapper>
          <CleeckyKit.ColorPicker
            disableOpacity
            value={globalColors.pageContentBackgroundColor}
            onChange={(color) =>
              handleColorChange("pageContentBackgroundColor")(color)
            }
          />
          <ColorPickerLabel>
            {t(
              "cleeckykit:common.interface.elements.canvas.global_settings.colors.content_background"
            )}
          </ColorPickerLabel>
        </ColorPickerWrapper>

        <ColorPickerWrapper>
          <CleeckyKit.ColorPicker
            disableOpacity
            value={globalColors.buttonsBackgroundColor}
            onChange={(color) =>
              handleColorChange("buttonsBackgroundColor")(color)
            }
          />
          <ColorPickerLabel>
            {t(
              "cleeckykit:common.interface.elements.canvas.global_settings.colors.buttons_background"
            )}
          </ColorPickerLabel>
        </ColorPickerWrapper>

        <ColorPickerWrapper>
          <CleeckyKit.ColorPicker
            disableOpacity
            value={globalColors.buttonsLabelColor}
            onChange={(color) => handleColorChange("buttonsLabelColor")(color)}
          />
          <ColorPickerLabel>
            {t(
              "cleeckykit:common.interface.elements.canvas.global_settings.colors.buttons_label"
            )}
          </ColorPickerLabel>
        </ColorPickerWrapper>
      </SettingsSection>

      <SettingsSection>
        <SettingsSectionTitle>
          {t(
            "cleeckykit:common.interface.elements.canvas.global_settings.sizes.title"
          )}
        </SettingsSectionTitle>

        <CleeckyKit.Form
          onSubmit={() => {}}
          defaultValues={{
            borderWidth: globalSizes.borderWidth,
            borderRadius: globalSizes.borderRadius,
          }}>
          <SizeInputWrapper>
            <CleeckyKit.NumberInput
              name="borderWidth"
              format="decimal"
              decimalPlaces={0}
              allowNegative={false}
              value={globalSizes.borderWidth}
              onChange={(e) => handleSizeChange("borderWidth")(e)}
              suffix="px"
            />
            <SizeInputLabel>
              {t(
                "cleeckykit:common.interface.elements.canvas.global_settings.sizes.border_width"
              )}
            </SizeInputLabel>
          </SizeInputWrapper>

          <SizeInputWrapper>
            <CleeckyKit.NumberInput
              name="borderRadius"
              format="decimal"
              decimalPlaces={0}
              allowNegative={false}
              value={globalSizes.borderRadius}
              onChange={(e) => handleSizeChange("borderRadius")(e)}
              suffix="px"
            />
            <SizeInputLabel>
              {t(
                "cleeckykit:common.interface.elements.canvas.global_settings.sizes.border_radius"
              )}
            </SizeInputLabel>
          </SizeInputWrapper>
        </CleeckyKit.Form>
      </SettingsSection>
    </GlobalSettingsContent>
  );
};

GlobalSettings.displayName = "GlobalSettings";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { userService } from "../../services/userService";
import { authService } from "../../services/authService";
import { paymentService } from "../../services/paymentService";
import {
  UserSettingsData,
  NotificationSettings,
  StripeSubscription,
} from "../../@types/interfaces";
import * as CleeckyKit from "CleeckyKit";
import { InterfaceColorPicker, HighlightColorPicker } from "CleeckyKit/themes";
import { LanguageDropdown } from "CleeckyKit";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { ptBR } from "CleeckyKit/configs/i18n/locales";
const NotificationHeader = styled.div`
  margin-bottom: 1rem;
`;

const NotificationTitle = styled.h3`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0.5rem;
`;

const NotificationDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.9rem;
`;

const ChannelsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const UserSettings: React.FC = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<UserSettingsData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<StripeSubscription | null>(null);
  const [alert, setAlert] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();
  const [isRequestingToken, setIsRequestingToken] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [subscriptions, setSubscriptions] = useState<StripeSubscription[]>([]);

  useEffect(() => {
    loadSettings();
    loadSubscriptions();
  }, []);

  const loadSettings = async () => {
    try {
      setLoading(true);
      const data = await userService.getUserSettings();
      setSettings(data);
    } catch (error) {
      console.error("Erro ao carregar configurações:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadSubscriptions = async () => {
    try {
      const data = await paymentService.getUserSubscriptions();
      setSubscriptions(data);
    } catch (error) {
      console.error("Erro ao carregar assinaturas:", error);
    }
  };

  const handleUpdateSettings = async (data: {
    category: string;
    preferences: any;
  }) => {
    try {
      await userService.updateUserSettings(data);
      await loadSettings(); // Recarrega as configurações após atualização
    } catch (error) {
      console.error("Erro ao atualizar configurações:", error);
    }
  };

  const handleToggleNotification = (
    type: keyof NotificationSettings,
    enabled: boolean
  ) => {
    setLoading(true);
    const notificationSettings = settings.find(
      (s) => s.category === "notifications"
    );
    if (notificationSettings) {
      const newPreferences = {
        ...notificationSettings.preferences,
        [type]: {
          ...notificationSettings.preferences[type],
          enabled,
        },
      };
      handleUpdateSettings({
        category: "notifications",
        preferences: newPreferences,
      });
    }
    setLoading(false);
  };

  const handleToggleChannel = (
    type: keyof NotificationSettings,
    channel: string,
    enabled: boolean
  ) => {
    setLoading(true);
    const notificationSettings = settings.find(
      (s) => s.category === "notifications"
    );
    if (notificationSettings) {
      const newPreferences = {
        ...notificationSettings.preferences,
        [type]: {
          ...notificationSettings.preferences[type],
          channels: {
            ...notificationSettings.preferences[type].channels,
            [channel]: enabled,
          },
        },
      };
      handleUpdateSettings({
        category: "notifications",
        preferences: newPreferences,
      });
    }
    setLoading(false);
  };

  const handleEmailChange = async (values: { newEmail: string }) => {
    try {
      await userService.changeEmail(values.newEmail);
      setAlert({
        type: "success",
        message: t("project:pages.user.settings.tabs.account.email.success"),
      });
    } catch (error) {
      console.error("Erro ao alterar email:", error);
      setAlert({
        type: "error",
        message: t("project:pages.user.settings.tabs.account.email.error"),
      });
    }
  };

  const handleRequestPasswordChange = async () => {
    try {
      setIsRequestingToken(true);
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      await authService.requestPasswordChangeToken(user.email);
      setShowPasswordForm(true);
      setAlert({
        type: "success",
        message: t(
          "project:pages.user.settings.tabs.account.password.code_sent"
        ),
      });
    } catch (error) {
      console.error("Erro ao solicitar código:", error);
      setAlert({
        type: "error",
        message: t(
          "project:pages.user.settings.tabs.account.password.code_error"
        ),
      });
    } finally {
      setIsRequestingToken(false);
    }
  };

  const handlePasswordChange = async (values: {
    recoveryToken: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    try {
      if (values.newPassword !== values.confirmPassword) {
        throw new Error("As senhas não coincidem");
      }

      const user = JSON.parse(localStorage.getItem("user") || "{}");
      await authService.changePassword(
        user.email,
        parseInt(values.recoveryToken),
        values.newPassword
      );

      setAlert({
        type: "success",
        message: t("project:pages.user.settings.tabs.account.password.success"),
      });
      setShowPasswordForm(false);
    } catch (error) {
      console.error("Erro ao alterar senha:", error);
      setAlert({
        type: "error",
        message: t("project:pages.user.settings.tabs.account.password.error"),
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      await userService.deleteAccount(user.uuid);
      localStorage.removeItem("user");
      window.location.href = "/";
    } catch (error) {
      console.error("Erro ao deletar conta:", error);
      setAlert({
        type: "error",
        message: t(
          "project:pages.user.settings.tabs.account.danger_zone.delete_account.error"
        ),
      });
    }
  };

  const handleCancelSubscription = async () => {
    if (!selectedSubscription) return;

    try {
      await paymentService.cancelSubscription(selectedSubscription.uuid);
      setAlert({
        type: "success",
        message: t(
          "project:pages.user.settings.tabs.subscription.cancel.success"
        ),
      });
      loadSubscriptions();
    } catch (error) {
      console.error("Erro ao cancelar assinatura:", error);
      setAlert({
        type: "error",
        message: t(
          "project:pages.user.settings.tabs.subscription.cancel.error"
        ),
      });
    } finally {
      setShowCancelSubscriptionModal(false);
      setSelectedSubscription(null);
    }
  };

  const handleManageSubscription = async () => {
    try {
      const { url } = await paymentService.createBillingPortalSession();
      window.location.href = url;
    } catch (error) {
      console.error("Erro ao acessar portal de gerenciamento:", error);
      setAlert({
        type: "error",
        message: t(
          "project:pages.user.settings.tabs.subscription.manage.error"
        ),
      });
    }
  };

  if (loading) {
    return <CleeckyKit.LoadingScreen />;
  }

  const notificationSettings = settings.find(
    (s) => s.category === "notifications"
  )?.preferences as NotificationSettings;

  return (
    <>
      <CleeckyKit.Alert
        isOpen={!!alert}
        variant={alert?.type || "success"}
        message={alert?.message || ""}
        onClose={() => setAlert(null)}
      />
      <CleeckyKit.Tabs
        defaultTab={t("project:pages.user.settings.tabs.account.title")}>
        {/* Account */}
        <CleeckyKit.Tab
          name={t("project:pages.user.settings.tabs.account.title")}>
          <CleeckyKit.Column gap="1.5rem">
            <CleeckyKit.Title level={3}>
              {t("project:pages.user.settings.tabs.account.title")}
            </CleeckyKit.Title>

            <CleeckyKit.Grid mode="bento" gap="0.5rem">
              <CleeckyKit.GridItem col={2} row={1}>
                <CleeckyKit.Card padding="1rem">
                  <CleeckyKit.Column gap="1rem">
                    <CleeckyKit.Title level={5}>
                      {t(
                        "project:pages.user.settings.tabs.account.email.title"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:pages.user.settings.tabs.account.email.description"
                      )}
                    </CleeckyKit.Text>

                    <CleeckyKit.Form onSubmit={handleEmailChange}>
                      <CleeckyKit.FormGroup>
                        <CleeckyKit.FormItem span={12}>
                          <CleeckyKit.EmailInput
                            name="newEmail"
                            label={t(
                              "project:pages.user.settings.tabs.account.email.new_email"
                            )}
                          />
                        </CleeckyKit.FormItem>
                      </CleeckyKit.FormGroup>
                      <CleeckyKit.Row>
                        <CleeckyKit.Button
                          text={t(
                            "project:pages.user.settings.tabs.account.email.submit"
                          )}
                          variant="solid"
                          size="medium"
                          textPosition="inside-right"
                          type="submit"
                          highlighted
                        />
                      </CleeckyKit.Row>
                    </CleeckyKit.Form>
                  </CleeckyKit.Column>
                </CleeckyKit.Card>
              </CleeckyKit.GridItem>

              <CleeckyKit.GridItem col={2} row={1}>
                <CleeckyKit.Card padding="1rem">
                  <CleeckyKit.Column gap="1rem">
                    <CleeckyKit.Title level={5}>
                      {t(
                        "project:pages.user.settings.tabs.account.password.title"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:pages.user.settings.tabs.account.password.description"
                      )}
                    </CleeckyKit.Text>

                    {!showPasswordForm ? (
                      <CleeckyKit.Row>
                        <CleeckyKit.Button
                          text={t(
                            "project:pages.user.settings.tabs.account.password.request_code"
                          )}
                          variant="solid"
                          size="medium"
                          textPosition="inside-right"
                          type="button"
                          onClick={handleRequestPasswordChange}
                          isLoading={isRequestingToken}
                          highlighted
                        />
                      </CleeckyKit.Row>
                    ) : (
                      <CleeckyKit.Form onSubmit={handlePasswordChange}>
                        <CleeckyKit.FormGroup>
                          <CleeckyKit.FormItem span={12}>
                            <CleeckyKit.NumberInput
                              name="recoveryToken"
                              label={t(
                                "project:pages.user.settings.tabs.account.password.recovery_token"
                              )}
                            />
                          </CleeckyKit.FormItem>
                          <CleeckyKit.FormItem span={12}>
                            <CleeckyKit.PasswordInput
                              name="newPassword"
                              label={t(
                                "project:pages.user.settings.tabs.account.password.new_password"
                              )}
                              showStrengthMeter
                            />
                          </CleeckyKit.FormItem>
                          <CleeckyKit.FormItem span={12}>
                            <CleeckyKit.ConfirmPasswordInput
                              name="confirmPassword"
                              label={t(
                                "project:pages.user.settings.tabs.account.password.confirm_password"
                              )}
                            />
                          </CleeckyKit.FormItem>
                        </CleeckyKit.FormGroup>
                        <CleeckyKit.Row>
                          <CleeckyKit.Button
                            text={t(
                              "project:pages.user.settings.tabs.account.password.submit"
                            )}
                            variant="solid"
                            size="medium"
                            textPosition="inside-right"
                            type="submit"
                            highlighted
                          />
                        </CleeckyKit.Row>
                      </CleeckyKit.Form>
                    )}
                  </CleeckyKit.Column>
                </CleeckyKit.Card>
              </CleeckyKit.GridItem>

              <CleeckyKit.GridItem col={4} row={1}>
                <CleeckyKit.Card padding="1rem">
                  <CleeckyKit.Column gap="1rem">
                    <CleeckyKit.Title level={5}>
                      {t(
                        "project:pages.user.settings.tabs.account.danger_zone.title"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:pages.user.settings.tabs.account.danger_zone.delete_account.description"
                      )}
                    </CleeckyKit.Text>

                    <CleeckyKit.Row>
                      <CleeckyKit.Button
                        text={t(
                          "project:pages.user.settings.tabs.account.danger_zone.delete_account.button"
                        )}
                        variant="outline"
                        size="small"
                        textPosition="inside-right"
                        type="button"
                        highlighted
                        onClick={() => setShowDeleteModal(true)}
                        customStyle={{
                          borderColor: currentTheme.colors.status.error,
                        }}
                      />
                    </CleeckyKit.Row>
                  </CleeckyKit.Column>
                </CleeckyKit.Card>
              </CleeckyKit.GridItem>
            </CleeckyKit.Grid>
          </CleeckyKit.Column>
        </CleeckyKit.Tab>

        {/* Notifications */}
        <CleeckyKit.Tab
          name={t("project:pages.user.settings.tabs.notifications.title")}>
          <CleeckyKit.Column gap="1.5rem">
            <CleeckyKit.Title level={3}>
              {t("project:pages.user.settings.tabs.notifications.title")}
            </CleeckyKit.Title>
            <CleeckyKit.Grid mode="bento" gap="0.5rem">
              {notificationSettings &&
                Object.entries(notificationSettings).map(
                  ([type, preferences]) => (
                    <CleeckyKit.GridItem col={2} row={1}>
                      <CleeckyKit.Card key={type} padding="1rem">
                        <CleeckyKit.Row>
                          <CleeckyKit.Column gap="0.5rem">
                            <CleeckyKit.Row justify="between" noBreak>
                              <CleeckyKit.Title level={5}>
                                {t(
                                  `project:pages.user.settings.tabs.notifications.${type.toLowerCase()}.title`
                                )}
                              </CleeckyKit.Title>
                              <CleeckyKit.Switch
                                checked={preferences.enabled}
                                onChange={(checked) =>
                                  handleToggleNotification(
                                    type as keyof NotificationSettings,
                                    checked
                                  )
                                }
                              />
                            </CleeckyKit.Row>

                            <CleeckyKit.Row>
                              <CleeckyKit.Text>
                                {t(
                                  `project:pages.user.settings.tabs.notifications.${type.toLowerCase()}.description`
                                )}
                              </CleeckyKit.Text>
                            </CleeckyKit.Row>
                          </CleeckyKit.Column>
                        </CleeckyKit.Row>

                        <CleeckyKit.Row justify="start" noBreak>
                          {preferences.enabled && (
                            <ChannelsContainer>
                              {Object.entries(preferences.channels).map(
                                ([channel, enabled]) => (
                                  <CleeckyKit.Checkbox
                                    key={channel}
                                    checked={enabled as boolean}
                                    onChange={(checked) =>
                                      handleToggleChannel(
                                        type as keyof NotificationSettings,
                                        channel,
                                        checked
                                      )
                                    }
                                    label={t(
                                      `project:pages.user.settings.tabs.notifications.channels.${channel.toLowerCase()}`
                                    )}
                                  />
                                )
                              )}
                            </ChannelsContainer>
                          )}
                        </CleeckyKit.Row>
                      </CleeckyKit.Card>
                    </CleeckyKit.GridItem>
                  )
                )}
            </CleeckyKit.Grid>
          </CleeckyKit.Column>
        </CleeckyKit.Tab>

        {/* Interface */}
        <CleeckyKit.Tab
          name={t("project:pages.user.settings.tabs.interface.title")}>
          <CleeckyKit.Column gap="1.5rem">
            <CleeckyKit.Title level={3}>
              {t("project:pages.user.settings.tabs.interface.title")}
            </CleeckyKit.Title>

            <CleeckyKit.Grid mode="bento" gap="0.5rem">
              <CleeckyKit.GridItem col={2} row={1}>
                <CleeckyKit.Card padding="1rem">
                  <CleeckyKit.Column gap="1rem">
                    <CleeckyKit.Title level={5}>
                      {t(
                        "project:pages.user.settings.tabs.interface.interface_colors.title"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:pages.user.settings.tabs.interface.interface_colors.description"
                      )}
                    </CleeckyKit.Text>
                    <InterfaceColorPicker />
                  </CleeckyKit.Column>
                </CleeckyKit.Card>
              </CleeckyKit.GridItem>

              <CleeckyKit.GridItem col={2} row={1}>
                <CleeckyKit.Card padding="1rem">
                  <CleeckyKit.Column gap="1rem">
                    <CleeckyKit.Title level={5}>
                      {t(
                        "project:pages.user.settings.tabs.interface.highlight_colors.title"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:pages.user.settings.tabs.interface.highlight_colors.description"
                      )}
                    </CleeckyKit.Text>
                    <HighlightColorPicker />
                  </CleeckyKit.Column>
                </CleeckyKit.Card>
              </CleeckyKit.GridItem>

              <CleeckyKit.GridItem col={2} row={1}>
                <CleeckyKit.Card padding="1rem">
                  <CleeckyKit.Column gap="1rem">
                    <CleeckyKit.Title level={5}>
                      {t(
                        "project:pages.user.settings.tabs.interface.language.title"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:pages.user.settings.tabs.interface.language.description"
                      )}
                    </CleeckyKit.Text>
                    <LanguageDropdown />
                  </CleeckyKit.Column>
                </CleeckyKit.Card>
              </CleeckyKit.GridItem>
            </CleeckyKit.Grid>
          </CleeckyKit.Column>
        </CleeckyKit.Tab>

        {/* Subscription */}
        <CleeckyKit.Tab
          name={t("project:pages.user.settings.tabs.subscription.title")}>
          <CleeckyKit.Column gap="1.5rem">
            {/* Active Subscriptions */}
            <CleeckyKit.Column gap="1rem">
              <CleeckyKit.Title level={3}>
                {t(
                  "project:pages.user.settings.tabs.subscription.sections.active.title"
                )}
              </CleeckyKit.Title>
              <CleeckyKit.Text>
                {t(
                  "project:pages.user.settings.tabs.subscription.sections.active.description"
                )}
              </CleeckyKit.Text>

              <CleeckyKit.Grid mode="bento" gap="0.5rem">
                {subscriptions
                  .filter(
                    (subscription) =>
                      (subscription.status === "active" ||
                        subscription.status === "trialing") &&
                      !subscription.cancelAtPeriodEnd &&
                      !subscription.canceledAt
                  )
                  .map((subscription) => (
                    <CleeckyKit.GridItem
                      key={subscription.uuid}
                      col={2}
                      row={1}>
                      <CleeckyKit.Card padding="1rem">
                        <CleeckyKit.Column gap="1rem">
                          <CleeckyKit.Row justify="between" noBreak>
                            <CleeckyKit.Title level={5}>
                              {subscription.plan.name}
                            </CleeckyKit.Title>
                            <CleeckyKit.Tag
                              variant={
                                subscription.status === "active"
                                  ? "success"
                                  : "warning"
                              }
                              label={t(
                                `project:pages.user.settings.tabs.subscription.status.${subscription.status}`
                              )}
                            />
                          </CleeckyKit.Row>

                          <CleeckyKit.Row>
                            <CleeckyKit.Text>
                              {t(
                                "project:pages.user.settings.tabs.subscription.price",
                                {
                                  price: new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(subscription.plan.amount / 100),
                                  interval: t(
                                    `project:pages.user.settings.tabs.subscription.interval.${subscription.plan.interval}`
                                  ),
                                }
                              )}
                            </CleeckyKit.Text>
                          </CleeckyKit.Row>

                          <CleeckyKit.Row>
                            <CleeckyKit.Text>
                              {t(
                                "project:pages.user.settings.tabs.subscription.start_date",
                                {
                                  date: dayjs(
                                    subscription.currentPeriodStart
                                  ).format("DD/MM/YYYY HH:mm"),
                                }
                              )}
                            </CleeckyKit.Text>
                          </CleeckyKit.Row>

                          {subscription.currentPeriodEnd && (
                            <CleeckyKit.Row>
                              <CleeckyKit.Text>
                                {t(
                                  "project:pages.user.settings.tabs.subscription.renewal_date",
                                  {
                                    date: dayjs(
                                      subscription.currentPeriodEnd
                                    ).format("DD/MM/YYYY HH:mm"),
                                  }
                                )}
                              </CleeckyKit.Text>
                            </CleeckyKit.Row>
                          )}

                          <CleeckyKit.Row>
                            {/*  <CleeckyKit.Button
                              text={t(
                                "project:pages.user.settings.tabs.subscription.manage.button"
                              )}
                              variant="solid"
                              size="small"
                              textPosition="inside-right"
                              type="button"
                              onClick={handleManageSubscription}
                              highlighted
                            /> */}

                            <CleeckyKit.Button
                              text={t(
                                "project:pages.user.settings.tabs.subscription.cancel.button"
                              )}
                              variant="outline"
                              size="small"
                              textPosition="inside-right"
                              type="button"
                              onClick={() => {
                                setSelectedSubscription(subscription);
                                setShowCancelSubscriptionModal(true);
                              }}
                              customStyle={{
                                borderColor: currentTheme.colors.status.error,
                              }}
                            />
                          </CleeckyKit.Row>
                        </CleeckyKit.Column>
                      </CleeckyKit.Card>
                    </CleeckyKit.GridItem>
                  ))}
                {subscriptions.filter(
                  (subscription) =>
                    (subscription.status === "active" ||
                      subscription.status === "trialing") &&
                    !subscription.cancelAtPeriodEnd &&
                    !subscription.canceledAt
                ).length === 0 && (
                  <CleeckyKit.GridItem col={4} row={1}>
                    <CleeckyKit.Card padding="1rem">
                      <CleeckyKit.Row justify="center" align="center">
                        <CleeckyKit.Text>
                          {t(
                            "project:pages.user.settings.tabs.subscription.sections.active.empty"
                          )}
                        </CleeckyKit.Text>
                      </CleeckyKit.Row>
                    </CleeckyKit.Card>
                  </CleeckyKit.GridItem>
                )}
              </CleeckyKit.Grid>

              {/* Subscription History */}
              <CleeckyKit.Title level={3}>
                {t(
                  "project:pages.user.settings.tabs.subscription.sections.history.title"
                )}
              </CleeckyKit.Title>
              <CleeckyKit.Text>
                {t(
                  "project:pages.user.settings.tabs.subscription.sections.history.description"
                )}
              </CleeckyKit.Text>

              <CleeckyKit.Grid mode="bento" gap="0.5rem">
                {subscriptions
                  .filter(
                    (subscription) =>
                      subscription.status === "canceled" ||
                      subscription.status === "incomplete" ||
                      subscription.status === "incomplete_expired" ||
                      subscription.status === "past_due" ||
                      subscription.status === "unpaid" ||
                      subscription.cancelAtPeriodEnd ||
                      subscription.canceledAt
                  )
                  .sort((a, b) => {
                    const dateA = a.canceledAt || a.currentPeriodEnd;
                    const dateB = b.canceledAt || b.currentPeriodEnd;
                    return (
                      new Date(dateB).getTime() - new Date(dateA).getTime()
                    );
                  })
                  .map((subscription) => (
                    <CleeckyKit.GridItem
                      key={subscription.uuid}
                      col={2}
                      row={1}>
                      <CleeckyKit.Card padding="1rem">
                        <CleeckyKit.Column gap="1rem">
                          <CleeckyKit.Row justify="between" noBreak>
                            <CleeckyKit.Title level={5}>
                              {subscription.plan.name}
                            </CleeckyKit.Title>
                            <CleeckyKit.Tag
                              variant={
                                subscription.cancelAtPeriodEnd ||
                                subscription.canceledAt
                                  ? "warning"
                                  : subscription.status === "active"
                                  ? "success"
                                  : "warning"
                              }
                              label={t(
                                `project:pages.user.settings.tabs.subscription.status.${
                                  subscription.cancelAtPeriodEnd ||
                                  subscription.canceledAt
                                    ? "canceled"
                                    : subscription.status
                                }`
                              )}
                            />
                          </CleeckyKit.Row>

                          <CleeckyKit.Row>
                            <CleeckyKit.Text>
                              {t(
                                "project:pages.user.settings.tabs.subscription.price",
                                {
                                  price: new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(subscription.plan.amount / 100),
                                  interval: t(
                                    `project:pages.user.settings.tabs.subscription.interval.${subscription.plan.interval}`
                                  ),
                                }
                              )}
                            </CleeckyKit.Text>
                          </CleeckyKit.Row>

                          <CleeckyKit.Row>
                            <CleeckyKit.Text>
                              {t(
                                "project:pages.user.settings.tabs.subscription.start_date",
                                {
                                  date: dayjs(
                                    subscription.currentPeriodStart
                                  ).format("DD/MM/YYYY HH:mm"),
                                }
                              )}
                            </CleeckyKit.Text>
                          </CleeckyKit.Row>

                          {subscription.canceledAt && (
                            <CleeckyKit.Row>
                              <CleeckyKit.Text>
                                {t(
                                  "project:pages.user.settings.tabs.subscription.canceled_at",
                                  {
                                    date: dayjs(subscription.canceledAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    ),
                                  }
                                )}
                              </CleeckyKit.Text>
                            </CleeckyKit.Row>
                          )}

                          {subscription.currentPeriodEnd && (
                            <CleeckyKit.Row>
                              <CleeckyKit.Text>
                                {subscription.cancelAtPeriodEnd
                                  ? t(
                                      "project:pages.user.settings.tabs.subscription.end_date",
                                      {
                                        date: dayjs(
                                          subscription.currentPeriodEnd
                                        ).format("DD/MM/YYYY HH:mm"),
                                      }
                                    )
                                  : t(
                                      "project:pages.user.settings.tabs.subscription.renewal_date",
                                      {
                                        date: dayjs(
                                          subscription.currentPeriodEnd
                                        ).format("DD/MM/YYYY HH:mm"),
                                      }
                                    )}
                              </CleeckyKit.Text>
                            </CleeckyKit.Row>
                          )}
                        </CleeckyKit.Column>
                      </CleeckyKit.Card>
                    </CleeckyKit.GridItem>
                  ))}
                {subscriptions.filter(
                  (subscription) =>
                    subscription.status === "canceled" ||
                    subscription.status === "incomplete" ||
                    subscription.status === "incomplete_expired" ||
                    subscription.status === "past_due" ||
                    subscription.status === "unpaid" ||
                    subscription.cancelAtPeriodEnd ||
                    subscription.canceledAt
                ).length === 0 && (
                  <CleeckyKit.GridItem col={4} row={1}>
                    <CleeckyKit.Card padding="1rem">
                      <CleeckyKit.Row justify="center" align="center">
                        <CleeckyKit.Text>
                          {t(
                            "project:pages.user.settings.tabs.subscription.sections.history.empty"
                          )}
                        </CleeckyKit.Text>
                      </CleeckyKit.Row>
                    </CleeckyKit.Card>
                  </CleeckyKit.GridItem>
                )}
              </CleeckyKit.Grid>
            </CleeckyKit.Column>
          </CleeckyKit.Column>
        </CleeckyKit.Tab>
      </CleeckyKit.Tabs>

      <CleeckyKit.Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t(
          "project:pages.user.settings.tabs.account.danger_zone.delete_account.modal.title"
        )}
        size="auto">
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Row>
            <CleeckyKit.Text>
              {t(
                "project:pages.user.settings.tabs.account.danger_zone.delete_account.modal.description"
              )}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
          <CleeckyKit.Row justify="end">
            <CleeckyKit.Button
              text={t(
                "project:pages.user.settings.tabs.account.danger_zone.delete_account.modal.confirm"
              )}
              variant="link"
              textPosition="inside-right"
              size="small"
              type="button"
              onClick={handleDeleteAccount}
            />
            <CleeckyKit.Button
              text={t(
                "project:pages.user.settings.tabs.account.danger_zone.delete_account.modal.cancel"
              )}
              variant="solid"
              textPosition="inside-right"
              size="small"
              type="button"
              onClick={() => setShowDeleteModal(false)}
              highlighted
            />
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Modal>

      <CleeckyKit.Modal
        isOpen={showCancelSubscriptionModal}
        onClose={() => {
          setShowCancelSubscriptionModal(false);
          setSelectedSubscription(null);
        }}
        title={t(
          "project:pages.user.settings.tabs.subscription.cancel.modal.title"
        )}
        size="auto">
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Row>
            <CleeckyKit.Text>
              {t(
                "project:pages.user.settings.tabs.subscription.cancel.modal.description"
              )}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
          <CleeckyKit.Row justify="end">
            <CleeckyKit.Button
              text={t(
                "project:pages.user.settings.tabs.subscription.cancel.modal.confirm"
              )}
              variant="link"
              textPosition="inside-right"
              size="small"
              type="button"
              onClick={handleCancelSubscription}
            />
            <CleeckyKit.Button
              text={t(
                "project:pages.user.settings.tabs.subscription.cancel.modal.cancel"
              )}
              variant="solid"
              textPosition="inside-right"
              size="small"
              type="button"
              onClick={() => {
                setShowCancelSubscriptionModal(false);
                setSelectedSubscription(null);
              }}
              highlighted
            />
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Modal>
    </>
  );
};

export default UserSettings;

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import AppRoutes from "./routes/AppRoutes";
import "./assets/styles/globals.css";
import { AuthProvider } from "./contexts/AuthContext";
import { AlertProvider } from "./contexts/AlertContext";
import * as CleeckyKit from "CleeckyKit";

function App() {
  useEffect(() => {
    // Registra o service worker
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            // Verifica por atualizações a cada 1 hora
            setInterval(() => {
              registration.update().then(() => {});
            }, 60 * 60 * 1000);

            // Detecta quando há uma nova versão disponível
            registration.addEventListener("updatefound", () => {
              const newWorker = registration.installing;
              if (newWorker) {
                newWorker.addEventListener("statechange", () => {
                  if (
                    newWorker.state === "installed" &&
                    navigator.serviceWorker.controller
                  ) {
                    window.location.reload();
                  }
                });
              }
            });
          })
          .catch((error) => {
            console.error("[App] Erro ao registrar Service Worker:", error);
          });
      });

      // Detecta quando o service worker foi atualizado em outra aba
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        window.location.reload();
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <CleeckyKit.ThemeProvider
        defaultTheme="dark"
        defaultGradient="purpleMidnight">
        <AlertProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </AlertProvider>
      </CleeckyKit.ThemeProvider>
    </Provider>
  );
}

export default App;

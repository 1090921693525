import React from "react";
import { useNavigate } from "react-router-dom";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";

interface LimitModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LimitModal: React.FC<LimitModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  return (
    <CleeckyKit.Modal
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      showCloseButton={false}>
      <CleeckyKit.Column gap="1rem">
        <CleeckyKit.Row>
          <CleeckyKit.Title level={3}>
            {t(
              "project:apps.toolbox.tools.link_aggregator.pageList.limit_modal.title"
            )}
          </CleeckyKit.Title>
        </CleeckyKit.Row>
        <CleeckyKit.Row>
          <CleeckyKit.Text>
            {t(
              "project:apps.toolbox.tools.link_aggregator.pageList.limit_modal.description"
            )}
          </CleeckyKit.Text>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="end" gap="1rem" noBreak>
          <CleeckyKit.Button
            text={t("cleeckykit:common.actions.cancel")}
            textPosition="inside-right"
            variant="link"
            size="small"
            onClick={onClose}
          />
          <CleeckyKit.Button
            href="/pricing"
            target="_self"
            text={t(
              "project:apps.toolbox.tools.link_aggregator.pageList.limit_modal.upgrade"
            )}
            textPosition="inside-right"
            variant="solid"
            highlighted
            size="small"
            icon={<FontAwesomeIcon icon={faRocket} />}
          />
        </CleeckyKit.Row>
      </CleeckyKit.Column>
    </CleeckyKit.Modal>
  );
};

export default LimitModal;

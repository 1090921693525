import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { authService } from "../services";
import { useAlert } from "./AlertContext";
import { MinLoadingScreen } from "components";
import { UserTag } from "cleecky@types/interfaces";
interface User {
  id: number;
  username: string;
  email: string;
  userTags?: UserTag[];
}

interface AuthContextData {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  signup: (userData: any) => Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { showAlert } = useAlert();

  const checkAuthStatus = async () => {
    try {
      setIsLoading(true);
      const userData = await authService.getCurrentUser();
      if (userData) {
        setUser(userData);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  if (isLoading) {
    return <MinLoadingScreen isLoading={isLoading} />;
  }

  const login = async (identifier: string, password: string) => {
    try {
      const { user } = await authService.login(identifier, password);
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      showAlert("success", "Yippee! Você se conectou a sua Cleecky!");
      return user;
    } catch (error) {
      showAlert("error", "Falha no login. Por favor, tente novamente.");
      throw error;
    }
  };

  const logout = async () => {
    try {
      await authService.logout();
      setUser(null);
      localStorage.removeItem("user");
      localStorage.setItem("showLogoutAlert", "true");

      // Força um reload da página após o logout
      window.location.reload();
    } catch (error) {
      showAlert("error", "Falha ao sair. Por favor, tente novamente.");
    }
  };

  const signup = async (userData: any) => {
    const newUser = await authService.signup(userData);
    setUser(newUser);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, signup }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import {
  AggregatorPageData,
  TextContent,
  ImageContent,
  LinkContent,
  SocialButtonsContent,
  GroupContent,
  SocialPlatform,
  SocialButton,
  AvatarContent,
} from "cleecky@types/interfaces";
import { MainLayout } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faGithub,
  faYoutube,
  faTiktok,
  faPinterest,
  faReddit,
  faSnapchat,
  faSpotify,
  faTumblr,
  faVimeo,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCamera,
  faCircle,
  faCircleUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import cleeckyLogo from "assets/images/icon-minimalist-black-1.svg";
import { useMetricsTracking } from "hooks/useMetricsTracking";

interface AggregatorPageProps {
  data: AggregatorPageData;
}

interface CentralColumnProps {
  $background?: string | null;
}

const CentralColumn = styled.div<CentralColumnProps>`
  min-height: 110dvh;
  max-height: fit-content;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: ${(props) => props.$background || "#ffffff"};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const AggregatorPage: React.FC<AggregatorPageProps> = ({ data }) => {
  const { contents, user } = data;

  useMetricsTracking({ pageUuid: data.uuid });

  const getSocialIcon = (platform: SocialPlatform): IconDefinition => {
    switch (platform) {
      case "FACEBOOK":
        return faFacebook;
      case "INSTAGRAM":
        return faInstagram;
      case "LINKEDIN":
        return faLinkedin;
      case "GITHUB":
        return faGithub;
      case "YOUTUBE":
        return faYoutube;
      case "TIKTOK":
        return faTiktok;
      case "X":
        return faXTwitter;
      case "PINTEREST":
        return faPinterest;
      case "REDDIT":
        return faReddit;
      case "SNAPCHAT":
        return faSnapchat;
      case "SPOTIFY":
        return faSpotify;
      case "TUMBLR":
        return faTumblr;
      case "VIMEO":
        return faVimeo;
      case "WHATSAPP":
        return faWhatsapp;
      case "GMAIL":
        return faEnvelope;
      default:
        return faEnvelope;
    }
  };

  const renderContent = (content: AggregatorPageData["contents"][0]) => {
    if (!content.show) return null;

    switch (content.type) {
      case "TITLE":
      case "TEXT":
      case "USERNAME": {
        const textContent = content.data as TextContent;
        return (
          <CleeckyKit.Row
            key={content.uuid}
            justify={textContent.alignment?.toLowerCase() as any}>
            {content.type === "TITLE" ? (
              <CleeckyKit.Title
                level={
                  textContent.size
                    ? (parseInt(textContent.size.slice(1)) as
                        | 1
                        | 2
                        | 3
                        | 4
                        | 5
                        | 6)
                    : 4
                }
                align={textContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: textContent.color || data.textColor || undefined,
                  textDecoration: textContent.decoration,
                }}>
                {textContent.text}
              </CleeckyKit.Title>
            ) : (
              <CleeckyKit.Text
                href={
                  content.type === "USERNAME"
                    ? `/@${textContent.text}`
                    : undefined
                }
                target={content.type === "USERNAME" ? "_blank" : undefined}
                align={textContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: textContent.color || data.textColor || undefined,
                  textDecoration: textContent.decoration,
                }}>
                {content.type === "USERNAME"
                  ? `@${textContent.text}`
                  : textContent.text}
              </CleeckyKit.Text>
            )}
          </CleeckyKit.Row>
        );
      }

      case "NAME": {
        const textContent = content.data as TextContent;
        return (
          <CleeckyKit.Row
            key={content.uuid}
            justify={textContent.alignment?.toLowerCase() as any}>
            <CleeckyKit.Title
              level={3}
              align={textContent.alignment?.toLowerCase() as any}
              customStyle={{
                color: textContent.color || data.textColor || undefined,
                textDecoration: textContent.decoration,
              }}>
              {textContent.text}
            </CleeckyKit.Title>
          </CleeckyKit.Row>
        );
      }

      case "AVATAR": {
        const avatarContent = content.data as AvatarContent;
        return (
          <CleeckyKit.Row key={content.uuid} justify="center">
            <div style={{ width: "10rem", height: "10rem" }}>
              {avatarContent.file?.path ? (
                <CleeckyKit.Image
                  src={avatarContent.file.path}
                  alt="Avatar"
                  width="100%"
                  height="100%"
                  fit="cover"
                  position="center"
                  borderRadius={
                    avatarContent.shape === "CIRCLE" ? "50%" : data.borderRadius
                  }
                  sourceType={
                    avatarContent.file.type.toLowerCase() as "external" | "file"
                  }
                  customStyle={{
                    border: `${data.borderWidth} solid ${data.borderColor}`,
                    ...(avatarContent.border && {
                      border: avatarContent.border,
                    }),
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: "1/1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#333333",
                    borderRadius:
                      avatarContent.shape === "CIRCLE"
                        ? "50%"
                        : data.borderRadius,
                    border: `${data.borderWidth} solid ${data.borderColor}`,
                    ...(avatarContent.border && {
                      border: avatarContent.border,
                    }),
                  }}>
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    style={{
                      fontSize: "3rem",
                      opacity: 0.5,
                      color: "#D4D4D4",
                    }}
                  />
                </div>
              )}
            </div>
          </CleeckyKit.Row>
        );
      }

      case "IMAGE": {
        const imageContent = content.data as ImageContent;
        return (
          <CleeckyKit.Row key={content.uuid}>
            <CleeckyKit.Column width="100%">
              {(imageContent.title || imageContent.description) &&
                imageContent.textPosition === "TOP" && (
                  <>
                    {imageContent.title && (
                      <CleeckyKit.Title
                        level={4}
                        align="left"
                        customStyle={{
                          marginBottom: "0.5rem",
                          color: data.textColor,
                        }}>
                        {imageContent.title}
                      </CleeckyKit.Title>
                    )}
                    {imageContent.description && (
                      <CleeckyKit.Text
                        align="left"
                        customStyle={{
                          marginBottom: "1rem",
                          color: data.textColor,
                        }}>
                        {imageContent.description}
                      </CleeckyKit.Text>
                    )}
                  </>
                )}
              <div
                style={{ width: "100%", maxWidth: "100%", margin: "0 auto" }}>
                {imageContent.file?.path ? (
                  <CleeckyKit.Image
                    src={imageContent.file.path}
                    alt={imageContent.title || ""}
                    width="100%"
                    height="auto"
                    fit="cover"
                    position="center"
                    borderRadius={imageContent.borderRadius}
                    sourceType={
                      imageContent.file.type.toLowerCase() as
                        | "external"
                        | "file"
                    }
                    customStyle={{
                      border: imageContent.border
                        ? `${imageContent.border} solid ${
                            imageContent.borderColor ||
                            data.borderColor ||
                            undefined
                          }`
                        : undefined,
                      aspectRatio: "16/9",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "16/9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#333333",
                      borderRadius: imageContent.borderRadius,
                      border: imageContent.border
                        ? `${imageContent.border} solid ${
                            imageContent.borderColor ||
                            data.borderColor ||
                            undefined
                          }`
                        : undefined,
                    }}>
                    <FontAwesomeIcon
                      icon={faCamera}
                      style={{
                        fontSize: "3rem",
                        opacity: 0.5,
                        color: "#D4D4D4",
                      }}
                    />
                  </div>
                )}
              </div>
              {(imageContent.title || imageContent.description) &&
                imageContent.textPosition === "BOTTOM" && (
                  <>
                    {imageContent.title && (
                      <CleeckyKit.Title
                        level={4}
                        align="left"
                        customStyle={{
                          marginTop: "1rem",
                          color: data.textColor,
                        }}>
                        {imageContent.title}
                      </CleeckyKit.Title>
                    )}
                    {imageContent.description && (
                      <CleeckyKit.Text
                        align="left"
                        customStyle={{
                          marginTop: "0.5rem",
                          color: data.textColor,
                        }}>
                        {imageContent.description}
                      </CleeckyKit.Text>
                    )}
                  </>
                )}
            </CleeckyKit.Column>
          </CleeckyKit.Row>
        );
      }

      case "LINK": {
        const linkContent = content.data as LinkContent;
        return (
          <CleeckyKit.Row key={content.uuid}>
            <CleeckyKit.Row justify="center" align="center">
              <CleeckyKit.Button
                text={linkContent.label}
                variant={linkContent.background ? "solid" : "outline"}
                onClick={() => window.open(linkContent.url, "_blank")}
                fullWidth
                textPosition="inside-right"
                borderRadius={
                  linkContent.borderRadius || data.borderRadius || undefined
                }
                customStyle={{
                  width: "100%",
                  background:
                    linkContent.background ||
                    data.buttonsBackgroundColor ||
                    undefined,
                  borderColor:
                    linkContent.border || data.borderColor || undefined,
                  color: data.buttonsLabelColor || undefined,
                }}
                hoverStyle={{
                  background: data.highlightColor || undefined,
                  color: data.buttonsLabelColor || undefined,
                }}
              />
            </CleeckyKit.Row>
          </CleeckyKit.Row>
        );
      }

      case "SOCIAL_BUTTONS": {
        const socialContent = content.data as SocialButtonsContent;
        return (
          <CleeckyKit.Row key={content.uuid}>
            {socialContent.buttons.length > 0 && (
              <CleeckyKit.Grid
                justify="center"
                gap="1rem"
                columns={
                  socialContent.orientation === "HORIZONTAL"
                    ? { xs: 2, sm: 5, md: 5, lg: 5, xl: 5, "2xl": 5 }
                    : { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, "2xl": 1 }
                }>
                {socialContent.buttons.map((button: SocialButton) => (
                  <CleeckyKit.Button
                    key={`${content.uuid}-${button.platform}`}
                    href={button.url}
                    target="_blank"
                    variant="icon"
                    iconBackground
                    size="large"
                    borderRadius={
                      socialContent.borderRadius ||
                      data.borderRadius ||
                      undefined
                    }
                    icon={
                      <FontAwesomeIcon icon={getSocialIcon(button.platform)} />
                    }
                    customStyle={{
                      background:
                        socialContent.background ||
                        data.buttonsBackgroundColor ||
                        undefined,
                      color: data.buttonsLabelColor || undefined,
                    }}
                  />
                ))}
              </CleeckyKit.Grid>
            )}
          </CleeckyKit.Row>
        );
      }

      default:
        return null;
    }
  };

  return (
    <>
      <CleeckyKit.Background
        color={data.pageBackgroundColor || "#ffffff"}
        size="fullscreen">
        <CentralColumn $background={data.pageContentBackgroundColor}>
          {contents
            .filter((content) => content.show)
            .sort((a, b) => a.order - b.order)
            .map(renderContent)}
        </CentralColumn>
      </CleeckyKit.Background>
      <CleeckyKit.Section background="#141414">
        <CleeckyKit.Column>
          <CleeckyKit.Row justify="center" align="center">
            <CleeckyKit.Image
              fill="#ffffff"
              format="svg"
              src={cleeckyLogo}
              alt="Cleecky"
              width="5rem"
              height="auto"
              fit="contain"
              target="_blank"
              href="https://cleecky.com/toolbox/link-aggregator"
            />
          </CleeckyKit.Row>
          <CleeckyKit.Row justify="center" align="center">
            <CleeckyKit.Text
              href="https://cleecky.com/toolbox/link-aggregator"
              target="_blank">
              {"Created with {bold}Cleecky.com{/bold}"}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Section>
    </>
  );
};

export default AggregatorPage;

import api from "../axiosConfig";
import {
  UserData,
  UpdateUserData,
  UserSettingsData,
  UpdateUserSettingsData,
} from "../@types/interfaces";

export const userService = {
  async getProfile(identifier: string): Promise<UserData> {
    const response = await api.get(`/shared/users/profile/${identifier}`);
    return response.data;
  },

  async updateProfile(uuid: string, data: UpdateUserData): Promise<UserData> {
    const response = await api.put(`/shared/users/${uuid}`, data);
    return response.data;
  },

  updateAvatar: async (uuid: string, formData: FormData): Promise<UserData> => {
    const response = await api.post(`/shared/users/${uuid}/avatar`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  updateBanner: async (uuid: string, formData: FormData): Promise<UserData> => {
    const response = await api.post(`/shared/users/${uuid}/banner`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  // Buscar todas as configurações do usuário
  async getUserSettings(category?: string): Promise<UserSettingsData[]> {
    const url = category
      ? `/user-settings?category=${category}`
      : "/user-settings";
    const response = await api.get(url);
    return response.data;
  },

  // Atualizar configurações do usuário
  async updateUserSettings(
    data: UpdateUserSettingsData
  ): Promise<UserSettingsData> {
    const response = await api.put("/user-settings", data);
    return response.data;
  },

  // Alterar email do usuário
  async changeEmail(newEmail: string): Promise<string> {
    const response = await api.post("/auth/change-email", { newEmail });
    return response.data;
  },

  // Alterar senha do usuário
  async changePassword(newPassword: string): Promise<string> {
    const response = await api.post("/auth/change-password", { newPassword });
    return response.data;
  },

  // Deletar conta do usuário
  async deleteAccount(uuid: string): Promise<void> {
    await api.delete(`/shared/users/${uuid}`);
  },
};

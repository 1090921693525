import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import * as CleeckyKit from "CleeckyKit";
import { paymentService } from "services/paymentService";
import { StripePlan } from "cleecky@types/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRocket,
  faStar,
  faInfinity,
  faCheck,
  faArrowDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { CleeckyInsidersWaitingList } from "components/CleeckyInsiders/CleeckyInsidersWaitingList";
import { authService } from "services/authService";
import { AuthModal } from "components/Auth/AuthModal";
import { useAuth } from "contexts/AuthContext";

const PlanCard = styled.div<{ $isPopular?: boolean; $isRecommended?: boolean }>`
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 12px;
  padding: 2rem;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  position: relative;
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border: 2px solid
    ${({ $isPopular, theme }) =>
      $isPopular ? theme.colors.status.warning : "transparent"};
  transition: all 0.3s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
    transform: translateY(-4px);
  }

  ${({ $isPopular, $isRecommended, theme }) =>
    ($isRecommended || $isPopular) &&
    `
    &::before {
      content: '${
        $isRecommended
          ? t("project:pages.pricing.pricing_universe.cards_plans.recommended")
          : t("project:pages.pricing.pricing_universe.cards_plans.popular")
      }';
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      background: ${
        $isRecommended
          ? theme.gradient?.highlightColor || theme.colors.interactive.primary
          : theme.colors.status.warning
      };
      color: ${theme.colors.text.onPrimary};
      padding: 4px 12px;
      border-radius: 20px;
      font-size: 0.875rem;
    }
  `}
`;

const PlanHeader = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const PlanIcon = styled.div<{ $isPopular?: boolean }>`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${({ theme, $isPopular }) =>
    $isPopular
      ? theme.colors.status.warning
      : theme.gradient?.highlightColor || theme.colors.interactive.primary};
`;

const PlanPrice = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.text.primary};

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const PlanPriceNote = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: -0.5rem;
  text-align: center;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
  flex-grow: 1;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.text.primary};

  svg {
    color: ${({ theme }) => theme.colors.status.success};
    margin-right: 0.5rem;
  }
`;

const IconWrapper = styled.div<{ $isActive: boolean }>`
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.status.success : theme.colors.status.error};
  display: flex;
  justify-content: center;
`;

const PricingHomepage: React.FC = () => {
  const [plans, setPlans] = useState<StripePlan[]>([]);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const comparePlansTableData = {
    headers: [
      {
        content: t("project:pages.pricing.pricing_universe.table.apps.title"),
        colSpan: 3,
        textAlign: "center",
        color: currentGradient?.secondaryHighlightColor,
      },
      {
        content: t(
          "project:pages.pricing.pricing_universe.table.plans.free.title"
        ),
        color: currentTheme.colors.charts.bar.default,
      },
      {
        content: t(
          "project:pages.pricing.pricing_universe.table.plans.first_steps_infinity.title"
        ),
        color: currentGradient?.highlightColor,
      },
      {
        content: t(
          "project:pages.pricing.pricing_universe.table.plans.explorer.title"
        ),
        color: currentGradient?.highlightColor,
      },
      {
        content: t(
          "project:pages.pricing.pricing_universe.table.plans.intergalactic.title"
        ),
        color: currentGradient?.highlightColor,
      },
    ],
    rows: [
      {
        headers: [
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.title"
            ),
            rowSpan: 6,
            color: currentTheme.colors.background.navbar.background,
            isVertical: true,
          },
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_aggregator.title"
            ),
            rowSpan: 2,
            color: currentTheme.colors.background.navbar.border,
            isVertical: true,
          },
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_aggregator.features.pages_elements"
            ),
            colSpan: 1,
            rowSpan: 1,
            color: `${currentTheme.colors.background.navbar.border}90`,
          },
        ],
        cells: [
          {
            content:
              "5 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_aggregator.features.pages"
              ) +
              " (20 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_aggregator.features.elements"
              ) +
              ")",
          },
          {
            content:
              "30 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_aggregator.features.pages"
              ) +
              " (100 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_aggregator.features.elements"
              ) +
              ")",
          },
          {
            colSpan: 2,
            rowSpan: 6,
            content: t(
              "project:pages.pricing.pricing_universe.table.new_plans"
            ),
          },
        ],
      },
      {
        headers: [
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_aggregator.features.elements_and_editions"
            ),
            colSpan: 1,
            rowSpan: 1,
            color: `${currentTheme.colors.background.navbar.border}90`,
          },
        ],
        cells: [
          {
            content: (
              <IconWrapper $isActive={false}>
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </IconWrapper>
            ),
          },
          {
            content: (
              <IconWrapper $isActive={true}>
                <FontAwesomeIcon icon={faCheck} size="lg" />
              </IconWrapper>
            ),
          },
        ],
      },
      {
        headers: [
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_shortener.title"
            ),
            colSpan: 1,
            rowSpan: 2,
            color: currentTheme.colors.background.navbar.border,
            isVertical: true,
          },
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_shortener.features.links_quantity"
            ),
            colSpan: 1,
            rowSpan: 1,
            color: `${currentTheme.colors.background.navbar.border}40`,
          },
        ],
        cells: [
          {
            content:
              "10 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_shortener.features.links"
              ),
          },
          {
            content:
              "100 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_shortener.features.links"
              ),
          },
        ],
      },
      {
        headers: [
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.link_shortener.features.exclusive_links"
            ),
            colSpan: 1,
            rowSpan: 1,
            color: `${currentTheme.colors.background.navbar.border}40`,
          },
        ],
        cells: [
          {
            content: "1",
          },
          {
            content: "30",
          },
        ],
      },
      {
        headers: [
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.qr_code_generator.title"
            ),
            colSpan: 1,
            rowSpan: 2,
            color: currentTheme.colors.background.navbar.border,
            isVertical: true,
          },
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.qr_code_generator.features.qr_codes_quantity"
            ),
            colSpan: 1,
            rowSpan: 1,
            color: `${currentTheme.colors.background.navbar.border}90`,
          },
        ],
        cells: [
          {
            content:
              "10 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.qr_code_generator.features.qr_codes"
              ),
          },
          {
            content:
              "100 " +
              t(
                "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.qr_code_generator.features.qr_codes"
              ),
          },
        ],
      },
      {
        headers: [
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.apps.toolbox.tools.qr_code_generator.features.custom_pages"
            ),
            colSpan: 1,
            rowSpan: 1,
            color: `${currentTheme.colors.background.navbar.border}90`,
          },
        ],
        cells: [
          {
            content: "5",
          },
          {
            content: "50",
          },
        ],
      },
      {
        headers: [
          {
            content: t(
              "project:pages.pricing.pricing_universe.table.new_tools"
            ),
            colSpan: 7,
            rowSpan: 1,
            textAlign: "center",
            color: `${currentTheme.colors.background.navbar.background}90`,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    const loadPlans = async () => {
      try {
        const plansData = await paymentService.getPlans();
        const userData = await authService.getCurrentUser();

        // Get the active subscription if exists
        const activeSubscription = userData?.subscriptions?.find(
          (sub: any) => sub.status === "active" || sub.status === "trialing"
        );

        setCurrentSubscription(activeSubscription);

        // Filtrar apenas os planos First Steps (mensal e anual) e Infinity
        const filteredPlans = plansData.filter(
          (plan) =>
            plan.metadata.level === "infinity" ||
            (plan.metadata.level === "first_steps" &&
              (plan.interval === "month" || plan.interval === "year"))
        );

        // Ordenar os planos filtrados
        const sortedPlans = filteredPlans.sort((a, b) => {
          // Primeiro, ordenar por nível
          const levelOrder = {
            first_steps: 1,
            infinity: 2,
          };
          const levelDiff =
            levelOrder[a.metadata.level as keyof typeof levelOrder] -
            levelOrder[b.metadata.level as keyof typeof levelOrder];

          if (levelDiff !== 0) return levelDiff;

          // Se o nível for igual (no caso do First Steps), ordenar por intervalo
          const intervalOrder = {
            month: 1,
            year: 2,
          };
          return (
            intervalOrder[a.interval as keyof typeof intervalOrder] -
            intervalOrder[b.interval as keyof typeof intervalOrder]
          );
        });

        setPlans(sortedPlans);
      } catch (error) {
        console.error("Erro ao carregar planos:", error);
      }
    };

    loadPlans();
  }, []);

  const handleSubscribe = async (planId: string) => {
    if (!user) {
      setSelectedPlanId(planId);
      setIsAuthModalOpen(true);
      return;
    }

    try {
      const { checkoutUrl } = await paymentService.createSubscription(planId);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error("Erro ao criar assinatura:", error);
    }
  };

  const handleAuthModalClose = () => {
    setIsAuthModalOpen(false);
    if (user && selectedPlanId) {
      handleSubscribe(selectedPlanId);
    }
    setSelectedPlanId(null);
  };

  const formatPrice = (amount: number, isYearly: boolean = false) => {
    const value = isYearly ? amount / 12 : amount;
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value / 100);
  };

  const getPlanIcon = (plan: StripePlan) => {
    switch (plan.metadata.level) {
      case "infinity":
        return faInfinity;
      case "first_steps":
        return plan.metadata.period === "monthly" ? faRocket : faStar;
      default:
        return faRocket;
    }
  };

  const isPopularPlan = (plan: StripePlan) => {
    return (
      plan.metadata.period === "yearly" && plan.metadata.level === "first_steps"
    );
  };

  const isRecommendedPlan = (plan: StripePlan) => {
    return (
      plan.metadata.period === "monthly" &&
      plan.metadata.level === "first_steps"
    );
  };

  const getButtonText = (plan: StripePlan) => {
    if (!currentSubscription)
      return t("project:pages.pricing.pricing_universe.cards_plans.start_now");

    const isCurrentPlan =
      plan.metadata.level === currentSubscription.plan.metadata.level &&
      plan.interval === currentSubscription.plan.interval;

    if (isCurrentPlan) {
      return t(
        "project:pages.pricing.pricing_universe.cards_plans.current_plan"
      );
    }

    return t("project:pages.pricing.pricing_universe.cards_plans.upgrade");
  };

  const isButtonDisabled = (plan: StripePlan) => {
    if (!currentSubscription) return false;

    return (
      plan.metadata.level === currentSubscription.plan.metadata.level &&
      plan.interval === currentSubscription.plan.interval
    );
  };

  return (
    <>
      <CleeckyKit.Column padding="0" gap="4rem">
        <CleeckyKit.Section padding="25dvh 0">
          <CleeckyKit.Row justify="center">
            <CleeckyKit.Title level={1}>
              <CleeckyKit.TextHighlight
                mode={{
                  highlight: true,
                }}>
                {`Cleecky Universe`}
              </CleeckyKit.TextHighlight>
            </CleeckyKit.Title>
          </CleeckyKit.Row>
          <CleeckyKit.Row justify="center">
            <CleeckyKit.Text align="center">
              {t("project:pages.pricing.pricing_universe.description")}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
          <CleeckyKit.Row justify="center">
            <CleeckyKit.Text align="center">
              <FontAwesomeIcon icon={faArrowDown} size="2x" />
            </CleeckyKit.Text>
          </CleeckyKit.Row>
        </CleeckyKit.Section>

        <CleeckyKit.Section padding="0 1rem">
          <CleeckyKit.Row justify="center" gap="2rem">
            {plans.map((plan) => (
              <PlanCard
                key={plan.uuid}
                $isPopular={isPopularPlan(plan)}
                $isRecommended={isRecommendedPlan(plan)}>
                <PlanHeader>
                  <PlanIcon $isPopular={isPopularPlan(plan)}>
                    <FontAwesomeIcon icon={getPlanIcon(plan)} />
                  </PlanIcon>
                  <CleeckyKit.Title align="center" level={3}>
                    {plan.metadata.type !== "lifetime"
                      ? t(
                          `project:pages.pricing.pricing_universe.cards_plans.${plan.metadata.level}.title_${plan.metadata.period}`
                        )
                      : t(
                          `project:pages.pricing.pricing_universe.cards_plans.${plan.metadata.level}.title`
                        )}
                  </CleeckyKit.Title>
                  <PlanPrice>
                    {formatPrice(
                      plan.amount,
                      plan.metadata.period === "yearly" ||
                        plan.metadata.level === "infinity"
                    )}
                    <span>
                      {plan.metadata.level === "infinity" ||
                      plan.metadata.period === "yearly"
                        ? "/" +
                          t("cleeckykit:common.datetime.period.month") +
                          "*"
                        : plan.metadata.period === "monthly"
                        ? "/" + t("cleeckykit:common.datetime.period.month")
                        : ""}
                    </span>
                    {plan.metadata.period === "yearly" &&
                      plan.metadata.level !== "infinity" && (
                        <PlanPriceNote>
                          {t(
                            "project:pages.pricing.pricing_universe.cards_plans.billed_yearly"
                          )}
                        </PlanPriceNote>
                      )}
                    {plan.metadata.level === "infinity" && (
                      <>
                        <PlanPriceNote>
                          {t(
                            "project:pages.pricing.pricing_universe.cards_plans.billed_once"
                          )}
                        </PlanPriceNote>
                      </>
                    )}
                  </PlanPrice>
                </PlanHeader>

                <FeaturesList>
                  {plan.features.map((feature, index) => (
                    <FeatureItem key={index}>
                      <FontAwesomeIcon icon={faCheck} />
                      {t(
                        `project:pages.pricing.pricing_universe.cards_plans.${plan.metadata.level}.features.${feature}`
                      )}
                    </FeatureItem>
                  ))}
                </FeaturesList>

                <CleeckyKit.Row>
                  <CleeckyKit.Button
                    text={getButtonText(plan)}
                    textPosition="inside-right"
                    onClick={() => handleSubscribe(plan.uuid)}
                    fullWidth
                    highlighted
                    isDisabled={isButtonDisabled(plan)}
                    variant={
                      plan.metadata.level === "infinity" ? "outline" : "solid"
                    }
                    customStyle={{
                      background: isPopularPlan(plan)
                        ? currentTheme.colors.status.warning
                        : undefined,
                    }}
                  />{" "}
                </CleeckyKit.Row>

                <CleeckyKit.Row>
                  <CleeckyKit.Button
                    text={t(
                      "project:pages.pricing.pricing_universe.cards_plans.all_features"
                    )}
                    variant="link"
                    onClick={() => {
                      const comparePlansTitle = document.getElementById(
                        "compare-plans-title"
                      );
                      comparePlansTitle?.scrollIntoView({ behavior: "smooth" });
                    }}
                  />
                </CleeckyKit.Row>
              </PlanCard>
            ))}
          </CleeckyKit.Row>
          <CleeckyKit.Row justify="center" padding="2rem 0">
            <CleeckyKit.Text
              align="center"
              customStyle={{ fontSize: "0.875rem" }}>
              {t("project:pages.pricing.pricing_universe.disclaimer")}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
        </CleeckyKit.Section>

        <CleeckyKit.Section padding="0 1rem">
          <CleeckyKit.Card
            background={currentGradient?.secondaryHighlightColor}>
            <CleeckyKit.Row justify="between" align="center">
              <CleeckyKit.Column gap="1rem">
                <CleeckyKit.Title level={4}>
                  {t(
                    "project:pages.pricing.pricing_universe.cards_plans.stay_tuned_card.title"
                  )}
                </CleeckyKit.Title>
                <CleeckyKit.Text>
                  {t(
                    "project:pages.pricing.pricing_universe.cards_plans.stay_tuned_card.description"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.Column>
              <CleeckyKit.Button
                type="button"
                text={t(
                  "project:pages.pricing.pricing_universe.cards_plans.stay_tuned_card.cta"
                )}
                textPosition="inside-right"
                variant="solid"
                onClick={() => navigate("/changelog")}
              />
            </CleeckyKit.Row>
          </CleeckyKit.Card>
        </CleeckyKit.Section>

        <div id="compare-plans-title"></div>
        <CleeckyKit.Section>
          <CleeckyKit.Row justify="center">
            <CleeckyKit.Title level={4}>
              {t("project:pages.pricing.pricing_universe.table.compare_plans")}
            </CleeckyKit.Title>
          </CleeckyKit.Row>

          <div style={{ overflowX: "visible", padding: "0 1rem" }}>
            <CleeckyKit.Table data={comparePlansTableData} />
          </div>

          <CleeckyKit.Row justify="center" padding="1rem 0">
            <CleeckyKit.Text
              align="center"
              customStyle={{ fontSize: "0.875rem" }}>
              {t("project:pages.pricing.pricing_universe.disclaimer_infinity")}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
        </CleeckyKit.Section>

        <CleeckyKit.Row justify="center" padding="1rem">
          <CleeckyKit.Card
            padding="0.3rem"
            width="fit-content"
            borderRadius="5rem"
            background="transparent"
            border={`1px solid ${currentGradient?.highlightColor}`}>
            <CleeckyKit.Row justify="center" align="center" noBreak>
              <CleeckyKit.Text align="center">
                {t(
                  "project:pages.pricing.pricing_universe.cards_plans.working_on_card.title"
                )}
              </CleeckyKit.Text>
              <CleeckyKit.Button
                type="button"
                text={t(
                  "project:pages.pricing.pricing_universe.cards_plans.working_on_card.cta"
                )}
                textPosition="inside-right"
                variant="solid"
                highlighted
                onClick={() => navigate("/changelog")}
              />
            </CleeckyKit.Row>
          </CleeckyKit.Card>
        </CleeckyKit.Row>
        <CleeckyKit.Section>
          <CleeckyInsidersWaitingList />
        </CleeckyKit.Section>
      </CleeckyKit.Column>

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={handleAuthModalClose}
        initialMode="login"
      />
    </>
  );
};

export default PricingHomepage;

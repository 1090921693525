import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { WorkProgressItem } from "CleeckyKit/types";

interface CleeckyKitWorkProgressProps {
  item: WorkProgressItem;
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 12px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  transition: all 0.3s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.card.hover};
    transform: translateY(-2px);
  }
`;

const Header = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.25rem;
  margin: 0 0 8px 0;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1rem;
  margin: 0;
`;

const ProgressContainer = styled.div`
  margin: 16px 0;
`;

const ProgressBar = styled.div<{
  progress: number;
  status: WorkProgressItem["status"];
}>`
  height: 8px;
  background: ${({ theme }) => theme.colors.background.content};
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ progress }) => progress}%;
    background: ${({ theme, status }) => {
      switch (status) {
        case "blocked":
          return theme.colors.status.error;
        case "in_progress":
          return theme.colors.status.info;
        case "testing":
          return theme.colors.status.warning;
        case "review":
          return theme.colors.interactive.primary;
        case "almost_done":
          return theme.colors.status.success;
        default:
          return theme.colors.interactive.primary;
      }
    }};
    transition: width 0.3s ease;
  }
`;

const Status = styled.div<{ status: WorkProgressItem["status"] }>`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.875rem;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.text.onPrimary};
  background: ${({ theme, status }) => {
    switch (status) {
      case "blocked":
        return theme.colors.status.error;
      case "in_progress":
        return theme.colors.status.info;
      case "testing":
        return theme.colors.status.warning;
      case "review":
        return theme.colors.interactive.primary;
      case "almost_done":
        return theme.colors.status.success;
      default:
        return theme.colors.interactive.primary;
    }
  }};
`;

const BlockingReasons = styled.div`
  margin-top: 16px;
`;

const BlockingReasonTitle = styled.h4`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  margin: 0 0 8px 0;
`;

const BlockingReasonList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const BlockingReasonItem = styled.li`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  &:before {
    content: "•";
    color: ${({ theme }) => theme.colors.text.secondary};
    margin-right: 8px;
  }
`;

export const CleeckyKitWorkProgress: React.FC<CleeckyKitWorkProgressProps> = ({
  item,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Title>{item.title}</Title>
        <Description>{item.description}</Description>
      </Header>

      <ProgressContainer>
        <ProgressBar progress={item.progress} status={item.status} />
      </ProgressContainer>

      <Status status={item.status}>
        {t(`pages.changelog.sections.working_on.status.${item.status}`)}
      </Status>

      {item.blockingReasons && item.blockingReasons.length > 0 && (
        <BlockingReasons>
          <BlockingReasonTitle>
            {t("pages.changelog.sections.working_on.blocking_reasons.title")}
          </BlockingReasonTitle>
          <BlockingReasonList>
            {item.blockingReasons.map((reason, index) => (
              <BlockingReasonItem key={index}>
                {t(
                  `pages.changelog.sections.working_on.blocking_reasons.${reason.type}`
                )}
                : {reason.description}
              </BlockingReasonItem>
            ))}
          </BlockingReasonList>
        </BlockingReasons>
      )}
    </Container>
  );
};

export default CleeckyKitWorkProgress;

import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { routes, RouteDefinition } from "../routes/routes";

export interface AuthRedirectState {
  selectedTool?: string;
  from?: string;
}

const getPublicRoutes = (routeDefinitions: RouteDefinition[]): string[] => {
  return routeDefinitions.reduce((acc: string[], route) => {
    if (route.isPublic) {
      acc.push(route.path);
    }
    if (route.children) {
      acc.push(...getPublicRoutes(route.children));
    }
    return acc;
  }, []);
};

const findAppRoute = (
  path: string
): { app: RouteDefinition | undefined; tool: string | undefined } => {
  const segments = path.split("/").filter(Boolean);
  if (segments.length < 2) return { app: undefined, tool: undefined };

  const appPath = `/${segments[0]}`;
  const app = routes.find((route) => route.path === appPath);
  const tool = segments[1];

  return { app, tool };
};

export const useAuthRedirect = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const publicRoutes = useMemo(() => getPublicRoutes(routes), []);

  useEffect(() => {
    // Não redireciona se estiver na página 404
    if (location.pathname === "/404") {
      return;
    }

    const firstSegment = location.pathname.split("/")[1];
    if (firstSegment && firstSegment.startsWith("@")) {
      return;
    }

    if (!user && !publicRoutes.includes(location.pathname)) {
      const { app, tool } = findAppRoute(location.pathname);

      if (app) {
        if (app.isPublic) {
          navigate(app.path, {
            replace: true,
            state: {
              selectedTool: tool,
              from: location.pathname,
            } as AuthRedirectState,
          });
        } else {
          navigate("/", { replace: true });
        }
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [user, location.pathname, navigate, publicRoutes]);
};

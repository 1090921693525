import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";

const StyledList = styled.ul`
  margin: 0.5rem 0;
  padding-left: 1.5rem;
`;

const StyledListItem = styled.li`
  margin: 0.25rem 0;
`;

export const PrivacyPolicySection: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Accordion title="Política de Privacidade da Cleecky">
        <CleeckyKit.AccordionGroup>
          <CleeckyKit.Accordion title="1. Introdução">
            <CleeckyKit.Text>
              Na Cleecky, a privacidade e a segurança dos dados dos nossos
              usuários são prioridades. Esta Política de Privacidade descreve
              como coletamos, usamos, compartilhamos e protegemos as suas
              informações pessoais ao utilizar nossa plataforma. Nosso
              compromisso é com a transparência e o respeito à sua privacidade.
              Ao acessar e utilizar os serviços da Cleecky, você concorda com as
              práticas aqui descritas.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="2. Informações Coletadas">
            <CleeckyKit.Title level={5}>
              2.1 Dados Fornecidos pelo Usuário
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                <strong>Informações Pessoais:</strong> Nome completo, e-mail,
                número de telefone, endereço, e quaisquer outros dados que você
                fornecer durante o cadastro ou ao atualizar seu perfil.
              </StyledListItem>
              <StyledListItem>
                <strong>Informações de Cadastro:</strong> Dados inseridos no
                processo de criação da conta, como nome de usuário e senha.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              2.2 Dados Gerados pelo Uso
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                <strong>Dados de Conexão:</strong> Endereço IP, informações
                sobre o dispositivo (tipo, sistema operacional, navegador) e
                dados de localização.
              </StyledListItem>
              <StyledListItem>
                <strong>Dados de Interação:</strong> Páginas visitadas, tempo de
                navegação, cliques, e outras informações coletadas por meio de
                cookies e tecnologias de rastreamento.
              </StyledListItem>
              <StyledListItem>
                <strong>Cookies e Tecnologias Similares:</strong> Cookies para
                armazenar preferências, identificar sessões, e analisar o
                comportamento de navegação na plataforma.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              2.3 Dados de Terceiros
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                <strong>Integração com Serviços Externos:</strong> Informações
                provenientes de plataformas de terceiros (como login via Google,
                Facebook, etc.) quando você optar por utilizar essas
                funcionalidades.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="3. Como os Dados São Usados">
            <CleeckyKit.Text>
              Utilizamos as informações coletadas para diversas finalidades,
              tais como:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Personalização:</strong> Adaptar a experiência do
                usuário na plataforma, oferecendo conteúdos e serviços de acordo
                com suas preferências.
              </StyledListItem>
              <StyledListItem>
                <strong>Suporte e Manutenção:</strong> Fornecer suporte técnico,
                responder a dúvidas e resolver problemas reportados.
              </StyledListItem>
              <StyledListItem>
                <strong>Melhorias e Desenvolvimento:</strong> Analisar dados de
                uso para melhorar os serviços, identificar tendências e
                desenvolver novas funcionalidades.
              </StyledListItem>
              <StyledListItem>
                <strong>Marketing e Comunicação:</strong> Enviar comunicações
                sobre atualizações, promoções e novidades, desde que haja
                consentimento prévio para atividades de marketing.
              </StyledListItem>
              <StyledListItem>
                <strong>Conformidade Legal:</strong> Cumprir obrigações legais e
                regulatórias.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="4. Compartilhamento de Dados">
            <CleeckyKit.Text>
              A Cleecky poderá compartilhar suas informações pessoais com
              terceiros, nas seguintes situações:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Provedores de Serviços:</strong> Empresas que oferecem
                serviços de pagamento, hospedagem, análise de dados e
                infraestrutura, necessárias para o funcionamento da plataforma.
              </StyledListItem>
              <StyledListItem>
                <strong>Parceiros de Marketing:</strong> Terceiros para a
                divulgação de promoções e novidades, apenas se houver
                consentimento prévio.
              </StyledListItem>
              <StyledListItem>
                <strong>Autoridades Legais:</strong> Em cumprimento a obrigações
                legais, investigações ou processos judiciais.
              </StyledListItem>
              <StyledListItem>
                <strong>Segurança e Prevenção:</strong> Para proteger nossos
                direitos, prevenir fraudes e solucionar problemas técnicos.
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>
              <strong>Importante:</strong> A Cleecky não vende, comercializa ou
              compartilha suas informações pessoais para fins comerciais sem o
              seu consentimento explícito.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="5. Segurança dos Dados">
            <CleeckyKit.Text>
              A proteção das suas informações é fundamental para nós. Para
              garantir a segurança dos dados, adotamos diversas medidas técnicas
              e administrativas, incluindo:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Criptografia:</strong> Uso de protocolos de criptografia
                para a transmissão e armazenamento de dados sensíveis.
              </StyledListItem>
              <StyledListItem>
                <strong>Firewalls e Sistemas de Detecção:</strong> Barreiras de
                proteção e monitoramento para prevenir acessos não autorizados.
              </StyledListItem>
              <StyledListItem>
                <strong>Controles de Acesso:</strong> Restrições de acesso aos
                dados somente a colaboradores autorizados e treinados.
              </StyledListItem>
              <StyledListItem>
                <strong>Monitoramento Contínuo:</strong> Avaliação constante de
                riscos e implementação de atualizações de segurança.
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>
              Embora adotemos medidas robustas, nenhum sistema é 100% infalível,
              e não podemos garantir a inviolabilidade absoluta dos dados.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="6. Cookies e Tecnologias de Rastreamento">
            <CleeckyKit.Title level={5}>6.1 Uso de Cookies</CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                <strong>Cookies Essenciais:</strong> Necessários para o
                funcionamento básico da plataforma (ex.: autenticação de sessão,
                segurança).
              </StyledListItem>
              <StyledListItem>
                <strong>Cookies de Desempenho:</strong> Coletam dados sobre como
                os usuários interagem com a plataforma, auxiliando na melhoria
                contínua dos nossos serviços.
              </StyledListItem>
              <StyledListItem>
                <strong>Cookies de Funcionalidade:</strong> Permitem a
                personalização dos serviços com base nas suas preferências.
              </StyledListItem>
              <StyledListItem>
                <strong>Cookies de Marketing:</strong> Utilizados para exibir
                anúncios relevantes (quando autorizado).
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              6.2 Controle de Cookies
            </CleeckyKit.Title>
            <CleeckyKit.Text>
              Você pode gerenciar as preferências de cookies através das
              configurações do seu navegador. Observe que a desativação de
              cookies pode afetar a funcionalidade e a experiência na
              plataforma.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="7. Direitos dos Usuários">
            <CleeckyKit.Text>
              Os usuários têm direitos sobre seus dados pessoais, incluindo:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Acesso:</strong> Solicitar informações sobre os dados
                que mantemos.
              </StyledListItem>
              <StyledListItem>
                <strong>Correção:</strong> Solicitar a atualização ou correção
                de dados imprecisos ou incompletos.
              </StyledListItem>
              <StyledListItem>
                <strong>Exclusão:</strong> Requerer a exclusão de seus dados,
                observadas as obrigações legais.
              </StyledListItem>
              <StyledListItem>
                <strong>Portabilidade:</strong> Pedir a transferência dos seus
                dados para outro serviço.
              </StyledListItem>
              <StyledListItem>
                <strong>Oposição e Restrição:</strong> Solicitar a limitação do
                processamento ou se opor ao uso dos seus dados para determinadas
                finalidades.
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>
              Para exercer esses direitos, entre em contato conosco pelos canais
              indicados na seção de Contato.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="8. Retenção de Dados">
            <CleeckyKit.Text>
              Manteremos suas informações pessoais apenas pelo tempo necessário
              para as finalidades para as quais foram coletadas, ou conforme
              exigido por lei. Após esse período, os dados serão eliminados ou
              anonimizados, garantindo que não possam ser associados a você.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="9. Alterações na Política de Privacidade">
            <CleeckyKit.Text>
              Podemos atualizar esta Política de Privacidade periodicamente para
              refletir mudanças em nossas práticas ou em requisitos legais. Em
              caso de alterações significativas, notificaremos os usuários por
              meio de avisos na plataforma ou via e-mail. O uso continuado dos
              nossos serviços após as alterações implica a aceitação da versão
              atualizada desta política.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="10. Contato para Questões de Privacidade">
            <CleeckyKit.Text>
              Se você tiver dúvidas, comentários ou solicitações relativas à
              nossa Política de Privacidade, entre em contato conosco:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                E-mail para assuntos legais: legal@cleecky.com
              </StyledListItem>
              <StyledListItem>
                E-mail para suporte: support@cleecky.com
              </StyledListItem>
              <StyledListItem>
                Endereço: Rua Gonçalves Jaquier, 25 - Jardim Ohara, Marília/SP,
                Brasil
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>Data de Vigência: 16/02/2025</CleeckyKit.Text>
            <CleeckyKit.Text>
              Data da Última Atualização: 16/02/2025
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>
        </CleeckyKit.AccordionGroup>
        <br />
        <CleeckyKit.Text>
          Esta Política de Privacidade reforça o compromisso da Cleecky em
          proteger os seus dados e garantir uma experiência segura e
          transparente. Caso tenha quaisquer dúvidas ou precise de mais
          informações, estamos à disposição para atendê-lo.
        </CleeckyKit.Text>
      </CleeckyKit.Accordion>
    </>
  );
};

export default PrivacyPolicySection;

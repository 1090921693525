import React, { forwardRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

interface SidebarHamburgerProps {
  onClick: () => void;
  isOpen: boolean;
}

const HamburgerButton = styled(motion.button)`
  display: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.text.primary};
  border: none;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    display: flex;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.interactive.hover};
  }
`;

export const CleeckyKitSidebarHamburger = forwardRef<
  HTMLButtonElement,
  SidebarHamburgerProps
>(({ onClick, isOpen }, ref) => {
  return (
    <HamburgerButton
      ref={ref}
      onClick={onClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}>
      <FontAwesomeIcon
        icon={faBars}
        style={{
          transform: isOpen ? "rotate(90deg)" : "none",
          transition: "transform 0.3s ease",
        }}
      />
    </HamburgerButton>
  );
});

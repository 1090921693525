import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useCleeckyKitCurrentTheme } from "./CleeckyKitThemeProvider";
import { gradientThemes } from "themes";
import { Z_INDEX } from "../zindex";

interface ColorButtonProps {
  $isActive: boolean;
  color: string;
}

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.text.onGradient};
  font-size: 1.2rem;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  opacity: 0;
  transition: opacity 0.2s ease;
`;

const ColorButton = styled.button<ColorButtonProps>`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.background.card};
  cursor: pointer;
  position: relative;
  background: ${({ color }) => color};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  ${CheckIcon} {
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  }

  &:hover {
    transform: scale(1.05);
    border-color: ${({ theme }) => theme.colors.interactive.active};
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    `
    border-color: ${theme.colors.interactive.selected};
    border-width: 3px;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  `}

  &:hover::after {
    content: attr(data-name);
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ theme }) => theme.colors.background.card};
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    z-index: ${Z_INDEX.default};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const formatThemeName = (name: string) => {
  return name
    .split(/(?=[A-Z])|[-_]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const CleeckyKitHighlightColorPicker: React.FC = () => {
  const { currentGradient, availableGradients, setGradient } =
    useCleeckyKitCurrentTheme();

  if (!availableGradients.length) {
    return null;
  }

  return (
    <ColorGrid>
      {availableGradients.map((gradientName: string) => (
        <ColorButton
          key={gradientName}
          color={gradientThemes[gradientName].highlightColor}
          $isActive={currentGradient?.name === gradientName}
          data-name={formatThemeName(gradientName)}
          onClick={() =>
            setGradient(gradientName, {
              enableAnimation: currentGradient?.enableAnimation ?? true,
              animation: currentGradient?.animation,
            })
          }>
          <CheckIcon icon={faCheck} />
        </ColorButton>
      ))}
    </ColorGrid>
  );
};

export default CleeckyKitHighlightColorPicker; 
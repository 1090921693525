import React, { useState } from "react";
import * as yup from "yup";
import * as CleeckyKit from "../../CleeckyKit/components";
import { t } from "i18next";
import { authService } from "services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";

interface SignUpModalProps {
  onToggleMode: () => void;
  onClose?: () => void;
}

export const SignUpModal: React.FC<SignUpModalProps> = ({
  onToggleMode,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptedTerms: false,
    birthDate: "",
  });

  const [alert, setAlert] = useState<{
    show: boolean;
    message: string;
    variant: "success" | "error";
  }>({
    show: false,
    message: "",
    variant: "success",
  });

  const handleSubmit = async (data: any) => {
    try {
      const birthDate = new Date(data.birthDate);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      if (age < 18) {
        setAlert({
          show: true,
          message: t("project:common.auth.modal.register.form.ageRestriction"),
          variant: "error",
        });
        return;
      }

      const { confirmPassword, ...userData } = data;
      await authService.signup(userData);
      setAlert({
        show: true,
        message: t("cleeckykit:common.status.success"),
        variant: "success",
      });
      setTimeout(() => {
        onClose?.();
      }, 1500);
    } catch (error: any) {
      console.error("Erro no registro:", error);
      let errorMessage = t("cleeckykit:common.form.validations.invalidFormat");

      if (error.response) {
        const status = error.response.status;
        const message = error.response.data?.message;

        if (status === 409) {
          if (message && message.includes("Email already registered")) {
            errorMessage = t(
              "project:common.auth.modal.register.form.emailExists"
            );
          } else if (message && message.includes("Username not available")) {
            errorMessage = t(
              "project:common.auth.modal.register.form.usernameExists"
            );
          } else {
            errorMessage = t(
              "project:common.auth.modal.register.form.userExists"
            );
          }
        } else if (status === 422 || (message && message.includes("18 anos"))) {
          errorMessage = t(
            "project:common.auth.modal.register.form.ageRestriction"
          );
        } else if (status === 400) {
          errorMessage = t(
            "project:common.auth.modal.register.form.missingFields"
          );
        }
      }

      setAlert({
        show: true,
        message: errorMessage,
        variant: "error",
      });
    }
  };

  return (
    <>
      <CleeckyKit.Alert
        isOpen={alert.show}
        message={alert.message}
        variant={alert.variant}
        onClose={() => setAlert({ ...alert, show: false })}
        autoClose={true}
        autoCloseTime={3000}
      />

      <CleeckyKit.Form onSubmit={handleSubmit} defaultValues={formData}>
        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={12}>
            <CleeckyKit.DatePicker
              name="birthDate"
              label={t("project:common.auth.modal.register.form.birthDate")}
              placeholder={t(
                "project:common.auth.modal.register.form.birthDate"
              )}
              borderRadius="1rem"
              fullWidth
              validationVariant="tooltip"
              maxDate={new Date().toISOString().split("T")[0]}
              validations={{
                required: true,
              }}
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>

        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={6}>
            <CleeckyKit.TextInput
              name="firstName"
              label={t("cleeckykit:common.form.name.label")}
              placeholder={t("cleeckykit:common.form.name.placeholder")}
              borderRadius="1rem"
              fullWidth
              validationVariant="tooltip"
              validations={{
                required: true,
              }}
            />
          </CleeckyKit.FormItem>

          <CleeckyKit.FormItem span={6}>
            <CleeckyKit.TextInput
              name="lastName"
              label={t("cleeckykit:common.form.lastName.label")}
              placeholder={t("cleeckykit:common.form.lastName.placeholder")}
              borderRadius="1rem"
              fullWidth
              validationVariant="tooltip"
              validations={{
                required: true,
              }}
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>

        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={12}>
            <CleeckyKit.TextInput
              name="username"
              label={t("cleeckykit:common.form.username.label")}
              placeholder={t("cleeckykit:common.form.username.placeholder")}
              borderRadius="1rem"
              fullWidth
              validationVariant="tooltip"
              prefix={<FontAwesomeIcon icon={faAt} />}
              validations={{
                required: true,
              }}
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>

        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={12}>
            <CleeckyKit.EmailInput
              name="email"
              label={t("cleeckykit:common.form.email.label")}
              placeholder={t("cleeckykit:common.form.email.placeholder")}
              borderRadius="1rem"
              fullWidth
              validationVariant="tooltip"
              validations={{
                required: true,
              }}
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>

        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={6}>
            <CleeckyKit.PasswordInput
              name="password"
              label={t("cleeckykit:common.form.password.label")}
              placeholder={t("cleeckykit:common.form.password.placeholder")}
              borderRadius="1rem"
              showToggleButton
              showStrengthMeter
              fullWidth
              validationVariant="tooltip"
              validations={{
                required: true,
              }}
            />
          </CleeckyKit.FormItem>

          <CleeckyKit.FormItem span={6}>
            <CleeckyKit.ConfirmPasswordInput
              name="confirmPassword"
              label={t("cleeckykit:common.form.confirmPassword.label")}
              placeholder={t(
                "cleeckykit:common.form.confirmPassword.placeholder"
              )}
              borderRadius="1rem"
              showToggleButton
              fullWidth
              validationVariant="tooltip"
              validations={{
                required: true,
              }}
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>

        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={12}>
            <CleeckyKit.Checkbox
              name="acceptedTerms"
              label={t("project:common.auth.modal.register.form.terms")}
              validations={{
                required: true,
                requiredText: t(
                  "project:common.auth.modal.register.form.termsRequired"
                ),
              }}
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>

        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={12}>
            <CleeckyKit.Button
              type="submit"
              text={t("cleeckykit:common.actions.register")}
              variant="solid"
              borderRadius={true}
              size="small"
              fullWidth
              highlighted
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>

        <CleeckyKit.FormGroup>
          <CleeckyKit.FormItem span={12}>
            <CleeckyKit.Button
              type="button"
              text={t("cleeckykit:common.auth.modal.register.form.login")}
              variant="link"
              onClick={onToggleMode}
              fullWidth
            />
          </CleeckyKit.FormItem>
        </CleeckyKit.FormGroup>
      </CleeckyKit.Form>
    </>
  );
};

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Flag from "react-world-flags";
import { Z_INDEX } from "CleeckyKit/themes/zindex";

interface LanguageConfig {
  languageCode: string;
  name: string;
  countryCode: string;
}

interface CleeckyKitLanguageDropdownProps {
  languages?: LanguageConfig[];
  onClose?: () => void;
}

const defaultLanguages: LanguageConfig[] = [
  { languageCode: "ptBR", name: "Português (Brasil)", countryCode: "BR" },
  { languageCode: "en", name: "English", countryCode: "US" },
];

const mergeLanguages = (
  defaults: LanguageConfig[],
  customs?: LanguageConfig[]
): LanguageConfig[] => {
  if (!customs) return defaults;

  const merged = [...defaults];

  customs.forEach((customLang) => {
    const existingIndex = merged.findIndex(
      (lang) => lang.languageCode === customLang.languageCode
    );
    if (existingIndex >= 0) {
      merged[existingIndex] = customLang;
    } else {
      merged.push(customLang);
    }
  });

  return merged;
};

const Dropdown = styled.div`
  background: ${({ theme }) => theme?.colors?.background?.dropdown};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 180px;
  z-index: ${Z_INDEX.buttons.dropdown.menu};
`;

const FlagContainer = styled.div`
  width: 24px;
  height: 16px;
  overflow: hidden;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LanguageOption = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme?.gradient?.highlightColor};
    svg {
      color: ${({ theme }) => theme?.colors?.text?.primary};
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.background?.base};
  }
`;

const LanguageName = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme?.colors?.text?.primary};
`;

const CheckIcon = styled(FontAwesomeIcon)<{ $isActive: boolean }>`
  margin-left: auto;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  color: ${({ theme }) => theme?.gradient?.highlightColor};
  transition: opacity 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme?.colors?.text?.primary};
  }
`;

export const CleeckyKitLanguageDropdown: React.FC<CleeckyKitLanguageDropdownProps> = ({
  languages: customLanguages,
  onClose,
}) => {
  const { i18n } = useTranslation();
  const availableLanguages = mergeLanguages(defaultLanguages, customLanguages);

  const handleLanguageChange = async (languageCode: string) => {
    await i18n.changeLanguage(languageCode);
    if (onClose) onClose();
    window.location.reload();
  };

  return (
    <Dropdown>
      {availableLanguages.map((language) => (
        <LanguageOption
          key={language.languageCode}
          onClick={() => handleLanguageChange(language.languageCode)}>
          <FlagContainer>
            <Flag code={language.countryCode} />
          </FlagContainer>
          <LanguageName>{language.name}</LanguageName>
          <CheckIcon
            icon={faCheck}
            $isActive={i18n.language === language.languageCode}
          />
        </LanguageOption>
      ))}
    </Dropdown>
  );
};

export default CleeckyKitLanguageDropdown; 
import { ComponentType } from "react";
import {
  faHome,
  faMoneyBill,
  faUser,
  faUserEdit,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLink,
  faQrcode,
  faChain,
  faToolbox,
  faCloud,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import {
  Home,
  Toolbox,
  AggregatorDashboard,
  AggregatorPageList,
  AggregatorCreatePage,
  UserProfile,
  UserProfileEdit,
  UserSettings,
  PricingHomepage,
  ChangeLogHomepage,
  ContactsHomepage,
  AboutHomepage,
  TermsAndPrivacyHomepage,
  LicensesHomepage,
  PricingPaymentSuccess,
  PricingPaymentCancel,
  UnderConstructionPage,
} from "../pages";
import i18next, { t } from "i18next";
import AggregatorAccessManagement from "../pages/Toolbox/Tools/Aggregator/AggregatorAccessManagement";

export interface RouteDefinition {
  id: string;
  path: string;
  name: string;
  icon: IconDefinition;
  component?: ComponentType<{ hideNavigation?: boolean }>;
  isPublic?: boolean;
  isDynamic?: boolean;
  dynamicPath?: string;
  showOnMenu?: boolean;
  hideNavigation?: boolean;
  children?: RouteDefinition[];
}

const getRoutes = (): RouteDefinition[] => [
  {
    id: "home",
    path: "/",
    name: t("project:pages.home.title"),
    icon: faHome,
    component: Home,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
  },
  {
    id: "user_profile",
    path: "/profile",
    isDynamic: true,
    dynamicPath: "/@:username",
    name: t("project:user.profile.title"),
    icon: faUser,
    component: /* UserProfile */ UnderConstructionPage,
    isPublic: false,
    showOnMenu: false,
    hideNavigation: false,
    children: [
      {
        id: "user_profile_edit",
        path: "/profile/edit",
        name: t("project:user.profile.editProfile"),
        icon: faUserEdit,
        component: /* UserProfileEdit */ UnderConstructionPage,
        isPublic: false,
        showOnMenu: false,
        hideNavigation: false,
      },
    ],
  },
  {
    id: "user_settings",
    path: "/settings",
    name: t("project:user.settings.title"),
    icon: faUser,
    component: UserSettings,
    isPublic: false,
    showOnMenu: false,
    hideNavigation: false,
  },
  {
    id: "pricing_homepage",
    path: "/pricing",
    name: t("project:pricing.title"),
    icon: faMoneyBill,
    component: PricingHomepage,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
    children: [
      {
        id: "pricing_payment_success",
        path: "/payment/success",
        name: t("pages.pricing.payment.success.title"),
        icon: faMoneyBill,
        component: PricingPaymentSuccess,
        isPublic: true,
        showOnMenu: false,
        hideNavigation: false,
      },
      {
        id: "pricing_payment_cancel",
        path: "/payment/cancel",
        name: t("pages.pricing.payment.cancel.title"),
        icon: faMoneyBill,
        component: PricingPaymentCancel,
        isPublic: true,
        showOnMenu: false,
        hideNavigation: false,
      },
    ],
  },
  {
    id: "change_log_homepage",
    path: "/changelog",
    name: t("project:changelog.title"),
    icon: faMoneyBill,
    component: ChangeLogHomepage,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
  },
  {
    id: "contacts_homepage",
    path: "/contacts",
    name: t("project:contacts.title"),
    icon: faMoneyBill,
    component: ContactsHomepage,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
  },
  {
    id: "about_homepage",
    path: "/about",
    name: t("project:about.title"),
    icon: faMoneyBill,
    component: AboutHomepage,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
  },
  {
    id: "terms_and_privacy_homepage",
    path: "/terms-and-privacy",
    name: t("project:terms_and_privacy.title"),
    icon: faMoneyBill,
    component: TermsAndPrivacyHomepage,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
  },
  /*  {
    id: "licenses_homepage",
    path: "/licenses",
    name: t("project:licenses.title"),
    icon: faMoneyBill,
    component: LicensesHomepage,
    isPublic: true,
    showOnMenu: false,
    hideNavigation: false,
  }, */
  {
    id: "toolbox",
    path: "/toolbox",
    name: t("project:apps.toolbox.app_name"),
    icon: faToolbox,
    component: Toolbox,
    isPublic: true,
    showOnMenu: true,
    hideNavigation: false,
    children: [
      {
        id: "link_aggregator",
        path: "/toolbox/link-aggregator",
        name: t("project:apps.toolbox.tools.link_aggregator.name"),
        icon: faLink,
        component: AggregatorDashboard,
        isDynamic: true,
        dynamicPath: "/@:username/:slug",
        showOnMenu: true,
        isPublic: false,
        hideNavigation: false,
        children: [
          {
            id: "link_aggregator_pages",
            path: "/toolbox/link-aggregator/pages",
            name: t("project:aggregator.pageList.title"),
            icon: faLink,
            component: AggregatorPageList,
            showOnMenu: false,
            isPublic: false,
            hideNavigation: false,
          },
          {
            id: "link_aggregator_create",
            path: "/toolbox/link-aggregator/pages/create",
            name: t("project:aggregator.createPage.title"),
            icon: faLink,
            component: AggregatorCreatePage,
            showOnMenu: false,
            isPublic: false,
            hideNavigation: false,
          },
          {
            id: "link_aggregator_edit",
            path: "/toolbox/link-aggregator/pages/:uuid/edit",
            name: t("project:aggregator.editPage.title"),
            icon: faLink,
            component: AggregatorCreatePage,
            showOnMenu: false,
            isPublic: false,
            hideNavigation: false,
          },
          {
            id: "link_aggregator_access_management",
            path: "/toolbox/link-aggregator/pages/:uuid/access-management",
            name: t("project:aggregator.accessManagement.title"),
            icon: faLink,
            component: AggregatorAccessManagement,
            showOnMenu: false,
            isPublic: false,
            hideNavigation: false,
          },
        ],
      },
      {
        id: "link_shortener",
        path: "/toolbox/link-shortener",
        name: t("project:apps.toolbox.tools.link_shortener.name"),
        icon: faChain,
        component: UnderConstructionPage,
        isDynamic: true,
        dynamicPath: "/:slug",
        showOnMenu: true,
        isPublic: false,
        hideNavigation: true,
      },
      {
        id: "qr_code_generator",
        path: "/toolbox/qr-code-generator",
        name: t("project:apps.toolbox.tools.qr_code_generator.name"),
        icon: faQrcode,
        component: UnderConstructionPage,
        showOnMenu: true,
        isPublic: false,
        hideNavigation: false,
      },
    ],
  },
  {
    id: "services",
    path: "/services",
    name: t("project:apps.services.app_name", "Serviços"),
    component: UnderConstructionPage,
    icon: faCloud,
    showOnMenu: true,
    isPublic: true,
    hideNavigation: false,
    children: [
      {
        id: "api",
        path: "/services/api",
        name: t("project:apps.services.tools.api.name", "API"),
        component: UnderConstructionPage,
        icon: faCode,
        showOnMenu: true,
        isPublic: false,
        hideNavigation: false,
      },
    ],
  },
];

export const routes = getRoutes();

// Atualiza as rotas quando o idioma mudar
i18next.on("languageChanged", () => {
  Object.assign(routes, getRoutes());
});

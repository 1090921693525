import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";
import { PrivacyPolicySection } from "./components/PrivacyPolicySection";
import { TermsOfUseSection } from "./components/TermsOfUseSection";
import { ModerationPolicySection } from "./components/ModerationPolicySection";
import { LicenseTermsSection } from "./components/LicenseTermsSection";

const StyledList = styled.ul`
  margin: 0.5rem 0;
  padding-left: 1.5rem;
`;

const StyledListItem = styled.li`
  margin: 0.25rem 0;
`;

export const TermsAndPrivacyHomepage: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Column
        padding="4rem 1rem"
        gap="2rem"
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}>
        <CleeckyKit.Title level={1} align="center">
          {t("project:pages.terms_and_privacy.title")}
        </CleeckyKit.Title>

        <CleeckyKit.AccordionGroup>
          <TermsOfUseSection />
          <PrivacyPolicySection />
          <ModerationPolicySection />
          <LicenseTermsSection />
        </CleeckyKit.AccordionGroup>

        <CleeckyKit.Text>
          Ao acessar e utilizar a plataforma Cleecky, você declara que leu,
          compreendeu e concorda integralmente com estes Termos, Políticas e
          quaisquer condições adicionais aqui estabelecidas. Se você não
          concordar com qualquer parte destes termos, solicitamos que interrompa
          o uso da plataforma imediatamente.
        </CleeckyKit.Text>
      </CleeckyKit.Column>
    </>
  );
};

export default TermsAndPrivacyHomepage;

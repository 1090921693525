import React, { useState } from "react";
import { CleeckyKitCanvasEditorArea } from "./components/EditorArea/CleeckyKitCanvasEditorArea";
import { CleeckyKitCanvasPreviewArea } from "./components/PreviewArea/CleeckyKitCanvasPreviewArea";
import * as CleeckyKit from "CleeckyKit";
import { CleeckyKitCanvasProps, GlobalColors, GlobalSizes } from "./types";
import { defaultGlobalColors, defaultGlobalSizes } from "./styles";

export const CleeckyKitCanvas: React.FC<CleeckyKitCanvasProps> = ({
  elements,
  sections = [],
  onElementsChange,
  onSectionsChange,
  className,
  globalColors: externalGlobalColors,
  globalSizes: externalGlobalSizes,
  onGlobalColorsChange,
  onGlobalSizesChange,
  elementsLimit,
}) => {
  const [previewMode, setPreviewMode] = useState<"desktop" | "mobile">(
    "desktop"
  );
  const [internalGlobalColors, setInternalGlobalColors] =
    useState<GlobalColors>(externalGlobalColors || defaultGlobalColors);
  const [internalGlobalSizes, setInternalGlobalSizes] = useState<GlobalSizes>(
    externalGlobalSizes || defaultGlobalSizes
  );

  // Atualiza as cores internas quando as cores iniciais mudarem
  React.useEffect(() => {
    if (externalGlobalColors) {
      setInternalGlobalColors(externalGlobalColors);
    }
  }, [externalGlobalColors]);

  // Atualiza os tamanhos internos quando os tamanhos iniciais mudarem
  React.useEffect(() => {
    if (externalGlobalSizes) {
      setInternalGlobalSizes(externalGlobalSizes);
    }
  }, [externalGlobalSizes]);

  const handleGlobalColorsChange = (newColors: GlobalColors) => {
    setInternalGlobalColors(newColors);
    onGlobalColorsChange?.(newColors);
  };

  const handleGlobalSizesChange = (newSizes: GlobalSizes) => {
    setInternalGlobalSizes(newSizes);
    onGlobalSizesChange?.(newSizes);
  };

  return (
    <CleeckyKit.Row>
      <CleeckyKitCanvasEditorArea
        elements={elements}
        sections={sections}
        globalColors={internalGlobalColors}
        globalSizes={internalGlobalSizes}
        onElementsChange={onElementsChange}
        onGlobalColorsChange={handleGlobalColorsChange}
        onGlobalSizesChange={handleGlobalSizesChange}
        className={className}
        elementsLimit={elementsLimit}
      />
      <CleeckyKitCanvasPreviewArea
        elements={elements}
        sections={sections}
        globalColors={internalGlobalColors}
        globalSizes={internalGlobalSizes}
        previewMode={previewMode}
        onPreviewModeChange={setPreviewMode}
      />
    </CleeckyKit.Row>
  );
};

CleeckyKitCanvas.displayName = "CleeckyKitCanvas";

import React, { createContext, ReactNode, useContext, useState } from "react";
import styled from "styled-components";

interface CleeckyKitAccordionsGroupProps {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

interface CleeckyKitAccordionContextProps {
  activeAccordion: string | null;
  setActiveAccordion: (id: string | null) => void;
}

const AccordionContext = createContext<CleeckyKitAccordionContextProps | null>(
  null
);

export const useAccordionContext = () => useContext(AccordionContext);

const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

export const CleeckyKitAccordionsGroup: React.FC<
  CleeckyKitAccordionsGroupProps
> = ({ children, className, style }) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

  return (
    <AccordionContext.Provider value={{ activeAccordion, setActiveAccordion }}>
      <GroupContainer className={className} style={style}>
        {children}
      </GroupContainer>
    </AccordionContext.Provider>
  );
};

export default CleeckyKitAccordionsGroup;

import React from "react";
import styled, { ThemeContext } from "styled-components";
import { GridProps, GridMode, Justify, Theme } from "../../../types";

const BREAKPOINTS = {
  lg: "1200px",
  md: "992px",
  sm: "768px",
} as const;

const getJustifyValue = (justify?: Justify) => {
  const value = justify || "start";
  switch (value) {
    case "center":
      return "center";
    case "end":
      return "end";
    case "between":
      return "space-between";
    case "around":
      return "space-around";
    case "evenly":
      return "space-evenly";
    default:
      return "start";
  }
};

const getGridStyles = (
  mode: GridMode | undefined,
  columns?: Record<string, number | undefined>,
  justify?: Justify
) => {
  switch (mode) {
    case "app":
      if (columns && Object.keys(columns).length > 0) {
        return `
          display: grid;
          justify-content: ${getJustifyValue(justify)};
          grid-auto-flow: row dense;
          & > * {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 120px;
            height: fit-content;
          }

          @media (max-width: ${BREAKPOINTS.sm}) {
            display: flex;
            flex-wrap: wrap;
            justify-content: ${getJustifyValue(justify)};
            gap: inherit;
            min-height: 120px;

            
            & > * {
              flex: 0 0 auto;
              width: calc((100% - (${
                columns.sm || 1
              } - 1) * var(--gap-size)) / ${columns.sm || 1});
            }
          }
        `;
      }
      return `
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        grid-auto-rows: minmax(120px, auto);
        justify-content: ${getJustifyValue(justify)};
        grid-auto-flow: row dense;
        & > * {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `;
    case "list":
      return `
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
      `;
    case "bento":
      return `
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(100px, auto);
        grid-auto-flow: dense;
        overflow-x: hidden;
        width: 100%;
        & > * {
          width: 100%;
          height: 100%;
          display: flex;
          & > * {
            flex: 1;
          }
        }

        @media (max-width: ${BREAKPOINTS.lg}) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: ${BREAKPOINTS.md}) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: ${BREAKPOINTS.sm}) {
          grid-template-columns: 1fr;
          & > * {
            grid-column: 1 !important;
          }
        }
      `;
    default:
      return "";
  }
};

const StyledGrid = styled.div<
  GridProps & {
    $columns: Record<string, number | undefined>;
    $autoFit?: boolean;
    $minColumnWidth?: string;
    $align?: string;
    $justify?: Justify;
    $gap?: string | number;
    $padding?: string | number;
    $margin?: string | number;
    $width?: string;
    $height?: string;
    $itemCount: number;
    $mode?: GridMode;
  }
>`
  display: grid;
  box-sizing: border-box;
  width: 100%;
  min-height: min-content;
  position: relative;
  overflow: visible;
  contain: layout style;
  transform: translateZ(0);
  will-change: transform;

  /* Previne que animações dos filhos afetem o layout */
  & > * {
    position: relative;
    z-index: 1;
    transform: translateZ(0);
  }

  ${({ $align }) => $align && `align-items: ${$align};`}
  ${({ $gap }) =>
    $gap && `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`}
  ${({ $padding }) =>
    $padding &&
    `padding: ${typeof $padding === "number" ? `${$padding}px` : $padding};`}
  ${({ $margin }) =>
    $margin &&
    `margin: ${typeof $margin === "number" ? `${$margin}px` : $margin};`}
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $mode, $columns, $justify }) => getGridStyles($mode, $columns, $justify)}

  ${({ $autoFit, $minColumnWidth, $columns, $itemCount, $justify, $mode }) => {
    if ($mode && !$columns) return "";

    const justifyContent = {
      start: "start",
      center: "center",
      end: "end",
      "flex-start": "start",
      "flex-end": "end",
      between: "space-between",
      around: "space-around",
      evenly: "space-evenly",
      "space-between": "space-between",
      "space-around": "space-around",
      "space-evenly": "space-evenly",
    }[$justify || "start"];

    if ($autoFit && $minColumnWidth) {
      return `
        grid-template-columns: repeat(auto-fit, minmax(${$minColumnWidth}, 1fr));
        justify-content: ${justifyContent};
      `;
    }

    return Object.entries($columns)
      .map(([breakpoint, columns]) => {
        if (!columns) return "";
        const actualColumns = Math.min(columns, $itemCount);

        return `
        @media (min-width: ${
          BREAKPOINTS[breakpoint as keyof typeof BREAKPOINTS] || BREAKPOINTS.sm
        }) {
          grid-template-columns: repeat(${actualColumns}, min-content);
          justify-content: ${justifyContent};
          grid-auto-flow: row dense;
        }
      `;
      })
      .join("\n");
  }}

  @media (max-width: ${BREAKPOINTS.sm}) {
    grid-template-columns: repeat(
      ${({ $columns, $itemCount, $mode }) => {
        if ($mode === "list") return 1;
        const smColumns = $columns.sm;
        if (!smColumns) return 1;
        return Math.min(smColumns, $itemCount);
      }},
      1fr
    );
    grid-auto-flow: row dense;
    ${({ $gap }) => {
      if (!$gap) return "gap: 1rem;";
      return `gap: ${typeof $gap === "number" ? `${$gap}px` : $gap};`;
    }}
  }
`;

const GridContainer = styled.div<{ $justify?: Justify }>`
  width: 100%;
  display: flex;
  justify-content: ${({ $justify }) => {
    const justify = $justify || "start";
    switch (justify) {
      case "start":
        return "flex-start";
      case "center":
        return "center";
      case "end":
        return "flex-end";
      case "between":
        return "space-between";
      case "around":
        return "space-around";
      case "evenly":
        return "space-evenly";
      default:
        return "flex-start";
    }
  }};
  overflow-x: hidden;
`;

export const CleeckyKitGrid: React.FC<GridProps> = ({
  children,
  columns = {},
  autoFit,
  minColumnWidth = "250px",
  align,
  justify,
  gap,
  padding,
  margin,
  width,
  height,
  className,
  mode,
}) => {
  const itemCount = React.Children.count(children);

  const grid = (
    <StyledGrid
      $columns={columns}
      $autoFit={autoFit}
      $minColumnWidth={minColumnWidth}
      $align={align}
      $justify={justify}
      $gap={gap}
      $padding={padding}
      $margin={margin}
      $width={width}
      $height={height}
      $itemCount={itemCount}
      $mode={mode}
      className={className}>
      {children}
    </StyledGrid>
  );

  if (mode === "app") {
    return <GridContainer $justify={justify}>{grid}</GridContainer>;
  }

  return grid;
};

import React from "react";
import styled from "styled-components";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useFormContext } from "react-hook-form";
import * as CleeckyKit from "CleeckyKit";

interface CheckboxProps {
  name?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  label?: React.ReactNode;
  labelPosition?: "left" | "right";
  size?: "small" | "medium" | "large";
  validations?: {
    required?: boolean;
    requiredText?: string;
  };
}

const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const CheckboxWrapper = styled.label<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const LabelContainer = styled.div<{ hasError?: boolean }>`
  margin: 0 0.5rem;
  color: ${({ theme, hasError }) =>
    hasError
      ? theme?.colors?.status?.error || "#ff0000"
      : theme?.colors?.text?.primary || "#000000"};
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const StyledCheckbox = styled.div<{
  checked: boolean;
  size: "small" | "medium" | "large";
  background: string;
  hasError?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) =>
    ({
      small: "16px",
      medium: "20px",
      large: "24px",
    }[size])};
  height: ${({ size }) =>
    ({
      small: "16px",
      medium: "20px",
      large: "24px",
    }[size])};
  background: ${({ checked, background }) =>
    checked ? background : "transparent"};
  border: 2px solid
    ${({ checked, background, theme, hasError }) =>
      hasError
        ? theme?.colors?.status?.error || "#ff0000"
        : checked
        ? background
        : theme?.colors?.border?.input || "#cccccc"};
  border-radius: 4px;
  transition: all 0.2s;
  color: white;
  font-size: ${({ size }) =>
    ({
      small: "10px",
      medium: "12px",
      large: "14px",
    }[size])};

  &:hover {
    border-color: ${({ background, hasError, theme }) =>
      hasError ? theme?.colors?.status?.error || "#ff0000" : background};
  }
`;

export const CleeckyKitCheckbox: React.FC<CheckboxProps> = ({
  name,
  checked: externalChecked,
  onChange: externalOnChange,
  disabled = false,
  label,
  labelPosition = "right",
  size = "medium",
  validations,
}) => {
  const { currentGradient } = useCleeckyKitCurrentTheme();
  const formContext = useFormContext();

  const isFormControlled = name && formContext;

  // Se não estiver em um formulário, use o estado controlado externamente
  if (!isFormControlled) {
    return (
      <CheckboxContainer disabled={disabled}>
        {label && labelPosition === "left" && (
          <LabelContainer>
            <CleeckyKit.Text>{label}</CleeckyKit.Text>
          </LabelContainer>
        )}
        <CheckboxWrapper disabled={disabled}>
          <HiddenCheckbox
            type="checkbox"
            checked={externalChecked}
            onChange={(e) => externalOnChange?.(e.target.checked)}
            disabled={disabled}
          />
          <StyledCheckbox
            checked={externalChecked || false}
            size={size}
            background={currentGradient?.highlightColor || "#007bff"}>
            {externalChecked && <FontAwesomeIcon icon={faCheck} />}
          </StyledCheckbox>
        </CheckboxWrapper>
        {label && labelPosition === "right" && (
          <LabelContainer>
            <CleeckyKit.Text>{label}</CleeckyKit.Text>
          </LabelContainer>
        )}
      </CheckboxContainer>
    );
  }

  // Se estiver em um formulário, use o estado do formulário
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = formContext;

  const error = errors[name];
  const hasError = !!error;
  const checked = watch(name);

  const { onChange: registerOnChange, ...registerRest } = register(name, {
    ...validations,
    setValueAs: (value: any) => {
      return value === "on" ? true : value === "off" ? false : Boolean(value);
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    registerOnChange(e);
    externalOnChange?.(e.target.checked);
  };

  return (
    <CheckboxContainer disabled={disabled}>
      {label && labelPosition === "left" && (
        <LabelContainer hasError={hasError}>
          <CleeckyKit.Text>{label}</CleeckyKit.Text>
        </LabelContainer>
      )}
      <CheckboxWrapper disabled={disabled}>
        <HiddenCheckbox
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...registerRest}
        />
        <StyledCheckbox
          checked={checked || false}
          size={size}
          hasError={hasError}
          background={currentGradient?.highlightColor || "#007bff"}>
          {checked && <FontAwesomeIcon icon={faCheck} />}
        </StyledCheckbox>
      </CheckboxWrapper>
      {label && labelPosition === "right" && (
        <LabelContainer hasError={hasError}>
          <CleeckyKit.Text>{label}</CleeckyKit.Text>
        </LabelContainer>
      )}
    </CheckboxContainer>
  );
};

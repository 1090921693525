import React, { useState, useEffect, useRef } from "react";
import * as CleeckyKit from "CleeckyKit";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { authService } from "services/authService";
import html2canvas from "html2canvas";
import {
  aggregatorService,
  AggregatorPageVisibilityType,
} from "services/aggregatorService";
import { toolLimitsService } from "services/toolLimits";
import type { ContentData, AggregatorPageData } from "cleecky@types/interfaces";
import { ToolType } from "cleecky@types/enums";
import {
  CanvasElement,
  GlobalColors,
  GlobalSizes,
} from "CleeckyKit/components/Canvas/types";
import { LoadingScreen } from "components/Loading/LoadingScreen";
import {
  defaultGlobalColors,
  defaultGlobalSizes,
} from "CleeckyKit/components/Canvas/styles";
import ReactDOM from "react-dom/client";
import AggregatorPage from "./AggregatorPage";
import { CleeckyKitThemeProvider } from "CleeckyKit/themes/themeUtilities/CleeckyKitThemeProvider";

// Função auxiliar para garantir que a cor está no formato correto
const ensureValidHexColor = (color: string) => {
  // Se a cor já começa com #, retorna ela mesma
  if (color.startsWith("#")) {
    return color;
  }
  // Se não, adiciona o #
  return `#${color}`;
};

// Função auxiliar para garantir que o tamanho está no formato correto
const ensureValidSize = (size: string) => {
  // Se o tamanho já termina com px, retorna ele mesmo
  if (size.endsWith("px")) {
    return size;
  }
  // Se não, adiciona px
  return `${size}px`;
};

// Função para formatar o nome do usuário (pegar primeira e última palavra)
const formatUserName = (firstName: string, lastName: string) => {
  // Combina firstName e lastName em uma única string
  const fullName = `${firstName} ${lastName}`.trim();

  // Divide o nome em palavras
  const words = fullName.split(/\s+/).filter((word) => word.length > 0);

  // Se tiver apenas uma palavra, retorna ela
  if (words.length === 1) {
    return words[0];
  }

  // Retorna a primeira e última palavra
  return `${words[0]} ${words[words.length - 1]}`;
};

const AggregatorCreatePage: React.FC = () => {
  const { uuid } = useParams();
  const { state } = useLocation();
  const [elements, setElements] = useState<CanvasElement[]>([]);
  const [pageData, setPageData] = useState<AggregatorPageData | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [globalColors, setGlobalColors] =
    useState<GlobalColors>(defaultGlobalColors);
  const [globalSizes, setGlobalSizes] =
    useState<GlobalSizes>(defaultGlobalSizes);
  const [userData, setUserData] = useState<any>(null);
  const [toolLimits, setToolLimits] = useState<any>(null);
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Carrega os limites da ferramenta
        const limits = await toolLimitsService.getToolLimits(
          ToolType.LINK_AGGREGATOR
        );
        setToolLimits(limits);

        if (uuid) {
          // Se tiver uuid, carrega os dados da página existente
          const page = await aggregatorService.getById(uuid);
          setPageData(page);

          // Atualiza o state com o título da página
          navigate(".", {
            state: {
              ...state,
              initialTitle: page.title,
              initialSlug: page.slug,
            },
            replace: true, // Substitui o estado atual ao invés de adicionar novo
          });

          // Configura as cores globais com os dados da página, usando valores padrão quando não definidos
          setGlobalColors({
            highlightColor:
              page.highlightColor || defaultGlobalColors.highlightColor,
            pageBackgroundColor:
              page.pageBackgroundColor ||
              defaultGlobalColors.pageBackgroundColor,
            pageContentBackgroundColor:
              page.pageContentBackgroundColor ||
              defaultGlobalColors.pageContentBackgroundColor,
            buttonsBackgroundColor:
              page.buttonsBackgroundColor ||
              defaultGlobalColors.buttonsBackgroundColor,
            buttonsLabelColor:
              page.buttonsLabelColor || defaultGlobalColors.buttonsLabelColor,
            textColor: page.textColor || defaultGlobalColors.textColor,
            borderColor: page.borderColor || defaultGlobalColors.borderColor,
          });

          // Configura os tamanhos globais, usando valores padrão quando não definidos
          setGlobalSizes({
            borderWidth: page.borderWidth || defaultGlobalSizes.borderWidth,
            borderRadius: page.borderRadius || defaultGlobalSizes.borderRadius,
          });

          // Converte os conteúdos da página para elementos do canvas
          const pageElements = page.contents.map((content) => {
            // Ajusta o conteúdo baseado no tipo
            let adjustedContent = { ...content.data };

            // Ajusta o tamanho do texto para o formato esperado
            if (content.type === "TITLE" || content.type === "TEXT") {
              const textContent = adjustedContent as any;
              if (textContent.size) {
                // Converte o tamanho para o formato esperado (h1-h6)
                const sizeMap: {
                  [key: string]: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
                } = {
                  "32px": "h1",
                  "28px": "h2",
                  "24px": "h3",
                  "20px": "h4",
                  "16px": "h5",
                  "14px": "h6",
                };
                textContent.size = sizeMap[textContent.size] || "h3";
              }
            }

            return {
              id: content.uuid,
              type: content.type,
              order: content.order,
              show: content.show,
              content: adjustedContent,
            } as CanvasElement;
          });

          setElements(pageElements);
        } else {
          // Se não tiver uuid, carrega os dados do usuário para criar uma nova página
          const userData = await authService.getCurrentUser();
          if (userData) {
            const initialElements: CanvasElement[] = [
              {
                id: `avatar_${Date.now()}`,
                type: "AVATAR",
                order: 0,
                show: true,
                content: {
                  file: {
                    path: userData.avatar,
                    type: "EXTERNAL",
                  },
                  order: 0,
                  show: true,
                  shape: "CIRCLE",
                },
              },
              {
                id: `name_${Date.now()}`,
                type: "NAME",
                order: 0,
                show: true,
                content: {
                  text: `${userData.firstName} ${userData.lastName}`,
                  order: 0,
                  show: true,
                  alignment: "CENTER",
                },
              },
              {
                id: `username_${Date.now()}`,
                type: "USERNAME",
                order: 1,
                show: true,
                content: {
                  text: userData.username,
                  order: 1,
                  show: true,
                  alignment: "CENTER",
                },
              },
            ];
            setElements(initialElements);
          }
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      } finally {
        setInitialLoading(false);
      }
    };

    loadData();
  }, [uuid]);

  // Novo useEffect para atualizar os elementos NAME e USERNAME
  useEffect(() => {
    const updateUserElements = async () => {
      if (!userData) {
        const currentUser = await authService.getCurrentUser();
        setUserData(currentUser);
        return;
      }

      setElements((currentElements) =>
        currentElements.map((element) => {
          if (element.type === "NAME") {
            return {
              ...element,
              content: {
                ...element.content,
                text: formatUserName(userData.firstName, userData.lastName),
              },
            };
          }
          if (element.type === "USERNAME") {
            return {
              ...element,
              content: {
                ...element.content,
                text: userData.username,
              },
            };
          }
          return element;
        })
      );
    };

    updateUserElements();
  }, [userData, elements.length]);

  const handleElementsChange = (newElements: CanvasElement[]) => {
    // Verifica se a quantidade de elementos não excede o limite
    if (toolLimits && newElements.length > toolLimits.elementsLimit) {
      return;
    }

    // Garantir que os elementos NAME e USERNAME mantenham os dados do usuário
    const updatedElements = newElements.map((element) => {
      if (element.type === "NAME" && userData) {
        return {
          ...element,
          content: {
            ...element.content,
            text: formatUserName(userData.firstName, userData.lastName),
          },
        };
      }
      if (element.type === "USERNAME" && userData) {
        return {
          ...element,
          content: {
            ...element.content,
            text: userData.username,
          },
        };
      }
      return element;
    });

    setElements(updatedElements);
  };

  const handleGlobalColorsChange = (newColors: GlobalColors) => {
    setGlobalColors(newColors);
  };

  const handleGlobalSizesChange = (newSizes: GlobalSizes) => {
    setGlobalSizes(newSizes);
  };

  const generateThumbnail = async () => {
    try {
      // Cria um elemento temporário para renderizar a página
      const tempDiv = document.createElement("div");
      tempDiv.style.width = "1200px";
      tempDiv.style.height = "630px";
      document.body.appendChild(tempDiv);

      // Cria os dados da página no formato esperado pelo AggregatorPage
      const previewData: AggregatorPageData = {
        uuid: uuid || "preview",
        title: state?.initialTitle || "",
        slug: state?.initialSlug || "",
        visibility: (pageData?.visibility ||
          AggregatorPageVisibilityType.UNPUBLISHED) as AggregatorPageVisibilityType,
        textColor: ensureValidHexColor(globalColors.textColor),
        highlightColor: ensureValidHexColor(globalColors.highlightColor),
        borderColor: ensureValidHexColor(globalColors.borderColor),
        pageBackgroundColor: ensureValidHexColor(
          globalColors.pageBackgroundColor
        ),
        pageContentBackgroundColor: ensureValidHexColor(
          globalColors.pageContentBackgroundColor
        ),
        buttonsBackgroundColor: ensureValidHexColor(
          globalColors.buttonsBackgroundColor
        ),
        buttonsLabelColor: ensureValidHexColor(globalColors.buttonsLabelColor),
        borderWidth: ensureValidSize(globalSizes.borderWidth),
        borderRadius: ensureValidSize(globalSizes.borderRadius),
        contents: elements.map((element, index) => ({
          uuid: element.id,
          type: element.type,
          data: {
            ...element.content,
            order: index,
          } as ContentData,
          order: index,
          show: element.show,
        })),
        user: {
          uuid: userData?.uuid || "",
          username: userData?.username || "",
          firstName: userData?.firstName || "",
          lastName: userData?.lastName || "",
          avatarUrl: userData?.avatar,
        },
      };

      // Renderiza o AggregatorPage dentro do ThemeProvider no elemento temporário
      const root = ReactDOM.createRoot(tempDiv);
      root.render(
        <CleeckyKitThemeProvider>
          <AggregatorPage data={previewData} />
        </CleeckyKitThemeProvider>
      );

      // Aguarda um momento para garantir que tudo foi renderizado
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Configura opções do html2canvas
      const options = {
        scale: 2, // Aumenta a qualidade
        width: 1200,
        height: 630,
        useCORS: true, // Permite carregar imagens de outros domínios
        backgroundColor: globalColors.pageBackgroundColor, // Mantém o fundo transparente
      };

      // Gera o canvas
      const canvas = await html2canvas(tempDiv, options);

      // Remove o elemento temporário
      root.unmount();
      document.body.removeChild(tempDiv);

      // Converte o canvas para blob
      return new Promise<File>((resolve) => {
        canvas.toBlob(async (blob) => {
          if (!blob) return null;
          const file = new File([blob], "thumbnail.png", { type: "image/png" });
          resolve(file);
        }, "image/png");
      });
    } catch (error) {
      console.error("Erro ao gerar thumbnail:", error);
      return null;
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      // Verifica se o título está vazio
      if (!state?.initialTitle) {
        throw new Error("O título da página é obrigatório");
      }

      // Gera a thumbnail antes de salvar
      const thumbnailFile = await generateThumbnail();
      let thumbnailUrl: string | undefined = undefined;

      if (thumbnailFile) {
        try {
          const uploadResult = await aggregatorService.uploadFile(
            thumbnailFile
          );
          thumbnailUrl = uploadResult.url;
        } catch (error) {
          console.error("Erro ao fazer upload da thumbnail:", error);
        }
      }

      // Função auxiliar para processar arquivos
      const processFileContent = async (content: any) => {
        try {
          // Se for um arquivo do computador (blob)
          if (
            content.file?.type === "FILE" &&
            content.file?.path.startsWith("blob:")
          ) {
            // Converte o blob URL para File
            const response = await fetch(content.file.path);
            const blob = await response.blob();
            const fileName = content.file.path.split("/").pop() || "image";
            const file = new File([blob], fileName, { type: blob.type });

            // Faz o upload do arquivo
            const uploadResult = await aggregatorService.uploadFile(file);

            // Atualiza o path com a URL do S3
            return {
              ...content,
              file: {
                path: uploadResult.url,
                type: "FILE",
              },
            };
          }

          // Se for uma imagem em um link
          if (
            content.image?.type === "FILE" &&
            content.image?.path.startsWith("blob:")
          ) {
            const response = await fetch(content.image.path);
            const blob = await response.blob();
            const fileName = content.image.path.split("/").pop() || "image";
            const file = new File([blob], fileName, { type: blob.type });

            const uploadResult = await aggregatorService.uploadFile(file);

            return {
              ...content,
              image: {
                ...content.image,
                path: uploadResult.url,
                type: "FILE",
              },
            };
          }

          return content;
        } catch (error: any) {
          console.error("Erro ao processar arquivo:", error);
          throw new Error(`Erro ao fazer upload do arquivo: ${error.message}`);
        }
      };

      // Processa todos os elementos que podem conter arquivos
      const processedElements = await Promise.all(
        elements.map(async (element) => {
          if (["AVATAR", "IMAGE", "LINK"].includes(element.type)) {
            try {
              const processedContent = await processFileContent(
                element.content
              );
              return {
                ...element,
                content: processedContent,
              };
            } catch (error: any) {
              console.error(
                `Erro ao processar elemento ${element.type}:`,
                error
              );
              throw error;
            }
          }
          return element;
        })
      );

      const pagePayload = {
        title: state?.initialTitle || "",
        slug: state?.initialSlug || `page-${Date.now()}`,
        visibility: (pageData?.visibility ||
          AggregatorPageVisibilityType.UNPUBLISHED) as AggregatorPageVisibilityType,
        textColor: ensureValidHexColor(globalColors.textColor),
        highlightColor: ensureValidHexColor(globalColors.highlightColor),
        borderColor: ensureValidHexColor(globalColors.borderColor),
        pageBackgroundColor: ensureValidHexColor(
          globalColors.pageBackgroundColor
        ),
        pageContentBackgroundColor: ensureValidHexColor(
          globalColors.pageContentBackgroundColor
        ),
        buttonsBackgroundColor: ensureValidHexColor(
          globalColors.buttonsBackgroundColor
        ),
        buttonsLabelColor: ensureValidHexColor(globalColors.buttonsLabelColor),
        borderWidth: ensureValidSize(globalSizes.borderWidth),
        borderRadius: ensureValidSize(globalSizes.borderRadius),
        contents: processedElements.map((element, index) => ({
          type: element.type,
          data: {
            ...element.content,
            order: index,
          },
          order: index,
          show: element.content.show,
        })),
        ...(thumbnailUrl && { thumbnailUrl }), // Só inclui thumbnailUrl se existir
      };

      if (uuid) {
        await aggregatorService.update(uuid, pagePayload);
      } else {
        await aggregatorService.create(pagePayload);
      }

      navigate("/toolbox/link-aggregator/pages");
    } catch (error) {
      console.error("Erro ao salvar página:", error);
    } finally {
      setLoading(false);
    }
  };

  if (initialLoading) {
    return (
      <LoadingScreen
        message={t(
          "project:apps.toolbox.tools.link_aggregator.loading.loading_page"
        )}
      />
    );
  }

  return (
    <>
      {loading && (
        <LoadingScreen
          message={t(
            `project:apps.toolbox.tools.link_aggregator.loading.${
              uuid ? "saving_page" : "creating_page"
            }`
          )}
        />
      )}
      <CleeckyKit.Column>
        <CleeckyKit.Row justify="between" noBreak>
          <CleeckyKit.Button
            text={t("cleeckykit:common.actions.back")}
            textPosition="inside-right"
            variant="solid"
            size="small"
            onClick={() => navigate(-1)}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
          />
          <CleeckyKit.Button
            text={t("cleeckykit:common.actions.save")}
            textPosition="inside-right"
            variant="solid"
            size="small"
            onClick={handleSave}
            icon={<FontAwesomeIcon icon={faSave} />}
            highlighted
          />
        </CleeckyKit.Row>
        <CleeckyKit.Row>
          <CleeckyKit.Title align="left" level={3}>
            {uuid
              ? t("project:apps.toolbox.tools.link_aggregator.edit_page.title")
              : t(
                  "project:apps.toolbox.tools.link_aggregator.create_page.title"
                )}
          </CleeckyKit.Title>
        </CleeckyKit.Row>
        <div ref={canvasRef}>
          <CleeckyKit.Canvas
            elements={elements}
            onElementsChange={handleElementsChange}
            globalColors={globalColors}
            globalSizes={globalSizes}
            onGlobalColorsChange={handleGlobalColorsChange}
            onGlobalSizesChange={handleGlobalSizesChange}
            elementsLimit={toolLimits?.elementsLimit}
          />
        </div>
      </CleeckyKit.Column>
    </>
  );
};

export default AggregatorCreatePage;

import React, { useEffect, useState } from "react";
import * as CleeckyKit from "CleeckyKit";
import { QuickSummaryCards } from "./components/QuickSummaryCards";
import { RecentPages } from "./components/RecentPages";
import { DetailedMetrics } from "./components/DetailedMetrics";
import { aggregatorService } from "../../../../services/aggregatorService";
import {
  MetricsPeriod,
  PageStatsResponse,
  PeakAccessPeriodsResponse,
  TrafficSourceStatsResponse,
} from "cleecky@types/interfaces";

const AggregatorDashboard: React.FC = () => {
  const [period, setPeriod] = useState<MetricsPeriod>("week");
  const [pageStats, setPageStats] = useState<PageStatsResponse | null>(null);
  const [peakPeriods, setPeakPeriods] =
    useState<PeakAccessPeriodsResponse | null>(null);
  const [trafficStats, setTrafficStats] =
    useState<TrafficSourceStatsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadMetrics = async () => {
    try {
      setIsLoading(true);
      const [stats, peaks, traffic] = await Promise.all([
        aggregatorService.getPageStats(period),
        aggregatorService.getPeakAccessPeriods(period),
        aggregatorService.getTrafficSourceStats(period),
      ]);

      setPageStats(stats);
      setPeakPeriods(peaks);
      setTrafficStats(traffic);
    } catch (error) {
      console.error("Erro ao carregar métricas:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMetrics();
  }, [period]);

  if (isLoading) {
    return <CleeckyKit.LoadingScreen />;
  }

  return (
    <>
      <CleeckyKit.Column gap="2rem">
        {/* Quick Summary */}
        <QuickSummaryCards
          period={period}
          onPeriodChange={setPeriod}
          pageStats={pageStats}
          peakPeriods={peakPeriods}
          trafficStats={trafficStats}
        />

        {/* Recent Pages */}
        <RecentPages />

        {/* Detailed Metrics */}
        <DetailedMetrics
          period={period}
          onPeriodChange={setPeriod}
          pageStats={pageStats}
          peakPeriods={peakPeriods}
          trafficStats={trafficStats}
        />
      </CleeckyKit.Column>
    </>
  );
};

export default AggregatorDashboard;

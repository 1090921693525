import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeDropper } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface ColorFormat {
  type: "hex" | "rgb" | "hsl";
  value: string;
}

interface ColorPickerProps {
  value?: string;
  opacity?: number;
  onChange?: (color: string, opacity: number) => void;
  defaultValue?: string;
  defaultOpacity?: number;
  label?: string | "none";
  required?: boolean;
  disableOpacity?: boolean;
}

const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  //width: 100%;
  max-width: 500px;
`;

const ColorPreviewButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  padding: 2px;
  background: transparent;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
  }
`;

const ColorPreviewBox = styled.div<{ $color: string; $opacity: number }>`
  width: 100%;
  height: 100%;
  background: ${({ $color }) => $color};
  opacity: ${({ $opacity }) => $opacity / 100};
  border-radius: 2px;
`;

const ColorCodeInput = styled.input`
  width: 100%;
  min-width: 6rem;
  max-width: 6rem;
  height: 2.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  padding: 0 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.background.input};

  &:focus {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
    outline: none;
  }
`;

const OpacityInput = styled.input`
  width: 100%;
  min-width: 3rem;
  max-width: 3rem;
  height: 2.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  padding: 0 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.background.input};

  &:focus {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
    outline: none;
  }
`;

const ColorPickerPopup = styled.div`
  position: fixed;
  background: ${({ theme }) => theme.colors.background.card};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  width: 100%;
  min-width: 240px;
  max-width: 300px;

  @media (max-width: 768px) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 90%;
    max-width: 320px;
  }
`;

const SaturationBox = styled.div<{ $hue: number }>`
  width: 100%;
  height: 160px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: crosshair;
  background: linear-gradient(
    to right,
    hsl(${({ $hue }) => $hue}, 0%, 50%),
    hsl(${({ $hue }) => $hue}, 100%, 50%)
  );

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, white, transparent 50%, black);
  }
`;

const SaturationCursor = styled.div<{ $x: number; $y: number }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  left: ${({ $x }) => $x}%;
  top: ${({ $y }) => $y}%;
  pointer-events: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const ControlsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 16px;
`;

const EyeDropperButton = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
  }
`;

const HueSlider = styled.input`
  width: 100%;
  height: 12px;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(
    to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  border-radius: 6px;
  margin: 8px 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 2px solid ${({ theme }) => theme.colors.border.input};
    cursor: pointer;
  }
`;

const OpacitySlider = styled.input<{ $color: string }>`
  width: 100%;
  height: 12px;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(to right, transparent, ${({ $color }) => $color});
  border-radius: 6px;
  margin: 8px 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 2px solid ${({ theme }) => theme.colors.border.input};
    cursor: pointer;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;
  flex-wrap: wrap;
`;

const FormatSelect = styled.select`
  height: 36px;
  min-width: 70px;
  max-width: 90px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  padding: 0 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.background.input};

  &:focus {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
    outline: none;
  }
`;

const ColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    text-align: left;
  }
`;

const hexToHsl = (hex: string): [number, number, number] => {
  // Remove o # se existir
  hex = hex.replace("#", "");

  // Converte hex para RGB
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
};

const hslToHex = (h: number, s: number, l: number): string => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  const rHex = Math.round((r + m) * 255)
    .toString(16)
    .padStart(2, "0");
  const gHex = Math.round((g + m) * 255)
    .toString(16)
    .padStart(2, "0");
  const bHex = Math.round((b + m) * 255)
    .toString(16)
    .padStart(2, "0");

  return `#${rHex}${gHex}${bHex}`;
};

const hexToRgb = (hex: string): [number, number, number] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [0, 0, 0];
};

const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const parseRgb = (rgb: string): [number, number, number] | null => {
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!match) return null;

  const [_, r, g, b] = match;
  return [Number(r), Number(g), Number(b)];
};

const parseHsl = (hsl: string): [number, number, number] | null => {
  const match = hsl.match(/^hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)$/);
  if (!match) return null;

  const [_, h, s, l] = match;
  return [Number(h), Number(s), Number(l)];
};

export const CleeckyKitColorPicker: React.FC<ColorPickerProps> = ({
  value = "#000000",
  opacity = 100,
  onChange,
  defaultValue = "#000000",
  defaultOpacity = 100,
  label,
  required,
  disableOpacity = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentColor, setCurrentColor] = useState(value || defaultValue);
  const [currentOpacity, setCurrentOpacity] = useState(
    opacity || defaultOpacity
  );
  const [colorFormat, setColorFormat] = useState<ColorFormat>({
    type: "hex",
    value: value || defaultValue,
  });
  const [hue, setHue] = useState(0);
  const [saturation, setSaturation] = useState(100);
  const [lightness, setLightness] = useState(50);
  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const saturationBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const [h, s, l] = hexToHsl(currentColor);
    setHue(h);
    setSaturation(s);
    setLightness(l);
  }, [currentColor]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const getPopupPosition = () => {
    if (!containerRef.current) return { top: 0, left: 0 };

    const rect = containerRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
    const popupHeight = 350; // Altura aproximada do popup

    // Se não houver espaço suficiente abaixo, posiciona acima
    if (spaceBelow < popupHeight && spaceAbove > spaceBelow) {
      return {
        top: rect.top - popupHeight - 8,
        left: rect.left,
      };
    }

    // Posiciona abaixo por padrão
    return {
      top: rect.bottom + 8,
      left: rect.left,
    };
  };

  const handleColorChange = (newColor: string) => {
    setCurrentColor(newColor);
    setColorFormat((prev) => ({
      ...prev,
      value: formatColor(newColor, prev.type),
    }));
    onChange?.(newColor, disableOpacity ? 100 : currentOpacity);
  };

  const handleOpacityChange = (newOpacity: number) => {
    setCurrentOpacity(newOpacity);
    onChange?.(currentColor, newOpacity);
  };

  const handleSaturationBoxClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!saturationBoxRef.current) return;

    const rect = saturationBoxRef.current.getBoundingClientRect();
    const x = Math.max(
      0,
      Math.min(100, ((e.clientX - rect.left) / rect.width) * 100)
    );
    const y = Math.max(
      0,
      Math.min(100, ((e.clientY - rect.top) / rect.height) * 100)
    );

    // Saturação vai de 0 a 100 (esquerda para direita)
    const s = x;
    // Luminosidade vai de 100 a 0 (cima para baixo)
    const l = 100 - y;

    // Ajuste para garantir que os valores extremos sejam alcançados
    const adjustedS = Math.round(s);
    const adjustedL = Math.round(l);

    setSaturation(adjustedS);
    setLightness(adjustedL);

    const newColor = hslToHex(hue, adjustedS, adjustedL);
    handleColorChange(newColor);
  };

  const handleHueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHue = Number(e.target.value);
    setHue(newHue);
    const newColor = hslToHex(newHue, saturation, lightness);
    handleColorChange(newColor);
  };

  const handleEyeDropper = async () => {
    try {
      // @ts-ignore - EyeDropper API ainda é experimental
      const eyeDropper = new EyeDropper();
      const result = await eyeDropper.open();
      handleColorChange(result.sRGBHex);
    } catch (e) {
      console.error("EyeDropper não suportado", e);
    }
  };

  const formatColor = (
    color: string,
    format: "hex" | "rgb" | "hsl"
  ): string => {
    switch (format) {
      case "hex":
        return color;
      case "rgb": {
        const [r, g, b] = hexToRgb(color);
        return `rgb(${r}, ${g}, ${b})`;
      }
      case "hsl": {
        const [h, s, l] = hexToHsl(color);
        return `hsl(${h}, ${s}%, ${l}%)`;
      }
      default:
        return color;
    }
  };

  const handleFormatChange = (newFormat: "hex" | "rgb" | "hsl") => {
    setColorFormat({
      type: newFormat,
      value: formatColor(currentColor, newFormat),
    });
  };

  const handleFormatColorChange = (value: string) => {
    let newHexColor = currentColor;

    switch (colorFormat.type) {
      case "hex": {
        if (/^#[0-9A-Fa-f]{6}$/.test(value)) {
          newHexColor = value;
        }
        break;
      }
      case "rgb": {
        const rgb = parseRgb(value);
        if (rgb) {
          const [r, g, b] = rgb;
          newHexColor = rgbToHex(r, g, b);
        }
        break;
      }
      case "hsl": {
        const hsl = parseHsl(value);
        if (hsl) {
          const [h, s, l] = hsl;
          newHexColor = hslToHex(h, s, l);
        }
        break;
      }
    }

    setColorFormat((prev) => ({ ...prev, value }));
    if (newHexColor !== currentColor) {
      handleColorChange(newHexColor);
    }
  };

  return (
    <ColorPickerWrapper>
      {label && label !== "none" && (
        <Label>
          {label}{" "}
          {required && (
            <span style={{ color: "#ff3030", fontSize: "1.1rem" }}>*</span>
          )}
        </Label>
      )}
      <ColorPickerContainer ref={containerRef}>
        <ColorPreviewButton onClick={() => setIsOpen(!isOpen)}>
          <ColorPreviewBox
            $color={currentColor}
            $opacity={disableOpacity ? 100 : currentOpacity}
          />
        </ColorPreviewButton>

        <ColorCodeInput
          value={currentColor}
          onChange={(e) => handleColorChange(e.target.value)}
          placeholder={t(
            "cleeckykit:common.interface.elements.color_picker.color_code"
          )}
        />

        {!disableOpacity && (
          <OpacityInput
            type="number"
            min={0}
            max={100}
            value={currentOpacity}
            onChange={(e) => handleOpacityChange(Number(e.target.value))}
            placeholder={t(
              "cleeckykit:common.interface.elements.color_picker.opacity"
            )}
          />
        )}

        {isOpen &&
          createPortal(
            <ColorPickerPopup ref={popupRef} style={getPopupPosition()}>
              <SaturationBox
                ref={saturationBoxRef}
                $hue={hue}
                onClick={handleSaturationBoxClick}>
                <SaturationCursor $x={saturation} $y={100 - lightness} />
              </SaturationBox>

              <ControlsContainer>
                <EyeDropperButton
                  onClick={handleEyeDropper}
                  title={t(
                    "cleeckykit:common.interface.elements.color_picker.eyedropper"
                  )}>
                  <FontAwesomeIcon icon={faEyeDropper} />
                </EyeDropperButton>
                <div style={{ flex: 1 }}>
                  <HueSlider
                    type="range"
                    min={0}
                    max={360}
                    value={hue}
                    onChange={handleHueChange}
                  />
                  {!disableOpacity && (
                    <OpacitySlider
                      type="range"
                      min={0}
                      max={100}
                      value={currentOpacity}
                      onChange={(e) =>
                        handleOpacityChange(Number(e.target.value))
                      }
                      $color={currentColor}
                    />
                  )}
                </div>
              </ControlsContainer>

              <Footer>
                <FormatSelect
                  value={colorFormat.type}
                  onChange={(e) => {
                    handleFormatChange(e.target.value as "hex" | "rgb" | "hsl");
                  }}>
                  <option value="hex">
                    {t(
                      "cleeckykit:common.interface.elements.color_picker.formats.hex"
                    )}
                  </option>
                  <option value="rgb">
                    {t(
                      "cleeckykit:common.interface.elements.color_picker.formats.rgb"
                    )}
                  </option>
                  <option value="hsl">
                    {t(
                      "cleeckykit:common.interface.elements.color_picker.formats.hsl"
                    )}
                  </option>
                </FormatSelect>
                <ColorCodeInput
                  value={colorFormat.value}
                  onChange={(e) => handleFormatColorChange(e.target.value)}
                />
                {!disableOpacity && (
                  <OpacityInput
                    type="number"
                    min={0}
                    max={100}
                    value={currentOpacity}
                    onChange={(e) =>
                      handleOpacityChange(Number(e.target.value))
                    }
                  />
                )}
              </Footer>
            </ColorPickerPopup>,
            document.body
          )}
      </ColorPickerContainer>
    </ColorPickerWrapper>
  );
};

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LogoSvg from "../../../assets/images/icon-minimalist-black-1.svg";
import * as CleeckyKit from "CleeckyKit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Logo = styled(CleeckyKit.Image)`
  height: auto;
  filter: ${({ theme }) => (theme.name === "dark" ? "invert(1)" : "invert(0)")};
`;

const ContentColumn = styled(CleeckyKit.Column)`
  width: min(600px, 100%);
  gap: 2rem;
  text-align: center;
`;

const UnderConstructionPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CleeckyKit.Column
      height="100dvh"
      padding="5rem 1rem"
      align="center"
      justify="center">
      <ContentColumn>
        <CleeckyKit.Row justify="center">
          <Logo
            src={LogoSvg}
            alt="Cleecky Logo"
            sourceType="file"
            format="svg"
            width="10rem"
          />
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center">
          <CleeckyKit.Title level={2} align="center">
            {t("project:pages.under_construction.title")}
          </CleeckyKit.Title>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center" align="center">
          <CleeckyKit.Text align="center">
            {t("project:pages.under_construction.description")}
          </CleeckyKit.Text>
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="center">
          <CleeckyKit.Button
            variant="solid"
            textPosition="inside-right"
            href="/"
            text={t("project:pages.under_construction.back_to_home")}
            icon={<FontAwesomeIcon icon={faHome} />}
          />
        </CleeckyKit.Row>
      </ContentColumn>
    </CleeckyKit.Column>
  );
};

export default UnderConstructionPage;

import React from "react";
import styled from "styled-components";

interface CleeckyKitTabProps {
  name: string;
  children: React.ReactNode;
}

const TabContainer = styled.div`
  height: 100%;
`;

export const CleeckyKitTab: React.FC<CleeckyKitTabProps> = ({ children }) => {
  return <TabContainer>{children}</TabContainer>;
};

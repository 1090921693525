import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useCleeckyKitTheme } from "../../../hooks";

interface CleeckyKitTabsProps {
  children: React.ReactElement | React.ReactElement[];
  defaultTab?: string;
  orientation?: "vertical" | "horizontal";
}

interface StyledProps {
  orientation: string;
}

const TabsContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "vertical" ? "row" : "column"};
  width: 100%;
  height: 100%;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    flex-direction: column;
  }
`;

const TabList = styled.div<StyledProps>`
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "vertical" ? "column" : "row"};
  gap: 0.5rem;
  padding: ${({ orientation }) =>
    orientation === "vertical" ? "1rem 0" : "0 1rem"};
  ${({ orientation }) =>
    orientation === "vertical"
      ? css`
          border-right: 1px solid ${({ theme }) => theme.colors.border.input};
        `
      : css`
          border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
        `}
  min-width: ${({ orientation }) =>
    orientation === "vertical" ? "200px" : "auto"};

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    flex-direction: row;
    overflow-x: auto;
    padding: 1rem 1rem 0 1rem;
    min-width: auto;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};

    /* Esconde a barra de rolagem mas mantém a funcionalidade */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TabButton = styled.button<{ isActive: boolean; orientation: string }>`
  padding: 0.75rem 1rem;
  border: none;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.interactive.active : "transparent"};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  border-radius: ${({ orientation }) =>
    orientation === "vertical" ? "0.5rem 0 0 0.5rem" : "0.5rem 0.5rem 0 0"};
  width: 100%;
  text-align: left;
  transition: background-color 0.2s;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    width: auto;
    border-radius: 0.5rem 0.5rem 0 0;
    text-align: center;
  }

  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive
        ? theme.colors.interactive.active
        : theme.colors.interactive.hover};
  }
`;

const TabContent = styled.div`
  flex: 1;
  padding: 1rem;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    padding: 1rem 0;
  }
`;

export const CleeckyKitTabs: React.FC<CleeckyKitTabsProps> = ({
  children,
  defaultTab,
  orientation = "vertical",
}) => {
  // Converte children para array mesmo se for único
  const tabs = React.Children.toArray(children) as React.ReactElement[];

  const [activeTab, setActiveTab] = useState(
    defaultTab || tabs[0]?.props?.name
  );

  return (
    <TabsContainer orientation={orientation}>
      <TabList orientation={orientation}>
        {tabs.map((tab) => (
          <TabButton
            key={tab.props.name}
            isActive={activeTab === tab.props.name}
            orientation={orientation}
            onClick={() => setActiveTab(tab.props.name)}>
            {tab.props.name}
          </TabButton>
        ))}
      </TabList>
      <TabContent>
        {tabs.find((tab) => tab.props.name === activeTab)}
      </TabContent>
    </TabsContainer>
  );
};

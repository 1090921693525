import React, { useState } from "react";
import * as CleeckyKit from "../../CleeckyKit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../contexts/AuthContext";
import { AuthModal } from "../Auth/AuthModal";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import {
  faSignOutAlt,
  faCog,
  faSignInAlt,
  faUserPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export const AccountDropdown: React.FC = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [authMode, setAuthMode] = useState<"login" | "register">("login");

  const handleAuthAction = (mode: "login" | "register") => {
    setAuthMode(mode);
    setIsAuthModalOpen(true);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <CleeckyKit.Dropdown
        icon={<FontAwesomeIcon icon={faUser} />}
        label={
          user
            ? user.username
            : t("cleeckykit:common.interface.elements.account")
        }
        variant="solid"
        size="small"
        borderRadius={true}
        showArrow={false}
        autoClose={true}>
        {!user ? (
          <>
            <CleeckyKit.DropdownMenuItem
              icon={<FontAwesomeIcon icon={faSignInAlt} />}
              onClick={() => handleAuthAction("login")}>
              {t("cleeckykit:common.auth.actions.signin")}
            </CleeckyKit.DropdownMenuItem>
            <CleeckyKit.DropdownMenuItem
              icon={<FontAwesomeIcon icon={faUserPlus} />}
              onClick={() => handleAuthAction("register")}>
              {t("cleeckykit:common.auth.actions.signup")}
            </CleeckyKit.DropdownMenuItem>
          </>
        ) : (
          <>
            {/* <CleeckyKit.DropdownMenuItem
              icon={<FontAwesomeIcon icon={faUser} />}
              onClick={() => navigate("/profile")}>
              {t("cleeckykit:common.interface.elements.profile")}
            </CleeckyKit.DropdownMenuItem> */}
            <CleeckyKit.DropdownMenuItem
              icon={<FontAwesomeIcon icon={faCog} />}
              onClick={() => navigate("/settings")}>
              {t("cleeckykit:common.interface.elements.settings")}
            </CleeckyKit.DropdownMenuItem>
            <CleeckyKit.DropdownDivider />
            <CleeckyKit.DropdownMenuItem
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
              onClick={handleLogout}
              danger>
              {t("cleeckykit:common.auth.actions.signout")}
            </CleeckyKit.DropdownMenuItem>
          </>
        )}
      </CleeckyKit.Dropdown>

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        initialMode={authMode}
      />
    </>
  );
};

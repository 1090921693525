import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CleeckyKitButton } from "../Button/CleeckyKitButton";
import { CleeckyKitImage } from "CleeckyKit/components/Image/CleeckyKitImage";
import { t } from "i18next";
import { Z_INDEX } from "CleeckyKit/themes/zindex";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  size?: "small" | "medium" | "large" | "full" | "auto" | { width: string };
  closeOnOverlayClick?: boolean;
  showCloseButton?: boolean;
  animation?: "fade" | "scale" | "slide";
  className?: string;
  sideImage?: {
    src: string;
    alt: string;
    width?: string;
    height?: string;
    position?: "left" | "right";
    fit?: "cover" | "contain" | "fill" | "none" | "scale-down";
    animation?: {
      initial?: any;
      animate?: any;
      transition?: any;
    };
  };
}

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => `${theme.colors.background.base}CC`};
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${Z_INDEX.modal.overlay};
  min-height: 100dvh;
  min-width: 100dvw;
  padding: 1rem;
  box-sizing: border-box;

  /* Garante que o overlay ocupe toda a tela mesmo com scroll desabilitado */
  position: fixed;
  inset: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const ModalContainer = styled(motion.div)<{ $size: ModalProps["size"] }>`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.content};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 95dvh;
  width: 100%;
  overflow: hidden;
  position: relative;

  ${({ $size }) => {
    if (!$size || $size === "medium") {
      return "max-width: 800px;";
    }

    if ($size === "auto") {
      return "width: fit-content;";
    }

    if (typeof $size === "object" && $size.width) {
      return `max-width: ${$size.width};`;
    }

    switch ($size) {
      case "small":
        return "max-width: 400px;";
      case "large":
        return "max-width: 1200px;";
      case "full":
        return "max-width: 90dvw;";
      default:
        return "max-width: 600px;";
    }
  }}

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    max-width: 95dvw;
    height: auto;
  }
`;

const ModalLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: inherit;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const ImageSection = styled.div<{
  $width?: string;
  $position?: "left" | "right";
}>`
  flex: 0 0 ${({ $width }) => $width || "40%"};
  order: ${({ $position }) => ($position === "right" ? 2 : 0)};
  overflow: hidden;
  position: relative;
  height: auto;

  /* Garante que a imagem ocupe todo o espaço disponível */
  & > * {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    flex: 0 0 200px;
    order: 0;
    width: 100%;
  }
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: inherit;
  position: relative;
`;

const ModalContent = styled.div`
  padding: 1rem;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  position: relative;

  /* Container para os elementos internos */
  & > * {
    position: relative;
    z-index: 1;
  }

  /* Previne que animações dos elementos internos afetem o scroll */
  & > *:hover {
    z-index: 2;
  }

  /* Garante que o conteúdo não seja cortado */
  &:after {
    content: "";
    display: block;
    //padding-bottom: 1rem;
  }
`;

const ModalHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.card};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  text-align: start;
  gap: 0.5rem;

  /* Se for um elemento h2 direto */
  h2 {
    margin: 0;
  }
`;

const animations = {
  fade: {
    overlay: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
    modal: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
  },
  scale: {
    overlay: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
    modal: {
      initial: { scale: 0.9, opacity: 0 },
      animate: { scale: 1, opacity: 1 },
      exit: { scale: 0.9, opacity: 0 },
    },
  },
  slide: {
    overlay: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
    modal: {
      initial: { y: 50, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: 50, opacity: 0 },
    },
  },
};

export const CleeckyKitModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  size = "medium",
  closeOnOverlayClick = true,
  showCloseButton = true,
  animation = "scale",
  className,
  sideImage,
}) => {
  const currentAnimation = animations[animation];

  useEffect(() => {
    if (isOpen) {
      // Salva a posição atual do scroll
      const scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.overflowY = "scroll"; // Mantém a barra de scroll para evitar "jumping"
    } else {
      // Restaura a posição do scroll
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.top = "";
      document.body.style.overflowY = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    }

    return () => {
      // Cleanup: garante que os estilos sejam removidos se o componente for desmontado
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.top = "";
      document.body.style.overflowY = "";
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          {...currentAnimation.overlay}
          transition={{ duration: 0.2 }}
          onClick={closeOnOverlayClick ? onClose : undefined}>
          <ModalContainer
            $size={size}
            {...currentAnimation.modal}
            transition={{ duration: 0.2 }}
            onClick={(e) => e.stopPropagation()}
            className={className}>
            <ModalLayout>
              {sideImage && (
                <ImageSection
                  $width={sideImage.width}
                  $position={sideImage.position}>
                  <CleeckyKitImage
                    src={sideImage.src}
                    alt={sideImage.alt}
                    width="100%"
                    height="100%"
                    fit={sideImage.fit || "cover"}
                    position="center"
                    animation={sideImage.animation}
                  />
                </ImageSection>
              )}
              <ContentSection>
                {(title || showCloseButton) && (
                  <ModalHeader>
                    {title && <ModalTitle>{title}</ModalTitle>}
                    {showCloseButton && (
                      <CleeckyKitButton
                        variant="icon"
                        size="small"
                        icon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={onClose}
                        aria-label={t("cleeckykit:common.actions.close")}
                      />
                    )}
                  </ModalHeader>
                )}
                <ModalContent>{children}</ModalContent>
              </ContentSection>
            </ModalLayout>
          </ModalContainer>
        </ModalOverlay>
      )}
    </AnimatePresence>,
    document.body
  );
};

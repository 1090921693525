import React, { useRef, ChangeEvent } from "react";
import styled from "styled-components";

interface FileInputProps {
  label?: string;
  accept?: string;
  onChange?: (file: File | null) => void;
  error?: string;
  disabled?: boolean;
  multiple?: boolean;
  name?: string;
}

const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0.25rem;
`;

const HiddenInput = styled.input`
  display: none;
`;

const FileButton = styled.button<{ hasError?: boolean; disabled?: boolean }>`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px dashed
    ${({ theme, hasError }) =>
      hasError ? theme.colors.status.error : theme.colors.border.primary};
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s ease-in-out;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? "transparent" : theme.colors.background.tertiary};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const SelectedFile = styled.div`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.status.error};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export const CleeckyKitFileInput: React.FC<FileInputProps> = ({
  label,
  accept,
  onChange,
  error,
  disabled,
  multiple = false,
  name,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);

  const handleClick = () => {
    if (!disabled && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setSelectedFiles(files);

    if (onChange) {
      if (multiple) {
        onChange(files[0]);
      } else {
        onChange(files[0] || null);
      }
    }
  };

  return (
    <FileInputContainer>
      {label && <Label>{label}</Label>}
      <FileButton
        type="button"
        onClick={handleClick}
        hasError={!!error}
        disabled={disabled}>
        {label || "Escolher arquivo"}
      </FileButton>
      <HiddenInput
        ref={inputRef}
        type="file"
        accept={accept}
        onChange={handleChange}
        disabled={disabled}
        multiple={multiple}
        name={name}
      />
      {selectedFiles.length > 0 && (
        <SelectedFile>
          {selectedFiles.map((file) => file.name).join(", ")}
        </SelectedFile>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FileInputContainer>
  );
};

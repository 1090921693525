import { GlobalColors } from "../components/Canvas/types";

export const defaultGlobalColors: GlobalColors = {
  highlightColor: "#3498db",
  pageBackgroundColor: "#f9f8f6",
  pageContentBackgroundColor: "#ffffff",
  buttonsBackgroundColor: "#454545",
  buttonsLabelColor: "#ffffff",
  textColor: "#000000",
  borderColor: "#454545",
};

export const getEffectiveColor = (
  elementColor: string | undefined,
  globalColorKey: keyof GlobalColors,
  globalColors: GlobalColors
) => {
  return elementColor || globalColors[globalColorKey];
};

import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://api.cleecky.com"
      : "http://localhost:31231",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "https://cleecky.com",
  },
});

// Adicionar interceptor para requisições
api.interceptors.request.use(
  (config) => {
    const cookies = document.cookie.split(";").reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split("=");
      acc[key] = value;
      return acc;
    }, {} as Record<string, string>);

    // Remove o header Access-Control-Allow-Origin pois ele deve ser definido pelo servidor
    if (config.headers) {
      delete config.headers["Access-Control-Allow-Origin"];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Melhorar o interceptor de resposta
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("user");
      document.cookie =
        "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    return Promise.reject(error);
  }
);

export default api;

import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import WorkingOnSection from "./components/WorkingOnSection";
import ChangelogSection from "./components/ChangelogSection";
import NextReleaseSection from "./components/NextReleaseSection";
import { useTranslation } from "react-i18next";
import packageJson from "../../../package.json";

export const ChangeLogHomepage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <CleeckyKit.Column
        padding="4rem 1rem"
        gap="2rem"
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}>
        <CleeckyKit.Title level={2}>Próxima atualização</CleeckyKit.Title>
        <NextReleaseSection />
        <WorkingOnSection />
        <ChangelogSection />
        <CleeckyKit.Text align="center" customStyle={{ fontSize: "0.875rem" }}>
          Versão atual
          <br /> v{packageJson.version} ({packageJson.codename})
        </CleeckyKit.Text>
      </CleeckyKit.Column>
    </>
  );
};

export default ChangeLogHomepage;

import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import { aggregatorService, authService } from "services";
import { useDebounce } from "hooks";
import { AggregatorPageData } from "cleecky@types/interfaces";

interface PageTitleModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode: "create" | "rename";
  page?: AggregatorPageData;
  onSuccess?: (updatedPage: AggregatorPageData) => void;
}

const PageTitleModal: React.FC<PageTitleModalProps> = ({
  isOpen,
  onClose,
  mode,
  page,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [username, setUsername] = useState<string>("");
  const debouncedSlug = useDebounce(slug, 500);

  // Busca o username do usuário atual
  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        setUsername(currentUser.username);
      } catch (err) {
        console.error("Erro ao buscar usuário:", err);
      }
    };

    fetchUsername();
  }, []);

  // Limpa os campos quando o modal é fechado
  useEffect(() => {
    if (!isOpen) {
      setTitle("");
      setSlug("");
      setError(null);
    }
  }, [isOpen]);

  // Inicializa o título com base no slug atual (apenas no modo rename)
  useEffect(() => {
    if (mode === "rename" && page) {
      setTitle(page.title);
      setSlug(page.slug);
    } else {
      setTitle("");
      setSlug("");
    }
  }, [mode, page]);

  // Gera o slug a partir do título
  useEffect(() => {
    const newSlug = title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
    setSlug(newSlug);
  }, [title]);

  // Verifica se o slug já existe
  useEffect(() => {
    const checkSlug = async () => {
      if (
        !debouncedSlug ||
        (mode === "rename" && debouncedSlug === page?.slug)
      ) {
        setError(null);
        return;
      }

      try {
        setIsLoading(true);
        const pages = await aggregatorService.getAll();
        const existingPage = pages.find((p) => p.slug === debouncedSlug);

        if (existingPage) {
          setError(
            t(
              `project:apps.toolbox.tools.link_aggregator.${mode}_modal.slug_exists`
            )
          );
        } else {
          setError(null);
        }
      } catch (err) {
        console.error("Erro ao verificar slug:", err);
      } finally {
        setIsLoading(false);
      }
    };

    checkSlug();
  }, [debouncedSlug, mode, page?.slug]);

  const handleSubmit = async () => {
    if (!error && slug && title.trim()) {
      if (mode === "create") {
        navigate("/toolbox/link-aggregator/pages/create", {
          state: { initialSlug: slug, initialTitle: title.trim() },
        });
        onClose();
      } else if (
        mode === "rename" &&
        page &&
        (slug !== page.slug || title.trim() !== page.title)
      ) {
        try {
          setIsLoading(true);
          const updatedPage = await aggregatorService.updateTitle(page.uuid, {
            slug,
            title: title.trim(),
          });
          onSuccess?.(updatedPage);
          onClose();
        } catch (err) {
          console.error("Erro ao renomear página:", err);
          setError(
            t("project:apps.toolbox.tools.link_aggregator.rename_modal.error")
          );
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <CleeckyKit.Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(
        `project:apps.toolbox.tools.link_aggregator.${mode}_modal.title`
      )}
      size="medium">
      <CleeckyKit.Form onSubmit={handleSubmit}>
        <CleeckyKit.Column>
          <CleeckyKit.Row>
            <CleeckyKit.TextInput
              name="title"
              label={t(
                `project:apps.toolbox.tools.link_aggregator.${mode}_modal.page_title`
              )}
              value={title}
              maxLength={100}
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value)
              }
              placeholder={t(
                `project:apps.toolbox.tools.link_aggregator.${mode}_modal.page_title_placeholder`
              )}
            />
          </CleeckyKit.Row>

          <CleeckyKit.Row>
            <CleeckyKit.Text customStyle={{ fontSize: "0.875rem" }} hyphenate>
              {t(
                `project:apps.toolbox.tools.link_aggregator.${mode}_modal.slug`,
                {
                  username,
                  slug,
                }
              )}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
          {error && (
            <CleeckyKit.Row>
              <CleeckyKit.Text customStyle={{ color: "error" }}>
                {error}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          )}

          <CleeckyKit.Row justify="end" noBreak>
            <CleeckyKit.Button
              text={t("cleeckykit:common.actions.cancel")}
              variant="link"
              size="small"
              onClick={onClose}
              type="button"
            />
            <CleeckyKit.Button
              text={t(
                `cleeckykit:common.actions.${
                  mode === "create" ? "continue" : "save"
                }`
              )}
              variant="solid"
              highlighted
              size="small"
              type="submit"
              isDisabled={
                !slug ||
                !title.trim() ||
                !!error ||
                isLoading ||
                (mode === "rename" &&
                  slug === page?.slug &&
                  title.trim() === page?.title)
              }
            />
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Form>
    </CleeckyKit.Modal>
  );
};

export default PageTitleModal;

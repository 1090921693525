// Importando as dependências
import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Z_INDEX } from "CleeckyKit/themes/zindex";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import * as CleeckyKit from "CleeckyKit";
import ReactDOM from "react-dom";

interface SelectButtonProps {
  $isOpen: boolean;
}

interface Option {
  label: string;
  value: string;
  icon?: ReactNode;
}

interface CleeckyKitSelectProps {
  options: Option[];
  placeholder?: string;
  icon?: ReactNode;
  multiple?: boolean;
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
  label?: string | "none";
  required?: boolean;
}

const SelectButton = styled.button<SelectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.background.dropdown};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  font-size: 0.875rem;
  position: relative;
  gap: 5px;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.interactive.hover};
    border-color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.primary};
  }

  .icon {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.icon.primary};
  }

  .arrow {
    transition: transform 0.3s ease;
    transform: rotate(${(props) => (props.$isOpen ? "0deg" : "-90deg")});
    color: ${({ theme }) => theme.colors.icon.primary};
  }
`;

const OptionsMenu = styled(motion.ul)`
  position: fixed;
  background: ${({ theme }) => theme.colors.background.dropdown};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: ${Z_INDEX.buttons.dropdown.menu};
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;

  /* Estilização da scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.scroll.track};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.scroll.thumb};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) =>
        theme.gradient?.highlightColor || theme.colors.interactive.primary};
    }
  }
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) =>
      `${theme.gradient?.highlightColor}50` || theme.colors.border.primary};
  }

  .icon {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.icon.primary};
  }

  &.selected {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.interactive.selected};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    text-align: left;
  }
`;

export const CleeckyKitSelect: React.FC<CleeckyKitSelectProps> = ({
  options = [],
  placeholder = t(
    "cleeckykit:common.interface.elements.select.default.placeholder"
  ),
  icon: ButtonIcon,
  multiple = false,
  value,
  onChange,
  label,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<
    string[] | string | null
  >(value ?? (multiple ? [] : null));
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const menuRef = React.useRef<HTMLUListElement>(null);
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();

  // Atualiza o estado interno quando o valor da prop muda
  React.useEffect(() => {
    if (value !== undefined) {
      setSelectedValues(value);
    }
  }, [value]);

  // Adiciona event listener para clicks fora do select
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        buttonRef.current &&
        menuRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const updateMenuPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom + 5,
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const toggleMenu = () => {
    if (!isOpen) {
      updateMenuPosition();
    }
    setIsOpen((prev) => !prev);
  };

  // Atualiza a posição do menu quando a janela é redimensionada
  React.useEffect(() => {
    if (isOpen) {
      const handleResize = () => updateMenuPosition();
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleResize, true);
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("scroll", handleResize, true);
      };
    }
  }, [isOpen]);

  const handleOptionClick = (value: string) => {
    if (multiple) {
      setSelectedValues((prev) => {
        const prevArray = prev as string[];
        const isSelected = prevArray.includes(value);
        const newValues = isSelected
          ? prevArray.filter((v) => v !== value)
          : [...prevArray, value];
        onChange?.(newValues);
        return newValues;
      });
    } else {
      setSelectedValues(value);
      onChange?.(value);
      setIsOpen(false);
    }
  };

  const isSelected = (value: string) => {
    return multiple
      ? (selectedValues as string[])?.includes(value)
      : selectedValues === value;
  };

  return (
    <SelectWrapper>
      {label && label !== "none" && (
        <Label>
          {label}{" "}
          {required && (
            <span
              style={{
                color: currentTheme.colors.status.error,
                fontSize: "1.1rem",
              }}>
              *
            </span>
          )}
        </Label>
      )}
      <div style={{ position: "relative" }}>
        <SelectButton onClick={toggleMenu} $isOpen={isOpen} ref={buttonRef}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {ButtonIcon && <span className="icon">{ButtonIcon}</span>}
            <span>
              {multiple
                ? (selectedValues as string[])?.length > 0
                  ? `${(selectedValues as string[]).length} selected`
                  : placeholder
                : options.find((o) => o.value === selectedValues)?.label ||
                  placeholder}
            </span>
          </div>
          <span className="arrow">
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </SelectButton>

        {isOpen &&
          ReactDOM.createPortal(
            <AnimatePresence>
              <OptionsMenu
                ref={menuRef}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.2 }}
                style={{
                  top: menuPosition.top,
                  left: menuPosition.left,
                  width: menuPosition.width,
                }}>
                {options.map(({ label, value, icon: OptionIcon }) => (
                  <Option
                    key={value}
                    className={isSelected(value) ? "selected" : ""}
                    onClick={() => handleOptionClick(value)}>
                    {OptionIcon && <span className="icon">{OptionIcon}</span>}
                    {label}
                  </Option>
                ))}
              </OptionsMenu>
            </AnimatePresence>,
            document.body
          )}
      </div>
    </SelectWrapper>
  );
};

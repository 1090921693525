import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";

const StyledList = styled.ul`
  margin: 0.5rem 0;
  padding-left: 1.5rem;
`;

const StyledListItem = styled.li`
  margin: 0.25rem 0;
`;

export const ModerationPolicySection: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Accordion title="Política de Moderação e Conduta da Cleecky">
        <CleeckyKit.AccordionGroup>
          <CleeckyKit.Accordion title="1. Introdução">
            <CleeckyKit.Text>
              A Cleecky preza por um ambiente digital seguro, inclusivo e
              respeitoso para todos os usuários. Esta Política de Moderação e
              Conduta estabelece as diretrizes e os comportamentos esperados
              dentro da nossa plataforma, bem como os procedimentos que adotamos
              para monitorar, moderar e, quando necessário, aplicar sanções para
              assegurar o cumprimento dessas normas.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="2. Escopo">
            <CleeckyKit.Text>
              Esta política se aplica a todas as interações, publicações,
              comentários e quaisquer outros conteúdos gerados pelos usuários na
              plataforma da Cleecky, incluindo redes sociais, fóruns, áreas de
              comentários e demais canais disponibilizados pela empresa.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="3. Definições">
            <StyledList>
              <StyledListItem>
                <strong>Usuário:</strong> Toda pessoa que utilize a plataforma
                da Cleecky, seja por meio de cadastro ou acesso público.
              </StyledListItem>
              <StyledListItem>
                <strong>Conteúdo:</strong> Qualquer informação, comentário,
                imagem, vídeo, link ou material compartilhado, publicado ou
                disponibilizado pelo usuário.
              </StyledListItem>
              <StyledListItem>
                <strong>Moderação:</strong> Atividades realizadas pela equipe da
                Cleecky para revisar, aprovar ou remover conteúdos que violem as
                diretrizes estabelecidas nesta política.
              </StyledListItem>
              <StyledListItem>
                <strong>Conduta Inadequada:</strong> Comportamentos e
                publicações que contrariem os princípios desta política e que
                possam prejudicar a integridade, segurança ou bem-estar dos
                demais usuários.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="4. Diretrizes de Conduta">
            <CleeckyKit.Title level={5}>
              4.1 Comportamento Respeitoso
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                Trate todos os membros da comunidade com respeito e cortesia.
              </StyledListItem>
              <StyledListItem>
                Evite o uso de linguagem ofensiva, discriminatória, abusiva ou
                que incite violência.
              </StyledListItem>
              <StyledListItem>
                Seja colaborativo e construtivo em seus comentários e
                interações.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>4.2 Conteúdo Adequado</CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                Publique apenas conteúdos que sejam relevantes e apropriados
                para a comunidade.
              </StyledListItem>
              <StyledListItem>
                Proíba publicações que contenham informações falsas,
                difamatórias, ilegais ou que infrinjam direitos autorais.
              </StyledListItem>
              <StyledListItem>
                Evite a divulgação de informações pessoais ou confidenciais de
                terceiros sem consentimento.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              4.3 Proibições Específicas
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                <strong>Discurso de Ódio e Discriminação:</strong> Qualquer
                conteúdo que promova racismo, sexismo, homofobia, xenofobia ou
                qualquer outra forma de discriminação é estritamente proibido.
              </StyledListItem>
              <StyledListItem>
                <strong>Spam e Publicidade Não Autorizada:</strong> Não é
                permitido o envio de mensagens repetitivas, propagandas sem
                autorização ou links irrelevantes que comprometam a experiência
                do usuário.
              </StyledListItem>
              <StyledListItem>
                <strong>Assédio e Bullying:</strong> Comportamentos que
                intimidem, ameacem ou humilhem outros usuários serão tratados
                com rigor.
              </StyledListItem>
              <StyledListItem>
                <strong>Conteúdos Ilegais:</strong> Publicação de materiais que
                incentivem ou promovam atividades ilegais, ilícitas ou que
                possam colocar em risco a segurança dos usuários.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="5. Processo de Moderação">
            <CleeckyKit.Title level={5}>
              5.1 Monitoramento e Revisão
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                A equipe de moderação da Cleecky monitora ativamente o conteúdo
                publicado na plataforma.
              </StyledListItem>
              <StyledListItem>
                Usuários podem reportar conteúdos que violem esta política por
                meio dos canais de denúncia disponibilizados.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>5.2 Avaliação e Ação</CleeckyKit.Title>
            <CleeckyKit.Text>
              Cada denúncia será avaliada com base em critérios objetivos e na
              gravidade da infração.
            </CleeckyKit.Text>
            <CleeckyKit.Text>
              Dependendo do caso, as ações podem incluir:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Aviso:</strong> Comunicação direta ao usuário para que
                corrija ou remova o conteúdo.
              </StyledListItem>
              <StyledListItem>
                <strong>Remoção de Conteúdo:</strong> Exclusão do material que
                contraria as diretrizes.
              </StyledListItem>
              <StyledListItem>
                <strong>Suspensão ou Banimento:</strong> Bloqueio temporário ou
                permanente da conta em casos de infrações graves ou
                reincidência.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              5.3 Transparência no Processo
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                Sempre que possível, a Cleecky informará o usuário afetado sobre
                as razões da ação tomada.
              </StyledListItem>
              <StyledListItem>
                O usuário terá a oportunidade de se manifestar ou solicitar uma
                revisão da decisão, seguindo os procedimentos de recurso
                estabelecidos.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="6. Sanções e Penalidades">
            <StyledList>
              <StyledListItem>
                <strong>Primeira Infração:</strong> Pode resultar em um aviso
                formal e solicitação de correção do comportamento.
              </StyledListItem>
              <StyledListItem>
                <strong>Infração Reiterada:</strong> Pode levar à suspensão
                temporária da conta.
              </StyledListItem>
              <StyledListItem>
                <strong>Infração Grave:</strong> Em casos de conteúdo ofensivo,
                ilegal ou que cause danos significativos à comunidade, a Cleecky
                se reserva o direito de banir permanentemente o usuário
                infrator.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="7. Apelação e Recurso">
            <StyledList>
              <StyledListItem>
                Usuários que se sintam prejudicados por uma decisão de moderação
                podem entrar em contato com a equipe de suporte para solicitar
                uma revisão.
              </StyledListItem>
              <StyledListItem>
                Todas as apelações serão analisadas de forma imparcial,
                considerando o contexto e as evidências apresentadas.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="8. Atualizações da Política">
            <StyledList>
              <StyledListItem>
                Esta política pode ser atualizada periodicamente para refletir
                mudanças nas diretrizes da plataforma ou adaptações necessárias
                decorrentes de alterações legais.
              </StyledListItem>
              <StyledListItem>
                Mudanças significativas serão comunicadas aos usuários por meio
                de avisos na plataforma ou via e-mail.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="9. Contato">
            <CleeckyKit.Text>
              Para dúvidas, sugestões ou para reportar violações desta Política
              de Moderação e Conduta, entre em contato através dos seguintes
              canais:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                E-mail para assuntos legais: legal@cleecky.com
              </StyledListItem>
              <StyledListItem>
                E-mail para suporte: support@cleecky.com
              </StyledListItem>
              <StyledListItem>
                Endereço: Rua Gonçalves Jaquier, 25 - Jardim Ohara, Marília/SP,
                Brasil
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>Data de Vigência: 16/02/2025</CleeckyKit.Text>
            <CleeckyKit.Text>
              Data da Última Atualização: 16/02/2025
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>
        </CleeckyKit.AccordionGroup>
        <br />
        <CleeckyKit.Text>
          Esta Política de Moderação e Conduta visa promover um ambiente seguro
          e produtivo para todos os usuários da Cleecky. Ao utilizar nossos
          serviços, você concorda em seguir estas diretrizes e colaborar para a
          construção de uma comunidade saudável e respeitosa.
        </CleeckyKit.Text>
      </CleeckyKit.Accordion>
    </>
  );
};

export default ModerationPolicySection;

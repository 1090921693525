import { CanvasElement, CanvasElementType } from "../components/Canvas/types";
import * as CanvasTypes from "../components/Canvas/types";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faDesktop,
  faUserCircle,
  faImage,
  faHeading,
  faFont,
  faLink,
  faUser,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faTiktok,
  faLinkedin,
  faTwitter,
  faPinterest,
  faReddit,
  faSnapchat,
  faSpotify,
  faTumblr,
  faVimeo,
  faWhatsapp,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const getRandomColor = () => {
  const colors = [
    "#FF6B6B",
    "#4ECDC4",
    "#45B7D1",
    "#96CEB4",
    "#FFEEAD",
    "#D4A5A5",
    "#9B59B6",
    "#3498DB",
    "#1ABC9C",
    "#F1C40F",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const getElementIcon = (type: CanvasElementType): IconDefinition => {
  switch (type) {
    case "AVATAR":
      return faUserCircle;
    case "NAME":
      return faUser;
    case "USERNAME":
      return faUser;
    case "LINK":
      return faLink;
    case "SOCIAL_BUTTONS":
      return faDesktop;
    case "TEXT":
      return faFont;
    case "TITLE":
      return faHeading;
    case "IMAGE":
      return faImage;
    case "GROUP":
      return faLayerGroup;
    default:
      return faLink;
  }
};

export const getSocialIcon = (platform: string): IconDefinition => {
  switch (platform.toUpperCase() as CanvasTypes.SocialPlatform) {
    case "INSTAGRAM":
      return faInstagram;
    case "FACEBOOK":
      return faFacebook;
    case "YOUTUBE":
      return faYoutube;
    case "TIKTOK":
      return faTiktok;
    case "LINKEDIN":
      return faLinkedin;
    case "X":
      return faTwitter;
    case "PINTEREST":
      return faPinterest;
    case "REDDIT":
      return faReddit;
    case "SNAPCHAT":
      return faSnapchat;
    case "SPOTIFY":
      return faSpotify;
    case "TUMBLR":
      return faTumblr;
    case "VIMEO":
      return faVimeo;
    case "WHATSAPP":
      return faWhatsapp;
    case "GITHUB":
      return faGithub;
    case "GMAIL":
      return faEnvelope;
    default:
      return faLink;
  }
};

export const createNewElement = (
  type: CanvasElementType,
  order: number
): CanvasElement => {
  const baseContent = {
    order,
    show: true,
  };

  let content: CanvasTypes.ElementContent;

  switch (type) {
    case "TITLE":
    case "TEXT":
    case "NAME":
    case "USERNAME":
      content = {
        ...baseContent,
        text: "",
        size: type === "TITLE" ? "h1" : undefined,
        alignment: "LEFT",
      };
      break;
    case "IMAGE":
      content = {
        ...baseContent,
        file: {
          path: "",
          type: "FILE",
        },
        textPosition: "BOTTOM",
      };
      break;
    case "AVATAR":
      content = {
        ...baseContent,
        file: {
          path: "",
          type: "FILE",
        },
      };
      break;
    case "LINK":
      content = {
        ...baseContent,
        url: "",
        label: "",
      };
      break;
    case "SOCIAL_BUTTONS":
      content = {
        ...baseContent,
        buttons: [],
        orientation: "HORIZONTAL",
      };
      break;
    case "GROUP":
      content = {
        ...baseContent,
        items: [],
      };
      break;
    default:
      content = {
        ...baseContent,
        text: "",
      } as CanvasTypes.TextContent;
  }

  return {
    id: `${type.toLowerCase()}_${Date.now()}`,
    type,
    content,
    order,
    show: true,
  };
};

export const updateElementsOrder = (
  elements: CanvasElement[],
  sourceId: string,
  targetId: string,
  position: "before" | "after"
) => {
  const newElements = [...elements];
  const sourceElement = newElements.find((el) => el.id === sourceId);
  const targetElement = newElements.find((el) => el.id === targetId);

  if (!sourceElement || !targetElement) return elements;

  // Remove o elemento da sua posição atual
  const filteredElements = newElements.filter((el) => el.id !== sourceId);

  // Encontra o índice onde o elemento deve ser inserido
  const targetIndex = filteredElements.findIndex((el) => el.id === targetId);
  const insertIndex = position === "before" ? targetIndex : targetIndex + 1;

  // Insere o elemento na nova posição
  filteredElements.splice(insertIndex, 0, sourceElement);

  // Atualiza os orders para corresponder à ordem visual
  filteredElements.forEach((element, index) => {
    element.content.order = index;
  });

  return filteredElements;
};

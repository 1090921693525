import React from "react";
import {
  BaseInputProps,
  CleeckyKitBaseInput,
  InputValidations,
} from "./CleeckyKitBaseInput";
import { formatNumber, parseNumber } from "CleeckyKit/utilities/numberUtils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface NumberInputProps extends Omit<BaseInputProps, "type"> {
  format?: "decimal" | "currency" | "percentage" | "weight";
  locale?: string;
  currency?: string;
  decimalPlaces?: number;
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  suffix?: string;
  allowNegative?: boolean;
}

const StyledNumberInput = styled(CleeckyKitBaseInput)<{ $format: string }>`
  width: 100%;
  min-width: ${({ $format }) => {
    switch ($format) {
      case "currency":
        return "8rem";
      case "percentage":
        return "5rem";
      case "weight":
        return "6rem";
      case "decimal":
      default:
        return "6rem";
    }
  }};
  max-width: ${({ $format }) => {
    switch ($format) {
      case "currency":
        return "10rem";
      case "percentage":
        return "6rem";
      case "weight":
        return "8rem";
      case "decimal":
      default:
        return "max-content";
    }
  }};
`;

export const CleeckyKitNumberInput = React.forwardRef<
  HTMLInputElement,
  NumberInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">
>(
  (
    {
      format = "decimal",
      locale = "pt-BR",
      currency = "BRL",
      decimalPlaces = 2,
      min,
      max,
      step,
      prefix,
      suffix,
      allowNegative = true,
      onChange,
      value,
      validations,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [displayValue, setDisplayValue] = React.useState("");
    const [internalValue, setInternalValue] = React.useState("");

    const defaultValidations: InputValidations = {
      required: true,
      requiredText: t("cleeckykit:common.form.number.required"),
      invalidText: t("cleeckykit:common.form.number.invalid"),
      validate: (value: string) => {
        const numValue = parseFloat(value);
        if (min !== undefined && numValue < min) {
          return t("cleeckykit:common.form.number.min", { min });
        }
        if (max !== undefined && numValue > max) {
          return t("cleeckykit:common.form.number.max", { max });
        }
        return true;
      },
    };

    React.useEffect(() => {
      if (value !== undefined) {
        const valueWithoutSuffix = String(value)
          .replace(suffix || "", "")
          .trim();

        const formattedValue = formatNumber(valueWithoutSuffix, {
          format,
          locale,
          currency,
          decimalPlaces,
          prefix,
        });

        setDisplayValue(formattedValue);
        setInternalValue(String(value));
      }
    }, [value, format, locale, currency, decimalPlaces, prefix, suffix]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = String(e.target.value);
      const parsedValue = parseNumber(rawValue, {
        format,
        locale,
        allowNegative,
      });

      if (
        (min === undefined || parsedValue >= min) &&
        (max === undefined || parsedValue <= max)
      ) {
        setDisplayValue(rawValue);

        const fullValue = `${parsedValue}${suffix || ""}`;
        setInternalValue(fullValue);

        if (onChange) {
          const syntheticEvent = {
            ...e,
            target: {
              ...e.target,
              value: fullValue,
            },
          };
          onChange(syntheticEvent);
        }
      }
    };

    const getInputMode = () => {
      switch (format) {
        case "decimal":
        case "currency":
        case "percentage":
        case "weight":
          return "decimal";
        default:
          return "numeric";
      }
    };

    return (
      <StyledNumberInput
        ref={ref}
        type="text"
        inputMode={getInputMode()}
        value={displayValue}
        onChange={handleChange}
        prefix={prefix}
        suffix={suffix}
        validations={{ ...defaultValidations, ...validations }}
        $format={format}
        {...rest}
      />
    );
  }
);

CleeckyKitNumberInput.displayName = "CleeckyKitNumberInput";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useCleeckyKitResponsive } from "../../../hooks";
import { Breakpoint } from "../../../configs/CleeckyKitResponsiveConfig";

type ItemsPerViewConfig = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  "2xl"?: number;
  default: number;
};

interface CarouselProps {
  children: React.ReactNode[];
  navigation?: {
    show: boolean;
    position?:
      | "outside-top-right"
      | "outside-top-left"
      | "outside-bottom-right"
      | "outside-bottom-left"
      | "outside-bottom"
      | "outside-middle"
      | "inside-top-right"
      | "inside-top-left"
      | "inside-bottom-right"
      | "inside-bottom-left"
      | "inside-bottom"
      | "inside-middle";
    navigationBackground?: string | "none";
  };
  pagination?: {
    show: boolean;
    position?:
      | "outside-top-right"
      | "outside-top-left"
      | "outside-bottom-right"
      | "outside-bottom-left"
      | "outside-bottom"
      | "outside-middle"
      | "inside-top-right"
      | "inside-top-left"
      | "inside-bottom-right"
      | "inside-bottom-left"
      | "inside-bottom"
      | "inside-middle";
  };
  autoPlay?: {
    active: boolean;
    interval?: number;
    mode?: "normal" | "infinite" | "reverse" | "infinite-reverse";
  };
  itemsPerView?: number | ItemsPerViewConfig;
  gap?: number;
  className?: string;
  enableDrag?: boolean;
}

const CarouselContainer = styled.div<{ $gap: number; $position?: string }>`
  position: relative;
  width: 100%;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  will-change: transform;
`;

const CarouselWrapper = styled.div<{
  $navigationPosition?: string;
  $paginationPosition?: string;
}>`
  position: relative;
  width: 100%;
  overflow: visible;
  margin-bottom: ${(props) =>
    props.$paginationPosition?.startsWith("outside-bottom") ||
    props.$navigationPosition?.startsWith("outside-bottom")
      ? "2rem"
      : "0"};
`;

const CarouselTrack = styled.div<{
  $gap: number;
  $itemsPerView: number;
  $isInfinite?: boolean;
  $hasMoreItems: boolean;
}>`
  display: flex;
  gap: ${(props) => props.$gap}px;
  margin-right: ${(props) => (props.$hasMoreItems ? "2.5rem" : "0.5rem")};
  transition: ${(props) =>
    props.$isInfinite
      ? "transform 0.3s ease-in-out"
      : "transform 0.3s ease-in-out"};

  > * {
    flex: 0 0
      ${(props) =>
        `calc((100% - ${(props.$itemsPerView - 1) * props.$gap}px) / ${
          props.$itemsPerView
        })`};
    min-width: 250px;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    margin-right: ${(props) => (props.$hasMoreItems ? "1.5rem" : "0.5rem")};
    gap: ${(props) => Math.max(8, props.$gap / 2)}px;

    > * {
      min-width: 200px;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.sm}) {
    margin-right: ${(props) => (props.$hasMoreItems ? "1rem" : "0.5rem")};
    gap: ${(props) => Math.max(8, props.$gap / 2)}px;

    > * {
      min-width: 180px;
    }
  }
`;

const NavigationButton = styled.button<{
  $position: string;
  $isLeft: boolean;
  $navigationBackground?: string;
}>`
  position: absolute;
  background: ${(props) =>
    props.$navigationBackground === "none"
      ? "transparent"
      : `${
          props.$navigationBackground ||
          props.theme.colors.background.navbar.background
        }94`};
  color: ${(props) => props.theme.colors.text.primary};
  border: none;
  border-radius: 15%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: ${(props) => (props.$position.startsWith("inside") ? 2 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${(props) => props.theme.gradient?.highlightColor};
    background: ${(props) =>
      props.$navigationBackground === "none"
        ? "transparent"
        : `${
            props.$navigationBackground ||
            props.theme.colors.background.navbar.background
          }`};
  }

  ${(props) => {
    const [layer, vertical, horizontal] = props.$position.split("-");

    // Para posições que especificam um canto específico
    if (horizontal === "left" || horizontal === "right") {
      const isOutside = layer === "outside";
      return `
        ${
          vertical === "top"
            ? `top: ${isOutside ? "-3.5rem" : "1rem"};`
            : `bottom: ${isOutside ? "-3.5rem" : "1rem"};`
        }
        ${horizontal === "left" ? "left: 1rem;" : "right: 1rem;"}
        ${props.$isLeft ? "margin-right: 3rem;" : "margin-left: 3rem;"}
      `;
    }

    // Para posições que especificam apenas a altura (top, middle, bottom)
    const isOutside = layer === "outside";
    if (vertical === "top") {
      return `
        top: ${isOutside ? "-3.5rem" : "1rem"};
        ${props.$isLeft ? "left: 1rem;" : "right: 1rem;"}
      `;
    }
    if (vertical === "bottom") {
      return `
        bottom: ${isOutside ? "-3.5rem" : "1rem"};
        ${props.$isLeft ? "left: 1rem;" : "right: 1rem;"}
      `;
    }
    return `
      top: 50%;
      transform: translateY(-50%);
      ${props.$isLeft ? "left: 1rem;" : "right: 1rem;"}
    `;
  }}
`;

const PaginationContainer = styled.div<{ $position: string }>`
  position: absolute;
  display: flex;
  gap: 8px;
  z-index: ${(props) => (props.$position.startsWith("inside") ? 2 : 1)};

  ${(props) => {
    const [layer, vertical, horizontal] = props.$position.split("-");
    const isOutside = layer === "outside";

    // Para posições que especificam um canto específico
    if (horizontal === "left" || horizontal === "right") {
      return `
        ${
          vertical === "top"
            ? `top: ${isOutside ? "-3.5rem" : "1rem"};`
            : `bottom: ${isOutside ? "-3.5rem" : "1rem"};`
        }
        ${horizontal === "left" ? "left: 1rem;" : "right: 1rem;"}
      `;
    }

    // Para posições que especificam apenas a altura (top, middle, bottom)
    if (vertical === "top") {
      return `
        top: ${isOutside ? "-3.5rem" : "1rem"};
        left: 50%;
        transform: translateX(-50%);
      `;
    }
    if (vertical === "bottom") {
      return `
        bottom: ${isOutside ? "-2.5rem" : "1rem"};
        left: 50%;
        transform: translateX(-50%);
      `;
    }
    return `
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `;
  }}
`;

const PaginationDot = styled.div<{ $active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) =>
    props.$active
      ? props.theme.colors.text.primary
      : "rgba(255, 255, 255, 0.5)"};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.gradient?.highlightColor};
  }
`;

export const CleeckyKitCarousel: React.FC<CarouselProps> = ({
  children,
  navigation = {
    show: true,
    position: "inside-middle",
    navigationBackground: undefined,
  },
  pagination = { show: true, position: "outside-bottom" },
  autoPlay = { active: false, interval: 3000, mode: "normal" },
  itemsPerView = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
    "2xl": 3,
    default: 3,
  },
  gap = 16,
  className,
  enableDrag = true,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const trackRef = useRef<HTMLDivElement>(null);
  const { currentBreakpoint } = useCleeckyKitResponsive();

  const getItemsPerViewValue = () => {
    if (typeof itemsPerView === "number") {
      // Aplicar responsividade proporcional quando um número é passado
      switch (currentBreakpoint) {
        case "xs":
          return Math.max(1, Math.floor(itemsPerView / 3));
        case "sm":
          return Math.max(1, Math.floor(itemsPerView / 3));
        case "md":
          return Math.max(1, Math.floor(itemsPerView / 1.5));
        default:
          return itemsPerView;
      }
    }

    return (
      itemsPerView[currentBreakpoint as Breakpoint] || itemsPerView.default
    );
  };

  const totalSlides = React.Children.count(children);
  const currentItemsPerView = getItemsPerViewValue();
  const shouldShowControls = totalSlides > currentItemsPerView;
  const maxIndex = Math.max(0, Math.ceil(totalSlides - currentItemsPerView));
  const isInfiniteMode = autoPlay.mode?.includes("infinite");

  useEffect(() => {
    if (isInfiniteMode) {
      setCurrentIndex(totalSlides);
    }
  }, [isInfiniteMode, totalSlides]);

  const getInfiniteSlides = () => {
    if (!isInfiniteMode) return children;
    return [...children, ...children, ...children];
  };

  const handleTransitionEnd = () => {
    if (!isInfiniteMode) return;

    const totalSlidesInfinite = totalSlides * 3;

    if (currentIndex >= totalSlidesInfinite - totalSlides) {
      setIsTransitioning(true);
      setCurrentIndex(totalSlides);
    } else if (currentIndex < totalSlides) {
      setIsTransitioning(true);
      setCurrentIndex(totalSlidesInfinite - totalSlides * 2);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (autoPlay.active && !isDragging) {
      interval = setInterval(() => {
        if (isInfiniteMode) {
          setCurrentIndex((prev) => prev + 1);
        } else if (autoPlay.mode?.includes("reverse")) {
          setCurrentIndex((prev) => {
            if (autoPlay.mode === "infinite-reverse") {
              return prev === 0 ? maxIndex : prev - 1;
            }
            return prev === 0 ? maxIndex : prev - 1;
          });
        } else {
          setCurrentIndex((prev) => {
            if (prev >= maxIndex) return 0;
            return prev + 1;
          });
        }
      }, autoPlay.interval);
    }

    return () => clearInterval(interval);
  }, [
    autoPlay,
    isDragging,
    totalSlides,
    maxIndex,
    currentItemsPerView,
    isInfiniteMode,
  ]);

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const handleNext = () => {
    if (isInfiniteMode) {
      setCurrentIndex((prev) => prev + 1);
    } else {
      setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
    }
  };

  const handlePrev = () => {
    if (isInfiniteMode) {
      setCurrentIndex((prev) => prev - 1);
    } else {
      setCurrentIndex((prev) => Math.max(prev - 1, 0));
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!enableDrag) return;
    setIsDragging(true);
    setStartX(e.pageX - (trackRef.current?.offsetLeft || 0));
    setScrollLeft(currentIndex);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !enableDrag) return;
    e.preventDefault();
    const x = e.pageX - (trackRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 2;
    const newIndex =
      scrollLeft -
      Math.round(
        (walk / (trackRef.current?.offsetWidth || 1)) * currentItemsPerView
      );
    setCurrentIndex(Math.max(0, Math.min(newIndex, maxIndex)));
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const getTransformValue = () => {
    const itemWidth = 100 / currentItemsPerView;
    const baseTransform = currentIndex * itemWidth;
    return `translateX(-${baseTransform}%)`;
  };

  return (
    <CarouselContainer
      className={className}
      $gap={gap}
      $position={navigation.position || pagination.position}>
      <CarouselWrapper
        $navigationPosition={navigation.position}
        $paginationPosition={pagination.position}>
        {navigation.show && shouldShowControls && (
          <>
            <NavigationButton
              onClick={handlePrev}
              $position={navigation.position || "inside-middle"}
              $isLeft={true}
              $navigationBackground={navigation.navigationBackground}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavigationButton>
            <NavigationButton
              onClick={handleNext}
              $position={navigation.position || "inside-middle"}
              $isLeft={false}
              $navigationBackground={navigation.navigationBackground}>
              <FontAwesomeIcon icon={faChevronRight} />
            </NavigationButton>
          </>
        )}

        <CarouselTrack
          ref={trackRef}
          $gap={gap}
          $itemsPerView={currentItemsPerView}
          $isInfinite={isInfiniteMode}
          $hasMoreItems={totalSlides > currentItemsPerView}
          style={{
            transform: getTransformValue(),
            transition: isTransitioning ? "none" : undefined,
          }}
          onTransitionEnd={handleTransitionEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}>
          {isInfiniteMode ? getInfiniteSlides() : children}
        </CarouselTrack>

        {pagination.show && !isInfiniteMode && shouldShowControls && (
          <PaginationContainer
            $position={pagination.position || "outside-bottom"}>
            {Array.from({ length: totalSlides - currentItemsPerView + 1 }).map(
              (_, index) => (
                <PaginationDot
                  key={index}
                  $active={currentIndex === index}
                  onClick={() => setCurrentIndex(index)}
                />
              )
            )}
          </PaginationContainer>
        )}
      </CarouselWrapper>
    </CarouselContainer>
  );
};

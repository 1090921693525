import React from "react";
import { CleeckyKitTextHighlight } from "../components/Typography/CleeckyKitTextHighlight";
import { CleeckyKitTitle } from "../components/Typography/CleeckyKitTitle";
import { CleeckyKitText } from "../components/Typography/CleeckyKitText";
interface TagConfig {
  highlight?: boolean;
  color?: string;
  bold?: boolean;
  underline?: "below" | "behind-middle" | "behind-bottom";
  marker?: boolean;
  link?: {
    url: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
  };
}

interface TextConfig {
  fontSize?: string;
  color?: string;
}

const parseTextConfig = (config: string): TextConfig => {
  const result: TextConfig = {};
  const pairs = config.split(";");

  pairs.forEach((pair) => {
    const [key, value] = pair.split(":");
    if (key && value) {
      const trimmedKey = key.trim();
      const trimmedValue = value.trim();

      if (trimmedKey === "fontSize") {
        result.fontSize = trimmedValue;
      } else if (trimmedKey === "color") {
        result.color = trimmedValue;
      }
    }
  });

  return result;
};

const parseLinkConfig = (
  config: string
): { url: string; target: "_blank" | "_self" | "_parent" | "_top" } => {
  const result = {
    url: "",
    target: "_blank" as "_blank" | "_self" | "_parent" | "_top",
  };
  const pairs = config.split(";");

  pairs.forEach((pair) => {
    const [key, value] = pair.split("=").map((s) => s.trim());
    if (
      key === "target" &&
      ["_blank", "_self", "_parent", "_top"].includes(value)
    ) {
      result.target = value as "_blank" | "_self" | "_parent" | "_top";
    } else if (!key.includes("=")) {
      // Se não tem "=" é a URL
      result.url = key;
    }
  });

  return result;
};

export const formatText = (text: string | React.ReactNode): React.ReactNode => {
  if (typeof text !== "string") {
    return text;
  }

  const parts: React.ReactNode[] = [];
  let currentText = "";
  const tagStack: { tag: string; value?: string; index: number }[] = [];
  let currentTags: TagConfig = {};

  const regex =
    /{(?:(\/?(?:highlight|color|bold|underline|marker|link|title|text))(?:=([^}]+))?|break(?:=(\d+))?)}|\\n/g;
  let lastIndex = 0;
  let match;

  const processCurrentText = (upToIndex: number) => {
    const textToProcess = text.slice(lastIndex, upToIndex);
    if (textToProcess) {
      // Aplica os estilos em camadas, começando do mais interno
      let styledText: React.ReactNode = textToProcess;

      // Se houver tags ativas, aplica os estilos em ordem
      if (Object.keys(currentTags).length > 0) {
        if (currentTags.link) {
          styledText = (
            <a
              href={currentTags.link.url}
              target={currentTags.link.target}
              rel={
                currentTags.link.target === "_blank"
                  ? "noopener noreferrer"
                  : undefined
              }
              style={{ textDecoration: "none" }}>
              <CleeckyKitTextHighlight
                key={parts.length}
                color={currentTags.color}
                mode={{
                  highlight: currentTags.highlight,
                  marker: currentTags.marker,
                  underline: currentTags.underline || "below",
                  bold: currentTags.bold,
                }}>
                {textToProcess}
              </CleeckyKitTextHighlight>
            </a>
          );
        } else {
          styledText = (
            <CleeckyKitTextHighlight
              key={parts.length}
              color={currentTags.color}
              mode={{
                highlight: currentTags.highlight,
                marker: currentTags.marker,
                underline: currentTags.underline,
                bold: currentTags.bold,
              }}>
              {textToProcess}
            </CleeckyKitTextHighlight>
          );
        }
      }

      parts.push(styledText);
    }
  };

  while ((match = regex.exec(text)) !== null) {
    const [fullMatch, tag, value, breakCount] = match;

    // Processa quebras de linha
    if (fullMatch === "\\n" || fullMatch.startsWith("{break")) {
      processCurrentText(match.index);
      lastIndex = regex.lastIndex;

      // Determina o número de quebras de linha
      let numBreaks = 1;
      if (breakCount) {
        numBreaks = parseInt(breakCount, 10);
      }

      // Adiciona as quebras de linha
      for (let i = 0; i < numBreaks; i++) {
        parts.push(<br key={`br-${parts.length}`} />);
      }
      continue;
    }

    const isClosing = tag?.startsWith("/");
    const tagName = isClosing ? tag.slice(1) : tag;

    // Processa tags especiais (title e text)
    if (tagName === "title" || tagName === "text") {
      if (!isClosing) {
        // Processa o texto anterior
        processCurrentText(match.index);
        lastIndex = regex.lastIndex;

        // Inicia coleta de conteúdo para title/text
        tagStack.push({ tag: tagName, value, index: match.index });
      } else {
        // Encontra a tag de abertura correspondente
        const openTagIndex = tagStack
          .slice()
          .reverse()
          .findIndex((item) => item.tag === tagName);

        if (openTagIndex !== -1) {
          const actualIndex = tagStack.length - 1 - openTagIndex;
          const openTag = tagStack[actualIndex];

          // Processa o texto coletado
          const contentStart = lastIndex;
          const contentEnd = match.index;
          const content = text.slice(contentStart, contentEnd);

          // Cria o componente apropriado
          if (tagName === "title") {
            const parsedLevel = openTag.value ? parseInt(openTag.value, 10) : 1;
            const level = (
              parsedLevel >= 1 && parsedLevel <= 6 ? parsedLevel : 1
            ) as 1 | 2 | 3 | 4 | 5 | 6;
            parts.push(
              <CleeckyKitTitle key={parts.length} level={level}>
                {content}
              </CleeckyKitTitle>
            );
          } else if (tagName === "text") {
            const config = openTag.value ? parseTextConfig(openTag.value) : {};
            parts.push(
              <CleeckyKitText
                key={parts.length}
                customStyle={{
                  fontSize: config.fontSize,
                  color: config.color,
                }}>
                {content}
              </CleeckyKitText>
            );
          }

          // Remove a tag do stack
          tagStack.splice(actualIndex);
          lastIndex = regex.lastIndex;
          continue;
        }
      }
      continue;
    }

    processCurrentText(match.index);
    lastIndex = regex.lastIndex;

    if (isClosing) {
      // Remove a tag mais recente que corresponde ao fechamento
      const openTagIndex = tagStack
        .slice()
        .reverse()
        .findIndex((item) => item.tag === tagName);

      if (openTagIndex !== -1) {
        const actualIndex = tagStack.length - 1 - openTagIndex;
        tagStack.splice(actualIndex);

        // Reconstrói o estado atual das tags mantendo a ordem
        currentTags = {};
        tagStack.forEach((item) => {
          switch (item.tag) {
            case "highlight":
              currentTags.highlight = true;
              break;
            case "color":
              currentTags.color = item.value;
              break;
            case "bold":
              currentTags.bold = true;
              break;
            case "underline":
              currentTags.underline = (item.value as any) || "below";
              break;
            case "marker":
              currentTags.marker = true;
              break;
            case "link":
              currentTags.link = parseLinkConfig(item.value || "");
              break;
          }
        });
      }
    } else {
      // Adiciona nova tag ao stack
      tagStack.push({ tag: tagName, value, index: match.index });

      // Atualiza as tags atuais
      switch (tagName) {
        case "highlight":
          currentTags.highlight = true;
          break;
        case "color":
          currentTags.color = value;
          break;
        case "bold":
          currentTags.bold = true;
          break;
        case "underline":
          currentTags.underline = (value as any) || "below";
          break;
        case "marker":
          currentTags.marker = true;
          break;
        case "link":
          currentTags.link = parseLinkConfig(value || "");
          break;
      }
    }
  }

  // Processa o texto restante
  processCurrentText(text.length);

  // Se houver apenas uma parte e não houver tags, retorna o texto original
  // Isso permite que os componentes processem suas próprias tags (como {icon})
  if (parts.length === 1 && Object.keys(currentTags).length === 0) {
    return text;
  }

  return parts;
};

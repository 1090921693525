import api from "../axiosConfig";
import {
  AggregatorPageData,
  AggregatorContentType,
  AggregatorAccessRequestData,
  PageStatsResponse,
  PeakAccessPeriodsResponse,
  TrafficSourceStatsResponse,
  MetricsPeriod,
  CreateMetricsResponse,
} from "cleecky@types/interfaces";
import {
  AggregatorMetricsSource,
  AggregatorAccessRequestStatus,
} from "cleecky@types/enums";
import { authService } from "./authService";

export enum AggregatorPageVisibilityType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  UNPUBLISHED = "UNPUBLISHED",
}

interface CreatePageContentDto {
  type: AggregatorContentType;
  data: any;
  order: number;
  show?: boolean;
}

interface CreatePageDto {
  title: string;
  slug: string;
  visibility: AggregatorPageVisibilityType;
  textColor?: string;
  highlightColor?: string;
  borderColor?: string;
  pageBackgroundColor?: string;
  pageContentBackgroundColor?: string;
  buttonsBackgroundColor?: string;
  buttonsLabelColor?: string;
  borderWidth?: string;
  borderRadius?: string;
  contents: CreatePageContentDto[];
  thumbnailUrl?: string;
}

interface CreateMetricsDto {
  pageUuid: string;
  city?: string;
  state?: string;
  country?: string;
  source: AggregatorMetricsSource;
  hasInteraction?: boolean;
  ip?: string;
}

interface UpdateMetricsInteractionDto {
  hasInteraction: boolean;
}

export const aggregatorService = {
  async create(createDto: CreatePageDto): Promise<AggregatorPageData> {
    const response = await api.post("/toolbox/aggregator-pages", createDto);
    return response.data;
  },

  async getAll(): Promise<AggregatorPageData[]> {
    const response = await api.get("/toolbox/aggregator-pages");
    return response.data;
  },

  async getById(uuid: string): Promise<AggregatorPageData> {
    const response = await api.get(`/toolbox/aggregator-pages/${uuid}`);
    return response.data;
  },

  async update(
    uuid: string,
    updateDto: Partial<CreatePageDto>
  ): Promise<AggregatorPageData> {
    const response = await api.put(
      `/toolbox/aggregator-pages/${uuid}`,
      updateDto
    );
    return response.data;
  },

  async delete(uuid: string): Promise<void> {
    await api.delete(`/toolbox/aggregator-pages/${uuid}`);
  },

  async toggleVisibility(
    uuid: string,
    updateDto: { visibility: AggregatorPageVisibilityType }
  ): Promise<AggregatorPageData> {
    const response = await api.put(
      `/toolbox/aggregator-pages/${uuid}/visibility`,
      updateDto
    );
    return response.data;
  },

  async addApprovedUser(
    pageUuid: string,
    userId: string
  ): Promise<AggregatorPageData> {
    const response = await api.post(
      `/toolbox/aggregator-pages/${pageUuid}/approved-users/${userId}`
    );
    return response.data;
  },

  async removeApprovedUser(
    pageUuid: string,
    userId: string
  ): Promise<AggregatorPageData> {
    const response = await api.delete(
      `/toolbox/aggregator-pages/${pageUuid}/approved-users/${userId}`
    );
    return response.data;
  },

  async uploadFile(file: File): Promise<{ url: string }> {
    const currentUser = await authService.getCurrentUser();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("folder", `uploads/${currentUser.uuid}/aggregator-pages`);
    formData.append("visibility", "PUBLIC");

    const response = await api.post("/shared/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { url: response.data.file.url };
  },

  async updateTitle(
    uuid: string,
    updateDto: { slug: string; title: string }
  ): Promise<AggregatorPageData> {
    const response = await api.put(
      `/toolbox/aggregator-pages/${uuid}/title`,
      updateDto
    );
    return response.data;
  },

  async getAccessRequests(
    pageUuid: string,
    status?: AggregatorAccessRequestStatus
  ): Promise<AggregatorAccessRequestData[]> {
    const response = await api.get(
      `/toolbox/aggregator-pages/${pageUuid}/access-requests${
        status ? `?status=${status}` : ""
      }`
    );
    return response.data;
  },

  async updateAccessStatus(
    pageUuid: string,
    accessRequestUuid: string,
    status: AggregatorAccessRequestStatus
  ): Promise<AggregatorAccessRequestData> {
    const response = await api.put(
      `/toolbox/aggregator-pages/${pageUuid}/access-status-update/${accessRequestUuid}`,
      { status }
    );
    return response.data;
  },

  async addUserAccess(
    pageUuid: string,
    username: string
  ): Promise<AggregatorAccessRequestData> {
    const response = await api.post(
      `/toolbox/aggregator-pages/${pageUuid}/access-request-add-user/${username}`
    );
    return response.data;
  },

  async requestAccess(pageUuid: string): Promise<AggregatorAccessRequestData> {
    const response = await api.post(
      `/toolbox/aggregator-pages/new-access-request/${pageUuid}`
    );
    return response.data;
  },

  async createMetrics(
    createDto: CreateMetricsDto
  ): Promise<CreateMetricsResponse> {
    // Primeiro, busca a página para verificar a visibilidade
    const page = await this.getById(createDto.pageUuid);

    // Só salva a métrica se a página for PUBLIC ou PRIVATE
    if (
      page.visibility === AggregatorPageVisibilityType.PUBLIC ||
      page.visibility === AggregatorPageVisibilityType.PRIVATE
    ) {
      const response = await api.post("/toolbox/aggregator-metrics", createDto);
      return response.data;
    }
    return { uuid: "" }; // Retorna um UUID vazio se a página não estiver visível
  },

  async updateMetricsInteraction(
    uuid: string,
    hasInteraction: boolean
  ): Promise<void> {
    await api.put(`/toolbox/aggregator-metrics/${uuid}/interaction`, {
      hasInteraction,
    });
  },

  async getPageStats(period?: MetricsPeriod): Promise<PageStatsResponse> {
    const response = await api.get(
      `/toolbox/aggregator-metrics/stats${period ? `?period=${period}` : ""}`
    );
    return response.data;
  },

  async getPeakAccessPeriods(
    period: MetricsPeriod
  ): Promise<PeakAccessPeriodsResponse> {
    const response = await api.get(
      `/toolbox/aggregator-metrics/peak-periods?period=${period}`
    );
    return response.data;
  },

  async getTrafficSourceStats(
    period?: MetricsPeriod
  ): Promise<TrafficSourceStatsResponse> {
    const response = await api.get(
      `/toolbox/aggregator-metrics/traffic-sources${
        period ? `?period=${period}` : ""
      }`
    );
    return response.data;
  },
};

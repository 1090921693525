import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

export const PricingPaymentCancel: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Column
        padding="4rem 0.5rem"
        gap="2rem"
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}>
        <CleeckyKit.Title level={3} align="center">
          {t("pages.pricing.payment.cancel.title")}
        </CleeckyKit.Title>

        <CleeckyKit.Text>
          {t("pages.pricing.payment.cancel.description")}
        </CleeckyKit.Text>
        <CleeckyKit.Row justify="center">
          <CleeckyKit.Button
            type="button"
            variant="solid"
            highlighted
            icon={<FontAwesomeIcon icon={faHome} />}
            text={t("pages.pricing.payment.cancel.back_to_home")}
            textPosition="inside-right"
            href="/"
            target="_self"
          />
        </CleeckyKit.Row>
      </CleeckyKit.Column>
    </>
  );
};

export default PricingPaymentCancel;

import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Z_INDEX } from "CleeckyKit/themes/zindex";
import * as CleeckyKit from "CleeckyKit";
interface CleeckyKitTooltipProps {
  children: React.ReactNode;
  content: string;
  position?: "top" | "bottom" | "left" | "right" | "cursor";
  delay?: number;
  closeDelay?: number;
  autoClose?: boolean;
  style?: React.CSSProperties;
}

interface TooltipContainerProps {
  $position: "top" | "bottom" | "left" | "right" | "cursor";
  $x?: number;
  $y?: number;
}

const TooltipContainer = styled(motion.div)<TooltipContainerProps>`
  position: absolute;
  background: ${({ theme }) => theme.colors.background.card};
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.8rem;
  z-index: ${Z_INDEX.tooltip.container};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  max-width: 250px;
  white-space: normal;
  word-wrap: break-word;

  ${({ $position, $x, $y }) => {
    if ($position === "cursor" && $x !== undefined && $y !== undefined) {
      return `
        position: fixed;
        left: ${$x}px;
        top: ${$y}px;
        transform: translate(10px, 10px);
      `;
    }

    const getPosition = () => {
      switch ($position) {
        case "top":
          return `
            bottom: 120%;
            left: 50%;
            transform: translateX(-50%);
          `;
        case "bottom":
          return `
            top: 120%;
            left: 50%;
            transform: translateX(-50%);
          `;
        case "left":
          return `
            right: 110%;
            top: 50%;
            transform: translateY(-50%);
          `;
        case "right":
          return `
            left: 110%;
            top: 50%;
            transform: translateY(-50%);
          `;
      }
    };

    return getPosition();
  }}
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const CleeckyKitTooltip: React.FC<CleeckyKitTooltipProps> = ({
  children,
  content,
  position = "cursor",
  delay = 0,
  closeDelay = 4000,
  autoClose = true,
  style,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  let showTimeoutId: NodeJS.Timeout;
  let hideTimeoutId: NodeJS.Timeout;

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (hideTimeoutId) {
      clearTimeout(hideTimeoutId);
    }

    if (position === "cursor") {
      setMousePos({ x: e.clientX, y: e.clientY });
    }

    showTimeoutId = setTimeout(() => {
      setIsVisible(true);
      if (autoClose) {
        hideTimeoutId = setTimeout(() => {
          setIsVisible(false);
        }, closeDelay);
      }
    }, delay);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (position === "cursor" && isVisible) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Calcula as coordenadas ajustadas
      const adjustedX = Math.min(e.clientX, viewportWidth - 200); // 200px é uma margem de segurança
      const adjustedY = Math.min(e.clientY, viewportHeight - 50); // 50px é uma margem de segurança

      setMousePos({ x: adjustedX, y: adjustedY });
    }
  };

  const handleMouseLeave = () => {
    if (showTimeoutId) {
      clearTimeout(showTimeoutId);
    }
    if (hideTimeoutId) {
      clearTimeout(hideTimeoutId);
    }
    setIsVisible(false);
  };

  return (
    <TooltipWrapper
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}>
      {children}
      <AnimatePresence>
        {isVisible && (
          <TooltipContainer
            $position={position}
            $x={mousePos.x}
            $y={mousePos.y}
            initial={{ opacity: 0, y: position === "top" ? 10 : -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: position === "top" ? 10 : -10 }}
            transition={{ duration: 0.2 }}
            style={style}>
            <CleeckyKit.Text>{content}</CleeckyKit.Text>
          </TooltipContainer>
        )}
      </AnimatePresence>
    </TooltipWrapper>
  );
};

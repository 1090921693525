import React, { useState, useMemo } from "react";
import styled from "styled-components";
import * as CleeckyKit from "../../CleeckyKit";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faQrcode, faChain } from "@fortawesome/free-solid-svg-icons";
import { useCleeckyKitCurrentTheme } from "../../CleeckyKit/themes/themeUtilities/CleeckyKitThemeProvider";
import mikeFrente from "../../assets/images/mike-frente.jpeg";
import mikePerfil from "../../assets/images/mike-perfil.jpeg";
import { CleeckyInsidersWaitingList } from "components";
import { routes, RouteDefinition } from "../../routes/routes";
import { AuthRedirectState } from "../../hooks/useAuthRedirect";
import { useAuth } from "../../contexts/AuthContext";
import { AuthModal } from "../../components/Auth/AuthModal";

const toolImages: Record<string, string> = {
  link_aggregator:
    "https://cleecky.s3.amazonaws.com/uploads/0x1311c318416f4e799cdab2471fa56d54/38d1a145-4abc-4a1b-8a0e-87fd0db932d4-BANNER%201.png",
  link_shortener:
    "https://cleecky.s3.amazonaws.com/uploads/0x1311c318416f4e799cdab2471fa56d54/766543de-17e9-4cd4-9e19-fcb003cda290-BANNER%202.png",
  qr_code_generator:
    "https://cleecky.s3.sa-east-1.amazonaws.com/uploads/0x1311c318416f4e799cdab2471fa56d54/78c83e8d-143e-4826-a629-4639ffe0c81e-BANNER%203.png",
};

const ToolboxHomepage: React.FC = () => {
  const { currentTheme, currentGradient } = useCleeckyKitCurrentTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const tools = useMemo(() => {
    const toolboxRoute = routes.find((route) => route.id === "toolbox");
    return toolboxRoute?.children || [];
  }, []);

  if (tools.length === 0) {
    throw new Error("No tools found in toolbox route");
  }

  const state = location.state as AuthRedirectState;
  const initialTool = useMemo(() => {
    if (state?.selectedTool) {
      const foundTool = tools.find((tool) => {
        const toolPath = tool.path.split("/").pop();
        return toolPath === state.selectedTool;
      });
      return foundTool || tools[0];
    }
    return tools[0];
  }, [state?.selectedTool, tools]);

  const [selectedTool, setSelectedTool] =
    useState<RouteDefinition>(initialTool);

  const getToolImage = (toolId: string) => toolImages[toolId] || mikeFrente;

  return (
    <CleeckyKit.Column padding="5rem 0 0 0">
      <CleeckyKit.Section>
        <CleeckyKit.Row justify="center">
          <div
            style={{
              padding: "0 2rem",
              minWidth: "350px",
              maxWidth: "650px",
            }}>
            <CleeckyKit.Column gap="2rem">
              <CleeckyKit.Title level={2} align="center">
                {t(
                  "project:pages.toolbox.home_page.sections.presentation_section.title"
                )}
              </CleeckyKit.Title>
              <CleeckyKit.Text align="center">
                {t(
                  "project:pages.toolbox.home_page.sections.presentation_section.description"
                )}
              </CleeckyKit.Text>
            </CleeckyKit.Column>
          </div>
        </CleeckyKit.Row>
      </CleeckyKit.Section>
      <CleeckyKit.Section padding="5rem 2rem">
        <CleeckyKit.Card
          padding="1rem"
          backgroundColor="transparent"
          border={`5px solid ${
            currentGradient?.highlightColor ||
            currentTheme.colors.interactive.clickable
          }`}
          borderRadius={25}>
          <CleeckyKit.Column>
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Column justify="between">
                <CleeckyKit.Section>
                  <CleeckyKit.Row>
                    <CleeckyKit.Title level={3}>
                      <CleeckyKit.TextHighlight
                        color={currentTheme.gradient?.highlightColor}
                        mode={{
                          underline: "behind-bottom",
                        }}>
                        {t(
                          `project:apps.toolbox.tools.${selectedTool.id}.name`
                        )}
                      </CleeckyKit.TextHighlight>
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>

                  <CleeckyKit.Row>
                    <CleeckyKit.Text
                      align="justify"
                      hyphenate
                      animation="fade"
                      preserveWhitespace>
                      {t(
                        `project:apps.toolbox.tools.${selectedTool.id}.description`
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Row>
                </CleeckyKit.Section>

                <CleeckyKit.Row>
                  <div>
                    <CleeckyKit.Grid
                      gap="1rem"
                      columns={{ sm: 1, md: 2, lg: 3 }}
                      justify="start">
                      {tools.map((tool) => (
                        <CleeckyKit.Button
                          type="button"
                          key={tool.id}
                          icon={<FontAwesomeIcon icon={tool.icon} />}
                          text={t(`project:apps.toolbox.tools.${tool.id}.name`)}
                          textPosition="inside-right"
                          variant="outline"
                          onClick={() => setSelectedTool(tool)}
                          borderRadius
                          size="small"
                          isActive={selectedTool.id === tool.id}
                          animation={{
                            type: "scale",
                            trigger: "hover",
                          }}
                        />
                      ))}
                    </CleeckyKit.Grid>
                  </div>
                </CleeckyKit.Row>
              </CleeckyKit.Column>

              <CleeckyKit.Column>
                <CleeckyKit.Image
                  src={getToolImage(selectedTool.id)}
                  alt={t(
                    `project:apps.toolbox.tools.${selectedTool.id}.cta_homepage`
                  )}
                  fit="cover"
                  height="100%"
                  aspectRatio="4/3"
                  position="center"
                  borderRadius={20}
                  sourceType="external"
                  loading="lazy"
                />
              </CleeckyKit.Column>
            </CleeckyKit.Row>
            <CleeckyKit.Row padding="1rem" justify="center">
              <CleeckyKit.Button
                text={t(
                  `project:apps.toolbox.tools.${selectedTool.id}.cta_homepage`
                )}
                onClick={() => {
                  if (!user) {
                    setIsAuthModalOpen(true);
                  } else {
                    navigate(selectedTool.path);
                  }
                }}
                variant="solid"
                size="medium"
                fullWidth
                animation={{
                  type: "scale",
                  trigger: "hover",
                }}
                highlighted
              />
            </CleeckyKit.Row>
          </CleeckyKit.Column>
        </CleeckyKit.Card>
      </CleeckyKit.Section>
      <CleeckyKit.Section background="white" padding="5rem 0">
        <CleeckyKit.Column>
          <CleeckyKit.Row justify="center">
            <div
              style={{
                padding: "0 2rem",
                minWidth: "350px",
                maxWidth: "650px",
              }}>
              <CleeckyKit.Column gap="2rem">
                <CleeckyKit.Title
                  level={2}
                  align="center"
                  customStyle={{ color: "black" }}>
                  {t(
                    "project:pages.toolbox.home_page.sections.tools_section.title"
                  )}
                </CleeckyKit.Title>
                <CleeckyKit.Text
                  customStyle={{ color: "black" }}
                  align="center">
                  {t(
                    "project:pages.toolbox.home_page.sections.tools_section.description"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.Column>
            </div>
          </CleeckyKit.Row>

          <CleeckyKit.Row justify="center" padding="3rem 0">
            <CleeckyKit.Carousel
              navigation={{ show: false }}
              pagination={{ show: false }}
              autoPlay={{
                active: true,
                interval: 3000,
                mode: "infinite-reverse",
              }}
              itemsPerView={{
                default: 3,
              }}
              gap={16}>
              <CleeckyKit.Image
                src="https://cleecky.s3.sa-east-1.amazonaws.com/uploads/0x1311c318416f4e799cdab2471fa56d54/bf502d45-423a-4188-82da-e2ecfaf185b6-carrossel%201.png"
                alt="Photo"
                fit="cover"
                height="100%"
                aspectRatio="1"
                position="center"
                borderRadius={20}
              />
              <CleeckyKit.Image
                src="https://cleecky.s3.sa-east-1.amazonaws.com/uploads/0x1311c318416f4e799cdab2471fa56d54/5ec56d35-be22-404f-b532-1054868957d3-carrossel%202.png"
                alt="Photo"
                fit="cover"
                height="100%"
                aspectRatio="1"
                position="center"
                borderRadius={20}
              />
              <CleeckyKit.Image
                src="https://cleecky.s3.sa-east-1.amazonaws.com/uploads/0x1311c318416f4e799cdab2471fa56d54/6b9675bf-7958-48cc-9587-37bd7fa11452-1000098254.jpg"
                alt="Photo"
                fit="cover"
                height="100%"
                aspectRatio="1"
                position="center"
                borderRadius={20}
              />

              <CleeckyKit.Image
                src="https://cleecky.s3.sa-east-1.amazonaws.com/uploads/0x1311c318416f4e799cdab2471fa56d54/0ee969dd-60a2-4c39-a502-90528ff91af6-carrossel%204.png"
                alt="Photo"
                fit="cover"
                height="100%"
                aspectRatio="1"
                position="center"
                borderRadius={20}
              />
            </CleeckyKit.Carousel>
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Section>
      <CleeckyInsidersWaitingList />

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        initialMode="login"
      />
    </CleeckyKit.Column>
  );
};

export default ToolboxHomepage;

import React, { useEffect, useState } from "react";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faAt,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { aggregatorService } from "services/aggregatorService";
import {
  AggregatorAccessRequestData,
  FilterType,
  AggregatorPageData,
} from "cleecky@types/interfaces";
import { AggregatorAccessRequestStatus } from "cleecky@types/enums";

const AggregatorAccessManagement: React.FC = () => {
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState<AggregatorPageData | null>(null);
  const [users, setUsers] = useState<AggregatorAccessRequestData[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType>("all");
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const filterOptions = [
    {
      value: "all",
      label: t(
        "project:apps.toolbox.tools.link_aggregator.access_management.filters.all"
      ),
    },
    {
      value: "approved",
      label: t(
        "project:apps.toolbox.tools.link_aggregator.access_management.filters.approved"
      ),
    },
    {
      value: "refused",
      label: t(
        "project:apps.toolbox.tools.link_aggregator.access_management.filters.refused"
      ),
    },
    {
      value: "pending",
      label: t(
        "project:apps.toolbox.tools.link_aggregator.access_management.filters.pending"
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!uuid) return;

      try {
        setIsLoading(true);
        const pageData = await aggregatorService.getById(uuid);
        setPage(pageData);

        const accessRequests = await aggregatorService.getAccessRequests(uuid);
        setUsers(accessRequests);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [uuid]);

  const filteredAccessRequests = users.filter((user) => {
    if (selectedFilter === "all") return true;
    if (selectedFilter === "pending")
      return user.status === AggregatorAccessRequestStatus.PENDING;
    if (selectedFilter === "approved")
      return user.status === AggregatorAccessRequestStatus.APPROVED;
    if (selectedFilter === "refused")
      return user.status === AggregatorAccessRequestStatus.REFUSED;
    return true;
  });

  const handleApproveUsers = async (userUuids: string[]) => {
    if (!uuid) return;

    try {
      const updatedUsers = await Promise.all(
        userUuids.map((userUuid) =>
          aggregatorService.updateAccessStatus(
            uuid,
            userUuid,
            AggregatorAccessRequestStatus.APPROVED
          )
        )
      );

      setUsers((prevUsers) =>
        prevUsers.map(
          (user) =>
            updatedUsers.find((updated: any) => updated.uuid === user.uuid) ||
            user
        )
      );
    } catch (error) {
      console.error("Erro ao aprovar usuários:", error);
    }
  };

  const handleRejectUsers = async (userUuids: string[]) => {
    if (!uuid) return;

    try {
      const updatedUsers = await Promise.all(
        userUuids.map((userUuid) =>
          aggregatorService.updateAccessStatus(
            uuid,
            userUuid,
            AggregatorAccessRequestStatus.REFUSED
          )
        )
      );

      setUsers((prevUsers) =>
        prevUsers.map(
          (user) =>
            updatedUsers.find((updated: any) => updated.uuid === user.uuid) ||
            user
        )
      );
    } catch (error) {
      console.error("Erro ao recusar usuários:", error);
    }
  };

  const handleAddUser = async (formData: { username: string }) => {
    if (!uuid) return;

    try {
      const newUser = await aggregatorService.addUserAccess(
        uuid,
        formData.username
      );
      setUsers((prevUsers) => [...prevUsers, newUser]);
      setIsAddUserModalOpen(false);
    } catch (error) {
      console.error("Erro ao adicionar usuário:", error);
    }
  };

  const getEmptyStateMessage = () => {
    switch (selectedFilter) {
      case "pending":
        return t(
          "project:apps.toolbox.tools.link_aggregator.access_management.empty_states.no_pending_requests"
        );
      case "approved":
        return t(
          "project:apps.toolbox.tools.link_aggregator.access_management.empty_states.no_approved_users"
        );
      case "refused":
        return t(
          "project:apps.toolbox.tools.link_aggregator.access_management.empty_states.no_refused_users"
        );
      default:
        return t(
          "project:apps.toolbox.tools.link_aggregator.access_management.empty_states.no_users"
        );
    }
  };

  if (isLoading) {
    return <CleeckyKit.LoadingScreen />;
  }

  if (!page) {
    return (
      <CleeckyKit.EmptyState
        icon="exclamation-triangle"
        title={t("cleeckykit:common.errors.not_found")}
        description={t("cleeckykit:common.errors.page_not_found")}
      />
    );
  }

  return (
    <CleeckyKit.Column gap="2rem">
      <CleeckyKit.Row justify="between" noBreak>
        <CleeckyKit.Button
          text={t("cleeckykit:common.actions.back")}
          textPosition="inside-right"
          variant="solid"
          size="small"
          onClick={() => navigate("/toolbox/link-aggregator/pages")}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
        />
      </CleeckyKit.Row>
      <CleeckyKit.Row>
        <CleeckyKit.Column gap={0.5}>
          <CleeckyKit.Title level={3}>
            {t(
              "project:apps.toolbox.tools.link_aggregator.access_management.title"
            )}
          </CleeckyKit.Title>
          <CleeckyKit.Text href={`/@${page?.user.username}/${page?.slug}`}>
            @{page?.user.username}/{page?.slug}
          </CleeckyKit.Text>
        </CleeckyKit.Column>
      </CleeckyKit.Row>

      <CleeckyKit.Row justify="between" align="center" noBreak>
        <CleeckyKit.ButtonGroup
          label={t(
            "project:apps.toolbox.tools.link_aggregator.access_management.filters.label"
          )}
          options={filterOptions}
          value={selectedFilter}
          onChange={(value) => setSelectedFilter(value as FilterType)}
        />

        {(selectedFilter === "approved" || selectedFilter === "all") && (
          <CleeckyKit.Button
            text={t(
              "project:apps.toolbox.tools.link_aggregator.access_management.add_user"
            )}
            textPosition="inside-right"
            variant="solid"
            size="small"
            highlighted
            onClick={() => setIsAddUserModalOpen(true)}
            icon={<FontAwesomeIcon icon={faUserPlus} />}
          />
        )}
      </CleeckyKit.Row>

      {filteredAccessRequests.length > 0 ? (
        <CleeckyKit.List hoverable={false}>
          {filteredAccessRequests.map((ar) => (
            <CleeckyKit.ListItem key={ar.uuid}>
              <CleeckyKit.Row justify="between" align="center">
                <CleeckyKit.Column width="4.5rem">
                  {ar.user.avatarUrl && (
                    <CleeckyKit.Image
                      alt={`Avatar de ${ar.user.firstName} ${ar.user.lastName}`}
                      fit="cover"
                      borderRadius="50%"
                      aspectRatio={1}
                      width="4rem"
                      height="4rem"
                      src={ar.user.avatarUrl}
                    />
                  )}
                  {!ar.user.avatarUrl && (
                    <CleeckyKit.Avatar
                      icon={<FontAwesomeIcon icon={faUser} />}
                    />
                  )}
                </CleeckyKit.Column>
                <CleeckyKit.Column>
                  <CleeckyKit.Title level={4} align="center">
                    {ar.user.firstName} {ar.user.lastName}
                  </CleeckyKit.Title>
                </CleeckyKit.Column>

                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="between" noBreak>
                    <CleeckyKit.Text>@{ar.user.username}</CleeckyKit.Text>

                    <CleeckyKit.Text>{ar.user.email}</CleeckyKit.Text>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>

                <CleeckyKit.Column width="10rem">
                  <CleeckyKit.Tag
                    label={t(
                      `project:apps.toolbox.tools.link_aggregator.access_management.status.${ar.status.toLowerCase()}`
                    )}
                    variant={
                      ar.status === AggregatorAccessRequestStatus.APPROVED
                        ? "success"
                        : ar.status === AggregatorAccessRequestStatus.REFUSED
                        ? "error"
                        : "warning"
                    }
                  />
                </CleeckyKit.Column>

                <CleeckyKit.Column>
                  {ar.status === AggregatorAccessRequestStatus.PENDING && (
                    <CleeckyKit.Row justify="end">
                      <CleeckyKit.Button
                        text={t(
                          "project:apps.toolbox.tools.link_aggregator.access_management.actions.approve"
                        )}
                        size="small"
                        onClick={() => handleApproveUsers([ar.uuid])}
                      />
                      <CleeckyKit.Button
                        text={t(
                          "project:apps.toolbox.tools.link_aggregator.access_management.actions.reject"
                        )}
                        size="small"
                        onClick={() => handleRejectUsers([ar.uuid])}
                      />
                    </CleeckyKit.Row>
                  )}

                  {ar.status === AggregatorAccessRequestStatus.REFUSED && (
                    <CleeckyKit.Row justify="end">
                      <CleeckyKit.Button
                        text={t(
                          "project:apps.toolbox.tools.link_aggregator.access_management.actions.approve"
                        )}
                        size="small"
                        onClick={() => handleApproveUsers([ar.uuid])}
                      />
                    </CleeckyKit.Row>
                  )}

                  {ar.status === AggregatorAccessRequestStatus.APPROVED && (
                    <CleeckyKit.Row justify="end">
                      <CleeckyKit.Button
                        text={t(
                          "project:apps.toolbox.tools.link_aggregator.access_management.actions.reject"
                        )}
                        size="small"
                        onClick={() => handleRejectUsers([ar.uuid])}
                      />
                    </CleeckyKit.Row>
                  )}
                </CleeckyKit.Column>
              </CleeckyKit.Row>
            </CleeckyKit.ListItem>
          ))}
        </CleeckyKit.List>
      ) : (
        <CleeckyKit.EmptyState
          icon={faUser}
          title={getEmptyStateMessage()}
          description=""
        />
      )}

      <CleeckyKit.Modal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        title={t(
          "project:apps.toolbox.tools.link_aggregator.access_management.add_user_modal.title"
        )}
        size="small">
        <CleeckyKit.Form onSubmit={handleAddUser}>
          <CleeckyKit.FormGroup>
            <CleeckyKit.FormItem span={12}>
              <CleeckyKit.TextInput
                prefix={<FontAwesomeIcon icon={faAt} />}
                name="username"
                label={t(
                  "project:apps.toolbox.tools.link_aggregator.access_management.add_user_modal.input_label"
                )}
                placeholder={t(
                  "project:apps.toolbox.tools.link_aggregator.access_management.add_user_modal.input_placeholder"
                )}
                required
              />
            </CleeckyKit.FormItem>
          </CleeckyKit.FormGroup>

          <CleeckyKit.Row justify="end">
            <CleeckyKit.Button
              text={t("cleeckykit:common.actions.cancel")}
              variant="link"
              onClick={() => setIsAddUserModalOpen(false)}
            />
            <CleeckyKit.Button
              text={t("cleeckykit:common.actions.confirm")}
              variant="solid"
              type="submit"
              highlighted
            />
          </CleeckyKit.Row>
        </CleeckyKit.Form>
      </CleeckyKit.Modal>
    </CleeckyKit.Column>
  );
};

export default AggregatorAccessManagement;

import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
  faLightbulb,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";

type AlertVariant =
  | "info"
  | "success"
  | "danger"
  | "warning"
  | "error"
  | "highlight"
  | "neutral";

interface AlertProps {
  variant: AlertVariant;
  message: string;
  isOpen: boolean;
  onClose?: () => void;
  autoClose?: boolean;
  autoCloseTime?: number;
}

const AlertContainer = styled.div<{ variant: AlertVariant }>`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: ${({ theme }) => theme.shadows.card.elevated};
  z-index: 9999;
  min-width: 300px;
  max-width: 500px;
  animation: slideIn 0.3s ease-in-out;

  ${({ variant, theme }) => {
    const getVariantStyles = () => {
      switch (variant) {
        case "info":
          return `
            background-color: ${theme.colors.status.info};
            color: ${theme.colors.text.onPrimary};
          `;
        case "success":
          return `
            background-color: ${theme.colors.status.success};
            color: ${theme.colors.text.onPrimary};
          `;
        case "danger":
          return `
            background-color: ${theme.colors.status.error};
            color: ${theme.colors.text.onPrimary};
          `;
        case "warning":
          return `
            background-color: ${theme.colors.status.warning};
            color: ${theme.colors.text.primary};
          `;
        case "error":
          return `
            background-color: ${theme.colors.status.error};
            color: ${theme.colors.text.onPrimary};
          `;
        case "highlight":
          return `
            background-color: ${theme.colors.interactive.primary};
            color: ${theme.colors.text.onPrimary};
          `;
        case "neutral":
          return `
            background-color: ${theme.colors.background.card};
            color: ${theme.colors.text.primary};
            border: 1px solid ${theme.colors.border.input};
          `;
        default:
          return "";
      }
    };
    return getVariantStyles();
  }}

  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 4px;
  margin-left: auto;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const getAlertIcon = (variant: AlertVariant) => {
  switch (variant) {
    case "info":
      return faInfoCircle;
    case "success":
      return faCheckCircle;
    case "danger":
    case "error":
      return faTimesCircle;
    case "warning":
      return faExclamationTriangle;
    case "highlight":
      return faLightbulb;
    case "neutral":
      return faBell;
    default:
      return faInfoCircle;
  }
};

export const CleeckyKitAlert: React.FC<AlertProps> = ({
  variant = "info",
  message,
  isOpen,
  onClose,
  autoClose = true,
  autoCloseTime = 5000,
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (autoClose && isOpen) {
      const timer = setTimeout(() => {
        onClose?.();
      }, autoCloseTime);

      return () => clearTimeout(timer);
    }
  }, [autoClose, autoCloseTime, isOpen, onClose]);

  if (!isOpen) return null;

  const alertContent = (
    <AlertContainer variant={variant} role="alert">
      <FontAwesomeIcon icon={getAlertIcon(variant)} />
      <span>{message}</span>
      {onClose && (
        <CloseButton onClick={onClose} aria-label={t("cleeckykit:alert.close")}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </CloseButton>
      )}
    </AlertContainer>
  );

  return ReactDOM.createPortal(alertContent, document.body);
};

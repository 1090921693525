import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { CanvasElement } from "../../../types";
import * as Types from "../../../types";
import { faPlus, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormProvider, useForm } from "react-hook-form";
import { CleeckyKitColorPicker } from "../../../../Elements/Input/CleeckyKitColorPicker";

interface EditModalProps {
  element: CanvasElement;
  onClose: () => void;
  onSave: (element: CanvasElement) => void;
  globalColors: Types.GlobalColors;
  globalSizes: Types.GlobalSizes;
  elements: CanvasElement[];
  onElementsChange: (elements: CanvasElement[]) => void;
}

interface FileInputProps {
  value: Types.FileData;
  onChange: (file: Types.FileData) => void;
  accept: string;
  label: string;
}

type TextSize = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const FileInput: React.FC<FileInputProps> = ({
  value,
  onChange,
  accept,
  label,
}) => {
  return (
    <CleeckyKit.Column gap={8}>
      <CleeckyKit.Text>{label}</CleeckyKit.Text>
      <CleeckyKit.TextInput
        name="file-path"
        value={value.path}
        onChange={(e) =>
          onChange({
            path: e.target.value,
            type: "EXTERNAL",
          })
        }
        placeholder="URL da imagem"
      />
      <StyledInput
        type="file"
        accept={accept}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            onChange({
              path: URL.createObjectURL(file),
              type: "FILE",
            });
          }
        }}
      />
    </CleeckyKit.Column>
  );
};

const ModalBody = styled.div`
  margin-bottom: 24px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 16px;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.interactive.primary};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;
  margin-bottom: 4px;
`;

export const EditModal: React.FC<EditModalProps> = ({
  element,
  onClose,
  onSave,
  globalColors,
  globalSizes,
  elements,
  onElementsChange,
}) => {
  const { t } = useTranslation();
  const [editingElement, setEditingElement] = useState<CanvasElement | null>(
    element
  );
  const methods = useForm({
    defaultValues: {
      ...(editingElement?.type === "TEXT" ||
      editingElement?.type === "TITLE" ||
      editingElement?.type === "NAME" ||
      editingElement?.type === "USERNAME"
        ? { text: (editingElement.content as Types.TextContent).text || "" }
        : {}),
      ...(editingElement?.type === "LINK"
        ? {
            label: (editingElement.content as Types.LinkContent).label || "",
            url: (editingElement.content as Types.LinkContent).url || "",
          }
        : {}),
    },
  });

  const renderEditFields = () => {
    switch (editingElement?.type) {
      case "AVATAR":
        const avatarContent = editingElement.content as Types.AvatarContent;
        return (
          <CleeckyKit.Column gap={16}>
            <FileInput
              value={avatarContent.file}
              onChange={(file) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...avatarContent,
                    file,
                  },
                })
              }
              accept="image/*"
              label={t(
                "cleeckykit:common.interface.elements.canvas.elements.avatar.modal.image_input"
              )}
            />
            <CleeckyKit.Row gap={8}>
              {/* <CleeckyKit.NumberInput
                allowNegative={false}
                suffix="px"
                decimalPlaces={0}
                label={t(
                  "cleeckykit:common.interface.elements.canvas.elements.avatar.modal.border_width"
                )}
                name="border-width"
                value={avatarContent.border || globalSizes.borderRadius}
                onChange={(value) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...avatarContent,
                      border: value.toString(),
                    },
                  })
                }
              /> */}
              {/* <CleeckyKit.Column gap={4} width="50%">
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.avatar.modal.border_color"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ColorPicker
                  disableOpacity
                  value={avatarContent.borderColor || globalColors.borderColor}
                  onChange={(color) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...avatarContent,
                        borderColor: color,
                      },
                    })
                  }
                />
              </CleeckyKit.Column> */}
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.avatar.modal.shape"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ButtonGroup
                  options={[
                    {
                      label: t("cleeckykit:common.shape.circle"),
                      value: "CIRCLE",
                    },
                    {
                      label: t("cleeckykit:common.shape.square"),
                      value: "SQUARE",
                    },
                  ]}
                  value={avatarContent.shape || "CIRCLE"}
                  onChange={(value: string) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...avatarContent,
                        shape: value as Types.AvatarShape,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
            </CleeckyKit.Row>

            <CleeckyKit.Row>
              <CleeckyKit.Switch
                checked={avatarContent.show}
                onChange={(checked) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...avatarContent,
                      show: checked,
                    },
                  })
                }
                label={t(
                  "cleeckykit:common.interface.elements.canvas.edit.show"
                )}
              />
            </CleeckyKit.Row>
          </CleeckyKit.Column>
        );

      case "IMAGE":
        const imageContent = editingElement.content as Types.ImageContent;
        return (
          <CleeckyKit.Column gap={16}>
            <FileInput
              value={imageContent.file}
              onChange={(file) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...imageContent,
                    file,
                  },
                })
              }
              accept="image/*"
              label={t(
                "cleeckykit:common.interface.elements.canvas.elements.image.modal.image_input"
              )}
            />
            {/*  <CleeckyKit.Row gap={8}>
              <CleeckyKit.NumberInput
                label={t(
                  "cleeckykit:common.interface.elements.canvas.elements.image.modal.border_width"
                )}
                name="image-border-width"
                allowNegative={false}
                suffix="px"
                decimalPlaces={0}
                value={imageContent.border || globalSizes.borderRadius}
                onChange={(e) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...imageContent,
                      border: e.target.value,
                    },
                  })
                }
              />

              <CleeckyKit.NumberInput
                name="image-border-radius"
                allowNegative={false}
                suffix="px"
                decimalPlaces={0}
                label={t(
                  "cleeckykit:common.interface.elements.canvas.elements.image.modal.border_radius"
                )}
                value={imageContent.borderRadius || globalSizes.borderRadius}
                onChange={(e) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...imageContent,
                      borderRadius: e.target.value,
                    },
                  })
                }
                required={false}
              />
              <InputWrapper>
                <InputLabel>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.image.modal.border_color"
                  )}
                </InputLabel>
                <CleeckyKit.ColorPicker
                  disableOpacity
                  value={imageContent.borderColor || globalColors.borderColor}
                  onChange={(color) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...imageContent,
                        borderColor: color,
                      },
                    })
                  }
                />
              </InputWrapper>
            </CleeckyKit.Row> */}

            <CleeckyKit.TextInput
              label={t(
                "cleeckykit:common.interface.elements.canvas.elements.image.modal.title_input_label"
              )}
              name="image-title"
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.image.modal.title_input_placeholder"
              )}
              value={imageContent.title || ""}
              onChange={(e) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...imageContent,
                    title: e.target.value,
                  },
                })
              }
            />
            <CleeckyKit.TextInput
              name="image-description"
              label={t(
                "cleeckykit:common.interface.elements.canvas.elements.image.modal.description_input_label"
              )}
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.image.modal.description_input_placeholder"
              )}
              value={imageContent.description || ""}
              onChange={(e) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...imageContent,
                    description: e.target.value,
                  },
                })
              }
            />
            <InputWrapper>
              <InputLabel>
                {t(
                  "cleeckykit:common.interface.elements.canvas.elements.image.modal.text_position"
                )}
              </InputLabel>
              <CleeckyKit.ButtonGroup
                options={
                  [
                    {
                      label: t("cleeckykit:common.position.top"),
                      value: "TOP",
                    },
                    {
                      label: t("cleeckykit:common.position.bottom"),
                      value: "BOTTOM",
                    },
                    /* {
                      label: t("cleeckykit:common.position.overlay"),
                      value: "OVERLAY",
                    }, */
                  ] as Array<{ label: string; value: Types.ImageTextPosition }>
                }
                value={imageContent.textPosition || "BOTTOM"}
                onChange={(value: string) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...imageContent,
                      textPosition: value as Types.ImageTextPosition,
                    },
                  })
                }
              />
            </InputWrapper>
            <CleeckyKit.Switch
              checked={imageContent.show}
              onChange={(checked) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...imageContent,
                    show: checked,
                  },
                })
              }
              label={t("cleeckykit:common.interface.elements.canvas.edit.show")}
            />
          </CleeckyKit.Column>
        );

      case "TITLE":
        const titleContent = editingElement.content as Types.TextContent;
        return (
          <CleeckyKit.Column gap={16}>
            <CleeckyKit.Column gap={4}>
              <CleeckyKit.TextInput
                label={t(
                  "cleeckykit:common.interface.elements.canvas.elements.title.modal.text_input_label"
                )}
                placeholder={t(
                  "cleeckykit:common.interface.elements.canvas.elements.title.modal.text_input_placeholder"
                )}
                name="title-text"
                value={titleContent.text}
                onChange={(e) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...titleContent,
                      text: e.target.value,
                    },
                  })
                }
                validations={{}}
              />
            </CleeckyKit.Column>
            <CleeckyKit.Row>
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.title.modal.size"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ButtonGroup
                  options={[
                    {
                      label: "H1",
                      value: "h1",
                    },
                    {
                      label: "H2",
                      value: "h2",
                    },

                    {
                      label: "H3",
                      value: "h3",
                    },

                    {
                      label: "H4",
                      value: "h4",
                    },

                    {
                      label: "H5",
                      value: "h5",
                    },

                    {
                      label: "H6",
                      value: "h6",
                    },
                  ]}
                  value={titleContent.size || "h3"}
                  onChange={(value) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...titleContent,
                        size: value as TextSize,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.title.modal.alignment"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ButtonGroup
                  options={[
                    {
                      label: t("cleeckykit:common.alignment.left"),
                      value: "LEFT",
                    },
                    {
                      label: t("cleeckykit:common.alignment.center"),
                      value: "CENTER",
                    },
                    {
                      label: t("cleeckykit:common.alignment.right"),
                      value: "RIGHT",
                    },
                    {
                      label: t("cleeckykit:common.alignment.justify"),
                      value: "JUSTIFY",
                    },
                  ]}
                  value={titleContent.alignment || "LEFT"}
                  onChange={(value) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...titleContent,
                        alignment: value as Types.TextAlignment,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
            </CleeckyKit.Row>
            <CleeckyKit.Column gap={4}>
              <CleeckyKit.Text>
                {t(
                  "cleeckykit:common.interface.elements.canvas.elements.title.modal.color"
                )}
              </CleeckyKit.Text>
              <CleeckyKit.ColorPicker
                disableOpacity
                value={titleContent.color || globalColors.textColor}
                onChange={(color) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...titleContent,
                      color,
                    },
                  })
                }
              />
            </CleeckyKit.Column>
            <CleeckyKit.Switch
              checked={titleContent.show}
              onChange={(checked) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...titleContent,
                    show: checked,
                  },
                })
              }
              label={t("cleeckykit:common.interface.elements.canvas.edit.show")}
            />
          </CleeckyKit.Column>
        );

      case "TEXT":
      case "NAME":
      case "USERNAME":
        const textContent = editingElement.content as Types.TextContent;
        return (
          <CleeckyKit.Column gap="2rem">
            {editingElement.type === "TEXT" && (
              <CleeckyKit.TextInput
                label={t(
                  "cleeckykit:common.interface.elements.canvas.elements.text.modal.text_input_label"
                )}
                placeholder={t(
                  "cleeckykit:common.interface.elements.canvas.elements.text.modal.text_input_placeholder"
                )}
                name="text-content"
                value={textContent.text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...textContent,
                      text: e.target.value,
                    },
                  })
                }
              />
            )}
            <CleeckyKit.Row>
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.text.modal.alignment"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ButtonGroup
                  options={[
                    {
                      label: t("cleeckykit:common.alignment.left"),
                      value: "LEFT",
                    },
                    {
                      label: t("cleeckykit:common.alignment.center"),
                      value: "CENTER",
                    },
                    {
                      label: t("cleeckykit:common.alignment.right"),
                      value: "RIGHT",
                    },
                    {
                      label: t("cleeckykit:common.alignment.justify"),
                      value: "JUSTIFY",
                    },
                  ]}
                  value={textContent.alignment || "LEFT"}
                  onChange={(value) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...textContent,
                        alignment: value as Types.TextAlignment,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    `cleeckykit:common.interface.elements.canvas.elements.${editingElement.type.toLowerCase()}.modal.color`
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ColorPicker
                  disableOpacity
                  value={textContent.color || globalColors.textColor}
                  onChange={(color) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...textContent,
                        color,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
            </CleeckyKit.Row>
            <CleeckyKit.Switch
              checked={textContent.show}
              onChange={(checked) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...textContent,
                    show: checked,
                  },
                })
              }
              label={t("cleeckykit:common.interface.elements.canvas.edit.show")}
            />
          </CleeckyKit.Column>
        );

      case "LINK":
        const linkContent = editingElement.content as Types.LinkContent;
        return (
          <CleeckyKit.Column gap={16}>
            <CleeckyKit.TextInput
              name="link-label"
              label={t(
                "cleeckykit:common.interface.elements.canvas.elements.link.modal.label_input_label"
              )}
              value={linkContent.label}
              onChange={(e) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...linkContent,
                    label: e.target.value,
                  },
                })
              }
              validations={{}}
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.link.modal.label_placeholder"
              )}
            />
            <CleeckyKit.TextInput
              name="link-url"
              label={t(
                "cleeckykit:common.interface.elements.canvas.elements.link.modal.url_input_label"
              )}
              value={linkContent.url}
              onChange={(e) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...linkContent,
                    url: e.target.value,
                  },
                })
              }
              validations={{}}
              placeholder={t(
                "cleeckykit:common.interface.elements.canvas.elements.link.modal.url_placeholder"
              )}
            />
            {/* <FileInput
              value={linkContent.image || { path: "", type: "FILE" }}
              onChange={(file) =>
                setEditingElement({
                  ...editingElement,
                  content: {
                    ...linkContent,
                    image: {
                      ...file,
                      imagePosition: linkContent.image?.imagePosition || "LEFT",
                    },
                  },
                })
              }
              accept="image/*"
              label={t(
                "cleeckykit:common.interface.elements.canvas.elements.link.modal.image_input"
              )}
            /> */}
            <CleeckyKit.Row>
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.link.modal.button_background_color"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ColorPicker
                  disableOpacity
                  value={
                    linkContent.background ||
                    globalColors.buttonsBackgroundColor
                  }
                  onChange={(color) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...linkContent,
                        background: color,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.link.modal.button_highlight_color"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ColorPicker
                  disableOpacity
                  value={
                    linkContent.highlightColor || globalColors.highlightColor
                  }
                  onChange={(color) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...linkContent,
                        highlightColor: color,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
              <CleeckyKit.Column gap={4}>
                <CleeckyKit.Text>
                  {t(
                    "cleeckykit:common.interface.elements.canvas.elements.link.modal.button_text_color"
                  )}
                </CleeckyKit.Text>
                <CleeckyKit.ColorPicker
                  disableOpacity
                  value={linkContent.color || globalColors.buttonsLabelColor}
                  onChange={(color) =>
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...linkContent,
                        color: color,
                      },
                    })
                  }
                />
              </CleeckyKit.Column>
            </CleeckyKit.Row>
            <CleeckyKit.Row>
              <CleeckyKit.NumberInput
                name="link-border-radius"
                label={t(
                  "cleeckykit:common.interface.elements.canvas.elements.link.modal.border_radius"
                )}
                allowNegative={false}
                suffix="px"
                decimalPlaces={0}
                value={linkContent.borderRadius || globalSizes.borderRadius}
                onChange={(e) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...linkContent,
                      borderRadius: e.target.value,
                    },
                  })
                }
                placeholder={t(
                  "cleeckykit:common.interface.elements.canvas.modal.edit.border_radius"
                )}
              />
              <CleeckyKit.Switch
                checked={linkContent.show}
                onChange={(checked) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...linkContent,
                      show: checked,
                    },
                  })
                }
                label={t(
                  "cleeckykit:common.interface.elements.canvas.edit.show"
                )}
              />
            </CleeckyKit.Row>
          </CleeckyKit.Column>
        );

      case "SOCIAL_BUTTONS":
        const socialContent =
          editingElement.content as Types.SocialButtonsContent;
        return (
          <CleeckyKit.Column gap="2rem">
            <CleeckyKit.Row gap="2rem" justify="start">
              <CleeckyKit.ButtonGroup
                label={t("cleeckykit:common.orientation.title")}
                options={
                  [
                    {
                      label: t("cleeckykit:common.orientation.horizontal"),
                      value: "HORIZONTAL",
                    },
                    {
                      label: t("cleeckykit:common.orientation.vertical"),
                      value: "VERTICAL",
                    },
                  ] as Array<{ label: string; value: Types.Orientation }>
                }
                value={socialContent.orientation}
                onChange={(value: string) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...socialContent,
                      orientation: value as Types.Orientation,
                    },
                  })
                }
              />
              <CleeckyKit.ColorPicker
                disableOpacity
                label={t(
                  "cleeckykit:common.interface.elements.canvas.elements.social_buttons.modal.button_background_color"
                )}
                value={
                  socialContent.background ||
                  globalColors.buttonsBackgroundColor
                }
                onChange={(color) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...socialContent,
                      background: color,
                    },
                  })
                }
              />
              <CleeckyKit.Switch
                checked={socialContent.show}
                onChange={(checked) =>
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...socialContent,
                      show: checked,
                    },
                  })
                }
                label={t(
                  "cleeckykit:common.interface.elements.canvas.edit.show"
                )}
              />
            </CleeckyKit.Row>

            {socialContent.buttons.map((button, index) => (
              <CleeckyKit.Row key={index} noBreak>
                <CleeckyKit.Select
                  label={t("cleeckykit:common.social.platform")}
                  options={Object.values(Types.SOCIAL_PLATFORMS).map(
                    (platform) => ({
                      label: t(
                        `cleeckykit:common.social.${platform.toLowerCase()}`
                      ),
                      value: platform,
                    })
                  )}
                  value={button.platform}
                  onChange={(value: string | string[]) => {
                    const newButtons = [...socialContent.buttons];
                    newButtons[index] = {
                      ...button,
                      platform: (Array.isArray(value)
                        ? value[0]
                        : value) as Types.SocialPlatform,
                    };
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...socialContent,
                        buttons: newButtons,
                      },
                    });
                  }}
                />
                <CleeckyKit.TextInput
                  name="link"
                  label={t("cleeckykit:common.social.link")}
                  value={button.url}
                  onChange={(e) => {
                    const newButtons = [...socialContent.buttons];
                    newButtons[index] = {
                      ...button,
                      url: e.target.value,
                    };
                    setEditingElement({
                      ...editingElement,
                      content: {
                        ...socialContent,
                        buttons: newButtons,
                      },
                    });
                  }}
                  placeholder={t(
                    "cleeckykit:common.interface.elements.canvas.elements.social_buttons.modal.url_placeholder"
                  )}
                />
                <div style={{ marginTop: "3rem" }}>
                  <CleeckyKit.Button
                    variant="icon"
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    iconBackground={false}
                    iconPosition="top"
                    onClick={() => {
                      const newButtons = socialContent.buttons.filter(
                        (_, i) => i !== index
                      );
                      setEditingElement({
                        ...editingElement,
                        content: {
                          ...socialContent,
                          buttons: newButtons,
                        },
                      });
                    }}
                  />
                </div>
              </CleeckyKit.Row>
            ))}
            <CleeckyKit.Row>
              <CleeckyKit.Button
                variant="outline"
                text={t("cleeckykit:common.actions.add_social")}
                textPosition="inside-right"
                highlighted
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => {
                  const newButtons = [
                    ...socialContent.buttons,
                    {
                      platform: "INSTAGRAM" as Types.SocialPlatform,
                      url: "",
                      title: null,
                    },
                  ];
                  setEditingElement({
                    ...editingElement,
                    content: {
                      ...socialContent,
                      buttons: newButtons,
                    },
                  });
                }}
              />
            </CleeckyKit.Row>
          </CleeckyKit.Column>
        );

      default:
        return null;
    }
  };

  const footerButtons = (
    <ModalFooter>
      <CleeckyKit.Button
        variant="link"
        text={t("cleeckykit:common.actions.cancel")}
        textPosition="inside-below"
        onClick={onClose}
        size="small"
        highlighted
      />
      <CleeckyKit.Button
        variant="solid"
        text={t("cleeckykit:common.actions.save")}
        textPosition="inside-below"
        onClick={() => {
          if (editingElement) {
            onSave(editingElement);
            const newElements = elements
              .map((el) => (el.id === editingElement.id ? editingElement : el))
              .filter((el): el is CanvasElement => el !== null);
            onElementsChange(newElements);
            setEditingElement(null);
            onClose();
          }
        }}
        size="small"
        highlighted
      />
    </ModalFooter>
  );

  return (
    <CleeckyKit.Modal
      isOpen={true}
      onClose={onClose}
      title={t(
        `cleeckykit:common.interface.elements.canvas.elements.${editingElement?.type?.toLowerCase()}.modal.title`
      )}
      size="medium"
      animation="scale">
      <FormProvider {...methods}>
        <ModalBody>{renderEditFields()}</ModalBody>
        {footerButtons}
      </FormProvider>
    </CleeckyKit.Modal>
  );
};

EditModal.displayName = "EditModal";

import { CleeckyInsiderData } from "cleecky@types/interfaces";
import api from "../axiosConfig";

export const cleeckyInsidersService = {
  subscribe: async (data: CleeckyInsiderData) => {
    const response = await api.post("/shared/cleecky-insider", data);
    return response.data;
  },

  getMySubscriptions: async () => {
    const response = await api.get("/shared/cleecky-insider/my-subscriptions");
    return response.data;
  },
};

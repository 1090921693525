import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";

export const ContactsHomepage: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Column
        padding="4rem 1rem"
        gap="2rem"
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}>
        {/* Card de Verificação de Veracidade */}
        <CleeckyKit.Card
          background={currentTheme.colors.background.navbar.background}
          border={`0.1rem solid ${currentGradient?.secondaryHighlightColor}`}>
          <CleeckyKit.Row>
            <CleeckyKit.Title
              level={4}
              customStyle={{ color: currentTheme.colors.text.secondary }}>
              ⚠️ {t("project:pages.contacts.veracity_verification.title")}
            </CleeckyKit.Title>
          </CleeckyKit.Row>
          <CleeckyKit.Row>
            <CleeckyKit.Text
              customStyle={{ color: currentTheme.colors.text.secondary }}>
              {t("project:pages.contacts.veracity_verification.description")}
            </CleeckyKit.Text>
          </CleeckyKit.Row>
        </CleeckyKit.Card>

        {/* Seção de Título */}
        <CleeckyKit.Title level={1}>
          {t("project:pages.contacts.title")}
        </CleeckyKit.Title>
        <CleeckyKit.Text>
          {t("project:pages.contacts.description")}
        </CleeckyKit.Text>

        {/* Seção de Contatos Oficiais */}
        <CleeckyKit.Card background={currentTheme.colors.background.card}>
          <CleeckyKit.Row>
            <CleeckyKit.Title level={3}>
              {t(
                "project:pages.contacts.veracity_verification.official_contacts.title"
              )}
            </CleeckyKit.Title>
          </CleeckyKit.Row>

          <CleeckyKit.Row gap="1rem">
            <CleeckyKit.List>
              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="mailto:official@cleecky.com">
                  <strong>official@cleecky.com</strong> -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_contacts.contacts.emails.official"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>
              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="mailto:legal@cleecky.com">
                  <strong>legal@cleecky.com</strong> -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_contacts.contacts.emails.legal"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>
              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="mailto:contact@cleecky.com">
                  <strong>contact@cleecky.com</strong> -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_contacts.contacts.emails.contact"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>
              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="mailto:support@cleecky.com">
                  <strong>support@cleecky.com</strong> -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_contacts.contacts.emails.support"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>
              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="mailto:feedback@cleecky.com">
                  <strong>feedback@cleecky.com</strong> -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_contacts.contacts.emails.feedback"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>
            </CleeckyKit.List>
          </CleeckyKit.Row>
        </CleeckyKit.Card>

        {/* Seção de Sites Oficiais */}
        <CleeckyKit.Card background={currentTheme.colors.background.card}>
          <CleeckyKit.Row>
            <CleeckyKit.Title level={3}>
              {t(
                "project:pages.contacts.veracity_verification.official_sites.title"
              )}
            </CleeckyKit.Title>
          </CleeckyKit.Row>

          <CleeckyKit.Row gap="1rem">
            <CleeckyKit.List>
              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="https://cleecky.com" target="_blank">
                  <strong>
                    {t(
                      "project:pages.contacts.veracity_verification.official_sites.sites.sites.cleecky_com.title"
                    )}
                  </strong>{" "}
                  -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_sites.sites.sites.cleecky_com.description"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>
              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="https://api.cleecky.com" target="_blank">
                  <strong>
                    {t(
                      "project:pages.contacts.veracity_verification.official_sites.sites.sites.api_cleecky_com.title"
                    )}
                  </strong>{" "}
                  -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_sites.sites.sites.api_cleecky_com.description"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>

              <CleeckyKit.ListItem>
                <CleeckyKit.Text href="https://clcky.me" target="_blank">
                  <strong>
                    {t(
                      "project:pages.contacts.veracity_verification.official_sites.sites.sites.clcky_me.title"
                    )}
                  </strong>{" "}
                  -{" "}
                  {t(
                    "project:pages.contacts.veracity_verification.official_sites.sites.sites.clcky_me.description"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.ListItem>
            </CleeckyKit.List>
          </CleeckyKit.Row>
        </CleeckyKit.Card>
      </CleeckyKit.Column>
    </>
  );
};

export default ContactsHomepage;

import React, { useState, useEffect } from "react";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faBan,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { aggregatorService, toolLimitsService } from "services";
import { AggregatorPageData } from "cleecky@types/interfaces";
import { useNavigate } from "react-router-dom";
import PageTitleModal from "./PageTitleModal";
import LimitModal from "./LimitModal";
import { ToolType } from "cleecky@types/enums";

export const RecentPages: React.FC = () => {
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();
  const navigate = useNavigate();
  const [pages, setPages] = useState<AggregatorPageData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showPageTitleModal, setShowPageTitleModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [pageStats, setPageStats] = useState<Record<string, number>>({});
  const [toolLimits, setToolLimits] = useState<{
    pagesLimit: number;
    pagesCreated: number;
  } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response, limits] = await Promise.all([
          aggregatorService.getAll(),
          toolLimitsService.getToolLimits(ToolType.LINK_AGGREGATOR),
        ]);

        // Pega apenas as 4 páginas mais recentes
        const recentPages = response.slice(0, 4);
        setPages(recentPages);
        setToolLimits(limits);

        // Busca as métricas de cada página
        const stats = await aggregatorService.getPageStats("month");
        const pageStatsMap = stats.pageStats.reduce((acc, stat) => {
          acc[stat.uuid] = stat.totalVisits;
          return acc;
        }, {} as Record<string, number>);
        setPageStats(pageStatsMap);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCreatePage = () => {
    if (!toolLimits) return;

    if (toolLimits.pagesCreated >= toolLimits.pagesLimit) {
      setShowLimitModal(true);
    } else {
      setShowPageTitleModal(true);
    }
  };

  const handleViewAllPages = () => {
    navigate("/toolbox/link-aggregator/pages");
  };

  const getPageStats = (page: AggregatorPageData) => {
    return pageStats[page.uuid] || 0;
  };

  if (!currentTheme) {
    return null;
  }

  return (
    <>
      <CleeckyKit.Section>
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Row>
            <CleeckyKit.Column>
              <CleeckyKit.Title level={3}>
                {t(
                  "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.title"
                )}
              </CleeckyKit.Title>
            </CleeckyKit.Column>
          </CleeckyKit.Row>

          <CleeckyKit.Carousel
            itemsPerView={{
              default: 4,
              "2xl": 4,
              xl: 4,
              lg: 3,
              md: 2,
              sm: 2,
              xs: 2,
            }}
            gap={16}
            navigation={{
              show: true,
              position: "outside-top-right",
              navigationBackground: "none",
            }}
            pagination={{ show: false }}>
            {/* Recent Pages */}
            {pages.map((page) => (
              <CleeckyKit.Card
                key={page.uuid}
                border={`2px solid ${currentTheme?.colors.background.card}`}
                backgroundColor={currentTheme?.colors.background.base}
                padding="0"
                hoverable
                onClick={() =>
                  navigate(`/@${page.user.username}/${page.slug}`)
                }>
                <div style={{ minHeight: "215px" }}>
                  <div
                    style={{
                      backgroundColor: currentTheme?.colors.background.content,
                      height: "10rem",
                      color: currentTheme?.colors.text.primary,
                      overflow: "hidden",
                    }}>
                    {page.thumbnail ? (
                      <CleeckyKit.Image
                        alt={`Preview of ${page.slug}`}
                        width="100%"
                        height="100%"
                        fit="cover"
                        src={page.thumbnail.url}
                        sourceType="external"
                        href={`/@${page.user.username}/${page.slug}`}
                        target="_blank"
                      />
                    ) : (
                      <CleeckyKit.Skeleton
                        width="100%"
                        height="100%"
                        animation="wave"
                        backgroundColor={
                          currentTheme?.colors.background.content
                        }
                        highlightColor={currentTheme?.colors.interactive.hover}
                      />
                    )}
                  </div>
                  <CleeckyKit.Column padding="1rem" gap="0.5rem">
                    <CleeckyKit.Title level={5}>{page.slug}</CleeckyKit.Title>
                    <CleeckyKit.Text>
                      <FontAwesomeIcon icon={faArrowPointer} />
                      {"  "} {getPageStats(page)} (
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.current_month"
                      )}
                      )
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                </div>
              </CleeckyKit.Card>
            ))}

            {pages.length === 0 && (
              <CleeckyKit.Card
                backgroundColor="transparent"
                border={`2px solid ${currentTheme?.colors.background.card}`}>
                <div style={{ minHeight: "215px" }}>
                  <CleeckyKit.Column>
                    <CleeckyKit.Row justify="center" align="center">
                      <CleeckyKit.Title align="center" level={4}>
                        <FontAwesomeIcon icon={faBan} />
                      </CleeckyKit.Title>
                    </CleeckyKit.Row>
                    <CleeckyKit.Row justify="center" align="center">
                      <CleeckyKit.Title align="center" level={5}>
                        {t(
                          "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.empty_state"
                        )}
                      </CleeckyKit.Title>
                    </CleeckyKit.Row>
                  </CleeckyKit.Column>
                </div>
              </CleeckyKit.Card>
            )}

            {/* Create Page */}
            <CleeckyKit.Card
              backgroundColor={currentTheme?.colors.background.card}
              onClick={handleCreatePage}>
              <div style={{ minHeight: "215px" }}>
                <CleeckyKit.Row justify="center" align="center">
                  <CleeckyKit.Column justify="center" align="center" gap="1rem">
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faFileLines} />
                    </CleeckyKit.Title>

                    <CleeckyKit.Title align="center" level={5}>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.create_a_new_page"
                      )}
                    </CleeckyKit.Title>

                    {toolLimits && (
                      <CleeckyKit.Text customStyle={{ fontSize: "0.875rem" }}>
                        {t(
                          "project:apps.toolbox.tools.link_aggregator.pageList.pages_limit",
                          {
                            current: toolLimits.pagesCreated,
                            limit: toolLimits.pagesLimit,
                          }
                        )}
                      </CleeckyKit.Text>
                    )}

                    <CleeckyKit.Button
                      size="medium"
                      text={t("cleeckykit:common.actions.create")}
                      textPosition="inside-below"
                      borderRadius={15}
                      type="button"
                      onClick={handleCreatePage}
                      variant="solid"
                      highlighted
                    />
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </div>
            </CleeckyKit.Card>
          </CleeckyKit.Carousel>
          <CleeckyKit.Row justify="center" align="center">
            <CleeckyKit.Button
              size="medium"
              variant="outline"
              text={t(
                "project:apps.toolbox.tools.link_aggregator.dashboard.recent_pages.see_all_pages"
              )}
              textPosition="inside-below"
              borderRadius={15}
              type="button"
              onClick={handleViewAllPages}
              highlighted
            />
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Section>

      <PageTitleModal
        isOpen={showPageTitleModal}
        onClose={() => setShowPageTitleModal(false)}
        mode="create"
      />

      <LimitModal
        isOpen={showLimitModal}
        onClose={() => setShowLimitModal(false)}
      />
    </>
  );
};

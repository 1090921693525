import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";

export const AboutHomepage: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Column
        padding="4rem 1rem"
        gap="2rem"
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}>
        <CleeckyKit.Title level={1} align="justify">
          {t("project:pages.about.title")}
        </CleeckyKit.Title>

        <CleeckyKit.Text align="justify">
          {t("project:pages.about.description")}
        </CleeckyKit.Text>
      </CleeckyKit.Column>
    </>
  );
};

export default AboutHomepage;

import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";

const StyledList = styled.ul`
  margin: 0.5rem 0;
  padding-left: 1.5rem;
`;

const StyledListItem = styled.li`
  margin: 0.25rem 0;
`;

export const TermsOfUseSection: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Accordion title="Termos de Uso da Cleecky">
        <CleeckyKit.AccordionGroup>
          <CleeckyKit.Accordion title="1. Introdução">
            <CleeckyKit.Text>
              Bem-vindo à Cleecky! Somos uma plataforma digital inovadora que
              oferece um conjunto de ferramentas para criação, gestão e
              monetização de conteúdos, incluindo, mas não se limitando a:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>Agregador de Links</StyledListItem>
              <StyledListItem>Encurtador de URLs</StyledListItem>
              <StyledListItem>Gerador de QR Codes</StyledListItem>
              <StyledListItem>
                E outras funcionalidades premium (atuais e futuras)
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>
              Ao acessar e utilizar a nossa plataforma, você concorda
              expressamente com estes Termos de Uso. Caso não concorde com
              qualquer parte destes termos, solicitamos que interrompa
              imediatamente o uso dos nossos serviços.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="2. Definições e Interpretação">
            <CleeckyKit.Text>
              Para os efeitos destes Termos, os seguintes termos terão os
              significados abaixo atribuídos:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Usuário:</strong> Toda pessoa física ou jurídica que
                acesse e utilize os serviços da Cleecky.
              </StyledListItem>
              <StyledListItem>
                <strong>Serviço:</strong> Qualquer funcionalidade, ferramenta ou
                produto oferecido pela Cleecky, independentemente do meio (web,
                mobile, API, etc.).
              </StyledListItem>
              <StyledListItem>
                <strong>Conta:</strong> O cadastro individual do Usuário na
                plataforma, que possibilita o acesso aos serviços.
              </StyledListItem>
              <StyledListItem>
                <strong>Plano Pago:</strong> Qualquer modalidade de assinatura
                que oferece acesso a funcionalidades premium mediante pagamento
                (incluindo os planos First Steps, Explorer, Intergalactic e
                Infinity).
              </StyledListItem>
              <StyledListItem>
                <strong>Conteúdo:</strong> Quaisquer dados, textos, imagens,
                vídeos ou outros materiais enviados, publicados ou
                disponibilizados pelo Usuário na plataforma.
              </StyledListItem>
              <StyledListItem>
                <strong>Contrato:</strong> Este acordo de Termos de Uso, que
                rege o acesso e a utilização dos serviços da Cleecky.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="3. Condições de Uso">
            <CleeckyKit.Title level={5}>
              3.1 Cadastro e Elegibilidade
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                O cadastro na plataforma é permitido apenas para pessoas maiores
                de 18 anos. Menores de 18 anos poderão se cadastrar somente com
                o consentimento expresso dos responsáveis legais.
              </StyledListItem>
              <StyledListItem>
                Cada Usuário deverá fornecer informações verdadeiras, precisas e
                atualizadas durante o processo de cadastro.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              3.2 Uso Aceitável e Proibições
            </CleeckyKit.Title>

            <CleeckyKit.Text>
              Os Usuários comprometem-se a utilizar a Cleecky de maneira ética e
              de acordo com as leis aplicáveis. Fica expressamente proibido:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                Publicar ou compartilhar conteúdos ilegais, difamatórios,
                ofensivos, abusivos ou que violem direitos de terceiros.
              </StyledListItem>
              <StyledListItem>
                Enviar spam, fraudes ou mensagens não solicitadas.
              </StyledListItem>
              <StyledListItem>
                Realizar qualquer atividade que possa comprometer a segurança, a
                integridade ou o funcionamento da plataforma.
              </StyledListItem>
              <StyledListItem>
                Utilizar scripts, robôs ou qualquer meio automatizado para
                acessar ou interagir com os serviços.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              3.3 Sanções e Penalidades
            </CleeckyKit.Title>

            <CleeckyKit.Text>
              Em caso de violação destes termos, a Cleecky se reserva o direito
              de:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>Advertir o Usuário.</StyledListItem>
              <StyledListItem>
                Suspender ou banir a conta sem aviso prévio.
              </StyledListItem>
              <StyledListItem>
                Adotar medidas legais cabíveis para resguardar os direitos da
                plataforma e de terceiros.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="4. Planos, Assinaturas e Pagamentos">
            <CleeckyKit.Title level={5}>
              4.1 Descrição dos Planos
            </CleeckyKit.Title>
            <CleeckyKit.Text>
              A Cleecky oferece os seguintes planos de acesso à plataforma:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>First Steps:</strong> Plano de entrada com
                funcionalidades essenciais. Disponível de forma recorrente
                (mensal ou anual).
              </StyledListItem>
              <StyledListItem>
                <strong>Explorer:</strong> Plano avançado, que inclui todas as
                funcionalidades do First Steps, acrescidas de benefícios
                adicionais. Disponível de forma recorrente (mensal ou anual).
              </StyledListItem>
              <StyledListItem>
                <strong>Intergalactic:</strong> Plano profissional, direcionado
                a criadores e negócios que necessitam de recursos ampliados,
                englobando tudo o que está nos planos anteriores e mais
                funcionalidades exclusivas. Disponível de forma recorrente
                (mensal ou anual).
              </StyledListItem>
              <StyledListItem>
                <strong>Infinity:</strong> Plano vitalício, com pagamento único,
                que garante acesso permanente às funcionalidades do First Steps.
                Observação: O plano Infinity está atrelado ao First Steps,
                podendo seus limites ser ajustados futuramente.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              4.2 Regras de Cobrança e Renovação
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                Para os planos First Steps, Explorer e Intergalactic, a cobrança
                será realizada de forma recorrente (mensal ou anual), conforme a
                opção escolhida pelo Usuário.
              </StyledListItem>
              <StyledListItem>
                As assinaturas são renovadas automaticamente ao final de cada
                período, salvo manifestação em contrário por parte do Usuário.
              </StyledListItem>
              <StyledListItem>
                O plano Infinity é adquirido mediante pagamento único e não
                implica renovação automática.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              4.3 Política de Reembolso e Cancelamento
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                O Usuário poderá solicitar o cancelamento da assinatura
                recorrente a qualquer momento, interrompendo as renovações
                automáticas.
              </StyledListItem>
              <StyledListItem>
                Solicitações de reembolso serão analisadas de acordo com os
                prazos e condições estabelecidos pela política interna da
                Cleecky.
              </StyledListItem>
              <StyledListItem>
                No caso do plano Infinity, uma vez efetuado o pagamento, não
                haverá reembolso, salvo em situações previstas em lei.
              </StyledListItem>
            </StyledList>

            <CleeckyKit.Title level={5}>
              4.4 Ajustes nos Limites dos Planos
            </CleeckyKit.Title>
            <StyledList>
              <StyledListItem>
                O plano Infinity garante acesso vitalício às funcionalidades do
                First Steps. Contudo, os limites e parâmetros deste plano
                poderão ser ajustados no futuro, visando manter a
                sustentabilidade e a qualidade dos serviços oferecidos.
              </StyledListItem>
              <StyledListItem>
                Tais ajustes serão realizados com comunicação prévia aos
                Usuários e, sempre que possível, de forma gradual para minimizar
                impactos.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="5. Modificações nos Serviços e Termos">
            <StyledList>
              <StyledListItem>
                A Cleecky se reserva o direito de modificar, suspender ou
                encerrar funcionalidades, alterar preços, planos e condições de
                uso a qualquer momento.
              </StyledListItem>
              <StyledListItem>
                Mudanças que possam afetar significativamente os Usuários serão
                comunicadas com antecedência, sempre que viável, por meio de
                avisos na plataforma ou via e-mail.
              </StyledListItem>
              <StyledListItem>
                O uso contínuo dos serviços após quaisquer alterações implica na
                aceitação dos termos revisados.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="6. Direitos de Propriedade Intelectual">
            <StyledList>
              <StyledListItem>
                Todos os direitos relativos à marca, logotipo, código-fonte,
                design e demais elementos de propriedade da Cleecky são de
                exclusiva titularidade da empresa.
              </StyledListItem>
              <StyledListItem>
                Os Usuários mantêm a propriedade dos conteúdos por eles gerados
                e publicados na plataforma, mas concedem à Cleecky uma licença
                não exclusiva, irrevogável e gratuita para armazenar, exibir e
                distribuir tais conteúdos, exclusivamente para a prestação dos
                serviços.
              </StyledListItem>
              <StyledListItem>
                É proibido o uso não autorizado de qualquer conteúdo ou material
                protegido por direitos autorais pertencente à Cleecky ou a
                terceiros.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="7. Responsabilidades e Isenção de Garantia">
            <StyledList>
              <StyledListItem>
                A Cleecky não garante a disponibilidade, continuidade ou a
                perfeita ausência de erros nos serviços. O acesso pode ser
                interrompido temporariamente por motivos de manutenção,
                atualizações ou imprevistos técnicos.
              </StyledListItem>
              <StyledListItem>
                Não nos responsabilizamos por perdas de dados, lucros cessantes,
                danos diretos, indiretos ou incidentais decorrentes do uso ou da
                incapacidade de uso dos serviços.
              </StyledListItem>
              <StyledListItem>
                A Cleecky reserva-se o direito de remover, a seu exclusivo
                critério, quaisquer conteúdos que julgue violar estes Termos ou
                que possam prejudicar a integridade da plataforma.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="8. Segurança e Proteção de Dados">
            <StyledList>
              <StyledListItem>
                A Cleecky adota medidas de segurança técnicas e administrativas
                para proteger os dados pessoais dos Usuários, em conformidade
                com as legislações aplicáveis.
              </StyledListItem>
              <StyledListItem>
                Embora nos empenhemos para garantir a segurança dos dados, não
                podemos assegurar a inviolabilidade absoluta das informações
                transmitidas pela internet.
              </StyledListItem>
              <StyledListItem>
                É expressamente proibido que os Usuários compartilhem suas
                credenciais de acesso ou permitam que terceiros utilizem suas
                contas, sendo de inteira responsabilidade do Usuário quaisquer
                ações realizadas com seus dados de acesso.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="9. Encerramento de Conta">
            <StyledList>
              <StyledListItem>
                O Usuário poderá solicitar a exclusão de sua conta a qualquer
                momento, mediante solicitação através dos canais de suporte da
                Cleecky.
              </StyledListItem>
              <StyledListItem>
                A exclusão da conta implica na remoção de todos os dados
                pessoais e conteúdos associados, de acordo com a política de
                retenção de dados da plataforma.
              </StyledListItem>
              <StyledListItem>
                A Cleecky reserva-se o direito de suspender ou banir uma conta
                sem aviso prévio, caso seja identificada qualquer violação
                destes Termos ou atividade suspeita que comprometa a segurança e
                o funcionamento da plataforma.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="10. Contato e Suporte">
            <CleeckyKit.Text>
              Caso você tenha dúvidas ou necessite de esclarecimentos sobre
              estes Termos de Uso, entre em contato conosco através dos
              seguintes canais:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                E-mail para assuntos legais: legal@cleecky.com
              </StyledListItem>
              <StyledListItem>
                E-mail para suporte: support@cleecky.com
              </StyledListItem>
              <StyledListItem>
                Endereço: Rua Gonçalves Jaquier, 25 - Jardim Ohara, Marília/SP,
                Brasil
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>Data de Vigência: 16/02/2025</CleeckyKit.Text>
            <CleeckyKit.Text>
              Data da Última Atualização: 16/02/2025
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>
        </CleeckyKit.AccordionGroup>
        <br />
        <CleeckyKit.Text>
          Este documento visa garantir a transparência e segurança para todos os
          Usuários da Cleecky, estabelecendo regras claras para o uso dos nossos
          serviços e contribuindo para um ambiente digital mais seguro e
          confiável. Caso tenha sugestões ou observações, entre em contato por
          meio dos canais de suporte.
        </CleeckyKit.Text>
      </CleeckyKit.Accordion>
    </>
  );
};

export default TermsOfUseSection;

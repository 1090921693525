import React from "react";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import {
  faCalendarDays,
  faEarthAmericas,
  faExpand,
  faLightbulb,
  faLink,
  faPeopleGroup,
  faSearch,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flag from "react-world-flags";
import {
  MetricsPeriod,
  PageStatsResponse,
  PeakAccessPeriodsResponse,
  TrafficSourceStatsResponse,
} from "cleecky@types/interfaces";
import { AggregatorMetricsSource } from "cleecky@types/enums";

interface DetailedMetricsProps {
  period: MetricsPeriod;
  onPeriodChange: (period: MetricsPeriod) => void;
  pageStats: PageStatsResponse | null;
  peakPeriods: PeakAccessPeriodsResponse | null;
  trafficStats: TrafficSourceStatsResponse | null;
}

export const DetailedMetrics: React.FC<DetailedMetricsProps> = ({
  period,
  onPeriodChange,
  pageStats,
  peakPeriods,
  trafficStats,
}) => {
  const { currentGradient, currentTheme } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  const periodOptions = [
    {
      label: t("project:common.filters.period.current_week"),
      value: "week",
    },
    {
      label: t("project:common.filters.period.current_month"),
      value: "month",
    },
    {
      label: t("project:common.filters.period.current_year"),
      value: "year",
    },
  ];

  // Calcula a porcentagem de cada fonte de tráfego
  const calculateTrafficSourcePercentage = (
    source: AggregatorMetricsSource
  ): string => {
    if (!trafficStats?.trafficSources || !trafficStats.totalAccesses)
      return "0%";
    const sourceCount = trafficStats.trafficSources[source] || 0;
    return `${Math.round((sourceCount / trafficStats.totalAccesses) * 100)}%`;
  };

  // Encontra a fonte com maior número de acessos
  const getMostAccessedSource = () => {
    if (!trafficStats?.trafficSources) return null;
    const sources = Object.entries(trafficStats.trafficSources);
    if (sources.length === 0) return null;
    return sources.sort((a, b) => b[1] - a[1])[0][0];
  };

  const mostAccessedSource = getMostAccessedSource();

  // Calcula a taxa de interação
  const calculateInteractionRate = () => {
    if (!pageStats?.totalStats) return { total: 0, interacted: 0 };
    const total = pageStats.totalStats.totalVisits || 0;
    // Aqui você precisaria ter o número de interações no pageStats
    // Por enquanto vou usar um valor mockado de 30% de interação
    const interacted = Math.round(total * 0.3);
    return { total, interacted };
  };

  // Prepara dados para o gráfico de barras
  const getBarChartData = () => {
    if (!peakPeriods?.peakPeriods) {
      return {
        categories: [],
        values: [],
        highlightIndex: -1,
        xAxisLabel: "",
        yAxisLabel: t(
          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.acesses"
        ),
      };
    }

    // Define o rótulo do eixo X baseado no período
    let xAxisLabel = "";
    switch (period) {
      case "week":
        xAxisLabel = t(
          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.weekdays"
        );
        break;
      case "month":
        xAxisLabel = t(
          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.week_of_month"
        );
        break;
      case "year":
        xAxisLabel = t(
          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.month"
        );
        break;
    }

    // Define todas as categorias possíveis baseado no período
    let allCategories: string[] = [];
    switch (period) {
      case "week": {
        // Dias da semana abreviados
        allCategories = [
          t("cleeckykit:common.datetime.weekDays.short.sunday"),
          t("cleeckykit:common.datetime.weekDays.short.monday"),
          t("cleeckykit:common.datetime.weekDays.short.tuesday"),
          t("cleeckykit:common.datetime.weekDays.short.wednesday"),
          t("cleeckykit:common.datetime.weekDays.short.thursday"),
          t("cleeckykit:common.datetime.weekDays.short.friday"),
          t("cleeckykit:common.datetime.weekDays.short.saturday"),
          t("cleeckykit:common.datetime.weekDays.short.sunday"),
        ];
        break;
      }
      case "month": {
        // Semanas do mês (1-7, 8-14, etc)
        const now = new Date();
        const daysInMonth = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          0
        ).getDate();

        // Gera as semanas do mês no mesmo formato que o backend
        for (let day = 1; day <= daysInMonth; day += 7) {
          const weekStart = day;
          const weekEnd = Math.min(day + 6, daysInMonth);
          allCategories.push(`${weekStart} - ${weekEnd}`);
        }
        break;
      }
      case "year": {
        // Meses do ano
        allCategories = [
          t("cleeckykit:common.datetime.months.short.january"),
          t("cleeckykit:common.datetime.months.short.february"),
          t("cleeckykit:common.datetime.months.short.march"),
          t("cleeckykit:common.datetime.months.short.april"),
          t("cleeckykit:common.datetime.months.short.may"),
          t("cleeckykit:common.datetime.months.short.june"),
          t("cleeckykit:common.datetime.months.short.july"),
          t("cleeckykit:common.datetime.months.short.august"),
          t("cleeckykit:common.datetime.months.short.september"),
          t("cleeckykit:common.datetime.months.short.october"),
          t("cleeckykit:common.datetime.months.short.november"),
          t("cleeckykit:common.datetime.months.short.december"),
        ];
        break;
      }
    }

    // Normaliza os dados recebidos para o formato esperado
    const normalizedData = peakPeriods.peakPeriods.reduce((acc, curr) => {
      let normalizedPeriod = curr.period;

      // Normaliza o formato do período baseado no tipo
      switch (period) {
        case "week": {
          // Converte para o formato abreviado (ex: "segunda-feira" -> "seg.")
          const weekdays = [
            t("cleeckykit:common.datetime.weekDays.short.sunday"),
            t("cleeckykit:common.datetime.weekDays.short.monday"),
            t("cleeckykit:common.datetime.weekDays.short.tuesday"),
            t("cleeckykit:common.datetime.weekDays.short.wednesday"),
            t("cleeckykit:common.datetime.weekDays.short.thursday"),
            t("cleeckykit:common.datetime.weekDays.short.friday"),
            t("cleeckykit:common.datetime.weekDays.short.saturday"),
          ];
          const fullWeekdays = [
            t("cleeckykit:common.datetime.weekDays.sunday"),
            t("cleeckykit:common.datetime.weekDays.monday"),
            t("cleeckykit:common.datetime.weekDays.tuesday"),
            t("cleeckykit:common.datetime.weekDays.wednesday"),
            t("cleeckykit:common.datetime.weekDays.thursday"),
            t("cleeckykit:common.datetime.weekDays.friday"),
            t("cleeckykit:common.datetime.weekDays.saturday"),
          ];

          // Encontra o índice do dia da semana nos nomes completos
          const index = fullWeekdays.findIndex((day) =>
            curr.period.toLowerCase().includes(day.toLowerCase())
          );

          if (index !== -1) {
            // Usa o nome abreviado correspondente
            normalizedPeriod = weekdays[index];
            acc[normalizedPeriod] = {
              period: normalizedPeriod,
              count: curr.count,
              peakDetail: curr.peakDetail,
            };
          }
          break;
        }
        case "month": {
          // Mantém o formato "dia - dia"
          acc[normalizedPeriod] = curr;
          break;
        }
        case "year": {
          // Converte para o formato traduzido do mês
          const months = [
            t("cleeckykit:common.datetime.months.january"),
            t("cleeckykit:common.datetime.months.february"),
            t("cleeckykit:common.datetime.months.march"),
            t("cleeckykit:common.datetime.months.april"),
            t("cleeckykit:common.datetime.months.may"),
            t("cleeckykit:common.datetime.months.june"),
            t("cleeckykit:common.datetime.months.july"),
            t("cleeckykit:common.datetime.months.august"),
            t("cleeckykit:common.datetime.months.september"),
            t("cleeckykit:common.datetime.months.october"),
            t("cleeckykit:common.datetime.months.november"),
            t("cleeckykit:common.datetime.months.december"),
          ];

          const monthsShort = [
            t("cleeckykit:common.datetime.months.short.january"),
            t("cleeckykit:common.datetime.months.short.february"),
            t("cleeckykit:common.datetime.months.short.march"),
            t("cleeckykit:common.datetime.months.short.april"),
            t("cleeckykit:common.datetime.months.short.may"),
            t("cleeckykit:common.datetime.months.short.june"),
            t("cleeckykit:common.datetime.months.short.july"),
            t("cleeckykit:common.datetime.months.short.august"),
            t("cleeckykit:common.datetime.months.short.september"),
            t("cleeckykit:common.datetime.months.short.october"),
            t("cleeckykit:common.datetime.months.short.november"),
            t("cleeckykit:common.datetime.months.short.december"),
          ];

          const monthIndex = months.findIndex((month) =>
            curr.period.toLowerCase().includes(month.toLowerCase())
          );

          if (monthIndex !== -1) {
            normalizedPeriod = monthsShort[monthIndex];
            acc[normalizedPeriod] = {
              period: normalizedPeriod,
              count: curr.count,
              peakDetail: curr.peakDetail,
            };
          }
          break;
        }
      }

      return acc;
    }, {} as Record<string, (typeof peakPeriods.peakPeriods)[0]>);

    // Preenche os valores para todas as categorias
    const values = allCategories.map((category) => {
      const data = normalizedData[category];
      return data ? data.count : 0;
    });

    // Encontra o índice do valor mais alto para destacar
    const maxValue = Math.max(...values);
    const highlightIndex = values.indexOf(maxValue);

    return {
      categories: allCategories,
      values,
      highlightIndex: highlightIndex >= 0 ? highlightIndex : undefined,
      xAxisLabel,
      yAxisLabel: t(
        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.acesses"
      ),
      tooltipFormatter: (index: number) => {
        const data = normalizedData[allCategories[index]];
        if (data?.peakDetail) {
          switch (period) {
            case "week":
              return t(
                "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.peak_hour",
                {
                  period: data.peakDetail.period,
                  count: data.peakDetail.count,
                }
              );
            case "month":
              return t(
                "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.peak_weekday",
                {
                  period: data.peakDetail.period,
                  count: data.peakDetail.count,
                }
              );
            case "year":
              return t(
                "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.peak_week",
                {
                  period: data.peakDetail.period,
                  count: data.peakDetail.count,
                }
              );
          }
        }
        return "";
      },
    };
  };

  const { total, interacted } = calculateInteractionRate();

  return (
    <CleeckyKit.Section>
      <CleeckyKit.Row>
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Title level={3}>
            {t(
              "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.title"
            )}
          </CleeckyKit.Title>
        </CleeckyKit.Column>
      </CleeckyKit.Row>

      <CleeckyKit.Grid mode="bento" gap="1rem">
        <CleeckyKit.GridItem row={2} col={2}>
          <CleeckyKit.Card>
            <CleeckyKit.Column justify="between">
              <CleeckyKit.Section>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="between" noBreak>
                    <CleeckyKit.Title level={6}>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.acesses"
                      )}
                    </CleeckyKit.Title>
                    <CleeckyKit.Select
                      options={periodOptions}
                      value={period}
                      onChange={(value) =>
                        onPeriodChange(value as MetricsPeriod)
                      }
                      placeholder={t(
                        "project:common.filters.period.select_period"
                      )}
                    />
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.BarChart
                      data={getBarChartData()}
                      showAverage={false}
                      width="100%"
                      height="450px"
                    />
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Section>

              <CleeckyKit.Section>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="start">
                    <CleeckyKit.Title level={6}>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.access_sources"
                      )}
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row>
                    <CleeckyKit.List gap="0.5rem" hoverable={false}>
                      <CleeckyKit.ListItem
                        icon={
                          <FontAwesomeIcon icon={faPeopleGroup} color="white" />
                        }
                        title={t(
                          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.social_media"
                        )}
                        value={calculateTrafficSourcePercentage(
                          AggregatorMetricsSource.SOCIAL_MEDIA
                        )}
                        background={
                          mostAccessedSource ===
                          AggregatorMetricsSource.SOCIAL_MEDIA
                            ? currentGradient?.highlightColor
                            : currentTheme.colors.background.card
                        }
                        borderRadius="0.75rem"
                      />
                      <CleeckyKit.ListItem
                        icon={<FontAwesomeIcon icon={faLink} color="white" />}
                        title={t(
                          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.direct_link"
                        )}
                        value={calculateTrafficSourcePercentage(
                          AggregatorMetricsSource.DIRECT_LINK
                        )}
                        background={
                          mostAccessedSource ===
                          AggregatorMetricsSource.DIRECT_LINK
                            ? currentGradient?.highlightColor
                            : currentTheme.colors.background.card
                        }
                        borderRadius="0.75rem"
                      />
                      <CleeckyKit.ListItem
                        icon={<FontAwesomeIcon icon={faSearch} color="white" />}
                        title={t(
                          "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.acesses_card.searches_and_sites"
                        )}
                        value={calculateTrafficSourcePercentage(
                          AggregatorMetricsSource.SEARCH_OR_OTHERS
                        )}
                        background={
                          mostAccessedSource ===
                          AggregatorMetricsSource.SEARCH_OR_OTHERS
                            ? currentGradient?.highlightColor
                            : currentTheme.colors.background.card
                        }
                        borderRadius="0.75rem"
                      />
                    </CleeckyKit.List>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Section>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>

        <CleeckyKit.GridItem row={1} col={1}>
          <CleeckyKit.Card backgroundColor={currentGradient?.highlightColor}>
            <CleeckyKit.Column justify="between">
              <CleeckyKit.Row noBreak gap={2}>
                <CleeckyKit.Title level={4}>
                  {t(
                    "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.suggestion_card.title"
                  )}
                </CleeckyKit.Title>
                <CleeckyKit.Title level={3}>
                  <FontAwesomeIcon icon={faLightbulb} />
                </CleeckyKit.Title>
              </CleeckyKit.Row>
              <CleeckyKit.Row>
                <CleeckyKit.Text>
                  {t(
                    "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.suggestion_card.description"
                  )}
                </CleeckyKit.Text>
              </CleeckyKit.Row>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>

        <CleeckyKit.GridItem row={1} col={1}>
          <CleeckyKit.Card>
            <CleeckyKit.Column gap="1rem">
              <CleeckyKit.Row justify="center">
                <CleeckyKit.PieChart
                  data={{
                    value: interacted,
                    valueLabel: t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.interacted_access"
                    ),
                    reamingLabel: t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.accesses_without_interaction"
                    ),
                    total: total,
                    label: t(
                      "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.title"
                    ),
                  }}
                />
              </CleeckyKit.Row>
              <CleeckyKit.Row justify="between" noBreak>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Section
                      background={currentTheme.colors.charts.pie.background}
                      borderRadius={10}
                      width="fit-content"
                      height="fit-content">
                      <CleeckyKit.Column justify="center">
                        <CleeckyKit.Title level={6} align="center">
                          {total}
                        </CleeckyKit.Title>
                      </CleeckyKit.Column>
                    </CleeckyKit.Section>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Text align="center">
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.total_access"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Section
                      background={currentGradient?.highlightColor}
                      borderRadius={10}
                      width="fit-content"
                      height="fit-content">
                      <CleeckyKit.Title level={6} align="center">
                        {interacted}
                      </CleeckyKit.Title>
                    </CleeckyKit.Section>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center">
                    <CleeckyKit.Text align="center">
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.interaction_rate_card.pie_chart.interacted_access"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Row>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>

        <CleeckyKit.GridItem row={1} col={2}>
          <CleeckyKit.Card>
            <CleeckyKit.Column>
              <CleeckyKit.Row justify="between" noBreak>
                <CleeckyKit.Title level={6}>
                  {t(
                    "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.locations_card.title"
                  )}
                </CleeckyKit.Title>
                <CleeckyKit.Select
                  options={periodOptions}
                  value={period}
                  onChange={(value) => onPeriodChange(value as MetricsPeriod)}
                  placeholder={t("project:common.filters.period.select_period")}
                />
              </CleeckyKit.Row>
              <CleeckyKit.Row>
                {trafficStats?.locations && (
                  <CleeckyKit.List
                    gap="0.5rem"
                    hoverable={false}
                    maxItems={5}
                    scrollable
                    background={currentTheme.colors.background.card}
                    borderRadius="0.75rem">
                    {Object.entries(trafficStats.locations)
                      .sort((a, b) => b[1] - a[1])
                      .slice(0, 5)
                      .map(([location, count]) => {
                        const [city, state, country, countryCode] =
                          location.split(", ");

                        const percentage = Math.round(
                          (count / trafficStats.totalAccesses) * 100
                        );

                        return (
                          <CleeckyKit.ListItem key={location}>
                            <CleeckyKit.Row
                              align="center"
                              height="100%"
                              noBreak>
                              <CleeckyKit.Column
                                width="5rem"
                                align="center"
                                justify="center">
                                {country && countryCode && (
                                  <Flag
                                    code={countryCode}
                                    height="100%"
                                    width="100%"
                                    fallback={
                                      <FontAwesomeIcon
                                        icon={faLocationDot}
                                        color="white"
                                      />
                                    }
                                  />
                                )}
                                {!country && (
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    color="white"
                                  />
                                )}
                              </CleeckyKit.Column>
                              <CleeckyKit.Column
                                align="center"
                                justify="center">
                                <CleeckyKit.Title level={5}>
                                  {city}
                                </CleeckyKit.Title>
                                {state && (
                                  <CleeckyKit.Text>{state}</CleeckyKit.Text>
                                )}
                              </CleeckyKit.Column>
                              <CleeckyKit.Column
                                align="center"
                                justify="center">
                                <CleeckyKit.Row justify="end" align="end">
                                  <CleeckyKit.Title level={5}>
                                    {count}{" "}
                                    {t(
                                      "project:apps.toolbox.tools.link_aggregator.dashboard.detailed_metrics.locations_card.total_accesses"
                                    )}
                                  </CleeckyKit.Title>
                                </CleeckyKit.Row>

                                <CleeckyKit.Row justify="end" align="end">
                                  <CleeckyKit.Text>
                                    ({percentage}%)
                                  </CleeckyKit.Text>
                                </CleeckyKit.Row>
                              </CleeckyKit.Column>
                            </CleeckyKit.Row>
                          </CleeckyKit.ListItem>
                        );
                      })}
                  </CleeckyKit.List>
                )}
              </CleeckyKit.Row>
            </CleeckyKit.Column>
          </CleeckyKit.Card>
        </CleeckyKit.GridItem>
      </CleeckyKit.Grid>
    </CleeckyKit.Section>
  );
};

import React, { useEffect, useState, useId, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useAccordionContext } from "./CleeckyKitAccordionsGroup";
import * as CleeckyKit from "CleeckyKit";

interface CleeckyKitAccordionProps {
  title: string;
  children: ReactNode;
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const AccordionContainer = styled.div<{
  disabled?: boolean;
  inGroup?: boolean;
}>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ inGroup }) => (inGroup ? "0" : "8px")};
  background-color: ${({ theme }) => theme.colors.background.card};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  margin-bottom: ${({ inGroup }) => (inGroup ? "-1px" : "8px")};

  &:first-child {
    border-top-left-radius: ${({ inGroup }) => (inGroup ? "8px" : "8px")};
    border-top-right-radius: ${({ inGroup }) => (inGroup ? "8px" : "8px")};
  }

  &:last-child {
    border-bottom-left-radius: ${({ inGroup }) => (inGroup ? "8px" : "8px")};
    border-bottom-right-radius: ${({ inGroup }) => (inGroup ? "8px" : "8px")};
    margin-bottom: 0;
  }
`;

const AccordionHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.interactive.hover};
  }

  svg {
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  }
`;

const AccordionTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  font-weight: 500;
`;

const AccordionContent = styled.div<{ isOpen: boolean; maxHeight: string }>`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: ${({ isOpen, maxHeight }) => (isOpen ? maxHeight : "0")};
`;

const ContentWrapper = styled.div`
  padding: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
`;

export const CleeckyKitAccordion: React.FC<CleeckyKitAccordionProps> = ({
  title,
  children,
  isOpen: controlledIsOpen,
  onToggle,
  disabled = false,
  className,
  style,
}) => {
  const id = useId();
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();
  const context = useAccordionContext();
  const [isOpen, setIsOpen] = useState(controlledIsOpen || false);
  const [contentHeight, setContentHeight] = useState("auto");

  useEffect(() => {
    if (controlledIsOpen !== undefined) {
      setIsOpen(controlledIsOpen);
    }
  }, [controlledIsOpen]);

  useEffect(() => {
    if (context) {
      setIsOpen(context.activeAccordion === id);
    }
  }, [context?.activeAccordion, id]);

  const handleToggle = () => {
    if (disabled) return;

    const newIsOpen = !isOpen;

    if (context) {
      context.setActiveAccordion(newIsOpen ? id : null);
    } else {
      setIsOpen(newIsOpen);
    }

    onToggle?.(newIsOpen);
  };

  return (
    <AccordionContainer
      disabled={disabled}
      className={className}
      style={style}
      inGroup={!!context}>
      <AccordionHeader isOpen={isOpen} onClick={handleToggle}>
        <AccordionTitle>{title}</AccordionTitle>
        <FontAwesomeIcon
          icon={faChevronDown}
          color={currentTheme.colors.text.primary}
          size="sm"
        />
      </AccordionHeader>
      <AccordionContent isOpen={isOpen} maxHeight={contentHeight}>
        <ContentWrapper>{children}</ContentWrapper>
      </AccordionContent>
    </AccordionContainer>
  );
};

export default CleeckyKitAccordion;

//Canvas Types
export type TextJustify = "START" | "CENTER" | "END";
export type TextAlignment = "LEFT" | "CENTER" | "RIGHT" | "JUSTIFY";
export type ImagePosition = "BACKGROUND" | "TOP" | "BOTTOM" | "LEFT" | "RIGHT";
export type ImageTextPosition = "TOP" | "BOTTOM" | "OVERLAY";
export type SocialPlatform =
  | "INSTAGRAM"
  | "FACEBOOK"
  | "YOUTUBE"
  | "TIKTOK"
  | "LINKEDIN"
  | "X"
  | "PINTEREST"
  | "REDDIT"
  | "SNAPCHAT"
  | "SPOTIFY"
  | "TUMBLR"
  | "VIMEO"
  | "WHATSAPP"
  | "GITHUB"
  | "GMAIL";

export const SOCIAL_PLATFORMS = {
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  YOUTUBE: "YOUTUBE",
  TIKTOK: "TIKTOK",
  LINKEDIN: "LINKEDIN",
  X: "X",
  PINTEREST: "PINTEREST",
  REDDIT: "REDDIT",
  SNAPCHAT: "SNAPCHAT",
  SPOTIFY: "SPOTIFY",
  TUMBLR: "TUMBLR",
  VIMEO: "VIMEO",
  WHATSAPP: "WHATSAPP",
  GITHUB: "GITHUB",
  GMAIL: "GMAIL",
} as const;

export type Orientation = "HORIZONTAL" | "VERTICAL";

export interface BaseContent {
  order: number;
  show: boolean;
}

export interface FileData {
  path: string;
  type: "EXTERNAL" | "FILE";
}

export interface TextContent extends BaseContent {
  size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  color?: string;
  decoration?: string;
  text: string;
  alignment?: TextAlignment;
  justify?: TextJustify;
}

export interface ImageContent extends BaseContent {
  file: FileData;
  border?: string;
  borderRadius?: string;
  borderWidth?: string;
  borderColor?: string;
  title?: string;
  description?: string;
  textPosition?: ImageTextPosition;
}

export interface AvatarContent extends BaseContent {
  file: FileData;
  border?: string;
  borderColor?: string;
  shape: AvatarShape;
}

export interface SocialButton {
  platform: SocialPlatform;
  url: string;
  title: string | null;
}

export interface SocialButtonsContent extends BaseContent {
  buttons: SocialButton[];
  background?: string;
  border?: string;
  borderRadius?: string;
  borderSize?: string;
  highlightColor?: string;
  orientation: Orientation;
}

export type ButtonSize = "small" | "medium" | "large";

export interface LinkContent extends BaseContent {
  url: string;
  label: string;
  image?: {
    path: string;
    type: "EXTERNAL" | "FILE";
    imagePosition: ImagePosition;
  };
  background?: string;
  border?: string;
  borderRadius?: string;
  borderSize?: string;
  highlightColor?: string;
  color?: string;
  size?: ButtonSize;
}

export interface GroupContent extends BaseContent {
  items: any[];
  background?: string;
  border?: string;
  borderRadius?: string;
  borderSize?: string;
}

export type ElementContent =
  | TextContent
  | ImageContent
  | AvatarContent
  | SocialButtonsContent
  | LinkContent
  | GroupContent;

export type CanvasElementType =
  | "TITLE"
  | "TEXT"
  | "IMAGE"
  | "AVATAR"
  | "LINK"
  | "SOCIAL_BUTTONS"
  | "NAME"
  | "USERNAME"
  | "GROUP";

export interface CanvasElement {
  id: string;
  type: CanvasElementType;
  content: ElementContent;
  order: number;
  show: boolean;
  sectionId?: string;
}

export interface CanvasSection {
  id: string;
  title: string;
  elements: string[];
}

export interface GlobalColors {
  highlightColor: string;
  pageBackgroundColor: string;
  pageContentBackgroundColor: string;
  buttonsBackgroundColor: string;
  buttonsLabelColor: string;
  textColor: string;
  borderColor: string;
}

export interface GlobalSizes {
  borderWidth: string; // Espessura da borda
  borderRadius: string; // Curvatura da borda
}

export interface CleeckyKitCanvasProps {
  elements: CanvasElement[];
  sections?: CanvasSection[];
  onElementsChange: (elements: CanvasElement[]) => void;
  onSectionsChange?: (sections: CanvasSection[]) => void;
  className?: string;
  globalColors?: GlobalColors;
  globalSizes?: GlobalSizes;
  onGlobalColorsChange?: (colors: GlobalColors) => void;
  onGlobalSizesChange?: (sizes: GlobalSizes) => void;
  elementsLimit?: number;
}

export interface EditorAreaProps {
  elements: CanvasElement[];
  sections: CanvasSection[];
  globalColors: GlobalColors;
  globalSizes: GlobalSizes;
  onElementsChange: (elements: CanvasElement[]) => void;
  onGlobalColorsChange: (colors: GlobalColors) => void;
  onGlobalSizesChange: (sizes: GlobalSizes) => void;
  className?: string;
  elementsLimit?: number;
}

export interface PreviewAreaProps {
  elements: CanvasElement[];
  sections: CanvasSection[];
  globalColors: GlobalColors;
  globalSizes: GlobalSizes;
  previewMode: "desktop" | "mobile";
  onPreviewModeChange: (mode: "desktop" | "mobile") => void;
}

export interface DragDropState {
  draggedId: string | null;
  dropTargetId: string | null;
  dropPosition: "before" | "after" | null;
  isDragging: boolean;
  touchStartY: number | null;
  touchedElement: string | null;
  touchedElementType: CanvasElementType | null;
}

export type AvatarShape = "CIRCLE" | "SQUARE";

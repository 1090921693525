import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";
import { CleeckyKitColumn } from "CleeckyKit/components/Layout/Column/CleeckyKitColumn";
import { useAuth } from "../../../contexts/AuthContext";
import { AuthModal } from "../../../components/Auth/AuthModal";
import { NotificationSettingsModal } from "../../../components/CleeckyInsiders/NotificationSettingsModal";
import { cleeckyInsidersService } from "../../../services/cleeckyInsidersService";

const Banner = styled.div<{ expanded: boolean }>`
  background: ${({ theme }) => theme.colors.status.info};
  border-radius: 24px;
  padding: 32px;
  margin-bottom: 48px;
  color: ${({ theme }) => theme.colors.text.onPrimary};
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${({ theme }) => theme.shadows.card.elevated};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 0.875rem;
`;

const Description = styled.p`
  font-size: 1.125rem;
  margin: 0 0 24px 0;
  opacity: 0.9;
  line-height: 1.6;
`;

const TopicList = styled.div`
  margin-bottom: 32px;
`;

const Topic = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  font-size: 1.125rem;
  line-height: 1.5;

  &:before {
    content: "✔️";
    margin-right: 12px;
  }
`;

const ExpandButton = styled.button<{ expanded: boolean }>`
  position: absolute;
  bottom: 24px;
  right: 24px;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.text.onPrimary};
  cursor: pointer;
  padding: 8px;
  transform: rotate(${({ expanded }) => (expanded ? "180deg" : "0deg")});
  transition: transform 0.3s ease;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Content = styled(CleeckyKitColumn)<{ expanded: boolean }>`
  height: ${({ expanded }) => (expanded ? "auto" : "0")};
  opacity: ${({ expanded }) => (expanded ? "1" : "0")};
  transition: all 0.3s ease;
  overflow: hidden;
`;

const InsidersButton = styled.button`
  background: ${({ theme }) => theme.colors.text.onPrimary};
  color: ${({ theme }) => theme.colors.status.info};
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 24px 0;

  &:hover {
    transform: translateY(-2px);
    opacity: 0.9;
  }
`;

const nextReleaseData = {
  releaseDate: "31/03/2025",
  version: "v1.4.x",
  title:
    "Duas novas ferramentas a caminho e uma conexão ainda mais próxima com a comunidade!",
  description:
    "Nas próximas semanas, lançaremos o Encurtador de Links e o Gerador de QR Code, além de um espaço interativo para sugestões e votação de ideias.",
  topics: [
    "Lançamento do Encurtador de Links e Gerador de QR Code",
    "Espaço interativo para sugestões e votação de ideias",
    'Melhorias visuais no tema "Claro" e desempenho aprimorado',
    "Mais elementos para personalizar suas páginas do Agregador de Links",
  ],
  futureTopics: [
    "Criador de Landing Pages com análise via Heatmap",
    "Novas métricas para o Dashboard do Agregador de Links",
    "Sistema de Afiliação e Venda de Acessos Privados",
  ],
};

export const NextReleaseSection: React.FC = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { user } = useAuth();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [authMode, setAuthMode] = useState<"login" | "register">("register");

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleInsidersClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (user) {
      setIsNotificationModalOpen(true);
    } else {
      setAuthMode("register");
      setIsAuthModalOpen(true);
    }
  };

  const handleNotificationSettingsSubmit = async (settings: any) => {
    try {
      await cleeckyInsidersService.subscribe({
        ...settings,
        useCleeckyAccount: !!user,
      });
      // Aqui você pode adicionar um feedback de sucesso se desejar
    } catch (error) {
      console.error("Erro ao se inscrever:", error);
    }
  };

  return (
    <Banner expanded={expanded} onClick={handleToggleExpand}>
      <Header>
        <span>
          Previsão do próximo lançamento para {nextReleaseData.releaseDate}
        </span>
        <span>Próxima versão: {nextReleaseData.version}</span>
      </Header>

      <CleeckyKit.Title level={2}>{nextReleaseData.title}</CleeckyKit.Title>

      {!expanded ? (
        <>
          <Description>{nextReleaseData.description}</Description>
          <TopicList>
            {nextReleaseData.topics.map((topic, index) => (
              <Topic key={index}>{topic}</Topic>
            ))}
          </TopicList>
        </>
      ) : (
        <Content expanded={expanded} gap="1rem">
          <CleeckyKit.Text>
            A Cleecky está em constante evolução, trazendo novas ferramentas e
            melhorias com base no feedback da comunidade!
          </CleeckyKit.Text>

          <CleeckyKit.Title level={3}>
            🚀 Novas Ferramentas a Caminho!
          </CleeckyKit.Title>
          <CleeckyKit.Text>
            Estamos trabalhando a todo vapor para manter a Cleecky sempre
            evoluindo! Atualizações constantes, novas ferramentas e ainda mais
            possibilidades para você explorar.
            <br />
            <br />
            Atualmente, temos mais de 100 ferramentas planejadas (que envolvem
            as áreas de: Comunicação, Educação, Entretenimento, Marketing,
            Negócios, etc.), e as novidades já começam a chegar: nas próximas
            semanas, vamos lançar duas delas – o Encurtador de Links e o Gerador
            de QR Code.
            <br />
            <br />E isso é só o começo!
          </CleeckyKit.Text>

          <CleeckyKit.Title level={3}>
            🤝 Mais Perto da Comunidade
          </CleeckyKit.Title>
          <CleeckyKit.Text>
            Você pediu, a gente ouviu! Queremos agradecer a cada um de vocês
            pelos feedbacks incríveis. Suas sugestões fazem toda a diferença, e
            estamos ansiosos para saber o que mais você gostaria de ver na
            Cleecky.
            <br />
            <br />
            Por isso, estamos criando um espaço exclusivo onde a comunidade vai
            poder sugerir novas funcionalidades e votar nas ideias que mais
            gostam. Pense nisso como um fórum interativo, onde você pode não
            apenas sugerir, mas também comentar e aprimorar as ideias de outros
            participantes!
            <br />
            <br />
            De tempos em tempos, a Cleecky Team também pode revelar quais
            novidades já estão nos planos (se não for segredo, é claro! 😉) para
            que vocês possam contribuir com melhorias e complementos.
            <br />
            <br />
            Ah, e para ter acesso a essa nova área, inscreva-se na Cleecky
            Insiders e faça parte dessa jornada com a gente!
          </CleeckyKit.Text>

          <InsidersButton
            onClick={handleInsidersClick}
            disabled={user?.userTags?.some(
              (tag) => tag.type === "CLEECKY_INSIDER"
            )}>
            {user
              ? user.userTags?.some((tag) => tag.type === "CLEECKY_INSIDER")
                ? t("project:common.cleecky_insiders.already_insider")
                : t("project:common.cleecky_insiders.join_with_account", {
                    username: user.username,
                  })
              : t("project:common.cleecky_insiders.join_without_account")}
          </InsidersButton>

          <CleeckyKit.Title level={3}>✨ Melhorias Chegando</CleeckyKit.Title>
          <CleeckyKit.Text>
            Nosso primeiro lançamento foi um sucesso! Muitas pessoas decidiram
            embarcar nessa jornada com a gente e contribuíram com feedbacks
            valiosos. Cada sugestão foi analisada e entrou para nossa lista de
            prioridades!
            <br />
            <br />
            Na próxima atualização, prepare-se para melhorias visuais incríveis,
            especialmente no tema de interface "Claro", e um desempenho ainda
            mais rápido!
          </CleeckyKit.Text>

          <CleeckyKit.Title level={3}>💻 Para Desenvolvedores</CleeckyKit.Title>
          <CleeckyKit.Text>
            A Cleecky está crescendo, e isso significa que nossos frameworks e
            API também estão evoluindo junto! Estamos constantemente
            implementando novos recursos para acompanhar essa expansão e
            garantir que você tenha as melhores ferramentas para o seu projeto.
            <br />
            <br />
            Ainda não temos uma data de lançamento para os frameworks, mas
            podemos garantir que estamos trabalhando com todo o carinho e
            dedicação para torná-los o mais flexíveis, completos e fáceis de
            usar possível.
            <br />
            <br />
            Fique ligado, porque quando eles chegarem, você vai perceber que
            desenvolver algo novo vai ser quase como "um clique"! 👀
          </CleeckyKit.Text>

          <CleeckyKit.Title level={3}>
            🎯 Metas e Funcionalidades Futuras
          </CleeckyKit.Title>
          <CleeckyKit.Text>
            Mesmo antes do lançamento da nova atualização, já estamos preparando
            as próximas novidades! Nossa missão é manter a Cleecky em constante
            evolução, sempre trazendo ferramentas inovadoras para você.
            <br />
            <br />E para dar um gostinho do que está por vir, aqui estão algumas
            das novidades em que já estamos trabalhando:
            <br />
            <br />
            🔹 <strong>Criador de Landing Pages</strong> – Construa páginas
            incríveis de forma rápida e intuitiva! E para aprimorar ainda mais
            essa ferramenta, também estamos desenvolvendo um Sistema de Heatmap
            para analisar o comportamento dos visitantes nas páginas que você
            criar.
            <br />
            <br />
            🔹{" "}
            <strong>
              Novas Métricas para o Dashboard do Agregador de Links
            </strong>{" "}
            – Queremos proporcionar um painel ainda mais completo! Em breve,
            teremos métricas detalhadas de desempenho, além de novos elementos
            no Editor para personalizar ainda mais seus links.
            <br />
            <br />
            🔹 <strong>
              Sistema de Afiliação e Venda de Acessos Privados
            </strong>{" "}
            – Estamos estudando maneiras de permitir que os usuários monetizem
            suas páginas. Isso significa que, em breve, você poderá cobrar pelo
            acesso a conteúdos exclusivos dentro da Cleecky! Mais uma forma de
            transformar sua criatividade em renda. 💰
          </CleeckyKit.Text>

          <CleeckyKit.Title level={3}>
            Palavras da Cleecky Team
          </CleeckyKit.Title>
          <Description>
            Estamos muito felizes com o crescimento e o engajamento da nossa
            comunidade! Cada nova ferramenta, cada melhoria e cada inovação que
            implementamos é pensando em vocês. Obrigado por fazerem parte dessa
            jornada! 🚀
          </Description>
        </Content>
      )}

      {isAuthModalOpen && (
        <AuthModal
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
          initialMode={authMode}
        />
      )}

      <NotificationSettingsModal
        isOpen={isNotificationModalOpen}
        onClose={() => setIsNotificationModalOpen(false)}
        onSubmit={handleNotificationSettingsSubmit}
        email={user?.email}
      />

      <ExpandButton expanded={expanded} onClick={(e) => e.stopPropagation()}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 10L12 15L17 10"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </ExpandButton>
    </Banner>
  );
};

export default NextReleaseSection;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMobileAlt,
  faLock,
  faCamera,
  faCircleUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";
import {
  TextContent,
  LinkContent,
  SocialButtonsContent,
  ImageContent,
  GroupContent,
  AvatarContent,
  SocialButton,
  SocialPlatform,
} from "../../types";
import { ReactComponent as MinimalistIcon } from "../../../../assets/images/icon-minimalist-black-1.svg";
import { PreviewAreaProps, CanvasElement, CanvasSection } from "../../types";
import { getEffectiveColor } from "../../../../utilities/canvasColorUtils";
import {
  getElementIcon,
  getSocialIcon,
} from "../../../../utilities/canvasElementUtils";
import {
  PreviewWrapper,
  PreviewArea as StyledPreviewArea,
  PreviewHeader,
  PreviewButton,
  PreviewContent,
  BrowserBar,
  BrowserIcon,
  BrowserDots,
  BrowserAddressBar,
} from "../../styles";

export const CleeckyKitCanvasPreviewArea: React.FC<PreviewAreaProps> = ({
  elements,
  sections,
  globalColors,
  globalSizes,
  previewMode,
  onPreviewModeChange,
}) => {
  const { currentTheme } = CleeckyKit.useCleeckyKitTheme();
  const { t } = useTranslation();

  const renderPreviewElement = (element: CanvasElement) => {
    if (!element.content.show) return null;
    const content = (() => {
      switch (element.type) {
        case "AVATAR":
          const avatarContent = element.content as AvatarContent;
          return (
            <CleeckyKit.Row justify="center">
              <div style={{ width: "120px", height: "120px" }}>
                {avatarContent.file?.path ? (
                  <CleeckyKit.Image
                    src={avatarContent.file.path}
                    alt="Avatar"
                    width="100%"
                    height="100%"
                    fit="cover"
                    position="center"
                    borderRadius={
                      avatarContent.shape === "CIRCLE"
                        ? "50%"
                        : globalSizes.borderRadius
                    }
                    customStyle={{
                      border:
                        avatarContent.border ||
                        `${globalSizes.borderWidth} solid ${globalColors.borderColor}`,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      aspectRatio: "1/1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#333333",
                      borderRadius: "50%",
                      border: avatarContent.border
                        ? `${avatarContent.border} solid ${
                            avatarContent.borderColor ||
                            globalColors.borderColor ||
                            undefined
                          }`
                        : undefined,
                    }}>
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      style={{
                        fontSize: "3rem",
                        opacity: 0.5,
                        color: "#D4D4D4",
                      }}
                    />
                  </div>
                )}
              </div>
            </CleeckyKit.Row>
          );

        case "IMAGE":
          const imageContent = element.content as ImageContent;
          return (
            <CleeckyKit.Row>
              <CleeckyKit.Column>
                {(imageContent.title || imageContent.description) &&
                  imageContent.textPosition === "TOP" && (
                    <>
                      {imageContent.title && (
                        <CleeckyKit.Title
                          level={5}
                          customStyle={{
                            color: getEffectiveColor(
                              undefined,
                              "textColor",
                              globalColors
                            ),
                          }}>
                          {imageContent.title}
                        </CleeckyKit.Title>
                      )}
                      {imageContent.description && (
                        <CleeckyKit.Text
                          customStyle={{
                            color: getEffectiveColor(
                              undefined,
                              "textColor",
                              globalColors
                            ),
                          }}>
                          {imageContent.description}
                        </CleeckyKit.Text>
                      )}
                    </>
                  )}
                {imageContent.file?.path ? (
                  <CleeckyKit.Image
                    src={imageContent.file.path}
                    alt={imageContent.title || "Imagem"}
                    width="100%"
                    height="auto"
                    fit="cover"
                    borderRadius={imageContent.borderRadius}
                    className={globalColors.borderColor ? "with-border" : ""}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "16/9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#333333",
                      borderRadius: imageContent.borderRadius,
                      border: imageContent.border
                        ? `${imageContent.border} solid ${
                            imageContent.borderColor ||
                            globalColors.borderColor ||
                            undefined
                          }`
                        : undefined,
                    }}>
                    <FontAwesomeIcon
                      icon={faCamera}
                      style={{
                        fontSize: "3rem",
                        opacity: 0.5,
                        color: "#D4D4D4",
                      }}
                    />
                  </div>
                )}
                {(imageContent.title || imageContent.description) &&
                  imageContent.textPosition === "BOTTOM" && (
                    <>
                      {imageContent.title && (
                        <CleeckyKit.Title
                          level={5}
                          customStyle={{
                            color: getEffectiveColor(
                              undefined,
                              "textColor",
                              globalColors
                            ),
                          }}>
                          {imageContent.title}
                        </CleeckyKit.Title>
                      )}
                      {imageContent.description && (
                        <CleeckyKit.Text
                          customStyle={{
                            color: getEffectiveColor(
                              undefined,
                              "textColor",
                              globalColors
                            ),
                          }}>
                          {imageContent.description}
                        </CleeckyKit.Text>
                      )}
                    </>
                  )}
              </CleeckyKit.Column>
            </CleeckyKit.Row>
          );

        case "TITLE":
          const titleContent = element.content as TextContent;
          return (
            <CleeckyKit.Row
              justify={titleContent.justify?.toLowerCase() as any}>
              <CleeckyKit.Title
                level={
                  titleContent.size
                    ? (parseInt(titleContent.size.replace("h", "")) as
                        | 1
                        | 2
                        | 3
                        | 4
                        | 5
                        | 6)
                    : 1
                }
                align={titleContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: getEffectiveColor(
                    titleContent.color,
                    "textColor",
                    globalColors
                  ),
                  textDecoration: titleContent.decoration,
                }}>
                {titleContent.text ||
                  t(
                    "cleeckykit:common.interface.elements.canvas.elements.title.label"
                  )}
              </CleeckyKit.Title>
            </CleeckyKit.Row>
          );

        case "NAME":
          const nameContent = element.content as TextContent;
          return (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Title
                level={4}
                align={nameContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: getEffectiveColor(
                    nameContent.color,
                    "textColor",
                    globalColors
                  ),
                  textDecoration: nameContent.decoration,
                }}>
                {nameContent.text || "[Nome]"}
              </CleeckyKit.Title>
            </CleeckyKit.Row>
          );

        case "USERNAME":
          const usernameContent = element.content as TextContent;
          return (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Text
                href={`/@${usernameContent.text}`}
                target="_blank"
                align={usernameContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: getEffectiveColor(
                    usernameContent.color,
                    "textColor",
                    globalColors
                  ),
                  textDecoration: usernameContent.decoration,
                }}>
                @{usernameContent.text || "username"}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          );

        case "TEXT":
          const textContent = element.content as TextContent;
          return (
            <CleeckyKit.Row justify={textContent.justify?.toLowerCase() as any}>
              <CleeckyKit.Text
                align={textContent.alignment?.toLowerCase() as any}
                customStyle={{
                  color: getEffectiveColor(
                    textContent.color,
                    "textColor",
                    globalColors
                  ),
                  textDecoration: textContent.decoration,
                }}>
                {textContent.text ||
                  t(
                    "cleeckykit:common.interface.elements.canvas.elements.text.label"
                  )}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          );

        case "LINK":
          const linkContent = element.content as LinkContent;
          return (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Button
                text={
                  linkContent.label ||
                  t(
                    "cleeckykit:common.interface.elements.canvas.elements.link.label"
                  )
                }
                textPosition="inside-right"
                variant="solid"
                href={linkContent.url}
                target="_blank"
                fullWidth
                size={"small"}
                type="button"
                borderRadius={
                  linkContent.borderRadius || globalSizes.borderRadius
                }
                customStyle={{
                  background: getEffectiveColor(
                    linkContent.background,
                    "buttonsBackgroundColor",
                    globalColors
                  ),
                  color: getEffectiveColor(
                    linkContent.color,
                    "buttonsLabelColor",
                    globalColors
                  ),
                  border: `${globalSizes.borderWidth} solid ${getEffectiveColor(
                    linkContent.border,
                    "borderColor",
                    globalColors
                  )}`,
                }}
                hoverStyle={{
                  background: getEffectiveColor(
                    linkContent.highlightColor,
                    "highlightColor",
                    globalColors
                  ),
                }}
              />
            </CleeckyKit.Row>
          );

        case "SOCIAL_BUTTONS":
          const socialContent = element.content as SocialButtonsContent;
          return (
            <CleeckyKit.Row justify="center">
              <CleeckyKit.Grid
                className="preview-grid"
                justify="center"
                columns={{
                  xs: socialContent.orientation === "HORIZONTAL" ? 5 : 1,
                }}
                gap={1}>
                {socialContent.buttons.map(
                  (button: SocialButton, index: number) => (
                    <CleeckyKit.Button
                      key={index}
                      type="button"
                      variant="icon"
                      iconBackground
                      href={button.url}
                      target="_blank"
                      format="circle"
                      size="small"
                      icon={
                        <FontAwesomeIcon
                          icon={getSocialIcon(button.platform)}
                        />
                      }
                      customStyle={{
                        backgroundColor: getEffectiveColor(
                          socialContent.background,
                          "buttonsBackgroundColor",
                          globalColors
                        ),
                        color: getEffectiveColor(
                          undefined,
                          "buttonsLabelColor",
                          globalColors
                        ),
                        border: `${
                          socialContent.borderSize || "1px"
                        } solid ${getEffectiveColor(
                          socialContent.border,
                          "borderColor",
                          globalColors
                        )}`,
                        borderRadius: socialContent.borderRadius,
                      }}
                    />
                  )
                )}
              </CleeckyKit.Grid>
            </CleeckyKit.Row>
          );

        case "GROUP":
          const groupContent = element.content as GroupContent;
          return (
            <CleeckyKit.Section
              padding="1rem"
              background={getEffectiveColor(
                groupContent.background,
                "buttonsBackgroundColor",
                globalColors
              )}
              borderRadius={groupContent.borderRadius || "8px"}
              width="100%">
              {groupContent.items.map((item: CanvasElement, index: number) => (
                <React.Fragment key={index}>
                  {renderPreviewElement(item)}
                </React.Fragment>
              ))}
            </CleeckyKit.Section>
          );

        default:
          return null;
      }
    })();

    return (
      <div key={element.id} className="preview-content-wrapper">
        {content}
      </div>
    );
  };

  const renderPreviewSection = (section: CanvasSection) => {
    const sectionElements = elements
      .filter((el) => section.elements.includes(el.id))
      .sort((a, b) => {
        const orderA = "order" in a.content ? a.content.order : 0;
        const orderB = "order" in b.content ? b.content.order : 0;
        return orderA - orderB;
      });

    return (
      <CleeckyKit.Section
        key={section.id}
        padding="1rem"
        background={getEffectiveColor(
          undefined,
          "buttonsBackgroundColor",
          globalColors
        )}
        borderRadius="8px"
        width="100%">
        <CleeckyKit.Title level={5}>{section.title}</CleeckyKit.Title>
        {sectionElements.map(renderPreviewElement)}
      </CleeckyKit.Section>
    );
  };

  const standaloneElements = elements
    .filter(
      (el) => !sections.some((section) => section.elements.includes(el.id))
    )
    .sort((a, b) => {
      const orderA = "order" in a.content ? a.content.order : 0;
      const orderB = "order" in b.content ? b.content.order : 0;
      return orderA - orderB;
    });

  return (
    <PreviewWrapper>
      <StyledPreviewArea>
        <PreviewHeader>
          <PreviewButton
            $isActive={previewMode === "desktop"}
            onClick={() => onPreviewModeChange("desktop")}
            title={t(
              "cleeckykit:common.interface.elements.canvas.preview.desktop"
            )}>
            <FontAwesomeIcon icon={faDesktop} />
          </PreviewButton>
          <PreviewButton
            $isActive={previewMode === "mobile"}
            onClick={() => onPreviewModeChange("mobile")}
            title={t(
              "cleeckykit:common.interface.elements.canvas.preview.mobile"
            )}>
            <FontAwesomeIcon icon={faMobileAlt} />
          </PreviewButton>
        </PreviewHeader>
        <PreviewContent
          $isMobile={previewMode === "mobile"}
          $globalColors={globalColors}>
          <BrowserBar>
            <BrowserIcon>
              <MinimalistIcon />
            </BrowserIcon>
            <BrowserAddressBar>
              <FontAwesomeIcon icon={faLock} />
              preview.cleecky.com
            </BrowserAddressBar>
            <BrowserDots>
              <span />
              <span />
              <span />
            </BrowserDots>
          </BrowserBar>
          <div className="preview-content-inner">
            <div className="preview-content-wrapper">
              <CleeckyKit.Column>
                {sections.map(renderPreviewSection)}
                {standaloneElements.map(renderPreviewElement)}
              </CleeckyKit.Column>
            </div>
          </div>
        </PreviewContent>
        <CleeckyKit.Row justify="center" margin="1rem 0 0 0">
          <CleeckyKit.Text
            align="center"
            customStyle={{
              opacity: 0.7,
              fontSize: "0.875rem",
              color: currentTheme?.colors?.text?.secondary,
            }}>
            {t(
              "cleeckykit:common.interface.elements.canvas.preview.disclaimer"
            )}
          </CleeckyKit.Text>
        </CleeckyKit.Row>
      </StyledPreviewArea>
    </PreviewWrapper>
  );
};

CleeckyKitCanvasPreviewArea.displayName = "CleeckyKitCanvasPreviewArea";

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";

const Section = styled.section`
  margin-bottom: 48px;
`;

const SectionHeader = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.75rem;
  margin: 0 0 8px 0;
`;

const SectionDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.125rem;
  margin: 0;
`;

const ChangelogArticle = styled.article`
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 12px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
`;

const ArticleHeader = styled.div`
  margin-bottom: 16px;
`;

const ArticleTitle = styled.h3`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.25rem;
  margin: 0 0 8px 0;
`;

const ArticleMetadata = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
`;

const ArticleContent = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  line-height: 1.6;

  img {
    max-width: 100%;
    border-radius: 8px;
    margin: 16px 0;
  }

  ul,
  ol {
    margin: 16px 0;
    padding-left: 24px;
  }

  li {
    margin-bottom: 8px;
  }
`;

// Dados de exemplo para o Changelog
const changelogItems = [
  {
    version: "1.0.0 (BigBang)",
    publishedAt: "2025-01-03T22:35:00Z",
    title: "Lançamento Inicial da Cleecky",
    content: `
      <p>Primeira versão oficial da Cleecky! Principais recursos:</p>
      <ul>
        <li>Sistema de autenticação seguro</li>
        <li>Interface responsiva e moderna com: Personalização de temas da interface e Suporte a múltiplos idiomas</li>
        <li>Lançamento oficial do Agregador de Links: Dashboard com métricas para análise de desempenho; Editor para personalização avançada e preview de paginas em diferentes dispositivos; Suporte a múltiplas páginas por usuário; Sistema de privacidade e gerenciamento de acesso à páginas</li>
        <li>Lançamento do plano Cleecky Universe que dá acesso a todos os recursos premium de todos os apps e ferramentas: Lançado inicialmente com o Nível "First Steps" (anual e mensal) e acompanhado do Nível "Infinity" (que permite acesso vitalício ao Nível "First Step")</li>
      </ul>
    `,
  },
];

export const ChangelogSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CleeckyKit.Section>
      <CleeckyKit.Column gap="2rem">
        <div>
          <CleeckyKit.Title level={2}>
            {t("pages.changelog.sections.changelog.title")}
          </CleeckyKit.Title>
          <CleeckyKit.Text>
            {t("pages.changelog.sections.changelog.description")}
          </CleeckyKit.Text>
        </div>

        {changelogItems.map((item, index) => (
          <ChangelogArticle key={index}>
            <ArticleHeader>
              <ArticleTitle>{item.title}</ArticleTitle>
              <ArticleMetadata>
                {t("pages.changelog.sections.changelog.version")}:{" "}
                {item.version} -{" "}
                {t("pages.changelog.sections.changelog.published_at")}:{" "}
                {new Date(item.publishedAt).toLocaleString()}
              </ArticleMetadata>
            </ArticleHeader>
            <ArticleContent
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </ChangelogArticle>
        ))}
      </CleeckyKit.Column>
    </CleeckyKit.Section>
  );
};

export default ChangelogSection;

import api from "axiosConfig";
import {
  CreateCheckoutResponse,
  StripePlan,
  StripeProduct,
  StripeSubscription,
} from "cleecky@types/interfaces";

class PaymentService {
  async getProducts(): Promise<StripeProduct[]> {
    const response = await api.get<StripeProduct[]>(
      "/management/stripe/products"
    );
    return response.data;
  }

  async getPlans(): Promise<StripePlan[]> {
    const response = await api.get<StripePlan[]>("/management/stripe/plans");
    return response.data;
  }

  async createSubscription(planId: string): Promise<CreateCheckoutResponse> {
    const response = await api.post<CreateCheckoutResponse>(
      "/payment/subscriptions",
      {
        planId,
      }
    );
    return response.data;
  }

  async getActiveSubscription(): Promise<StripeSubscription | null> {
    const response = await api.get<StripeSubscription>(
      "/payment/subscriptions/active"
    );
    return response.data;
  }

  async getUserSubscriptions(): Promise<StripeSubscription[]> {
    const response = await api.get<StripeSubscription[]>(
      "/payment/subscriptions"
    );
    return response.data;
  }

  async createBillingPortalSession(): Promise<{ url: string }> {
    const response = await api.post<{ url: string }>("/payment/billing-portal");
    return response.data;
  }

  async cancelSubscription(subscriptionId: string): Promise<void> {
    await api.post(`/payment/subscriptions/${subscriptionId}/cancel`);
  }
}

export const paymentService = new PaymentService();

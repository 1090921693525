import React, { ReactNode } from "react";
import styled from "styled-components";

interface TableCell {
  content: ReactNode;
  colSpan?: number;
  rowSpan?: number;
  textAlign?: string;
  verticalAlign?: string;
}

interface TableHeader extends TableCell {
  children?: TableHeader[];
  color?: string;
  isVertical?: boolean;
}

interface TableRow {
  headers?: TableHeader[];
  cells?: TableCell[];
  children?: TableRow[];
}

interface TableData {
  headers: TableHeader[];
  rows: TableRow[];
}

interface CleeckyKitTableProps {
  data?: TableData;
  children?: ReactNode;
  freeTitle?: string;
  paidTitle?: string;
}

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  background: ${({ theme }) => theme.colors.background.card};
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.colors.background.card};
`;

const validateAlign = (
  align: string | undefined,
  defaultValue: string
): string => {
  if (!align) return defaultValue;

  switch (align.toLowerCase()) {
    case "left":
    case "center":
    case "right":
      return align.toLowerCase();
    default:
      return defaultValue;
  }
};

const validateVerticalAlign = (
  align: string | undefined,
  defaultValue: string
): string => {
  if (!align) return defaultValue;

  switch (align.toLowerCase()) {
    case "top":
    case "middle":
    case "bottom":
      return align.toLowerCase();
    default:
      return defaultValue;
  }
};

const Th = styled.th<{
  $backgroundColor?: string;
  $isVertical?: boolean;
  $textAlign?: string;
  $verticalAlign?: string;
}>`
  padding: 1rem;
  text-align: ${({ $textAlign, $isVertical }) =>
    $isVertical ? "center" : validateAlign($textAlign, "left")};
  vertical-align: ${({ $verticalAlign }) =>
    validateVerticalAlign($verticalAlign, "middle")};
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor || theme.colors.interactive.primary};
  color: ${({ theme }) => theme.colors.text.onPrimary};
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  ${({ $isVertical }) =>
    $isVertical &&
    `
    writing-mode: vertical-lr;
    text-orientation: sideways;
    transform: rotate(180deg);
    text-align: center;
    padding: 2rem 0.5rem;
    -webkit-writing-mode: vertical-lr;
    -webkit-text-orientation: sideways;
    `}
`;

const Td = styled.td<{
  textAlign?: string;
  verticalAlign?: string;
}>`
  padding: 1rem;
  text-align: ${({ textAlign }) => validateAlign(textAlign, "left")};
  vertical-align: ${({ verticalAlign }) =>
    validateVerticalAlign(verticalAlign, "middle")};
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.card};
`;

const renderHeaders = (headers: TableHeader[]) => {
  return headers.map((header, index) => (
    <Th
      key={index}
      colSpan={header.colSpan}
      rowSpan={header.rowSpan}
      $backgroundColor={header.color}
      $isVertical={header.isVertical}
      $textAlign={header.textAlign}
      $verticalAlign={header.verticalAlign}>
      {header.content}
    </Th>
  ));
};

const renderRows = (rows: TableRow[]) => {
  return rows.map((row, rowIndex) => (
    <React.Fragment key={rowIndex}>
      <tr>
        {row.headers?.map((header, headerIndex) => (
          <Th
            key={headerIndex}
            colSpan={header.colSpan}
            rowSpan={header.rowSpan}
            $backgroundColor={header.color}
            $isVertical={header.isVertical}
            $textAlign={header.textAlign}
            $verticalAlign={header.verticalAlign}>
            {header.content}
          </Th>
        ))}
        {row.cells?.map((cell, cellIndex) => (
          <Td
            key={cellIndex}
            colSpan={cell.colSpan}
            rowSpan={cell.rowSpan}
            textAlign={cell.textAlign}
            verticalAlign={cell.verticalAlign}>
            {cell.content}
          </Td>
        ))}
      </tr>
      {row.children && renderRows(row.children)}
    </React.Fragment>
  ));
};

export const CleeckyKitTable: React.FC<CleeckyKitTableProps> = ({
  data,
  children,
  freeTitle,
  paidTitle,
}) => {
  if (data) {
    return (
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>{renderHeaders(data.headers)}</tr>
          </thead>
          <tbody>{renderRows(data.rows)}</tbody>
        </StyledTable>
      </TableContainer>
    );
  }

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <Th>Recursos</Th>
            <Th>{freeTitle}</Th>
            <Th>{paidTitle}</Th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </StyledTable>
    </TableContainer>
  );
};

// Componentes auxiliares para uso declarativo
export const CleeckyKitTableHeader = styled.th<{ backgroundColor?: string }>`
  padding: 1rem;
  text-align: left;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.interactive.primary};
  color: ${({ theme }) => theme.colors.text.onPrimary};
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
`;

export const CleeckyKitTableCell = styled.td`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.card};
`;

export const CleeckyKitTableRow = styled.tr``;

export const CleeckyKitTableHead = styled.thead``;

export const CleeckyKitTableBody = styled.tbody``;

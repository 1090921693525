import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";
import Flag from "react-world-flags";
import logo from "../../../assets/images/icon-minimalist-black-1.svg";

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <CleeckyKit.Row justify="center">
      <CleeckyKit.Section
        background={`${currentTheme.colors.background.navbar.background}`}>
        <CleeckyKit.Column gap="1rem" padding="4rem 1rem">
          <CleeckyKit.Row justify="between">
            <CleeckyKit.Column xs={12} md={6}>
              <CleeckyKit.Title level={6}>CLEECKY</CleeckyKit.Title>
              <CleeckyKit.Text customStyle={{ fontSize: "0.8rem" }}>
                "For in this hope we were saved. But hope that is seen is no
                hope at all. Who hopes for what they already have? But if we
                hope for what we do not yet have, we wait for it patiently." -
                Romans 8:24-25
              </CleeckyKit.Text>
            </CleeckyKit.Column>

            <CleeckyKit.Column xs={12} md={4}>
              <CleeckyKit.Title level={6} align="left">
                LINKS
              </CleeckyKit.Title>
              <CleeckyKit.Text href="/about" target="_self">
                {t("project:pages.about.title")}
              </CleeckyKit.Text>
              <CleeckyKit.Text href="/contacts" target="_self">
                {t("project:pages.contacts.title")}
              </CleeckyKit.Text>
              <CleeckyKit.Text href="/changelog" target="_self">
                {t("project:pages.changelog.label")}
              </CleeckyKit.Text>
              <CleeckyKit.Text href="/terms-and-privacy" target="_self">
                {t("project:pages.terms_and_privacy.title")}
              </CleeckyKit.Text>
              {/*  <CleeckyKit.Text href="/licenses" target="_self">
                {t("project:pages.licenses.title")}
              </CleeckyKit.Text> */}
            </CleeckyKit.Column>
          </CleeckyKit.Row>
          <CleeckyKit.Divider />
          <CleeckyKit.Row align="center" justify="around" noBreak>
            <CleeckyKit.Image
              src={logo}
              height="3rem"
              fit="contain"
              alt="Cleecky Logo"
              format="svg"
              fill={`${currentTheme.colors.text.primary}`}
            />
            <CleeckyKit.Text
              align="center"
              customStyle={{
                fontSize: "0.8rem",
              }}>
              @2025 Cleecky | All rights reserved.
            </CleeckyKit.Text>
            <CleeckyKit.Column width="2.5rem" align="center" justify="center">
              <Flag code="BR" height="100%" width="100%" />
            </CleeckyKit.Column>
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Section>
    </CleeckyKit.Row>
  );
};

export default Footer;

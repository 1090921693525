import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLink,
  faCog,
  faArrowLeft,
  faArrowPointer,
  faBan,
  faPlus,
  faUsers,
  faTrash,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import * as CleeckyKit from "CleeckyKit";
import styled from "styled-components";
import { t } from "i18next";
import { aggregatorService, toolLimitsService } from "services";
import {
  AggregatorPageData,
  AggregatorPageVisibilityType,
  ToolType,
} from "cleecky@types";
import PageTitleModal from "./components/PageTitleModal";
import LimitModal from "./components/LimitModal";

const PageStats = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.875rem;
`;

const StatusTag = styled.span<{
  $status: "PUBLIC" | "PRIVATE" | "UNPUBLISHED";
}>`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  background: ${({ theme, $status }) =>
    $status === "PUBLIC"
      ? theme.colors.status.success
      : $status === "PRIVATE"
      ? theme.colors.status.error
      : theme.colors.status.warning};
  color: ${({ theme }) => theme.colors.text.onPrimary};
`;

const AggregatorPageList: React.FC = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState<AggregatorPageData[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();
  const [selectedPage, setSelectedPage] = useState<AggregatorPageData | null>(
    null
  );
  const [showManageModal, setShowManageModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showPageTitleModal, setShowPageTitleModal] = useState(false);
  const [pageTitleModalMode, setPageTitleModalMode] = useState<
    "create" | "rename"
  >("create");
  const [pageStats, setPageStats] = useState<Record<string, number>>({});
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [toolLimits, setToolLimits] = useState<{
    pagesLimit: number;
    pagesCreated: number;
  } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [response, limits] = await Promise.all([
          aggregatorService.getAll(),
          toolLimitsService.getToolLimits(ToolType.LINK_AGGREGATOR),
        ]);

        setPages(response);
        setToolLimits(limits);

        // Busca as métricas de cada página
        const stats = await aggregatorService.getPageStats("month");
        const pageStatsMap = stats.pageStats.reduce((acc, stat) => {
          acc[stat.uuid] = stat.totalVisits;
          return acc;
        }, {} as Record<string, number>);
        setPageStats(pageStatsMap);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getPageStats = (page: AggregatorPageData) => {
    return pageStats[page.uuid] || 0;
  };

  const handleVisibilityChange = async (
    page: AggregatorPageData,
    visibility: AggregatorPageVisibilityType
  ) => {
    try {
      const updatedPage = await aggregatorService.toggleVisibility(page.uuid, {
        visibility,
      });
      setPages((prevPages) =>
        prevPages.map((p) => (p.uuid === page.uuid ? updatedPage : p))
      );
      setSelectedPage(updatedPage);
    } catch (error) {
      console.error("Erro ao alterar visibilidade:", error);
    }
  };

  const handleDeletePage = async () => {
    if (!selectedPage) return;

    try {
      await aggregatorService.delete(selectedPage.uuid);
      setPages((prevPages) =>
        prevPages.filter((page) => page.uuid !== selectedPage.uuid)
      );
      setShowDeleteConfirmation(false);
      setShowManageModal(false);
      setSelectedPage(null);
    } catch (error) {
      console.error("Erro ao excluir página:", error);
    }
  };

  const handlePageRenamed = (updatedPage: AggregatorPageData) => {
    setPages((prevPages) =>
      prevPages.map((p) => (p.uuid === updatedPage.uuid ? updatedPage : p))
    );
  };

  const handleCreatePage = () => {
    if (!toolLimits) return;

    if (toolLimits.pagesCreated >= toolLimits.pagesLimit) {
      setShowLimitModal(true);
    } else {
      setPageTitleModalMode("create");
      setShowPageTitleModal(true);
    }
  };

  if (!currentTheme) {
    return null;
  }

  return (
    <>
      <CleeckyKit.Column gap="1rem">
        <CleeckyKit.Row justify="between" noBreak>
          <CleeckyKit.Button
            text={t("cleeckykit:common.actions.back")}
            textPosition="inside-right"
            variant="solid"
            size="small"
            onClick={() => navigate("/toolbox/link-aggregator")}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
          />
          <CleeckyKit.Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            type="button"
            text={t(
              "project:apps.toolbox.tools.link_aggregator.pageList.create_new_page"
            )}
            textPosition="inside-right"
            variant="solid"
            size="small"
            onClick={handleCreatePage}
            highlighted
          />
        </CleeckyKit.Row>

        <CleeckyKit.Row justify="between" align="center">
          <CleeckyKit.Title level={2}>
            {t("project:apps.toolbox.tools.link_aggregator.pageList.title")}
          </CleeckyKit.Title>
          {toolLimits && (
            <CleeckyKit.Text>
              {t(
                "project:apps.toolbox.tools.link_aggregator.pageList.pages_limit",
                {
                  current: toolLimits.pagesCreated,
                  limit: toolLimits.pagesLimit,
                }
              )}
            </CleeckyKit.Text>
          )}
        </CleeckyKit.Row>

        <CleeckyKit.Row>
          <CleeckyKit.List hoverable={false}>
            {loading ? (
              <CleeckyKit.Skeleton
                width="100%"
                height="10rem"
                animation="wave"
                backgroundColor={currentTheme?.colors.background.content}
                highlightColor={currentTheme?.colors.interactive.hover}
              />
            ) : pages.length === 0 ? (
              <CleeckyKit.Card
                backgroundColor="transparent"
                border={`2px solid ${currentTheme?.colors.background.card}`}>
                <CleeckyKit.Column>
                  <CleeckyKit.Row justify="center" align="center">
                    <CleeckyKit.Title align="center" level={4}>
                      <FontAwesomeIcon icon={faBan} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>
                  <CleeckyKit.Row justify="center" align="center">
                    <CleeckyKit.Title align="center" level={5}>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.pageList.empty_state"
                      )}
                    </CleeckyKit.Title>
                  </CleeckyKit.Row>
                </CleeckyKit.Column>
              </CleeckyKit.Card>
            ) : (
              pages.map((page) => (
                <CleeckyKit.ListItem key={page.uuid}>
                  <CleeckyKit.Row align="center" gap="1rem" height="100%">
                    {page.thumbnail ? (
                      <CleeckyKit.Image
                        src={page.thumbnail.url}
                        alt={`Preview of ${page.title}`}
                        width="100%"
                        height="100%"
                        borderRadius="0.5rem"
                        fit="contain"
                        sourceType="external"
                        href={`${window.location.origin}/@${page.user.username}/${page.slug}`}
                        target="_blank"
                      />
                    ) : (
                      <CleeckyKit.Skeleton
                        width="100%"
                        height="100%"
                        animation="wave"
                        backgroundColor={
                          currentTheme?.colors.background.content
                        }
                        highlightColor={currentTheme?.colors.interactive.hover}
                      />
                    )}

                    <CleeckyKit.Column justify="between" gap="1rem">
                      <CleeckyKit.Section>
                        <CleeckyKit.Title level={4}>
                          {page.title}
                        </CleeckyKit.Title>
                        <CleeckyKit.Text
                          href={`${window.location.origin}/@${page.user.username}/${page.slug}`}
                          target="_blank"
                          customStyle={{
                            fontSize: "0.875rem",
                          }}>
                          {`@${page.user.username}/${page.slug}`}
                        </CleeckyKit.Text>
                      </CleeckyKit.Section>
                      <CleeckyKit.Section>
                        <CleeckyKit.Row justify="between" noBreak>
                          <StatusTag $status={page.visibility}>
                            {t(
                              `project:apps.toolbox.tools.link_aggregator.pageList.status.${page.visibility.toLowerCase()}`
                            )}
                          </StatusTag>
                          <PageStats>
                            <FontAwesomeIcon icon={faArrowPointer} />{" "}
                            {getPageStats(page)}{" "}
                            {t(
                              "project:apps.toolbox.tools.link_aggregator.pageList.current_month"
                            )}
                          </PageStats>
                        </CleeckyKit.Row>
                      </CleeckyKit.Section>
                    </CleeckyKit.Column>

                    <CleeckyKit.Column justify="center">
                      <CleeckyKit.Row justify="center">
                        <CleeckyKit.Title level={6}>
                          {t("cleeckykit:common.actions.actions")}
                        </CleeckyKit.Title>
                      </CleeckyKit.Row>
                      <CleeckyKit.Row justify="center" noBreak>
                        <div
                          style={{
                            display: "flex",
                            gap: "0.5rem",
                            minWidth: "fit-content",
                            padding: "0.5rem",
                          }}>
                          <CleeckyKit.Button
                            size="small"
                            text={t("cleeckykit:common.actions.edit")}
                            textPosition="inside-right"
                            variant="solid"
                            type="button"
                            icon={<FontAwesomeIcon icon={faEdit} />}
                            onClick={() =>
                              navigate(
                                `/toolbox/link-aggregator/pages/${page.uuid}/edit`
                              )
                            }
                          />
                          <CleeckyKit.Button
                            size="small"
                            text={t("cleeckykit:common.actions.share")}
                            textPosition="inside-right"
                            variant="solid"
                            type="button"
                            icon={<FontAwesomeIcon icon={faLink} />}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${window.location.origin}/@${page.user.username}/${page.slug}`
                              );
                            }}
                          />
                          <CleeckyKit.Button
                            size="small"
                            text={t("cleeckykit:common.actions.manage")}
                            textPosition="inside-right"
                            variant="solid"
                            type="button"
                            icon={<FontAwesomeIcon icon={faCog} />}
                            onClick={() => {
                              setSelectedPage(page);
                              setShowManageModal(true);
                            }}
                          />
                        </div>
                      </CleeckyKit.Row>
                    </CleeckyKit.Column>
                  </CleeckyKit.Row>
                </CleeckyKit.ListItem>
              ))
            )}
          </CleeckyKit.List>
        </CleeckyKit.Row>
      </CleeckyKit.Column>
      <CleeckyKit.Modal
        isOpen={showManageModal}
        onClose={() => {
          setShowManageModal(false);
          setSelectedPage(null);
        }}
        title={t(
          "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.title"
        )}
        size="medium">
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Section>
            <CleeckyKit.Row>
              <CleeckyKit.Title level={6}>
                {t(
                  "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.visibility.label"
                )}
              </CleeckyKit.Title>
            </CleeckyKit.Row>

            <CleeckyKit.Row justify="start">
              <CleeckyKit.ButtonGroup
                options={[
                  {
                    value: "PUBLIC",
                    label: t(
                      "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.visibility.status.public.label"
                    ),
                  },
                  {
                    value: "UNPUBLISHED",
                    label: t(
                      "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.visibility.status.unpublished.label"
                    ),
                  },
                  {
                    value: "PRIVATE",
                    label: t(
                      "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.visibility.status.private.label"
                    ),
                  },
                ]}
                value={selectedPage?.visibility || "PUBLIC"}
                onChange={(newVisibility) =>
                  selectedPage &&
                  handleVisibilityChange(
                    selectedPage,
                    newVisibility as AggregatorPageVisibilityType
                  )
                }
              />
            </CleeckyKit.Row>

            <CleeckyKit.Row justify="start">
              <CleeckyKit.Text
                customStyle={{ color: "text.secondary", fontSize: "0.875rem" }}>
                {t(
                  `project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.visibility.status.${(
                    selectedPage?.visibility || "PUBLIC"
                  ).toLowerCase()}.description`
                )}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          </CleeckyKit.Section>

          <CleeckyKit.Row justify="start" gap="1rem">
            <CleeckyKit.Button
              text={t(
                "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.delete_page"
              )}
              textPosition="inside-right"
              icon={<FontAwesomeIcon icon={faTrash} />}
              variant="outline"
              highlighted
              size="small"
              onClick={() => setShowDeleteConfirmation(true)}
            />
            <CleeckyKit.Button
              text={t(
                "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.rename_page"
              )}
              textPosition="inside-right"
              icon={<FontAwesomeIcon icon={faEdit} />}
              variant="outline"
              highlighted
              size="small"
              onClick={() => {
                setPageTitleModalMode("rename");
                setShowPageTitleModal(true);
              }}
            />
            {selectedPage?.visibility === "PRIVATE" && (
              <CleeckyKit.Button
                text={t(
                  "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.manage_access"
                )}
                icon={<FontAwesomeIcon icon={faUsers} />}
                variant="solid"
                textPosition="inside-right"
                size="small"
                highlighted
                onClick={() => {
                  navigate(
                    `/toolbox/link-aggregator/pages/${selectedPage.uuid}/access-management`
                  );
                  setShowManageModal(false);
                }}
              />
            )}
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Modal>

      <CleeckyKit.Modal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        title={t(
          "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.delete_confirmation.title"
        )}
        size="small">
        <CleeckyKit.Column gap="2rem">
          <CleeckyKit.Text>
            {t(
              "project:apps.toolbox.tools.link_aggregator.pageList.manage_modal.delete_confirmation.message"
            )}
          </CleeckyKit.Text>
          <CleeckyKit.Row justify="end">
            <CleeckyKit.Button
              text={t("cleeckykit:common.actions.confirm")}
              highlighted
              variant="link"
              size="small"
              onClick={handleDeletePage}
            />
            <CleeckyKit.Button
              text={t("cleeckykit:common.actions.cancel")}
              variant="solid"
              highlighted
              size="small"
              onClick={() => setShowDeleteConfirmation(false)}
            />
          </CleeckyKit.Row>
        </CleeckyKit.Column>
      </CleeckyKit.Modal>

      <PageTitleModal
        isOpen={showPageTitleModal}
        onClose={() => setShowPageTitleModal(false)}
        mode={pageTitleModalMode}
        page={selectedPage || undefined}
        onSuccess={handlePageRenamed}
      />

      <LimitModal
        isOpen={showLimitModal}
        onClose={() => setShowLimitModal(false)}
      />
    </>
  );
};

export default AggregatorPageList;

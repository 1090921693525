export const Z_INDEX = {
  modal: {
    content: 1002,
    overlay: 1001,
  },
  navbar: {
    container: 1000,
  },
  sidebar: {
    container: 999,
    hamburger: 1001,
  },
  buttons: {
    fixed: 50,
    switchers: {
      container: 102,
      themeSwitcher: 103,
      languageSwitcher: 101,
    },
    dropdown: {
      menu: 9999,
    },
    pickers: {
      popover: 1002,
    },
  },
  tooltip: {
    container: 9999,
  },
  default: 1,
  baseBackground: -1,
};

import React from "react";
import { CleeckyKitBaseInput, BaseInputProps } from "./CleeckyKitBaseInput";
import { useTranslation } from "react-i18next";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface DatePickerProps extends Omit<BaseInputProps, "type"> {
  minDate?: string;
  maxDate?: string;
}

export const CleeckyKitDatePicker: React.FC<DatePickerProps> = ({
  minDate,
  maxDate,
  validations,
  ...props
}) => {
  const { t } = useTranslation();

  const defaultValidations = {
    ...validations,
    validate: (value: string) => {
      if (!value) return true;

      const date = new Date(value);

      if (isNaN(date.getTime())) {
        return t("cleeckykit:common.form.validations.invalidDate");
      }

      if (minDate && date < new Date(minDate)) {
        return t("cleeckykit:common.form.validations.dateBeforeMin");
      }

      if (maxDate && date > new Date(maxDate)) {
        return t("cleeckykit:common.form.validations.dateAfterMax");
      }

      if (validations?.validate) {
        return validations.validate(value);
      }

      return true;
    },
  };

  return (
    <CleeckyKitBaseInput
      type="date"
      min={minDate}
      max={maxDate}
      validations={defaultValidations}
      prefix={<FontAwesomeIcon icon={faCalendar} />}
      {...props}
    />
  );
};

import React from "react";
import styled from "styled-components";
import { defaultResponsiveConfig } from "../../../configs/CleeckyKitResponsiveConfig";

export interface ButtonGroupOption {
  value: string;
  label: string;
}

interface CleeckyKitButtonGroupProps {
  options: ButtonGroupOption[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
  label?: string | "none";
  required?: boolean;
}

const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    text-align: left;
  }
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;

  & > button {
    flex-grow: 0;
    white-space: nowrap;
  }
`;

const Button = styled.button<{ $isSelected: boolean }>`
  padding: 8px 16px;
  border: 1px solid transparent;
  background: ${({ $isSelected, theme }) =>
    $isSelected
      ? theme.gradient?.highlightColor || theme.colors.interactive.primary
      : theme.colors.selection.background};
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.selection.text : theme.colors.text.primary};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 8px;

  &:hover {
    background: ${({ $isSelected, theme }) =>
      $isSelected
        ? theme.gradient?.highlightColor ||
          theme.colors.interactive.primaryHover
        : theme.colors.interactive.hover};
  }

  &:focus {
    outline: none;
    border: ${({ $isSelected, theme }) =>
      $isSelected
        ? `1px solid ${
            theme.gradient?.highlightColor || theme.colors.interactive.primary
          }`
        : `1px solid ${theme.colors.interactive.hover}`};
    position: relative;
    z-index: 1;
  }

  @media (max-width: ${defaultResponsiveConfig.breakpoints.sm}) {
    font-size: ${defaultResponsiveConfig.fontSize.mobile};
    padding: 6px 12px;
  }
`;

export const CleeckyKitButtonGroup: React.FC<CleeckyKitButtonGroupProps> = ({
  options,
  value,
  onChange,
  className,
  label,
  required,
}) => {
  return (
    <ButtonGroupWrapper className={className}>
      {label && label !== "none" && (
        <Label>
          {label}{" "}
          {required && (
            <span style={{ color: "#ff3030", fontSize: "1.1rem" }}>*</span>
          )}
        </Label>
      )}
      <ButtonGroupContainer>
        {options.map((option) => (
          <Button
            key={option.value}
            $isSelected={value === option.value}
            onClick={() => onChange(option.value)}
            type="button"
            role="button"
            aria-pressed={value === option.value}>
            {option.label}
          </Button>
        ))}
      </ButtonGroupContainer>
    </ButtonGroupWrapper>
  );
};

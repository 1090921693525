import React, { useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { CleeckyKitImage } from "../../Image/CleeckyKitImage";

interface CardImageProps {
  src: string;
  alt: string;
  position?: "top" | "bottom" | "left" | "right" | "background";
  height?: string;
  width?: string;
  fit?: "cover" | "contain" | "fill" | "none" | "scale-down";
}

interface CardProps {
  children?: React.ReactNode;
  image?: CardImageProps;
  divided?: boolean;
  hoverable?: boolean;
  className?: string;
  onClick?: () => void;
  padding?: string;
  borderRadius?: string | number;
  elevation?: number;
  backgroundColor?: string;
  border?: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  background?: string;
  margin?: string;
  shadow?: boolean;
  tabs?: {
    key: string;
    label: string;
    content: React.ReactNode;
  }[];
}

const CardContainer = styled(motion.div)<{
  $hasImage?: boolean;
  $imagePosition?: string;
  $borderRadius?: string | number;
  $elevation?: number;
  $hoverable?: boolean;
  $elevated?: boolean;
  $backgroundColor?: string;
  $border?: string;
  $width?: string;
  $height?: string;
  $minWidth?: string;
  $minHeight?: string;
  $maxWidth?: string;
  $maxHeight?: string;
  $background?: string;
  $margin?: string;
  $shadow?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: ${({ $imagePosition }) =>
    $imagePosition === "left" || $imagePosition === "right" ? "row" : "column"};
  background: ${({ theme, $background, $backgroundColor }) =>
    $background || $backgroundColor || theme.colors.background.card};
  border: ${({ $border }) => $border || "none"};
  border-radius: ${({ $borderRadius }) =>
    typeof $borderRadius === "number"
      ? `${$borderRadius}px`
      : $borderRadius || "8px"};
  box-shadow: ${({ $shadow, theme }) =>
    $shadow ? theme.shadows.card.default : "none"};
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  width: ${({ $width }) => $width || "auto"};
  height: ${({ $height }) => $height || "auto"};
  min-width: ${({ $minWidth }) => $minWidth || "auto"};
  min-height: ${({ $minHeight }) => $minHeight || "auto"};
  max-width: ${({ $maxWidth }) => $maxWidth || "none"};
  max-height: ${({ $maxHeight }) => $maxHeight || "none"};
  margin: ${({ $margin }) => $margin || "0"};

  ${({ $hoverable }) =>
    $hoverable &&
    css`
      &:hover {
        box-shadow: ${({ theme }) => theme.shadows.card.hover};
      }
    `}

  ${({ $elevated }) =>
    $elevated &&
    css`
      box-shadow: ${({ theme }) => theme.shadows.card.elevated};
    `}
`;

const ImageWrapper = styled.div<{
  $position?: string;
  $height?: string;
  $width?: string;
}>`
  position: ${({ $position }) =>
    $position === "background" ? "absolute" : "relative"};
  height: ${({ $height, $position }) =>
    $position === "background" ? "100%" : $height || "200px"};
  width: ${({ $width, $position }) =>
    $position === "background"
      ? "100%"
      : $position === "left" || $position === "right"
      ? $width || "40%"
      : "100%"};
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 0;
`;

const Content = styled.div<{
  $hasBackgroundImage?: boolean;
  $divided?: boolean;
  $padding?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ $padding }) => $padding || "1rem"};
  z-index: 1;
  color: ${({ theme }) => theme.colors.text.primary};
  min-height: ${({ $hasBackgroundImage }) =>
    $hasBackgroundImage ? "300px" : "auto"};
  height: 100%;
  overflow: visible;

  ${({ $hasBackgroundImage, theme }) =>
    $hasBackgroundImage &&
    css`
      background: linear-gradient(
        to top,
        ${theme.colors.background.cardOverlay} 0%,
        transparent 100%
      );
      color: ${theme.colors.text.onGradient};
    `}

  ${({ $divided, theme }) =>
    $divided &&
    css`
      & > *:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.border.card};
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
      }
    `}
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  background: ${({ theme }) => theme.colors.background.card};
`;

const TabButton = styled.button<{ $isActive: boolean }>`
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.text.primary : theme.colors.text.secondary};
  cursor: pointer;
  font-weight: ${({ $isActive }) => ($isActive ? "600" : "400")};
  position: relative;
  transition: all 0.2s ease;

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background: ${({ theme, $isActive }) =>
      $isActive
        ? theme.gradient?.highlightColor || theme.colors.interactive.primary
        : "transparent"};
    transform: scaleX(${({ $isActive }) => ($isActive ? 1 : 0)});
    transition: transform 0.2s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};

    &::after {
      transform: scaleX(1);
      background: ${({ theme }) =>
        theme.gradient?.highlightColor || theme.colors.interactive.primary}40;
    }
  }
`;

export const CleeckyKitCard: React.FC<CardProps> = ({
  children,
  image,
  divided,
  hoverable,
  className,
  onClick,
  padding,
  borderRadius,
  elevation,
  backgroundColor,
  border,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  background,
  margin,
  shadow = true,
  tabs,
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabs?.[0]?.key || "");

  const renderImage = () => {
    if (!image) return null;

    return (
      <ImageWrapper
        $position={image.position}
        $height={image.height}
        $width={image.width}>
        <CleeckyKitImage
          src={image.src}
          alt={image.alt}
          fit={image.fit || "cover"}
          width="100%"
          height="100%"
        />
      </ImageWrapper>
    );
  };

  return (
    <CardContainer
      $hasImage={!!image}
      $imagePosition={image?.position}
      $borderRadius={borderRadius}
      $elevation={elevation}
      $hoverable={hoverable}
      $elevated={!!elevation}
      $backgroundColor={backgroundColor}
      $border={border}
      $width={width}
      $height={height}
      $minWidth={minWidth}
      $minHeight={minHeight}
      $maxWidth={maxWidth}
      $maxHeight={maxHeight}
      $background={background}
      $margin={margin}
      $shadow={shadow}
      className={className}
      onClick={onClick}
      whileHover={hoverable ? { scale: 1.02 } : undefined}
      whileTap={hoverable ? { scale: 0.98 } : undefined}>
      {image?.position === "top" && renderImage()}
      {image?.position === "left" && renderImage()}
      {image?.position === "background" && renderImage()}
      <Content
        $hasBackgroundImage={image?.position === "background"}
        $divided={divided}
        $padding={padding}>
        {tabs && (
          <TabsContainer>
            {tabs.map((tab) => (
              <TabButton
                key={tab.key}
                $isActive={activeTab === tab.key}
                onClick={() => setActiveTab(tab.key)}>
                {tab.label}
              </TabButton>
            ))}
          </TabsContainer>
        )}
        {tabs ? tabs.find((tab) => tab.key === activeTab)?.content : children}
      </Content>
      {image?.position === "right" && renderImage()}
      {image?.position === "bottom" && renderImage()}
    </CardContainer>
  );
};

CleeckyKitCard.displayName = "CleeckyKitCard";

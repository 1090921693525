import React from "react";
import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";

type AnimationTrigger = "hover" | "click" | "both";

type PredefinedAnimationType =
  | "pulse"
  | "shake"
  | "bounce"
  | "tilt"
  | "scale"
  | "slide"
  | "none";

type AnimationType = PredefinedAnimationType | "custom";

interface CustomAnimation {
  initial?: any;
  animate?: any;
  whileHover?: any;
  whileTap?: any;
  transition?: any;
}

interface ButtonAnimation {
  type: AnimationType;
  trigger: AnimationTrigger;
  custom?: CustomAnimation;
}

// Adicione estes tipos antes da interface CleeckyKitButtonProps
type ButtonFormat =
  | "auto"
  | "square"
  | "circle"
  | { width?: string; height?: string };

type AnimationConfig = ButtonAnimation | PredefinedAnimationType;

interface CleeckyKitButtonProps {
  // Funcionalidade
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  isDisabled?: boolean;

  // Conteúdo
  text?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right" | "top";
  tooltip?: string;
  suffix?: React.ReactNode;

  // Estilo
  variant?: "solid" | "outline" | "link" | "icon";
  size?: "xsmall" | "small" | "medium" | "large";
  textPosition?:
    | "inside-below"
    | "inside-right"
    | "outside-right"
    | "outside-below";
  customStyle?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
  className?: string;
  borderRadius?: boolean | number | string;
  highlighted?: boolean;

  // Outros
  children?: React.ReactNode;
  "aria-label"?: string;
  iconBackground?: boolean;

  animation?: AnimationConfig;

  format?: ButtonFormat;
  fullWidth?: boolean;
  isActive?: boolean;
}

const ContentLayer = styled.div<{
  $variant: string;
  $size: string;
  $iconBackground?: boolean;
  $borderRadius?: boolean | number | string;
  $format?: ButtonFormat;
  $hasText: boolean;
  $fullWidth?: boolean;
  $isActive?: boolean;
  $highlighted?: boolean;
  style?: React.CSSProperties;
  $hoverStyle?: React.CSSProperties;
  dataHoverOverride?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: inherit;
  transform-origin: center;
  will-change: transform;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};

  ${({ $format, $hasText, $size }) => {
    if (!$format || $format === "auto") {
      return "";
    }

    if ($format === "square") {
      const size = {
        xsmall: "32px",
        small: "40px",
        medium: "48px",
        large: "56px",
      }[$size];

      return css`
        width: ${$hasText ? "auto" : size};
        height: ${size};
        min-width: ${size};
      `;
    }

    if ($format === "circle") {
      const size = {
        xsmall: "32px",
        small: "40px",
        medium: "48px",
        large: "56px",
      }[$size];

      return css`
        width: ${$hasText ? "auto" : size};
        height: ${size};
        min-width: ${size};
        border-radius: 50%;
      `;
    }

    if (typeof $format === "object") {
      return css`
        width: ${$format.width || "auto"};
        height: ${$format.height || "auto"};
        min-width: ${$format.width || "auto"};
      `;
    }
  }}

  ${({ $borderRadius }) => {
    if (typeof $borderRadius === "boolean" && $borderRadius) {
      return css`
        border-radius: 9999px;
      `;
    } else if (typeof $borderRadius === "boolean" && !$borderRadius) {
      return css`
        border-radius: none;
      `;
    }
    if (typeof $borderRadius === "number") {
      return css`
        border-radius: ${$borderRadius}px;
      `;
    }
    if (typeof $borderRadius === "string") {
      return css`
        border-radius: ${$borderRadius};
      `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case "xsmall":
        return css`
          padding: 0.25rem 0.5rem;
          font-size: 0.75rem;
        `;
      case "small":
        return css`
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
        `;
      case "medium":
        return css`
          padding: 0.75rem 1.5rem;
          font-size: 1rem;
        `;
      case "large":
        return css`
          padding: 1rem 2rem;
          font-size: 1.125rem;
        `;
    }
  }}

  ${({ style }) =>
    style &&
    css`
      ${Object.entries(style)
        .map(([key, value]) => `${key}: ${value};`)
        .join("\n")}
    `}

  ${({ $variant, theme, $highlighted, $isActive, $iconBackground }) => {
    const { colors } = theme;

    switch ($variant) {
      case "solid":
        return css`
          background: ${$highlighted
            ? theme.gradient?.highlightColor
            : colors.interactive.clickable};
          color: ${colors.text.onGradient};
          border: none;

          &:hover:not([data-hover-override]) {
            background: ${$highlighted
              ? colors.interactive.clickable
              : theme.gradient?.highlightColor || colors.interactive.active};
          }
        `;
      case "outline":
        return css`
          background: ${$isActive
            ? `${theme.gradient?.highlightColor}20`
            : "transparent"};
          color: ${$highlighted
            ? theme.gradient?.highlightColor
            : $isActive
            ? theme.gradient?.highlightColor
            : colors.text.primary};
          border: 2px solid
            ${$highlighted
              ? theme.gradient?.highlightColor
              : $isActive
              ? theme.gradient?.highlightColor
              : colors.interactive.clickable};

          &:hover:not([data-hover-override]) {
            border-color: ${$highlighted
              ? `${theme.gradient?.highlightColor}50` ||
                colors.interactive.clickable
              : theme.gradient?.highlightColor};
            color: ${$highlighted
              ? colors.interactive.clickable
              : theme.gradient?.highlightColor};
            background: ${$highlighted
              ? `${colors.interactive.clickable}20`
              : $isActive
              ? `${theme.gradient?.highlightColor}20`
              : `${theme.gradient?.highlightColor}10`};
          }
        `;
      case "link":
        return css`
          background: transparent;
          color: ${$highlighted
            ? theme.gradient?.highlightColor
            : colors.text.primary};
          border: none;

          &:hover:not([data-hover-override]) {
            color: ${$highlighted
              ? colors.interactive.clickable
              : theme.gradient?.highlightColor};
          }
        `;
      case "icon":
        return css`
          background: ${$iconBackground
            ? $highlighted
              ? `${theme.gradient?.highlightColor}20`
              : colors.background.card
            : "transparent"};
          color: ${$highlighted
            ? theme.gradient?.highlightColor
            : colors.text.primary};
          border: none;
          padding: ${$iconBackground ? "0.5rem" : "0"};

          &:hover:not([data-hover-override]) {
            color: ${$highlighted
              ? colors.interactive.clickable
              : theme.gradient?.highlightColor};
            background: ${$iconBackground
              ? $highlighted
                ? `${colors.interactive.clickable}20`
                : theme.colors.background.hover
              : "transparent"};
          }
        `;
    }
  }}

  /* Aplicando os estilos de hover customizados por último para ter maior prioridade */
  ${({ $hoverStyle }) =>
    $hoverStyle &&
    css`
      &[data-hover-override]:hover {
        ${Object.entries($hoverStyle)
          .map(([key, value]) => `${key}: ${value} !important;`)
          .join("\n")}
      }
    `}

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.interactive.buttonDisabled};
  }

  &:active {
    transform: scale(0.98);
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ButtonContainer = styled.button<{
  $textPosition: string;
  $fullWidth?: boolean;
}>`
  display: inline-flex;
  position: relative;
  align-items: center;
  gap: 8px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  min-width: min-content;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  ${({ $textPosition }) => {
    switch ($textPosition) {
      case "outside-below":
        return css`
          flex-direction: column;
          align-items: center;
        `;
      case "outside-right":
        return css`
          flex-direction: row;
          align-items: center;
          width: auto;
          white-space: nowrap;
        `;
      case "inside-below":
        return css`
          flex-direction: column;
          align-items: center;
        `;
      case "inside-right":
      default:
        return css`
          flex-direction: row;
          align-items: center;
          width: auto;
          white-space: nowrap;
        `;
    }
  }}
`;

const TextLabel = styled.span<{
  $textPosition: string;
  $variant?: string;
}>`
  display: inline-flex;
  white-space: normal;
  word-break: break-word;
  hyphens: none;
  color: ${({ theme }) => theme.colors.text.primary};
  transition: color 0.3s ease;
  min-width: 0;
  flex: 0 1 auto;

  ${({ $textPosition }) => {
    switch ($textPosition) {
      case "outside-below":
        return css`
          margin-top: 4px;
          text-align: center;
        `;
      case "outside-right":
        return css`
          margin-left: 8px;
          text-align: left;
          white-space: nowrap;
          flex-shrink: 0;
        `;
      case "inside-below":
        return css`
          margin-top: 4px;
          text-align: center;
        `;
      case "inside-right":
        return css`
          margin-left: 8px;
          text-align: left;
          white-space: nowrap;
          flex-shrink: 0;
        `;
    }
  }}

  ${({ theme, $variant }) =>
    $variant === "link" &&
    css`
      &:hover {
        color: ${theme.gradient?.highlightColor};
      }
    `}
`;

const AnimationContainer = styled(motion.div)<{
  $fullWidth?: boolean;
}>`
  position: relative;
  display: inline-block;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  transform-origin: 50% 50%;
  will-change: transform;

  /* Importante: isso mantém o botão no fluxo normal do layout */
  & > * {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  }

  /* Previne que as animações afetem o layout */
  &,
  & > * {
    isolation: isolate;
    transform: translateZ(0);
  }
`;

const predefinedAnimations: Record<PredefinedAnimationType, CustomAnimation> = {
  scale: {
    initial: { scale: 1 },
    animate: { scale: 1 },
    whileHover: { scale: 1.05 },
    whileTap: { scale: 0.95 },
    transition: { duration: 0.2 },
  },
  tilt: {
    initial: { rotate: 0 },
    animate: { rotate: 0 },
    whileHover: { rotate: 5 },
    whileTap: { rotate: -5 },
    transition: { duration: 0.2 },
  },
  pulse: {
    initial: { scale: 1 },
    animate: { scale: 1 },
    whileHover: { scale: 1.05 },
    whileTap: { scale: 1 },
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 10,
    },
  },
  shake: {
    initial: { x: 0 },
    animate: { x: 0 },
    whileHover: { x: [-2, 2, -2, 2, 0] },
    whileTap: { x: 0 },
    transition: {
      type: "spring",
      duration: 0.4,
      repeat: Infinity,
      repeatType: "mirror",
    },
  },
  bounce: {
    initial: { y: 0 },
    animate: { y: 0 },
    whileHover: { y: -6 },
    whileTap: { y: 0 },
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 8,
    },
  },
  slide: {
    initial: { x: 0 },
    animate: { x: 0 },
    whileHover: { x: 5 },
    whileTap: { x: 0 },
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 20,
    },
  },
  none: {
    initial: {},
    animate: {},
    whileHover: {},
    whileTap: {},
    transition: {},
  },
};

interface AnimationProps {
  initial: any;
  animate: any;
  whileHover?: any;
  whileTap?: any;
  transition: any;
}

export const CleeckyKitButton: React.FC<CleeckyKitButtonProps> = ({
  onClick,
  href,
  target = "_self",
  type = "button",
  isLoading,
  isDisabled,
  text,
  icon,
  tooltip,
  variant = "solid",
  size = "medium",
  textPosition = "inside-below",
  customStyle,
  hoverStyle,
  className,
  borderRadius = true,
  children,
  "aria-label": ariaLabel,
  iconBackground = false,
  animation = {
    type: "scale",
    trigger: "both",
  },
  format = "auto",
  fullWidth = false,
  suffix,
  isActive,
  highlighted = false,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (isDisabled || isLoading) {
      e.preventDefault();
      return;
    }

    if (href) {
      e.preventDefault();
      if (target === "_blank") {
        window.open(href, "_blank", "noopener,noreferrer");
      } else {
        window.location.href = href;
      }
      return;
    }

    if (onClick && type !== "submit") {
      onClick(e);
    }
  };

  const getAnimationProps = () => {
    if (!animation) return {};

    const animationConfig: ButtonAnimation =
      typeof animation === "string"
        ? { type: animation as PredefinedAnimationType, trigger: "both" }
        : animation;

    if (animationConfig.type === "custom" && animationConfig.custom) {
      const { custom, trigger } = animationConfig;
      return {
        initial: custom.initial,
        animate: custom.animate,
        whileHover:
          trigger === "hover" || trigger === "both"
            ? custom.whileHover
            : undefined,
        whileTap:
          trigger === "click" || trigger === "both"
            ? custom.whileTap
            : undefined,
        transition: custom.transition,
      };
    }

    const predefinedAnimation =
      predefinedAnimations[animationConfig.type as PredefinedAnimationType];
    if (predefinedAnimation) {
      const animationProps: AnimationProps = {
        initial: predefinedAnimation.initial,
        animate: predefinedAnimation.animate,
        transition: predefinedAnimation.transition,
      };

      if (
        animationConfig.trigger === "click" ||
        animationConfig.trigger === "both"
      ) {
        animationProps.whileTap = predefinedAnimation.whileTap;
      }

      if (
        animationConfig.trigger === "hover" ||
        animationConfig.trigger === "both"
      ) {
        animationProps.whileHover = predefinedAnimation.whileHover;
      }

      return animationProps;
    }

    return {};
  };

  const ButtonElement = href ? "a" : "button";

  return (
    <AnimationContainer {...getAnimationProps()} $fullWidth={fullWidth}>
      <ButtonContainer
        as={ButtonElement}
        onClick={handleClick}
        disabled={isDisabled || isLoading}
        type={type}
        $textPosition={textPosition}
        $fullWidth={fullWidth}
        className={className}
        aria-label={ariaLabel}
        title={tooltip}
        target={target}
        href={href}>
        <ContentLayer
          $variant={variant}
          $size={size}
          $iconBackground={iconBackground}
          $borderRadius={borderRadius}
          $format={format}
          $hasText={!!text || !!children}
          $fullWidth={fullWidth}
          $isActive={isActive}
          $highlighted={highlighted}
          style={customStyle}
          $hoverStyle={hoverStyle}
          data-hover-override={!!hoverStyle}>
          {icon && <span className="icon-wrapper">{icon}</span>}
          {text && textPosition.startsWith("inside") && (
            <TextLabel $textPosition={textPosition} $variant={variant}>
              {text}
            </TextLabel>
          )}
          {suffix}
        </ContentLayer>
        {text && textPosition.startsWith("outside") && (
          <TextLabel $textPosition={textPosition} $variant={variant}>
            {text}
          </TextLabel>
        )}
        {children}
      </ButtonContainer>
    </AnimationContainer>
  );
};

import React from "react";
import styled, { css } from "styled-components";

interface CleeckyKitTagProps {
  label: string;
  icon?: React.ReactNode;
  variant:
    | "error"
    | "info"
    | "danger"
    | "warning"
    | "success"
    | "neutral"
    | "highlight"
    | "primary"
    | "secondary";
}

const variantStyles = {
  error: css`
    background-color: #f8d7da;
    color: #721c24;
  `,
  info: css`
    background-color: #d1ecf1;
    color: #0c5460;
  `,
  danger: css`
    background-color: #f5c6cb;
    color: #721c24;
  `,
  warning: css`
    background-color: #fff3cd;
    color: #856404;
  `,
  success: css`
    background-color: #d4edda;
    color: #155724;
  `,
  neutral: css`
    background-color: #e2e3e5;
    color: #383d41;
  `,
  highlight: css`
    background-color: #ffeeba;
    color: #856404;
  `,
  primary: css`
    background-color: #cce5ff;
    color: #004085;
  `,
  secondary: css`
    background-color: #e2e3e5;
    color: #383d41;
  `,
};

const TagContainer = styled.div<{ variant: CleeckyKitTagProps["variant"] }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  ${({ variant }) => variantStyles[variant]}
`;

const IconWrapper = styled.span`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`;

export const CleeckyKitTag: React.FC<CleeckyKitTagProps> = ({
  label,
  icon,
  variant,
}) => {
  return (
    <TagContainer variant={variant}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {label}
    </TagContainer>
  );
};

import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import Flag from "react-world-flags";
import { CleeckyKitTooltip } from "../Elements/Tooltip/CleeckyKitTooltip";
import { CleeckyKitButton } from "../Elements/Button/CleeckyKitButton";
import { CleeckyKitLanguageDropdown } from "./CleeckyKitLanguageDropdown";
import { t } from "i18next";
import { Z_INDEX } from "CleeckyKit/themes/zindex";

interface LanguageConfig {
  languageCode: string;
  name: string;
  countryCode: string;
}

interface CleeckyKitLanguageSwitcherProps {
  languages?: LanguageConfig[];
  position?: {
    bottom?: string | number;
    right?: string | number;
    top?: string | number;
    left?: string | number;
  };
}

const defaultLanguages: LanguageConfig[] = [
  { languageCode: "ptBR", name: "Português (Brasil)", countryCode: "BR" },
  { languageCode: "en", name: "English", countryCode: "US" },
];

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const LanguageSwitcherContainer = styled.div<{
  $position: Required<CleeckyKitLanguageSwitcherProps>["position"];
}>`
  position: fixed;
  bottom: ${({ $position }) => $position.bottom};
  right: ${({ $position }) => $position.right};
  top: ${({ $position }) => $position.top};
  left: ${({ $position }) => $position.left};
  z-index: ${Z_INDEX.buttons.switchers.languageSwitcher};
`;

const FlagContainer = styled.div`
  width: 24px;
  height: 16px;
  overflow: hidden;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DropdownContainer = styled(motion.div)`
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
`;

export const CleeckyKitLanguageSwitcher: React.FC<
  CleeckyKitLanguageSwitcherProps
> = ({
  languages: customLanguages,
  position = { bottom: "20px", right: "80px", top: "auto", left: "auto" },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentLanguage = defaultLanguages.find(
    (lang) => lang.languageCode === i18n.language
  );

  return (
    <Container>
      <LanguageSwitcherContainer $position={position}>
        <div ref={buttonRef}>
          <CleeckyKitTooltip
            content={t("cleeckykit:common.interface.settings.change_language")}
            position="left">
            <CleeckyKitButton
              icon={
                currentLanguage ? (
                  <FlagContainer>
                    <Flag code={currentLanguage.countryCode} />
                  </FlagContainer>
                ) : (
                  <FontAwesomeIcon icon={faLanguage} />
                )
              }
              onClick={() => setIsOpen(!isOpen)}
              variant="icon"
              size="medium"
              format="circle"
              iconBackground
              animation={{
                type: "tilt",
                trigger: "hover",
              }}
              aria-label={t(
                "cleeckykit:common.interface.settings.change_language"
              )}
            />
          </CleeckyKitTooltip>
        </div>

        <AnimatePresence>
          {isOpen && (
            <DropdownContainer
              ref={dropdownRef}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}>
              <CleeckyKitLanguageDropdown
                languages={customLanguages}
                onClose={() => setIsOpen(false)}
              />
            </DropdownContainer>
          )}
        </AnimatePresence>
      </LanguageSwitcherContainer>
    </Container>
  );
};

import React, { useState } from "react";
import * as CleeckyKit from "CleeckyKit";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { aggregatorService } from "services/aggregatorService";
import { AuthModal } from "components/Auth/AuthModal";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const AggregatorAuthPage: React.FC = () => {
  const { currentTheme } = CleeckyKit.useCleeckyKitCurrentTheme();
  const { user } = useAuth();
  const { username, slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pageUuid } = location.state || {};
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authModalMode, setAuthModalMode] = useState<"login" | "register">(
    "login"
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleRequestAccess = async () => {
    if (!user || !pageUuid) return;

    try {
      setIsLoading(true);
      await aggregatorService.requestAccess(pageUuid);

      const cleanUsername = username?.startsWith("@")
        ? username.slice(1)
        : username;

      // Redireciona de volta para a página do agregador
      navigate(`/@${cleanUsername}/${slug}`);
    } catch (error) {
      console.error("Erro ao solicitar acesso:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenAuthModal = (mode: "login" | "register") => {
    setAuthModalMode(mode);
    setShowAuthModal(true);
  };

  return (
    <CleeckyKit.Background
      color={currentTheme.colors.background.base}
      size="fullscreen">
      <CleeckyKit.Column
        height="100dvh"
        justify="center"
        align="center"
        gap="2rem">
        <CleeckyKit.Card padding="2rem">
          <CleeckyKit.Column gap="2rem" align="center">
            <FontAwesomeIcon icon={faLock} size="3x" />

            <CleeckyKit.Title level={4} align="center">
              {t("project:apps.toolbox.tools.link_aggregator.auth_page.title")}
            </CleeckyKit.Title>

            <CleeckyKit.Text align="center">
              {t(
                "project:apps.toolbox.tools.link_aggregator.auth_page.description"
              )}
            </CleeckyKit.Text>

            {user ? (
              <CleeckyKit.Button
                text={t(
                  "project:apps.toolbox.tools.link_aggregator.auth_page.request_access"
                )}
                variant="solid"
                highlighted
                fullWidth
                isLoading={isLoading}
                onClick={handleRequestAccess}
              />
            ) : (
              <CleeckyKit.Column gap="1rem" width="100%">
                <CleeckyKit.Button
                  text={t("cleeckykit:common.actions.login")}
                  variant="solid"
                  highlighted
                  fullWidth
                  onClick={() => handleOpenAuthModal("login")}
                />
                <CleeckyKit.Button
                  text={t("cleeckykit:common.actions.register")}
                  variant="outline"
                  highlighted
                  fullWidth
                  onClick={() => handleOpenAuthModal("register")}
                />
              </CleeckyKit.Column>
            )}
          </CleeckyKit.Column>
        </CleeckyKit.Card>

        <AuthModal
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
          initialMode={authModalMode}
        />
      </CleeckyKit.Column>
    </CleeckyKit.Background>
  );
};

export default AggregatorAuthPage;

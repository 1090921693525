import { CanvasElement, CanvasElementType } from "../components/Canvas/types";
import { createNewElement, updateElementsOrder } from "./canvasElementUtils";

export const handleDragStart = (
  event: React.DragEvent<HTMLDivElement>,
  elementId: string,
  setDraggedId: (id: string) => void,
  setIsDragging: (isDragging: boolean) => void,
  disableScroll: () => void
) => {
  event.dataTransfer.setData("text/plain", elementId);
  event.dataTransfer.setData("isExistingElement", "true");
  setDraggedId(elementId);
  setIsDragging(true);
  disableScroll();
};

export const handleDragEnd = (
  setDraggedId: (id: string | null) => void,
  setIsDragging: (isDragging: boolean) => void,
  setDropTargetId: (id: string | null) => void,
  setDropPosition: (position: "before" | "after" | null) => void,
  enableScroll: () => void
) => {
  setDraggedId(null);
  setIsDragging(false);
  setDropTargetId(null);
  setDropPosition(null);
  enableScroll();
};

export const handleDragOver = (
  event: React.DragEvent<HTMLDivElement>,
  draggedId: string | null,
  setDropTargetId: (id: string | null) => void,
  setDropPosition: (position: "before" | "after" | null) => void
) => {
  event.preventDefault();
  const element = event.currentTarget;
  const rect = element.getBoundingClientRect();
  const midY = rect.top + rect.height / 2;
  const position = event.clientY < midY ? "before" : "after";
  const elementId = element.getAttribute("data-element-id");

  if (elementId && elementId !== draggedId) {
    setDropTargetId(elementId);
    setDropPosition(position);
  }
};

export const handleDragLeave = (
  event: React.DragEvent<HTMLDivElement>,
  dropTargetId: string | null,
  setDropTargetId: (id: string | null) => void,
  setDropPosition: (position: "before" | "after" | null) => void
) => {
  const relatedTarget = event.relatedTarget as HTMLElement;
  if (!relatedTarget?.closest(`[data-element-id="${dropTargetId}"]`)) {
    setDropTargetId(null);
    setDropPosition(null);
  }
};

export const handleToolbarDragStart = (
  event: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  elementType: CanvasElementType,
  elements: CanvasElement[],
  setIsDragging: (isDragging: boolean) => void,
  disableScroll: () => void
) => {
  const newElement = createNewElement(elementType, elements.length);

  if ("dataTransfer" in event) {
    // Mouse drag event
    event.dataTransfer.setData("elementType", elementType);
    event.dataTransfer.setData("newElement", JSON.stringify(newElement));
  } else {
    // Touch event
    const touch = event.touches[0];
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();

    // Criar um elemento fantasma para o touch drag
    const ghost = target.cloneNode(true) as HTMLElement;
    ghost.classList.add("dragging-element");
    ghost.style.position = "fixed";
    ghost.style.left = `${touch.clientX - rect.width / 2}px`;
    ghost.style.top = `${touch.clientY - rect.height / 2}px`;
    ghost.style.pointerEvents = "none";
    ghost.style.zIndex = "9999";
    ghost.style.opacity = "0.8";
    ghost.style.transform = "scale(1.05)";
    document.body.appendChild(ghost);

    // Armazenar informações do elemento sendo arrastado
    (window as any).dragData = {
      elementType,
      newElement,
      ghost,
      offsetX: touch.clientX - rect.left,
      offsetY: touch.clientY - rect.top,
    };
  }

  setIsDragging(true);
  disableScroll();

  // Adiciona a classe dragging ao elemento original
  const originalElement = event.currentTarget;
  originalElement.classList.add("dragging");
};

export const handleCanvasDrop = (
  event: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  elements: CanvasElement[],
  dropTargetId: string | null,
  dropPosition: "before" | "after" | null,
  onElementsChange: (elements: CanvasElement[]) => void,
  setDropTargetId: (id: string | null) => void,
  setDropPosition: (position: "before" | "after" | null) => void,
  setIsDragging: (isDragging: boolean) => void,
  enableScroll: () => void,
  elementsLimit?: number
) => {
  event.preventDefault();

  // Verifica se já atingiu o limite de elementos
  if (elementsLimit !== undefined && elements.length >= elementsLimit) {
    setDropTargetId(null);
    setDropPosition(null);
    setIsDragging(false);
    enableScroll();
    return;
  }

  // Verifica se o drop ocorreu na toolbar ou em algum elemento dentro dela
  const toolbar = document.querySelector(".toolbar-wrapper");
  if (
    toolbar &&
    (toolbar.contains(event.target as Node) || event.target === toolbar)
  ) {
    setDropTargetId(null);
    setDropPosition(null);
    setIsDragging(false);
    enableScroll();
    return;
  }

  let elementType: CanvasElementType | undefined;
  let elementId: string | undefined;
  let isExistingElement = false;

  if ("dataTransfer" in event) {
    // Mouse drag event
    isExistingElement =
      event.dataTransfer.getData("isExistingElement") === "true";
    elementId = event.dataTransfer.getData("text/plain");
    elementType = event.dataTransfer.getData(
      "elementType"
    ) as CanvasElementType;
  } else {
    // Touch event
    const dragData = (window as any).dragData;
    if (dragData) {
      elementType = dragData.elementType;
      elementId = dragData.elementId;
      isExistingElement = dragData.isExistingElement;

      // Limpa o elemento fantasma
      if (dragData.ghost) {
        dragData.ghost.remove();
      }
      // Limpa os dados do drag
      delete (window as any).dragData;
    }
  }

  const targetId = dropTargetId;
  const position = dropPosition;

  if (isExistingElement && elementId && targetId && position) {
    // Reordena elementos existentes
    const updatedElements = updateElementsOrder(
      elements,
      elementId,
      targetId,
      position
    );
    onElementsChange(updatedElements);
  } else if (elementType) {
    // Adiciona novo elemento da toolbar
    const newElement = createNewElement(elementType, elements.length);
    const newElements = [...elements];

    if (targetId && position) {
      const targetElement = newElements.find((el) => el.id === targetId);
      if (targetElement) {
        if (position === "before") {
          newElement.content.order = targetElement.content.order;
          newElements.forEach((el) => {
            if (
              "order" in el.content &&
              el.content.order >= targetElement.content.order
            ) {
              el.content.order += 1;
            }
          });
        } else {
          newElement.content.order = targetElement.content.order + 1;
          newElements.forEach((el) => {
            if (
              "order" in el.content &&
              el.content.order > targetElement.content.order
            ) {
              el.content.order += 1;
            }
          });
        }
      }
    } else {
      newElement.content.order = elements.length;
    }

    onElementsChange(
      [...newElements, newElement].sort((a, b) => {
        const orderA = "order" in a.content ? a.content.order : 0;
        const orderB = "order" in b.content ? b.content.order : 0;
        return orderA - orderB;
      })
    );
  }

  // Limpa o estado
  setDropTargetId(null);
  setDropPosition(null);
  setIsDragging(false);
  enableScroll();

  // Remove a classe dragging de todos os elementos
  document.querySelectorAll(".dragging").forEach((el) => {
    el.classList.remove("dragging");
  });
};

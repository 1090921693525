import React from "react";
import * as CleeckyKit from "CleeckyKit";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faClock,
  faFileLines,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  MetricsPeriod,
  PageStatsResponse,
  PeakAccessPeriodsResponse,
  TrafficSourceStatsResponse,
} from "cleecky@types/interfaces";

interface QuickSummaryCardsProps {
  period: MetricsPeriod;
  onPeriodChange: (period: MetricsPeriod) => void;
  pageStats: PageStatsResponse | null;
  peakPeriods: PeakAccessPeriodsResponse | null;
  trafficStats: TrafficSourceStatsResponse | null;
}

export const QuickSummaryCards: React.FC<QuickSummaryCardsProps> = ({
  period,
  onPeriodChange,
  pageStats,
  peakPeriods,
  trafficStats,
}) => {
  const { currentGradient } = CleeckyKit.useCleeckyKitCurrentTheme();

  const periodOptions = [
    {
      label: t("project:common.filters.period.current_week"),
      value: "week",
    },
    {
      label: t("project:common.filters.period.current_month"),
      value: "month",
    },
    {
      label: t("project:common.filters.period.current_year"),
      value: "year",
    },
  ];

  const getAverageLabel = () => {
    switch (period) {
      case "week":
        return t("project:common.filters.period.average.daily_average");
      case "month":
        return t("project:common.filters.period.average.weekly_average");
      case "year":
        return t("project:common.filters.period.average.monthly_average");
      default:
        return "";
    }
  };

  const getAverageValue = () => {
    if (!pageStats?.averages) return 0;
    switch (period) {
      case "week":
        return pageStats.averages.dailyAverage?.toFixed(1) || 0;
      case "month":
        return pageStats.averages.weeklyAverage?.toFixed(1) || 0;
      case "year":
        return pageStats.averages.monthlyAverage?.toFixed(1) || 0;
      default:
        return 0;
    }
  };

  const getPeakPeriodLabel = () => {
    if (!peakPeriods?.peakPeriods?.[0]) return "";

    switch (period) {
      case "week": {
        // Retorna o horário com mais acessos (exemplo: 17:00 - 17:59)
        // Encontra o período com mais acessos
        const peakPeriod = peakPeriods.peakPeriods.reduce((max, current) => {
          return (current.peakDetail?.count || 0) > (max.peakDetail?.count || 0)
            ? current
            : max;
        }, peakPeriods.peakPeriods[0]);

        return peakPeriod.peakDetail?.period || "";
      }
      case "month": {
        // Retorna o dia da semana com mais acessos (exemplo: segunda-feira)
        // Encontra o período com mais acessos
        const peakPeriod = peakPeriods.peakPeriods.reduce((max, current) => {
          return (current.peakDetail?.count || 0) > (max.peakDetail?.count || 0)
            ? current
            : max;
        }, peakPeriods.peakPeriods[0]);

        const weekDay = peakPeriod.peakDetail?.period.toLowerCase() || "";

        // Encontra a chave do dia da semana
        const weekDayKey = Object.keys(
          t("cleeckykit:common.datetime.weekDays", { returnObjects: true })
        ).find(
          (key) =>
            t(`cleeckykit:common.datetime.weekDays.${key}`).toLowerCase() ===
            weekDay
        );

        if (weekDayKey) {
          return t(`cleeckykit:common.datetime.weekDays.${weekDayKey}`);
        }
        return weekDay;
      }
      case "year": {
        // Retorna o mês com mais acessos (exemplo: janeiro)
        // Encontra o período com mais acessos
        const peakPeriod = peakPeriods.peakPeriods.reduce((max, current) => {
          return current.count > max.count ? current : max;
        }, peakPeriods.peakPeriods[0]);

        const month = peakPeriod.period.toLowerCase();

        // Encontra a chave do mês
        const monthKey = Object.keys(
          t("cleeckykit:common.datetime.months", { returnObjects: true })
        ).find(
          (key) =>
            t(`cleeckykit:common.datetime.months.${key}`).toLowerCase() ===
            month
        );

        if (monthKey) {
          return t(`cleeckykit:common.datetime.months.${monthKey}`);
        }
        return month;
      }
      default:
        return "";
    }
  };

  const getPeakPeriodCount = () => {
    if (!peakPeriods?.peakPeriods[0]) return 0;

    // Encontra o período com mais acessos
    switch (period) {
      case "week":
      case "month": {
        const peakPeriod = peakPeriods.peakPeriods.reduce((max, current) => {
          return (current.peakDetail?.count || 0) > (max.peakDetail?.count || 0)
            ? current
            : max;
        }, peakPeriods.peakPeriods[0]);

        return peakPeriod.peakDetail?.count || 0;
      }
      case "year": {
        const peakPeriod = peakPeriods.peakPeriods.reduce((max, current) => {
          return current.count > max.count ? current : max;
        }, peakPeriods.peakPeriods[0]);

        return peakPeriod.count;
      }
      default:
        return 0;
    }
  };

  const getMostAccessedLocation = () => {
    if (!trafficStats?.locations) return "";
    const locations = Object.entries(trafficStats.locations);
    if (locations.length === 0) return "";
    return locations.sort((a, b) => b[1] - a[1])[0][0];
  };

  const getMostCommonSource = () => {
    if (!trafficStats?.trafficSources) return "";
    const sources = Object.entries(trafficStats.trafficSources);
    if (sources.length === 0) return "";
    const [source] = sources.sort((a, b) => b[1] - a[1])[0];
    return t(
      `project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.sources_card.${source.toLowerCase()}`
    );
  };

  return (
    <CleeckyKit.Section>
      <CleeckyKit.Row>
        <CleeckyKit.Column gap="1rem">
          <CleeckyKit.Title level={3}>
            {t(
              "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.title"
            )}
          </CleeckyKit.Title>
          <CleeckyKit.Carousel
            navigation={{
              show: true,
              position: "outside-top-right",
              navigationBackground: "none",
            }}
            pagination={{ show: true, position: "outside-bottom" }}
            itemsPerView={3}>
            {/* Total Hits*/}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>
                      {pageStats?.totalStats.totalVisits || 0}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.access_card.total_hits"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column>
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faArrowPointer} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>
                      {getAverageValue()}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>{getAverageLabel()}</CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column align="end">
                    <CleeckyKit.Select
                      options={periodOptions}
                      value={period}
                      onChange={(value) =>
                        onPeriodChange(value as MetricsPeriod)
                      }
                      placeholder={t(
                        "project:common.filters.period.select_period"
                      )}
                    />
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>

            {/* Pages */}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>
                      {pageStats?.totalStats.totalPages || 0}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.pages_card.total_pages"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column>
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faFileLines} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>
                      {pageStats?.totalStats.activePages || 0}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.pages_card.active_pages"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>

            {/* Period */}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>
                      {getPeakPeriodLabel()}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.period_card.period_with_most_hits"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column minWidth="min-content">
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faClock} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>
                      {getPeakPeriodCount()}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.period_card.hits_quantity"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column align="end">
                    <CleeckyKit.Select
                      options={periodOptions}
                      value={period}
                      onChange={(value) =>
                        onPeriodChange(value as MetricsPeriod)
                      }
                      placeholder={t(
                        "project:common.filters.period.select_period"
                      )}
                    />
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>

            {/* Sources */}
            <CleeckyKit.Card
              backgroundColor={currentGradient?.secondaryHighlightColor}>
              <CleeckyKit.Column height="100%" justify="between" gap="1rem">
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={4}>
                      {getMostCommonSource()}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.sources_card.access_source"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                  <CleeckyKit.Column>
                    <CleeckyKit.Title align="right" level={3}>
                      <FontAwesomeIcon icon={faLocationDot} />
                    </CleeckyKit.Title>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
                <CleeckyKit.Row wrap={false} noBreak justify="between">
                  <CleeckyKit.Column>
                    <CleeckyKit.Title level={6}>
                      {getMostAccessedLocation()}
                    </CleeckyKit.Title>
                    <CleeckyKit.Text>
                      {t(
                        "project:apps.toolbox.tools.link_aggregator.dashboard.quick_summary.cards.sources_card.most_accessed_location"
                      )}
                    </CleeckyKit.Text>
                  </CleeckyKit.Column>
                </CleeckyKit.Row>
              </CleeckyKit.Column>
            </CleeckyKit.Card>
          </CleeckyKit.Carousel>
        </CleeckyKit.Column>
      </CleeckyKit.Row>
    </CleeckyKit.Section>
  );
};

import React, { useState, useRef, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faPen,
  faTrash,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";
import {
  CanvasOuterWrapper,
  DropZone,
  ContentWrapper,
  EditorArea as StyledEditorArea,
  ElementWrapper,
  DraggableElement,
  DropRegion,
} from "../../styles";
import {
  EditorAreaProps,
  CanvasElement,
  CanvasSection,
  CanvasElementType,
  Orientation,
  TextContent,
  LinkContent,
  SocialButtonsContent,
  ImageContent,
  GroupContent,
  ElementContent,
  AvatarContent,
  ButtonSize,
} from "../../types";
import { getElementIcon } from "../../../../utilities/canvasElementUtils";
import {
  handleDragStart,
  handleDragEnd,
  handleDragOver,
  handleDragLeave,
  handleCanvasDrop,
} from "../../../../utilities/canvasDragDropUtils";
import { updateElementsOrder } from "../../../../utilities/canvasElementUtils";
import { ElementToolbar } from "./components/ElementToolbar";
import { EditModal } from "./components/EditModal";
import { GlobalSettings } from "./components/GlobalSettings";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";
import emptyIcon from "assets/images/icon-minimalist-black-1.svg";
import styled from "styled-components";

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  img {
    width: 10rem;
    height: 10rem;
    //filter: ${({ theme }) => (theme.name === "dark" ? "invert(1)" : "none")};
  }
`;

export const CleeckyKitCanvasEditorArea: React.FC<EditorAreaProps> = ({
  elements,
  sections,
  globalColors,
  globalSizes,
  onElementsChange,
  onGlobalColorsChange,
  onGlobalSizesChange,
  className,
  elementsLimit,
}) => {
  const { t } = useTranslation();
  const { currentGradient, currentTheme } = useCleeckyKitCurrentTheme();

  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [draggedId, setDraggedId] = useState<string | null>(null);
  const [dropTargetId, setDropTargetId] = useState<string | null>(null);
  const [dropPosition, setDropPosition] = useState<"before" | "after" | null>(
    null
  );
  const [isDragging, setIsDragging] = useState(false);
  const [selectedElementId, setSelectedElementId] = useState<string | null>(
    null
  );
  const [editingElement, setEditingElement] = useState<CanvasElement | null>(
    null
  );
  const [isGlobalSettingsExpanded, setIsGlobalSettingsExpanded] =
    useState(false);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [touchedElementId, setTouchedElementId] = useState<string | null>(null);
  const [elementToDelete, setElementToDelete] = useState<string | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const disableScroll = useCallback(() => {
    // Desabilita apenas quando estiver arrastando
    if (dropZoneRef.current) {
      dropZoneRef.current.style.touchAction = "none";
    }
  }, []);

  const enableScroll = useCallback(() => {
    // Reabilita o scroll quando não estiver arrastando
    if (dropZoneRef.current) {
      dropZoneRef.current.style.touchAction = "auto";
    }
  }, []);

  const handleElementClick = (elementId: string) => {
    setSelectedElementId(elementId === selectedElementId ? null : elementId);
  };

  const handleElementTouch = (event: React.TouchEvent, elementId: string) => {
    // Previne o comportamento padrão para evitar problemas com scroll
    event.preventDefault();

    // Se for um toque rápido (tap), verifica se não foi na região dos botões
    if (event.type === "touchend" && !isDragging) {
      // Verifica se o toque foi na região dos botões
      const target = event.target as HTMLElement;
      const isActionButton = target.closest(".element-actions") !== null;

      // Só seleciona o elemento se o toque não foi na região dos botões
      if (!isActionButton) {
        handleElementClick(elementId);
      }
    }
  };

  const handleDeleteElement = (
    event: React.MouseEvent | React.TouchEvent,
    elementId: string
  ) => {
    event.stopPropagation();
    event.preventDefault();

    // Verifica se é um dispositivo touch
    const isTouchDevice =
      "ontouchstart" in window || navigator.maxTouchPoints > 0;

    if (isTouchDevice) {
      // Em dispositivos touch, só mostra o modal se o elemento já estiver selecionado
      if (selectedElementId === elementId) {
        setElementToDelete(elementId);
        setShowDeleteConfirmation(true);
      }
    } else {
      // Em dispositivos desktop, seleciona o elemento e mostra o modal
      if (selectedElementId !== elementId) {
        setSelectedElementId(elementId);
      }
      setElementToDelete(elementId);
      setShowDeleteConfirmation(true);
    }
  };

  const confirmDelete = () => {
    if (elementToDelete) {
      const newElements = elements.filter((el) => el.id !== elementToDelete);
      // Reordena os elementos restantes
      newElements.forEach((el, index) => {
        if ("order" in el.content) {
          el.content.order = index;
        }
      });
      onElementsChange(newElements);
      setSelectedElementId(null);
      setElementToDelete(null);
      setShowDeleteConfirmation(false);
    }
  };

  const handleEditClick = (
    event: React.MouseEvent | React.TouchEvent,
    element: CanvasElement
  ) => {
    event.stopPropagation();
    event.preventDefault(); // Previne qualquer comportamento padrão

    // Verifica se é um dispositivo touch
    const isTouchDevice =
      "ontouchstart" in window || navigator.maxTouchPoints > 0;

    if (isTouchDevice) {
      // Em dispositivos touch, só abre o modal se o elemento já estiver selecionado
      if (selectedElementId === element.id) {
        setEditingElement(element);
      }
    } else {
      // Em dispositivos desktop, seleciona o elemento e abre o modal
      if (selectedElementId !== element.id) {
        setSelectedElementId(element.id);
      }
      setEditingElement(element);
    }
  };

  const getElementLabel = (element: CanvasElement) => {
    switch (element.type) {
      case "TITLE":
      case "TEXT":
      case "NAME":
      case "USERNAME":
        return (element.content as TextContent).text || element.type;
      case "LINK":
        return (element.content as LinkContent).label || element.type;
      case "SOCIAL_BUTTONS":
        return `${element.type} (${
          (element.content as SocialButtonsContent).buttons.length
        })`;
      case "IMAGE":
        return (element.content as ImageContent).title || element.type;
      case "AVATAR":
        return element.type;
      case "GROUP":
        return `${element.type} (${
          (element.content as GroupContent).items.length
        })`;
      default:
        return element.type;
    }
  };

  const handleTouchStart = (event: React.TouchEvent, elementId: string) => {
    // Verifica se o toque foi no ícone de drag
    const target = event.target as HTMLElement;
    const isDragHandle = target.closest(".drag-handle") !== null;

    if (!isDragHandle) {
      return; // Se não for no ícone de drag, permite o scroll normal
    }

    // Inicia um timer para detectar se é um toque longo
    const timer = setTimeout(() => {
      if (event.target) {
        setIsDragging(true);
        disableScroll();
        initiateDrag(event, elementId, event.target as HTMLElement);
      }
    }, 200); // 200ms é um bom tempo para distinguir entre tap e drag

    // Armazena o timer para poder cancelá-lo se necessário
    (window as any).touchTimer = timer;

    const touch = event.touches[0];
    setTouchStartY(touch.clientY);
    setTouchedElementId(elementId);
  };

  const initiateDrag = (
    event: React.TouchEvent,
    elementId: string,
    target: HTMLElement
  ) => {
    const touch = event.touches[0];
    const rect = target.getBoundingClientRect();

    // Criar elemento fantasma para o touch drag
    const ghost = target.cloneNode(true) as HTMLElement;
    ghost.classList.add("dragging-element");
    ghost.style.position = "fixed";
    ghost.style.left = `${touch.clientX - rect.width / 2}px`;
    ghost.style.top = `${touch.clientY - rect.height / 2}px`;
    ghost.style.width = `${rect.width}px`;
    ghost.style.pointerEvents = "none";
    ghost.style.zIndex = "9999";
    ghost.style.opacity = "0.8";
    ghost.style.transform = "scale(1.05)";
    document.body.appendChild(ghost);

    // Armazenar informações do elemento sendo arrastado
    (window as any).dragData = {
      elementId,
      isExistingElement: true,
      ghost,
      offsetX: touch.clientX - rect.left,
      offsetY: touch.clientY - rect.top,
    };
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (!touchStartY || !touchedElementId) return;

    const touch = event.touches[0];
    const dragData = (window as any).dragData;

    // Atualiza a posição do elemento fantasma
    if (dragData?.ghost) {
      dragData.ghost.style.left = `${touch.clientX - dragData.offsetX}px`;
      dragData.ghost.style.top = `${touch.clientY - dragData.offsetY}px`;
    }

    // Encontra o elemento alvo sob o toque
    const elementUnderTouch = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );

    if (elementUnderTouch) {
      // Procura o elemento mais próximo que pode receber o drop
      const dropTarget = elementUnderTouch.closest(
        "[data-element-id]"
      ) as HTMLElement;
      if (dropTarget) {
        const rect = dropTarget.getBoundingClientRect();
        const midY = rect.top + rect.height / 2;
        const position = touch.clientY < midY ? "before" : "after";
        const elementId = dropTarget.getAttribute("data-element-id");

        if (elementId && elementId !== touchedElementId) {
          setDropTargetId(elementId);
          setDropPosition(position);
        }
      } else {
        setDropTargetId(null);
        setDropPosition(null);
      }
    }
  };

  const handleTouchEnd = (event: React.TouchEvent) => {
    // Limpa o timer de toque longo se existir
    if ((window as any).touchTimer) {
      clearTimeout((window as any).touchTimer);
      delete (window as any).touchTimer;
    }

    // Se não estiver arrastando, permite que o handleElementTouch processe o evento
    if (!isDragging) {
      return;
    }

    event.preventDefault();
    const dragData = (window as any).dragData;

    // Limpa o elemento fantasma
    if (dragData?.ghost) {
      dragData.ghost.remove();
    }

    if (!touchedElementId || !dropTargetId || !dropPosition) {
      setIsDragging(false);
      setTouchStartY(null);
      setTouchedElementId(null);
      delete (window as any).dragData;
      enableScroll();
      return;
    }

    const updatedElements = updateElementsOrder(
      elements,
      touchedElementId,
      dropTargetId,
      dropPosition
    );
    onElementsChange(updatedElements);

    setIsDragging(false);
    setTouchStartY(null);
    setTouchedElementId(null);
    setDropTargetId(null);
    setDropPosition(null);
    delete (window as any).dragData;
    enableScroll();
  };

  const handleDropZoneTouchMove = (e: React.TouchEvent) => {
    //e.preventDefault();
    const touch = e.touches[0];
    const dragData = (window as any).dragData;

    if (dragData?.ghost) {
      dragData.ghost.style.left = `${touch.clientX - dragData.offsetX}px`;
      dragData.ghost.style.top = `${touch.clientY - dragData.offsetY}px`;
    }
  };

  const handleDropZoneTouchEnd = (e: React.TouchEvent) => {
    //e.preventDefault();
    const dragData = (window as any).dragData;
    const touch = e.changedTouches[0];

    // Limpa o elemento fantasma
    if (dragData?.ghost) {
      dragData.ghost.remove();
    }

    if (dragData?.isNewElement && dragData?.elementType) {
      // Criar um novo elemento do tipo arrastado com o conteúdo apropriado
      const newElement: CanvasElement = {
        id: `${dragData.elementType.toLowerCase()}_${Date.now()}`,
        type: dragData.elementType,
        content: createInitialContent(dragData.elementType, elements.length),
        order: elements.length,
        show: true,
      };

      // Adicionar o novo elemento à lista
      onElementsChange([...elements, newElement]);
    }

    delete (window as any).dragData;
    setIsDragging(false);
    enableScroll();
  };

  // Função auxiliar para criar o conteúdo inicial baseado no tipo
  const createInitialContent = (
    type: CanvasElementType,
    order: number
  ): ElementContent => {
    const baseContent = {
      order,
      show: true,
    };

    switch (type) {
      case "TEXT":
      case "TITLE":
      case "NAME":
      case "USERNAME":
        return {
          ...baseContent,
          text: "",
          alignment: "CENTER" as const,
          justify: "CENTER" as const,
        };
      case "LINK":
        return {
          ...baseContent,
          label: "",
          url: "",
          borderRadius: globalSizes.borderRadius,
          borderSize: globalSizes.borderWidth,
          background: globalColors.buttonsBackgroundColor,
          color: globalColors.buttonsLabelColor,
        };
      case "SOCIAL_BUTTONS":
        return {
          ...baseContent,
          buttons: [],
          orientation: "HORIZONTAL" as Orientation,
          borderRadius: globalSizes.borderRadius,
          borderSize: globalSizes.borderWidth,
          background: globalColors.buttonsBackgroundColor,
        };
      case "IMAGE":
        return {
          ...baseContent,
          file: {
            path: "",
            type: "FILE" as const,
          },
          title: "",
          textPosition: "BOTTOM" as const,
          borderRadius: globalSizes.borderRadius,
          borderWidth: globalSizes.borderWidth,
          borderColor: globalColors.borderColor,
        };
      case "AVATAR":
        return {
          ...baseContent,
          file: {
            path: "",
            type: "FILE" as const,
          },
          shape: "CIRCLE" as const,
          borderColor: globalColors.borderColor,
        };
      case "GROUP":
        return {
          ...baseContent,
          items: [],
          borderRadius: globalSizes.borderRadius,
          borderSize: globalSizes.borderWidth,
          background: globalColors.pageContentBackgroundColor,
        };
      default:
        throw new Error(`Tipo de elemento não suportado: ${type}`);
    }
  };

  const renderElement = (element: CanvasElement) => {
    const isDragging =
      element.id === draggedId || element.id === touchedElementId;
    const isDropTarget = element.id === dropTargetId;
    const isSelected = element.id === selectedElementId;

    // Aplica as configurações globais ao elemento
    const elementWithGlobals: CanvasElement = {
      ...element,
      content: (() => {
        switch (element.type) {
          case "TITLE":
          case "TEXT":
          case "NAME":
          case "USERNAME":
            return {
              ...(element.content as TextContent),
              color:
                (element.content as TextContent).color ||
                globalColors.textColor,
            } as TextContent;
          case "LINK":
            return {
              ...(element.content as LinkContent),
              borderRadius:
                (element.content as LinkContent).borderRadius ||
                globalSizes.borderRadius,
              background:
                (element.content as LinkContent).background ||
                globalColors.buttonsBackgroundColor,
              color:
                (element.content as LinkContent).color ||
                globalColors.buttonsLabelColor,
              borderSize:
                (element.content as LinkContent).borderSize ||
                globalSizes.borderWidth,
              highlightColor:
                (element.content as LinkContent).highlightColor ||
                globalColors.highlightColor,
            } as LinkContent;
          case "AVATAR":
            return {
              ...(element.content as AvatarContent),
              borderColor:
                (element.content as AvatarContent).borderColor ||
                globalColors.borderColor,
            } as AvatarContent;
          case "IMAGE":
            return {
              ...(element.content as ImageContent),
              borderRadius:
                (element.content as ImageContent).borderRadius ||
                globalSizes.borderRadius,
              borderWidth:
                (element.content as ImageContent).borderWidth ||
                globalSizes.borderWidth,
              borderColor:
                (element.content as ImageContent).borderColor ||
                globalColors.borderColor,
            } as ImageContent;
          case "SOCIAL_BUTTONS":
            return {
              ...(element.content as SocialButtonsContent),
              borderRadius:
                (element.content as SocialButtonsContent).borderRadius ||
                globalSizes.borderRadius,
              borderSize:
                (element.content as SocialButtonsContent).borderSize ||
                globalSizes.borderWidth,
              background:
                (element.content as SocialButtonsContent).background ||
                globalColors.buttonsBackgroundColor,
              highlightColor:
                (element.content as SocialButtonsContent).highlightColor ||
                globalColors.highlightColor,
            } as SocialButtonsContent;
          case "GROUP":
            return {
              ...(element.content as GroupContent),
              borderRadius:
                (element.content as GroupContent).borderRadius ||
                globalSizes.borderRadius,
              borderSize:
                (element.content as GroupContent).borderSize ||
                globalSizes.borderWidth,
              background:
                (element.content as GroupContent).background ||
                globalColors.pageContentBackgroundColor,
            } as GroupContent;
          default:
            return element.content;
        }
      })(),
    };

    return (
      <ElementWrapper
        key={elementWithGlobals.id}
        data-element-id={elementWithGlobals.id}
        onDragOver={(e) =>
          handleDragOver(e, draggedId, setDropTargetId, setDropPosition)
        }
        onDragLeave={(e) =>
          handleDragLeave(e, dropTargetId, setDropTargetId, setDropPosition)
        }
        onTouchMove={handleTouchMove}
        onTouchEnd={(e) => {
          handleTouchEnd(e);
          handleElementTouch(e, elementWithGlobals.id);
        }}
        onClick={() => handleElementClick(elementWithGlobals.id)}>
        {isDropTarget && dropPosition === "before" && (
          <DropRegion
            data-drop-region="true"
            $isActive={true}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCanvasDrop(
                e,
                elements,
                dropTargetId,
                dropPosition,
                onElementsChange,
                setDropTargetId,
                setDropPosition,
                setIsDragging,
                enableScroll,
                elementsLimit
              );
            }}>
            <CleeckyKit.Row
              justify="center"
              align="center"
              className="drop-hint-row">
              <CleeckyKit.Text align="center">
                {t("cleeckykit:common.interface.elements.canvas.drop_hint")}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          </DropRegion>
        )}
        <DraggableElement
          $isDragging={isDragging}
          $isSelected={isSelected}
          onDrop={(e) => e.preventDefault()}
          onDragOver={(e) => e.preventDefault()}>
          <div
            className="drag-handle"
            draggable
            onDragStart={(e) =>
              handleDragStart(
                e,
                elementWithGlobals.id,
                setDraggedId,
                setIsDragging,
                disableScroll
              )
            }
            onDragEnd={() =>
              handleDragEnd(
                setDraggedId,
                setIsDragging,
                setDropTargetId,
                setDropPosition,
                enableScroll
              )
            }
            onTouchStart={(e) => handleTouchStart(e, elementWithGlobals.id)}>
            <FontAwesomeIcon icon={faEllipsisV} className="drag-handle-icon" />
          </div>
          <FontAwesomeIcon
            icon={getElementIcon(elementWithGlobals.type)}
            className="element-icon"
          />
          <div className="element-content">
            {getElementLabel(elementWithGlobals)}
          </div>
          <div className="element-actions">
            <FontAwesomeIcon
              icon={faPen}
              className="action-button edit"
              onClick={(e) => handleEditClick(e, elementWithGlobals)}
              onTouchEnd={(e) => handleEditClick(e, elementWithGlobals)}
              title={t("cleeckykit:common.actions.edit")}
            />
            <FontAwesomeIcon
              icon={faTrash}
              className="action-button delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteElement(e, elementWithGlobals.id);
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                handleDeleteElement(e, elementWithGlobals.id);
              }}
              title={t("cleeckykit:common.actions.delete")}
            />
          </div>
        </DraggableElement>
        {isDropTarget && dropPosition === "after" && (
          <DropRegion
            data-drop-region="true"
            $isActive={true}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCanvasDrop(
                e,
                elements,
                dropTargetId,
                dropPosition,
                onElementsChange,
                setDropTargetId,
                setDropPosition,
                setIsDragging,
                enableScroll,
                elementsLimit
              );
            }}>
            <CleeckyKit.Row
              justify="center"
              align="center"
              className="drop-hint-row">
              <CleeckyKit.Text align="center">
                {t("cleeckykit:common.interface.elements.canvas.drop_hint")}
              </CleeckyKit.Text>
            </CleeckyKit.Row>
          </DropRegion>
        )}
      </ElementWrapper>
    );
  };

  const renderSection = (section: CanvasSection) => {
    const sectionElements = elements
      .filter((el) => section.elements.includes(el.id))
      .sort((a, b) => {
        const orderA = "order" in a.content ? a.content.order : 0;
        const orderB = "order" in b.content ? b.content.order : 0;
        return orderA - orderB;
      });

    return (
      <CleeckyKit.Section
        key={section.id}
        padding="1rem"
        background={currentTheme.colors.background.card}
        borderRadius="8px"
        width="100%">
        <CleeckyKit.Title level={5}>{section.title}</CleeckyKit.Title>
        {sectionElements.map(renderElement)}
      </CleeckyKit.Section>
    );
  };

  const standaloneElements = elements
    .filter(
      (el) => !sections.some((section) => section.elements.includes(el.id))
    )
    .sort((a, b) => {
      const orderA = "order" in a.content ? a.content.order : 0;
      const orderB = "order" in b.content ? b.content.order : 0;
      return orderA - orderB;
    });

  const renderContent = () => {
    if (elements.length === 0) {
      return (
        <EmptyState>
          <img src={emptyIcon} alt="Empty canvas" />
          <CleeckyKit.Text align="center">
            {t("cleeckykit:common.interface.elements.canvas.empty_state")}
          </CleeckyKit.Text>
        </EmptyState>
      );
    }

    return (
      <CleeckyKit.Row>
        <CleeckyKit.Column>
          {sections.map(renderSection)}
          {standaloneElements.map(renderElement)}
        </CleeckyKit.Column>
      </CleeckyKit.Row>
    );
  };

  const handleElementSave = (updatedElement: CanvasElement) => {
    const updatedElements = elements.map((el) =>
      el.id === updatedElement.id ? updatedElement : el
    );
    onElementsChange(updatedElements);
  };

  return (
    <StyledEditorArea>
      <CanvasOuterWrapper>
        <CleeckyKit.Row>
          <CleeckyKit.Card
            width="100%"
            height="90dvh"
            background={currentTheme.colors.background.card}
            tabs={[
              {
                key: "editor",
                label: t(
                  "cleeckykit:common.interface.elements.canvas.tabs.editor"
                ),
                content: (
                  <>
                    <CleeckyKit.Row justify="center" margin="0 0 8px 0">
                      <CleeckyKit.Text align="center">
                        {t(
                          "cleeckykit:common.interface.elements.canvas.drag_hint"
                        )}
                      </CleeckyKit.Text>
                    </CleeckyKit.Row>

                    {elementsLimit !== undefined && (
                      <CleeckyKit.Row justify="center" margin="0 0 8px 0">
                        <CleeckyKit.Text align="center">
                          {`${t(
                            "cleeckykit:common.interface.elements.canvas.elements_limit",
                            {
                              count: elements.length,
                              limit: elementsLimit,
                            }
                          )}`}
                        </CleeckyKit.Text>
                      </CleeckyKit.Row>
                    )}

                    <DropZone
                      ref={dropZoneRef}
                      className="drop-zone"
                      $isDragging={isDragging}
                      onDrop={(e) =>
                        handleCanvasDrop(
                          e,
                          elements,
                          dropTargetId,
                          dropPosition,
                          onElementsChange,
                          setDropTargetId,
                          setDropPosition,
                          setIsDragging,
                          enableScroll,
                          elementsLimit
                        )
                      }
                      onDragOver={(e) => e.preventDefault()}
                      onTouchMove={handleDropZoneTouchMove}
                      onTouchEnd={handleDropZoneTouchEnd}
                      style={{ touchAction: isDragging ? "none" : "auto" }}>
                      <ContentWrapper>{renderContent()}</ContentWrapper>
                    </DropZone>

                    <ElementToolbar
                      elements={elements}
                      onElementsChange={onElementsChange}
                      setIsDragging={setIsDragging}
                      disableScroll={disableScroll}
                      elementsLimit={elementsLimit}
                    />
                  </>
                ),
              },
              {
                key: "appearance",
                label: t(
                  "cleeckykit:common.interface.elements.canvas.tabs.appearance"
                ),
                content: (
                  <div
                    style={{
                      height: "calc(70dvh - 48px)", // 48px é a altura aproximada da tab
                      overflowY: "auto",
                      padding: "1rem",
                    }}>
                    <CleeckyKit.Text
                      icons={[
                        {
                          icon: <FontAwesomeIcon icon={faPen} />,
                        },
                      ]}>
                      {t(
                        "cleeckykit:common.interface.elements.canvas.global_settings.description"
                      )}
                    </CleeckyKit.Text>
                    <br />
                    <GlobalSettings
                      globalColors={globalColors}
                      globalSizes={globalSizes}
                      onGlobalColorsChange={onGlobalColorsChange}
                      onGlobalSizesChange={onGlobalSizesChange}
                    />
                  </div>
                ),
              },
            ]}
          />
        </CleeckyKit.Row>
      </CanvasOuterWrapper>

      {editingElement && (
        <EditModal
          element={editingElement}
          onClose={() => setEditingElement(null)}
          onSave={(updatedElement: CanvasElement) => {
            handleElementSave(updatedElement);
            setEditingElement(null);
          }}
          globalColors={globalColors}
          globalSizes={globalSizes}
          elements={elements}
          onElementsChange={onElementsChange}
        />
      )}

      {showDeleteConfirmation && (
        <CleeckyKit.Modal
          isOpen={showDeleteConfirmation}
          onClose={() => {
            setShowDeleteConfirmation(false);
            setElementToDelete(null);
          }}
          title={t("cleeckykit:common.interface.elements.canvas.delete.title")}
          size="small"
          animation="scale">
          <CleeckyKit.Column gap={16}>
            <CleeckyKit.Text>
              {t(
                "cleeckykit:common.interface.elements.canvas.delete.confirmation"
              )}
            </CleeckyKit.Text>
            <CleeckyKit.Row justify="end" noBreak>
              <CleeckyKit.Button
                variant="link"
                text={t("cleeckykit:common.actions.cancel")}
                onClick={() => {
                  setShowDeleteConfirmation(false);
                  setElementToDelete(null);
                }}
                size="small"
              />
              <CleeckyKit.Button
                variant="solid"
                text={t("cleeckykit:common.actions.delete")}
                onClick={confirmDelete}
                size="small"
                highlighted
              />
            </CleeckyKit.Row>
          </CleeckyKit.Column>
        </CleeckyKit.Modal>
      )}
    </StyledEditorArea>
  );
};

CleeckyKitCanvasEditorArea.displayName = "CleeckyKitCanvasEditorArea";

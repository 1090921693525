import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { GlobalColors, GlobalSizes } from "../types";

// Editor Area Styles
export const CanvasOuterWrapper = styled.div`
  position: relative;
`;

export const DropZone = styled.div<{ $isDragging?: boolean }>`
  width: 100%;
  //height: calc(100% - 80px);
  border-radius: 0.8rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 7rem;
  touch-action: ${({ $isDragging }) => ($isDragging ? "none" : "auto")};
  -webkit-overflow-scrolling: ${({ $isDragging }) =>
    $isDragging ? "none" : "touch"};
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.colors.background.navbar.background};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.card};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.border.input};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`;

export const ContentWrapper = styled.div`
  //padding: 16px 16px 26px 8px;
  min-height: 100%;
`;

export const EditorArea = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  min-width: 0;
`;

// Preview Area Styles
export const PreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: inherit;
`;

export const PreviewArea = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.card.default};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const PreviewHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  display: flex;
  gap: 8px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.card};
`;

export const PreviewButton = styled.button<{ $isActive: boolean }>`
  padding: 8px;
  border: 1px solid
    ${({ theme, $isActive }) =>
      $isActive
        ? theme.gradient?.highlightColor || theme.colors.interactive.primary
        : theme.colors.border.input};
  background: ${({ theme, $isActive }) =>
    $isActive
      ? theme.gradient?.highlightColor || theme.colors.interactive.primary
      : "transparent"};
  color: ${({ theme }) => theme.colors.text.primary};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) =>
      `${
        theme.gradient?.highlightColor || theme.colors.interactive.primary
      }40`};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const PreviewContent = styled.div<{
  $isMobile: boolean;
  $globalColors?: GlobalColors;
}>`
  position: relative;
  width: ${({ $isMobile }) =>
    $isMobile ? "min(375px, 90%)" : "min(960px, 90%)"};
  aspect-ratio: ${({ $isMobile }) => ($isMobile ? "9/16" : "16/9")};
  height: ${({ $isMobile }) => ($isMobile ? "70dvh" : "50dvh")};
  margin: auto;
  background: ${({ $globalColors = defaultGlobalColors, theme }) =>
    $globalColors.pageBackgroundColor || theme.colors.background.base};
  transition: all 0.3s ease;
  border-radius: 1rem;
  font-size: clamp(0.75rem, 1.5dvw, 0.9rem);
  box-shadow: ${({ theme }) => theme.shadows.card.elevated};
  border: 2px solid ${({ theme }) => theme.colors.background.navbar.background};
  display: flex;
  flex-direction: column;

  .preview-content-inner {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;

    /*  @media (max-width: 768px) {
      padding: 0.6rem;
    } */
  }

  .preview-content-wrapper {
    //padding: 0.2rem 0;
    width: 20rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({ $globalColors = defaultGlobalColors }) =>
      $globalColors?.pageContentBackgroundColor};
  }

  @media (max-width: 768px) {
    width: ${({ $isMobile }) =>
      $isMobile ? "min(375px, 90%)" : "min(90%, 90dvh * 16/9)"};
    height: ${({ $isMobile }) => ($isMobile ? "min(667px, 90dvh)" : "auto")};
    max-height: ${({ $isMobile }) =>
      $isMobile ? "90dvh" : "min(90dvw * 9/16, 90dvh)"};
  }

  .preview-content-inner::-webkit-scrollbar {
    width: 0.4rem;
  }

  .preview-content-inner::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.card};
    border-radius: 0.2rem;
  }

  .preview-content-inner::-webkit-scrollbar-thumb {
    background: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors.border.input};
    border-radius: 0.2rem;

    &:hover {
      background: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`;

// Browser Bar Styles
export const BrowserBar = styled.div`
  background: ${({ theme }) => theme.colors.background.card};
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BrowserIcon = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => `${theme.colors.text.primary}40`};
  }
`;

export const BrowserDots = styled.div`
  display: flex;
  gap: 6px;
  margin-right: 8px;

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.text.secondary};
    opacity: 0.5;

    &:nth-child(1) {
      background: #ff5f57;
    }
    &:nth-child(2) {
      background: #ffbd2e;
    }
    &:nth-child(3) {
      background: #28c940;
    }
  }
`;

export const BrowserAddressBar = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.background.input};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 12px;
    height: 12px;
    opacity: 0.5;
  }
`;

// Element Styles
export const ElementWrapper = styled.div`
  position: relative;
  width: 100%;
  touch-action: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    .element-actions {
      opacity: 1;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: none;
  }

  &::before {
    top: -10px;
  }

  &::after {
    bottom: -10px;
  }
`;

const popOutAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
`;

export const DraggableElement = styled.div<{
  $isDragging: boolean;
  $isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px;
  background: ${({ theme }) => theme.colors?.background?.card};
  border: 1px solid
    ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.gradient?.highlightColor || theme.colors?.interactive?.primary
        : theme.colors?.background?.card};
  border-radius: 8px;
  margin: 4px 0;
  cursor: move;
  user-select: none;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  opacity: ${({ $isDragging }) => ($isDragging ? 0.5 : 1)};
  transition: all 0.2s ease;

  &:hover {
    border-color: ${({ theme }) =>
      theme.gradient?.highlightColor || theme.colors?.interactive?.primary};
  }

  .drag-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: move;
    touch-action: none;
  }

  .drag-handle-icon {
    color: ${({ theme }) => theme.colors?.text?.secondary};
    font-size: 16px;
  }

  .element-content {
    flex: 1;
    margin: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .element-actions {
    display: flex;
    gap: 8px;
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
    transition: opacity 0.2s ease;

    .action-button {
      cursor: pointer;
      color: ${({ theme }) => theme.colors?.text?.secondary};
      font-size: 16px;
      transition: color 0.2s ease;
      padding: 8px;
      margin: -8px;

      &:hover {
        color: ${({ theme }) =>
          theme.gradient?.highlightColor || theme.colors?.interactive?.primary};

        &.delete {
          color: ${({ theme }) => theme.colors.status.error};
        }
      }
    }
  }

  &:hover .element-actions {
    opacity: 1;
  }

  &.dragging {
    opacity: 0.5;
  }
`;

export const DropRegion = styled.div<{ $isActive: boolean }>`
  height: 40px;
  background-color: ${({ theme, $isActive }) =>
    $isActive
      ? `${
          theme.gradient?.highlightColor || theme.colors?.interactive?.primary
        }15`
      : "transparent"};
  border: 2px dashed
    ${({ theme, $isActive }) =>
      $isActive
        ? theme.gradient?.highlightColor || theme.colors?.interactive?.primary
        : "transparent"};
  margin: 8px 0;
  transition: all 0.2s ease;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  .drop-hint-row {
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
    transition: opacity 0.2s ease;
  }

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    height: 16px;
    background: transparent;
  }
`;

export const GlobalStyle = styled.div`
  .dragging-element {
    animation: ${popOutAnimation} 0.2s ease-out;
    pointer-events: none;
    z-index: 9999;
    opacity: 0.8;
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;

// Global Settings Styles
export const GlobalSettingsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const GlobalSettingsContent = styled(motion.div)`
  overflow: hidden;
  width: 100%;
`;

export const SettingsSection = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const SettingsSectionTitle = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 16px;
`;

export const ColorPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
`;

export const ColorPickerLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.primary};
  flex: 1;
`;

export const SizeInputWrapper = styled(ColorPickerWrapper)`
  .size-input {
    width: 100px;
    padding: 4px 8px;
    border: 1px solid ${({ theme }) => theme.colors.border.input};
    border-radius: 4px;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.text.primary};
    background: ${({ theme }) => theme.colors.background.input};

    &:focus {
      border-color: ${({ theme }) => theme.colors.interactive.primary};
      outline: none;
    }
  }
`;

export const SizeInputLabel = styled(ColorPickerLabel)``;

export const ColorPickerButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: 4px;
  padding: 2px;
  background: transparent;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: ${({ theme }) => theme.colors.interactive.primary};
  }
`;

export const ColorInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const ColorPreview = styled.div<{ $color: string }>`
  width: 100%;
  height: 100%;
  background: ${({ $color }) => $color};
  border-radius: 2px;
`;

export const defaultGlobalColors: GlobalColors = {
  highlightColor: "#3498db",
  pageBackgroundColor: "#e0e0e0",
  pageContentBackgroundColor: "#ffffff",
  buttonsBackgroundColor: "#454545",
  buttonsLabelColor: "#ffffff",
  textColor: "#000000",
  borderColor: "#454545",
};

export const defaultGlobalSizes: GlobalSizes = {
  borderWidth: "2px",
  borderRadius: "16px",
};

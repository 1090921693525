import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as CleeckyKit from "CleeckyKit";
import { WorkProgressItem } from "CleeckyKit/types";

const Section = styled.section`
  margin-bottom: 48px;
`;

const SectionHeader = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1.75rem;
  margin: 0 0 8px 0;
`;

const SectionDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.125rem;
  margin: 0;
`;

// Dados de exemplo para o Working-On
const workingOnItems: WorkProgressItem[] = [
  {
    title: "Melhorias visuais da interface",
    description:
      "Estamos trabalhando para manter a consistência de design flexível e contrastes visuais para todos os temas",
    progress: 35,
    status: "review",
    blockingReasons: [
      {
        type: "design_review",
        description: "Aprovação do novo design",
      },
    ],
  },
  {
    title: "Encurtador de Links",
    description: "Desenvolvimento do sistema do Encurtador de Links.",
    progress: 45,
    status: "in_progress",
  },
  {
    title: "Gerador de QR Code",
    description:
      "Desenvolvimento do Gerador de QR Code com suporte a personalização visual.",
    progress: 30,
    status: "in_progress",
  },
  {
    title: "Área interativa para votação de ideias sugeridas pela comunidade",
    description:
      "Implementação do espaço exclusivo para sugestões da comunidade com votação e área de comentários.",
    progress: 5,
    status: "blocked",
    blockingReasons: [
      {
        type: "design_review",
        description: "Aguardando aprovação para início do desenvolvimento",
      },
    ],
  },
  {
    title: "Sistema de Notificações",
    description:
      "Implementação do sistema de notificações em tempo real para atualizações, eventos importantes, interação e comunicação na comunidade, segurança e marketing",
    progress: 5,
    status: "blocked",
    blockingReasons: [
      {
        type: "design_review",
        description: "Aguardando aprovação para início do desenvolvimento",
      },
    ],
  },
  {
    title: "Correções e alterações pedidas pela comunidade",
    description:
      "Algumas correções e melhorias na visibilidade do design foram sugeridas.",
    progress: 35,
    status: "review",
  },
];

export const WorkingOnSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CleeckyKit.Section>
      <CleeckyKit.Column gap="2rem">
        <div>
          <CleeckyKit.Title level={2}>
            {t("pages.changelog.sections.working_on.title")}
          </CleeckyKit.Title>
          <CleeckyKit.Text>
            {t("pages.changelog.sections.working_on.description")}
          </CleeckyKit.Text>
        </div>

        {workingOnItems.map((item, index) => (
          <CleeckyKit.WorkProgress key={index} item={item} />
        ))}
      </CleeckyKit.Column>
    </CleeckyKit.Section>
  );
};

export default WorkingOnSection;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as echarts from "echarts";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";

interface BarChartProps {
  data: {
    categories: string[];
    values: number[];
    highlightIndex?: number;
    tooltipFormatter?: (index: number) => string;
    xAxisLabel?: string;
    yAxisLabel?: string;
  };
  title?: string;
  height?: string;
  width?: string;
  showAverage?: boolean;
  averageLineColor?: string;
  barColor?: string;
  highlightColor?: string;
  onBarClick?: (index: number) => void;
}

const ChartContainer = styled.div<{ $height?: string; $width?: string }>`
  height: ${({ $height }) => $height || "350px"};
  width: ${({ $width }) => $width || "100%"};
  min-height: ${({ $height }) => $height || "175px"};
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
`;

export const CleeckyKitBarChart: React.FC<BarChartProps> = ({
  data,
  title,
  height,
  width,
  showAverage = false,
  averageLineColor,
  barColor,
  highlightColor,
  onBarClick,
}) => {
  const { t } = useTranslation();
  const { currentGradient, currentTheme } = useCleeckyKitCurrentTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [activeBarIndex, setActiveBarIndex] = useState<number | undefined>(
    data.highlightIndex
  );

  useEffect(() => {
    if (chartRef.current) {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);

        // Adiciona o evento de clique
        chartInstance.current.on("click", (params) => {
          if (params.componentType === "series") {
            const index = params.dataIndex as number;
            setActiveBarIndex(index);
            onBarClick?.(index);
          }
        });
      }

      const average = showAverage
        ? data.values.reduce((acc, val) => acc + val, 0) / data.values.length
        : undefined;

      // Encontra o valor máximo para definir o limite do eixo Y
      const maxValue = Math.max(...data.values, average || 0);
      const yAxisMax = maxValue === 0 ? 10 : Math.ceil(maxValue * 1.2); // Adiciona 20% de espaço acima do valor máximo

      const series: any[] = [
        {
          type: "bar",
          data: data.values.map((value, index) => ({
            value,
            itemStyle: {
              borderRadius: 5,
              color:
                index === activeBarIndex
                  ? highlightColor ||
                    currentGradient?.highlightColor ||
                    currentTheme.colors.charts.bar.active
                  : barColor || currentTheme.colors.charts.bar.default,
            },
            emphasis: {
              itemStyle: {
                color:
                  index === activeBarIndex
                    ? highlightColor ||
                      currentGradient?.highlightColor ||
                      currentTheme.colors.charts.bar.active
                    : currentTheme.colors.charts.bar.hover,
              },
            },
          })),
        },
      ];

      if (showAverage && average !== undefined) {
        series.push({
          type: "line",
          markLine: {
            data: [{ yAxis: average }],
            symbol: ["none", "none"],
            lineStyle: {
              color: averageLineColor || currentTheme.colors.charts.bar.average,
              type: "dashed",
            },
            label: {
              formatter:
                t("cleeckykit:common.charts.average") +
                "\n" +
                average.toFixed(2),
              position: "insideEndTop",
              color: "white",
              backgroundColor: currentTheme.colors.charts.bar.average,
              borderRadius: 4,
              padding: 4,
              fontSize: 12,
              fontWeight: "bold",
            },
          },
        });
      }

      const options = {
        title: title
          ? {
              text: title,
              left: "center",
              textStyle: {
                color: currentTheme.colors.charts.text,
              },
            }
          : undefined,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: currentTheme.colors.charts.tooltip.background,
          borderColor: currentTheme.colors.charts.tooltip.border,
          textStyle: {
            color: currentTheme.colors.charts.tooltip.text,
          },
          formatter: (params: any) => {
            const index = params[0].dataIndex;
            const value = params[0].value;
            const category = data.categories[index];

            let tooltipContent = `${category}: ${value}`;

            if (data.tooltipFormatter) {
              const additionalInfo = data.tooltipFormatter(index);
              if (additionalInfo) {
                tooltipContent += `<br/>${additionalInfo}`;
              }
            }

            return tooltipContent;
          },
          confine: true,
          position: (
            point: number[],
            params: any,
            dom: HTMLElement,
            rect: any,
            size: { contentSize: number[]; viewSize: number[] }
          ) => {
            const [x, y] = point;
            const [contentWidth, contentHeight] = size.contentSize;
            const [viewWidth, viewHeight] = size.viewSize;

            let posX = x;
            let posY = y;

            // Ajusta a posição horizontal
            if (x + contentWidth > viewWidth) {
              posX = x - contentWidth - 20;
            } else {
              posX = x + 20;
            }

            // Ajusta a posição vertical
            if (y + contentHeight > viewHeight) {
              posY = y - contentHeight - 10;
            } else {
              posY = y + 10;
            }

            // Garante que o tooltip não saia da área visível
            posX = Math.max(0, Math.min(viewWidth - contentWidth, posX));
            posY = Math.max(0, Math.min(viewHeight - contentHeight, posY));

            return { left: posX, top: posY };
          },
        },
        grid: {
          left: "15%",
          right: "4%",
          bottom: "15%",
          top: title ? "15%" : "8%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data.categories,
          axisTick: {
            alignWithLabel: true,
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLine: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLabel: {
            color: currentTheme.colors.charts.grid.text,
            interval: 0,
            rotate: data.categories.length > 7 ? 45 : 0,
            fontSize: 11,
            width: 50,
            overflow: "truncate",
            formatter: (value: string) => {
              return value.length > 10 ? value.substring(0, 10) + "..." : value;
            },
          },
          name: "(" + data.xAxisLabel + ")",
          nameLocation: "middle",
          nameGap: 35,
          nameTextStyle: {
            color: currentTheme.colors.charts.grid.text,
            fontSize: 12,
            padding: [5, 0, 0, 0],
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: yAxisMax,
          minInterval: 1,
          axisTick: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLine: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
            },
          },
          axisLabel: {
            color: currentTheme.colors.charts.grid.text,
            formatter: (value: number) => Math.floor(value),
          },
          splitLine: {
            lineStyle: {
              color: currentTheme.colors.charts.grid.line,
              opacity: 0.3,
            },
          },
          name: "(" + data.yAxisLabel + ")",
          nameLocation: "middle",
          nameGap: 50,
          nameRotate: 90,
          nameTextStyle: {
            color: currentTheme.colors.charts.grid.text,
            fontSize: 12,
            padding: [0, 0, 3, 0],
          },
        },
        series,
      };

      chartInstance.current.setOption(options);
      chartInstance.current.resize();
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, [
    data,
    title,
    showAverage,
    averageLineColor,
    barColor,
    highlightColor,
    t,
    currentTheme,
    currentGradient,
    activeBarIndex,
    onBarClick,
  ]);

  // Adiciona um efeito para redimensionar o gráfico quando o container mudar de tamanho
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    });

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return <ChartContainer ref={chartRef} $height={height} $width={width} />;
};

CleeckyKitBarChart.displayName = "CleeckyKitBarChart";

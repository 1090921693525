import { useEffect, useRef, useState } from "react";
import { AggregatorMetricsSource } from "cleecky@types/enums";
import {
  aggregatorService,
  AggregatorPageVisibilityType,
} from "../services/aggregatorService";

interface UseMetricsTrackingProps {
  pageUuid: string;
}

interface LocationData {
  city?: string;
  state?: string;
  country?: string;
  countryCode?: string;
  ip?: string;
}

export const useMetricsTracking = ({ pageUuid }: UseMetricsTrackingProps) => {
  const hasTrackedRef = useRef(false);
  const [hasInteraction, setHasInteraction] = useState(false);
  const metricsUuidRef = useRef<string>("");

  // Detecta a fonte de tráfego
  const detectTrafficSource = (): AggregatorMetricsSource => {
    const referrer = document.referrer;

    // Lista de domínios de redes sociais
    const socialMediaDomains = [
      "facebook.com",
      "instagram.com",
      "twitter.com",
      "linkedin.com",
      "t.co",
      "youtube.com",
      "pinterest.com",
      "reddit.com",
      "tumblr.com",
      "tiktok.com",
    ];

    // Lista de domínios de mecanismos de busca
    const searchEngineDomains = [
      "google",
      "bing.com",
      "yahoo.com",
      "duckduckgo.com",
      "baidu.com",
      "yandex.com",
    ];

    if (!referrer) {
      return AggregatorMetricsSource.DIRECT_LINK;
    }

    const referrerDomain = new URL(referrer).hostname;

    if (socialMediaDomains.some((domain) => referrerDomain.includes(domain))) {
      return AggregatorMetricsSource.SOCIAL_MEDIA;
    }

    if (searchEngineDomains.some((domain) => referrerDomain.includes(domain))) {
      return AggregatorMetricsSource.SEARCH_OR_OTHERS;
    }

    return AggregatorMetricsSource.SEARCH_OR_OTHERS;
  };

  // Obtém a localização do usuário
  const getLocation = async (): Promise<LocationData> => {
    try {
      // Primeiro, vamos obter apenas o IP público
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      const ipData = await ipResponse.json();
      const publicIp = ipData.ip;

      // Agora vamos obter os dados de localização usando o IP público
      const response = await fetch(`https://ipapi.co/${publicIp}/json/`);
      const data = await response.json();

      return {
        city: data.city,
        state: data.region,
        country: data.country_name,
        countryCode: data.country_code,
        ip: publicIp, // Usando o IP público obtido
      };
    } catch (error) {
      return {};
    }
  };

  // Registra interação do usuário
  const trackInteraction = async () => {
    if (!hasInteraction && metricsUuidRef.current) {
      // Verifica a visibilidade da página antes de registrar a interação
      const page = await aggregatorService.getById(pageUuid);
      if (
        page.visibility !== AggregatorPageVisibilityType.PUBLIC &&
        page.visibility !== AggregatorPageVisibilityType.PRIVATE
      ) {
        return;
      }

      setHasInteraction(true);
      await aggregatorService.updateMetricsInteraction(
        metricsUuidRef.current,
        true
      );
    }
  };

  useEffect(() => {
    const initializeTracking = async () => {
      // Verifica se já foi rastreado
      if (hasTrackedRef.current) return;

      try {
        // Verifica a visibilidade da página antes de iniciar o tracking
        const page = await aggregatorService.getById(pageUuid);
        if (
          page.visibility !== AggregatorPageVisibilityType.PUBLIC &&
          page.visibility !== AggregatorPageVisibilityType.PRIVATE
        ) {
          return;
        }

        hasTrackedRef.current = true;
        const source = detectTrafficSource();
        const location = await getLocation();

        const { uuid } = await aggregatorService.createMetrics({
          pageUuid,
          ...location,
          source,
          hasInteraction: false,
        });

        // Salva o UUID da métrica para uso posterior
        metricsUuidRef.current = uuid;

        // Adiciona listeners para interações
        const interactionEvents = [
          "click",
          "touchstart",
          "scroll",
          "mousemove",
          "keypress",
        ];

        const handleInteraction = () => {
          trackInteraction();
          // Remove os listeners após a primeira interação
          interactionEvents.forEach((event) => {
            document.removeEventListener(event, handleInteraction);
          });
        };

        interactionEvents.forEach((event) => {
          document.addEventListener(event, handleInteraction);
        });

        // Cleanup
        return () => {
          interactionEvents.forEach((event) => {
            document.removeEventListener(event, handleInteraction);
          });
        };
      } catch (error) {
        console.error("Erro ao inicializar tracking:", error);
      }
    };

    initializeTracking();
  }, [pageUuid]);

  return {
    hasInteraction,
  };
};

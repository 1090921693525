import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { routes, RouteDefinition } from "../../routes/routes";
import * as CleeckyKit from "../../CleeckyKit";
import { useCleeckyKitCurrentTheme } from "CleeckyKit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccountDropdown, AppToolsModal } from "components";
import { Z_INDEX } from "../../CleeckyKit";
import LogoSvg from "../../assets/images/cleecky-slogan-logo-black-minimalist-1.svg";
import LogoSvg2 from "../../assets/images/cleecky-slogan-logo-black-minimalist-2.svg";
import { useTranslation } from "react-i18next";

const AccountButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: ${Z_INDEX.buttons.fixed};
`;

const Container = styled(CleeckyKit.Container)`
  min-height: 100dvh;
`;

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { currentGradient } = useCleeckyKitCurrentTheme();
  const [selectedApp, setSelectedApp] = useState<RouteDefinition | null>(null);
  const [currentLogo, setCurrentLogo] = useState(LogoSvg);
  const { t } = useTranslation();

  const handleAppClick = (app: RouteDefinition) => {
    if (app.children?.length) {
      setSelectedApp(app);
    } else {
      navigate(app.path);
    }
  };

  const visibleRoutes = routes.filter((route) => route.showOnMenu);

  return (
    <Container>
      <CleeckyKit.Column padding="35dvh 0 0 0" gap="2rem" align="center">
        <CleeckyKit.Row justify="center" align="center">
          <CleeckyKit.Image
            src={currentLogo}
            height="10rem"
            fit="contain"
            alt="Cleecky Logo"
            format="svg"
            fill="white"
            onMouseDown={() => setCurrentLogo(LogoSvg2)}
            onMouseUp={() => setCurrentLogo(LogoSvg)}
            onMouseLeave={() => setCurrentLogo(LogoSvg)}
            animation={{
              initial: { opacity: 0, y: -20 },
              animate: { opacity: 1, y: 0 },
              transition: { duration: 0.5 },
            }}
          />
        </CleeckyKit.Row>

        <CleeckyKit.Section padding="0 0 5rem 0">
          <CleeckyKit.Row justify="center">
            <CleeckyKit.Grid
              mode="app"
              gap="2rem"
              justify="center"
              width="100%"
              columns={{ sm: 3, md: 4, lg: 5 }}>
              {visibleRoutes.map((route) => (
                <CleeckyKit.GridItem key={route.path}>
                  <CleeckyKit.Button
                    key={route.path}
                    format="square"
                    icon={<FontAwesomeIcon icon={route.icon} />}
                    text={route.name}
                    onClick={() => handleAppClick(route)}
                    variant="solid"
                    size="large"
                    borderRadius="1rem"
                    textPosition="outside-below"
                    animation={{
                      type: "scale",
                      trigger: "hover",
                    }}
                  />
                </CleeckyKit.GridItem>
              ))}
            </CleeckyKit.Grid>
          </CleeckyKit.Row>
        </CleeckyKit.Section>
      </CleeckyKit.Column>
      {selectedApp && (
        <AppToolsModal
          isOpen={!!selectedApp}
          onClose={() => setSelectedApp(null)}
          app={selectedApp}
        />
      )}
      <CleeckyKit.ThemeSwitcher />
      <CleeckyKit.LanguageSwitcher />
      <AccountButtonContainer>
        <CleeckyKit.Button
          onClick={() => navigate("/pricing")}
          text={t("project:pages.pricing.title")}
          variant="link"
          size="medium"
          borderRadius="1rem"
          textPosition="inside-right"
        />
        <AccountDropdown />
      </AccountButtonContainer>
    </Container>
  );
};

export default Home;

import React from "react";
import styled from "styled-components";
import * as CleeckyKit from "CleeckyKit";
import { useTranslation } from "react-i18next";

const StyledList = styled.ul`
  margin: 0.5rem 0;
  padding-left: 1.5rem;
`;

const StyledListItem = styled.li`
  margin: 0.25rem 0;
`;

export const LicenseTermsSection: React.FC = () => {
  const { t } = useTranslation();
  const { currentTheme, currentGradient } =
    CleeckyKit.useCleeckyKitCurrentTheme();

  return (
    <>
      <CleeckyKit.Accordion title="Termos de Licença de Uso da Cleecky">
        <CleeckyKit.AccordionGroup>
          <CleeckyKit.Accordion title="1. Introdução">
            <CleeckyKit.Text>
              A Cleecky desenvolve e disponibiliza diversos Produtos – incluindo
              seu Framework Frontend, APIs e ferramentas que geram páginas,
              formulários, landing pages, entre outros – com o objetivo de
              oferecer soluções inovadoras para criação e gestão de conteúdo
              digital. Estes Termos regulam:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                O acesso, uso e distribuição dos Produtos.
              </StyledListItem>
              <StyledListItem>
                A responsabilidade relativa ao conteúdo gerado pelos Usuários a
                partir das ferramentas disponibilizadas.
              </StyledListItem>
              <StyledListItem>
                As limitações e obrigações que norteiam a utilização dos nossos
                softwares e serviços.
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>
              Ao utilizar nossos Produtos, você declara que leu, compreendeu e
              concorda integralmente com estes Termos.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="2. Definições">
            <CleeckyKit.Text>
              Para os fins deste documento, os seguintes termos terão os
              significados abaixo atribuídos:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Produto:</strong> Qualquer software, API, framework,
                template, ferramenta ou recurso disponibilizado pela Cleecky.
              </StyledListItem>
              <StyledListItem>
                <strong>Licença:</strong> O direito não exclusivo,
                intransferível e revogável concedido ao Usuário para uso dos
                Produtos, conforme estes Termos.
              </StyledListItem>
              <StyledListItem>
                <strong>Usuário:</strong> Qualquer pessoa física ou jurídica que
                acesse ou utilize os Produtos da Cleecky.
              </StyledListItem>
              <StyledListItem>
                <strong>Conteúdo Gerado pelo Usuário:</strong> Qualquer dado,
                texto, imagem, vídeo, ou outro material que o Usuário venha a
                criar, modificar ou carregar utilizando os Produtos.
              </StyledListItem>
              <StyledListItem>
                <strong>Uso Permitido:</strong> A utilização dos Produtos
                exclusivamente de acordo com os termos aqui estabelecidos e para
                os fins legais e autorizados.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="3. Concessão de Licença">
            <CleeckyKit.Text>
              Sujeito à observância destes Termos, a Cleecky concede ao Usuário
              uma licença limitada, não exclusiva, intransferível e revogável
              para utilizar, instalar, executar e integrar os Produtos,
              exclusivamente para fins internos e de acordo com as finalidades
              previstas.
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Uso Comercial e Não Comercial:</strong> Salvo disposição
                em contrário, a licença permite tanto o uso comercial quanto o
                não comercial dos Produtos, desde que não infrinja direitos de
                terceiros.
              </StyledListItem>
              <StyledListItem>
                <strong>Integração:</strong> O Usuário pode integrar APIs,
                frameworks e templates em seus projetos, respeitando as
                restrições e condições previstas nestes Termos.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="4. Restrições de Uso">
            <CleeckyKit.Text>O Usuário concorda em não:</CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                <strong>Modificar ou Distribuir:</strong> Alterar, modificar,
                descompilar, fazer engenharia reversa, traduzir, vender,
                sublicenciar, alugar ou distribuir os Produtos, exceto quando
                expressamente permitido.
              </StyledListItem>
              <StyledListItem>
                <strong>Uso Indevido:</strong> Utilizar os Produtos para fins
                ilegais, imorais, fraudulentos ou que infrinjam direitos de
                terceiros.
              </StyledListItem>
              <StyledListItem>
                <strong>Conteúdo Gerado Indevido:</strong> Criar ou distribuir
                Conteúdo Gerado pelo Usuário que viole leis, regulamentos ou
                direitos de propriedade intelectual de terceiros.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="5. Propriedade Intelectual">
            <StyledList>
              <StyledListItem>
                <strong>Titularidade:</strong> Todos os direitos, títulos e
                interesses relativos aos Produtos (incluindo, sem limitação,
                direitos autorais, marcas, segredos comerciais e outras
                propriedades intelectuais) permanecem de propriedade exclusiva
                da Cleecky ou de seus licenciadores.
              </StyledListItem>
              <StyledListItem>
                <strong>Licença Restrita:</strong> Nada nestes Termos confere ao
                Usuário qualquer direito de propriedade sobre os Produtos,
                exceto a licença de uso limitada aqui concedida.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="6. Conteúdo Gerado pelo Usuário">
            <StyledList>
              <StyledListItem>
                <strong>Responsabilidade:</strong> Todo o Conteúdo Gerado pelo
                Usuário utilizando as ferramentas e templates da Cleecky é de
                exclusiva responsabilidade do Usuário. A Cleecky não endossa,
                controla ou verifica o conteúdo criado e não será
                responsabilizada por quaisquer consequências decorrentes do uso
                indevido desses conteúdos.
              </StyledListItem>
              <StyledListItem>
                <strong>Finalidade e Conformidade:</strong> O Usuário declara
                que utilizará o conteúdo gerado em conformidade com todas as
                leis aplicáveis, respeitando os direitos de terceiros. Qualquer
                uso que implique em danos, ofensas ou infrações legais será de
                inteira responsabilidade do Usuário.
              </StyledListItem>
              <StyledListItem>
                <strong>Remoção de Conteúdo:</strong> A Cleecky reserva-se o
                direito de, a seu exclusivo critério, remover ou bloquear o
                acesso a conteúdos que violem estes Termos ou que sejam
                considerados prejudiciais para a plataforma ou para terceiros.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="7. Garantias e Isenção de Responsabilidade">
            <StyledList>
              <StyledListItem>
                <strong>"As Is":</strong> Os Produtos são fornecidos "no estado
                em que se encontram", sem garantias de qualquer espécie,
                expressas ou implícitas, incluindo, mas não se limitando a
                garantias de comercialização, adequação a um determinado fim ou
                não violação.
              </StyledListItem>
              <StyledListItem>
                <strong>Limitação de Responsabilidade:</strong> Em nenhum caso a
                Cleecky será responsável por quaisquer danos diretos, indiretos,
                incidentais, especiais ou consequenciais decorrentes do uso ou
                da incapacidade de uso dos Produtos, mesmo que a Cleecky tenha
                sido avisada da possibilidade de tais danos.
              </StyledListItem>
              <StyledListItem>
                <strong>Uso sob Risco:</strong> O Usuário assume integral
                responsabilidade por qualquer modificação, integração ou
                adaptação dos Produtos em seus projetos.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="8. Limitação de Responsabilidade e Indenização">
            <StyledList>
              <StyledListItem>
                <strong>Limitação:</strong> A responsabilidade total da Cleecky,
                em qualquer hipótese, não excederá o valor pago pelo Usuário
                para obter o direito de uso dos Produtos.
              </StyledListItem>
              <StyledListItem>
                <strong>Indenização:</strong> O Usuário concorda em indenizar,
                defender e isentar a Cleecky, seus diretores, funcionários e
                parceiros de quaisquer reivindicações, danos, obrigações,
                perdas, responsabilidades, custos ou despesas decorrentes do uso
                indevido dos Produtos ou da violação destes Termos.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="9. Rescisão">
            <StyledList>
              <StyledListItem>
                <strong>Violação dos Termos:</strong> Em caso de descumprimento
                destes Termos, a Cleecky poderá, a seu exclusivo critério,
                rescindir imediatamente a licença concedida, sem prejuízo da
                adoção das medidas legais cabíveis.
              </StyledListItem>
              <StyledListItem>
                <strong>Obrigações Após Rescisão:</strong> Após a rescisão, o
                Usuário deverá cessar imediatamente o uso dos Produtos e
                destruir todas as cópias, seja em meio físico ou digital, de
                quaisquer materiais fornecidos sob esta licença.
              </StyledListItem>
            </StyledList>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="10. Alterações nos Termos">
            <CleeckyKit.Text>
              A Cleecky reserva-se o direito de modificar estes Termos a
              qualquer momento, mediante notificação aos Usuários. O uso
              contínuo dos Produtos após a publicação de alterações constitui a
              aceitação dos novos termos.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="11. Lei Aplicável e Jurisdição">
            <CleeckyKit.Text>
              Estes Termos serão regidos e interpretados de acordo com as leis
              da República Federativa do Brasil. Qualquer disputa decorrente
              destes Termos será submetida aos tribunais competentes da cidade
              de Marília, Estado de São Paulo.
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>

          <CleeckyKit.Accordion title="12. Contato">
            <CleeckyKit.Text>
              Para esclarecimentos, dúvidas ou solicitações referentes a estes
              Termos de Licença de Uso, entre em contato com:
            </CleeckyKit.Text>
            <StyledList>
              <StyledListItem>
                E-mail para assuntos legais: legal@cleecky.com
              </StyledListItem>
              <StyledListItem>
                E-mail para suporte: support@cleecky.com
              </StyledListItem>
              <StyledListItem>
                Endereço: Rua Gonçalves Jaquier, 25 - Jardim Ohara, Marília/SP,
                Brasil
              </StyledListItem>
            </StyledList>
            <CleeckyKit.Text>Data de Vigência: 16/02/2025</CleeckyKit.Text>
            <CleeckyKit.Text>
              Data da Última Atualização: 16/02/2025
            </CleeckyKit.Text>
          </CleeckyKit.Accordion>
        </CleeckyKit.AccordionGroup>
        <br />
        <CleeckyKit.Text>
          Estes Termos de Licença de Uso estabelecem as bases legais para o
          acesso, uso e integração dos Produtos da Cleecky, assim como delimitam
          a responsabilidade do Usuário quanto ao conteúdo que venha a ser
          gerado ou manipulado por meio das nossas ferramentas. Caso necessite
          de adaptações específicas ou tenha dúvidas adicionais, nossa equipe de
          suporte está à disposição para auxiliá-lo.
        </CleeckyKit.Text>
      </CleeckyKit.Accordion>
    </>
  );
};

export default LicenseTermsSection;

import api from "axiosConfig";
import { AggregatorPage, ProfilePage, UnderConstructionPage } from "pages";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingScreen, MainLayout } from "components";
import { ResolvedPageData } from "cleecky@types/interfaces";
import { RouteType } from "cleecky@types/enums";
import { AggregatorPageVisibilityType } from "cleecky@types/enums";

interface DynamicPageResolverProps {
  routeId: string;
  hideNavigation?: boolean;
}

const DynamicPageResolver: React.FC<DynamicPageResolverProps> = () => {
  const { username, slug } = useParams();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<ResolvedPageData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const resolvePage = async () => {
      if (username?.startsWith("@")) {
        try {
          const cleanUsername = username.startsWith("@")
            ? username.slice(1)
            : username;

          // Verifica se é uma requisição do Puppeteer
          const isPuppeteer = document.cookie.includes(
            "x-puppeteer-generate=true"
          );

          // Se for Puppeteer, pega os tokens dos cookies
          const headers: Record<string, string> = {};
          if (isPuppeteer) {
            const getCookieValue = (name: string) => {
              const match = document.cookie.match(
                new RegExp(`(^| )${name}=([^;]+)`)
              );
              return match ? match[2] : "";
            };

            headers["X-Puppeteer-Generate"] = "true";
            headers["X-Puppeteer-Token"] = getCookieValue("x-puppeteer-token");
            headers["X-Puppeteer-Timestamp"] = getCookieValue(
              "x-puppeteer-timestamp"
            );
          }

          const resolution = await api.get(
            slug
              ? `/shared/route-resolver/${cleanUsername}/${slug}`
              : `/shared/route-resolver/${cleanUsername}`,
            { headers }
          );

          if (resolution.data.type === "NOT_FOUND") {
            navigate("/404");
            return;
          }

          if (resolution.data.type === "RESERVED") {
            navigate(resolution.data.destination);
            return;
          }

          try {
            const response = await api.get(resolution.data.endpoint, {
              headers,
            });
            setPageData({
              type: resolution.data.type,
              data: response.data,
            });
          } catch (error: any) {
            // Se for erro 403 (Forbidden) e for uma página do agregador
            if (
              error.response?.status === 403 &&
              resolution.data.type === "AGGREGATOR"
            ) {
              // Verifica o tipo de visibilidade da página
              const pageVisibility = error.response.data?.visibility;

              // Se for uma página não publicada, redireciona para 404
              if (pageVisibility === AggregatorPageVisibilityType.UNPUBLISHED) {
                navigate("/404");
                return;
              }

              // Se for uma página privada, redireciona para a página de autenticação
              if (pageVisibility === AggregatorPageVisibilityType.PRIVATE) {
                navigate(`/@${cleanUsername}/${slug}/auth`, {
                  state: { pageUuid: resolution.data.uuid },
                });
                return;
              }
            }

            // Para outros erros, redireciona para 404
            navigate("/404");
          }
        } catch (error) {
          console.error("Erro ao resolver rota:", error);
          navigate("/404");
        } finally {
          setLoading(false);
        }
      }

      return;
    };

    resolvePage();
  }, [username, slug, navigate]);

  if (loading) {
    return (
      <MainLayout hideNavigation>
        <LoadingScreen />
      </MainLayout>
    );
  }

  if (!pageData) {
    return null;
  }

  switch (pageData.type) {
    case "AGGREGATOR":
      return <AggregatorPage data={pageData.data} />;
    case RouteType.PROFILE:
      /* return <ProfilePage data={pageData.data} />; */
      return <UnderConstructionPage />;
    default:
      return null;
  }
};

export default DynamicPageResolver;

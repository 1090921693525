import { useState, useEffect } from "react";
import { CleeckyKitTheme } from "../themes/CleeckyKitTheme";
import { CleeckyKitGradientTheme } from "../themes/CleeckyKitGradientTheme";
import { interfaceThemes } from "../../themes";

interface ThemeConfig {
  interfaceTheme: CleeckyKitTheme;
  gradientTheme?: CleeckyKitGradientTheme;
}

export const useCleeckyKitTheme = (
  initialInterface?: CleeckyKitTheme,
  initialGradient?: CleeckyKitGradientTheme
) => {
  const defaultTheme = interfaceThemes.lightTheme;

  const [themeConfig, setThemeConfig] = useState<ThemeConfig>({
    interfaceTheme: initialInterface || defaultTheme,
    gradientTheme: initialGradient,
  });

  const setInterfaceTheme = (newTheme: CleeckyKitTheme) => {
    setThemeConfig((prev) => ({
      ...prev,
      interfaceTheme: newTheme,
    }));
  };

  const setGradientTheme = (newGradient?: CleeckyKitGradientTheme) => {
    setThemeConfig((prev) => ({
      ...prev,
      gradientTheme: newGradient,
    }));
  };

  return {
    currentTheme: {
      ...themeConfig.interfaceTheme,
      gradient: themeConfig.gradientTheme,
    },
    setInterfaceTheme,
    setGradientTheme,
  };
};

import React from "react";
import styled from "styled-components";

interface AvatarProps {
  icon?: React.ReactNode;
  size?: "small" | "medium" | "large";
  className?: string;
}

const StyledAvatar = styled.div<{ $size: "small" | "medium" | "large" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background.input};
  color: ${({ theme }) => theme.colors.text.secondary};
  width: ${({ $size }) =>
    $size === "small" ? "32px" : $size === "medium" ? "48px" : "64px"};
  height: ${({ $size }) =>
    $size === "small" ? "32px" : $size === "medium" ? "48px" : "64px"};
  aspect-ratio: 1;
  flex-shrink: 0;

  svg {
    width: ${({ $size }) =>
      $size === "small" ? "16px" : $size === "medium" ? "24px" : "32px"};
    height: ${({ $size }) =>
      $size === "small" ? "16px" : $size === "medium" ? "24px" : "32px"};
    aspect-ratio: 1;
  }
`;

export const CleeckyKitAvatar: React.FC<AvatarProps> = ({
  icon,
  size = "medium",
  className,
}) => {
  return (
    <StyledAvatar $size={size} className={className}>
      {icon}
    </StyledAvatar>
  );
};

CleeckyKitAvatar.displayName = "CleeckyKitAvatar";

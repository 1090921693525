import React, { useState, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Z_INDEX } from "CleeckyKit/themes/zindex";
import { CleeckyKitSidebarHamburger } from "./CleeckyKitSidebarHamburger";
import { useCleeckyKitOutsideClick } from "CleeckyKit/hooks/useCleeckyKitOutsideClick";

interface SidebarProps {
  children: React.ReactNode;
  position?: "left" | "right";
  isExpandable?: boolean;
  defaultExpanded?: boolean;
  width?: string;
  minWidth?: string;
  showIconsWhenCollapsed?: boolean;
  onExpandChange?: (expanded: boolean) => void;
  isMobileOpen?: boolean;
  onMobileOpenChange?: (isOpen: boolean) => void;
  customStyle?: React.CSSProperties;
  shouldCollapseOnOutsideClick?: boolean;
  hamburgerRef?: React.RefObject<HTMLButtonElement>;
}

const SidebarContainer = styled(motion.nav)<{
  $position: "left" | "right";
  $width: string;
  $minWidth: string;
  $isMobile: boolean;
}>`
  position: fixed;
  top: 0;
  ${({ $position }) => $position}: 0;
  height: 100dvh;
  z-index: ${Z_INDEX.sidebar.container};
  display: flex;
  flex-direction: column;
  width: ${({ $minWidth }) => $minWidth};
  transform: translateX(0);
  transition: width 0.3s ease, transform 0.3s ease;
  box-shadow: ${({ theme }) => theme.shadows.navbar};

  &[data-expanded="true"] {
    width: ${({ $width }) => $width};
  }

  @media (max-width: ${({ theme }) => theme.responsive.breakpoints.md}) {
    width: ${({ $width }) => $width} !important;
    transform: translateX(
      ${({ $position, $isMobile }) =>
        $position === "left"
          ? $isMobile
            ? "0"
            : "-100%"
          : $isMobile
          ? "0"
          : "100%"}
    );
  }
`;

const SidebarBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.background.base};
  z-index: -2;
`;

const SidebarGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) =>
    theme.gradient.backgroundImage ||
    theme.colors.background.navbar.background};
  box-shadow: ${({ theme }) => theme.shadows.navbar};
  z-index: -1;
`;

const ToggleButton = styled(motion.button)<{ $position: "left" | "right" }>`
  position: absolute;
  bottom: 10%;
  ${({ $position }) => ($position === "left" ? "right" : "left")}: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.interactive.clickable};
  color: ${({ theme }) => theme.colors.text.onPrimary};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${({ theme }) =>
      theme.gradient.highlightColor || theme.colors.interactive.primaryHover};
  }
`;

const Content = styled.div<{ $showIcons: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  opacity: ${({ $showIcons }) => ($showIcons ? 1 : 0)};
  transition: opacity 0.3s ease;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  /*  
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.interactive.clickable};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.interactive.primaryHover};
  } */
`;

export const CleeckyKitSidebar: React.FC<SidebarProps> = ({
  children,
  position = "left",
  isExpandable = true,
  defaultExpanded = true,
  width = "240px",
  minWidth = "80px",
  showIconsWhenCollapsed = true,
  onExpandChange,
  isMobileOpen = false,
  onMobileOpenChange,
  customStyle,
  shouldCollapseOnOutsideClick = true,
  hamburgerRef,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const isMobile = window.innerWidth <= 768;
  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 768;
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    if (isMobile || isTablet) {
      onMobileOpenChange?.(!isMobileOpen);
    } else {
      const newState = !isExpanded;
      setIsExpanded(newState);
      onExpandChange?.(newState);
    }
  };

  useCleeckyKitOutsideClick(sidebarRef, (event: MouseEvent) => {
    if (shouldCollapseOnOutsideClick) {
      if (isMobile || isTablet) {
        if (isMobileOpen) {
          const isHamburgerClick = hamburgerRef?.current?.contains(
            event?.target as Node
          );
          if (!isHamburgerClick) {
            onMobileOpenChange?.(false);
          }
        }
      } else if (isExpanded) {
        setIsExpanded(false);
        onExpandChange?.(false);
      }
    }
  });

  return (
    <>
      <SidebarContainer
        ref={sidebarRef}
        $position={position}
        $width={width}
        $minWidth={minWidth}
        $isMobile={isMobileOpen}
        initial={false}
        data-expanded={isExpanded}
        style={customStyle}
        animate={{
          width: isExpanded ? width : showIconsWhenCollapsed ? minWidth : "0px",
        }}>
        <SidebarBackground />
        <SidebarGradient />
        {isExpandable && !isMobile && !isTablet && (
          <ToggleButton
            $position={position}
            onClick={handleToggle}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}>
            <FontAwesomeIcon
              icon={position === "left" ? faChevronLeft : faChevronRight}
              style={{
                transform: !isExpanded ? "rotate(180deg)" : "none",
                transition: "transform 0.3s ease",
              }}
            />
          </ToggleButton>
        )}
        <Content $showIcons={isExpanded || showIconsWhenCollapsed}>
          {children}
        </Content>
      </SidebarContainer>
    </>
  );
};
